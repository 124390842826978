import BaseModel from './BaseModel';

/**
 * Represents a Company in the system.
 * @extends BaseModel
 */
class Company extends BaseModel {
  /** @type {string} The name of the database table for Companies */
  static table = 'companies';

  /** @type {string} The SQL query to select Company data with related information */
  static selectQuery = `
    *,
    user_profiles!account_manager (
      id,
      first_name,
      last_name,
      full_name,
      profile_photo
    ),
    status:core_entity_types!status (id, type),
    term_type:core_entity_types!term_type (id, type),
    credit_contract:documents(id, file_name)
  `;

  /**
   * Creates a Company instance from database format data
   * @param {Object} data - The database format company data
   * @returns {Company} A new Company instance
   */
  static fromDatabase(data = {}) {
    const company = new Company();
    company.id = data.id;
    company.createdDate = data.created_date;
    company.updatedDate = data.updated_date;
    company.name = data.name;
    company.accountManager = data.user_profiles
      ? {
          id: data.user_profiles.id,
          firstName: data.user_profiles.first_name,
          lastName: data.user_profiles.last_name,
          fullName: data.user_profiles.full_name,
          profilePhoto: data.user_profiles.profile_photo
        }
      : null;
    company.onAccount = data.on_account || false;
    company.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    company.capsuleId = data.capsule_id;
    company.accountNumber = data.account_number;
    company.terms = {
      days: data.term_days || null,
      type: data.term_type
        ? { id: data.term_type.id, type: data.term_type.type }
        : null
    };
    company.creditContract = data.credit_contract || null;
    company.levy = data.levy;
    company.tradex = data.tradex || false;
    company.tradexOption = data.tradex_option;
    company.lastBooked = data.last_booked;
    company.links = data.links;
    company.accountComment = data.account_comment;
    return company;
  }

  /**
   * Creates an instance of Company.
   * @param {Object} data - The company data.
   */
  constructor(data = {}) {
    super(data);
    this.name = data.name ?? this.name ?? null;
    this.accountManager = data.accountManager ?? this.accountManager ?? null;
    this.onAccount = data.onAccount ?? this.onAccount ?? false;
    this.status = data.status ?? this.status ?? null;
    this.capsuleId = data.capsuleId ?? this.capsuleId ?? null;
    this.accountNumber = data.accountNumber ?? this.accountNumber ?? null;
    this.terms = data.terms ??
      this.terms ?? {
        days: null,
        type: null
      };
    this.creditContract = data.creditContract ?? this.creditContract ?? null;
    this.levy = data.levy ?? this.levy ?? null;
    this.tradex = data.tradex ?? this.tradex ?? false;
    this.tradexOption = data.tradexOption ?? this.tradexOption ?? null;
    this.lastBooked = data.lastBooked ?? this.lastBooked ?? null;
    this.links = data.links ?? this.links ?? null;
    this.accountComment = data.accountComment ?? this.accountComment ?? null;
  }

  /**
   * Converts the Company instance to a database-friendly format.
   * @returns {Object} The company data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.name,
      account_manager: this.accountManager ? this.accountManager.id : null,
      on_account: this.onAccount,
      status: this.status ? this.status.id : null,
      capsule_id: this.capsuleId,
      account_number: this.accountNumber,
      term_days: this.terms.days,
      term_type: this.terms.type ? this.terms.type.id : null,
      credit_contract: this.creditContract ? this.creditContract.id : null,
      levy: this.levy,
      tradex: this.tradex,
      tradex_option: this.tradexOption,
      last_booked: this.lastBooked,
      links: this.links,
      account_comment: this.accountComment
    };
  }
}

export default Company;
