import BaseModel from './BaseModel';

/**
 * Represents a BookingSpace in the system.
 * @extends BaseModel
 */
class BookingSpace extends BaseModel {
  /** @type {string} The name of the database table for BookingSpaces */
  static table = 'booking_spaces';

  static selectQuery = `*,
    booking:bookings!inner(id, company_id, company:companies!inner(id, name), course_id, course:courses!inner(id,name), booking_date),
    delegate:user_profiles(id, display_id, first_name, last_name, full_name),
    status:core_entity_types!booking_space_status_fkey(id, type),
    levy_status:core_entity_types!booking_space_levy_status_fkey(id, type)`;

  /**
   * Creates a BookingSpace instance from database format data
   * @param {Object} data - The database format booking space data
   * @returns {BookingSpace} A new BookingSpace instance
   */
  static fromDatabase(data = {}) {
    const bookingSpace = new BookingSpace();
    bookingSpace.id = data.id;
    bookingSpace.createdDate = data.created_date;
    bookingSpace.updatedDate = data.updated_date;
    bookingSpace.booking = data.booking
      ? {
          id: data.booking.id,
          company: {
            id: data.booking.company?.id,
            name: data.booking.company?.name
          },
          course: {
            id: data.booking.course?.id,
            name: data.booking.course?.name
          }
        }
      : null;
    bookingSpace.companyId = data.booking ? data.booking.company_id : null;
    bookingSpace.delegate = data.delegate
      ? {
          id: data.delegate.id,
          displayId: data.delegate.display_id,
          firstName: data.delegate.first_name,
          lastName: data.delegate.last_name,
          fullName: data.delegate.full_name
        }
      : null;
    bookingSpace.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    bookingSpace.levyStatus = data.levy_status
      ? { id: data.levy_status.id, name: data.levy_status.type }
      : null;
    bookingSpace.levy = data.levy;
    bookingSpace.completionDate = data.completion_date || null;
    bookingSpace.expiryDate = data.expiry_date || null;
    return bookingSpace;
  }

  /**
   * Creates an instance of BookingSpace.
   * @param {Object} data - The booking space data.
   */
  constructor(data = {}) {
    super(data);
    this.booking = data.booking ?? this.booking ?? null;
    this.companyId = data.companyId ?? this.companyId ?? null;
    this.delegate = data.delegate ?? this.delegate ?? null;
    this.status = data.status ?? this.status ?? null;
    this.levyStatus = data.levyStatus ?? this.levyStatus ?? null;
    this.levy = data.levy ?? this.levy ?? null;
    this.completionDate = data.completionDate ?? this.completionDate ?? null;
    this.expiryDate = data.expiryDate ?? this.expiryDate ?? null;
  }

  /**
   * Converts the BookingSpace instance to a database-friendly format.
   * @returns {Object} The booking space data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      booking_id: this.booking ? this.booking.id : null,
      delegate_id: this.delegate ? this.delegate.id : null,
      status: this.status ? this.status.id : null,
      levy_status: this.levyStatus ? this.levyStatus.id : null,
      levy: this.levy,
      completion_date: this.completionDate,
      expiry_date: this.expiryDate
    };
  }

  /**
   * Override the getAll method from BaseModel to handle delegate name filtering.
   * @param {Object} filters - The filters to apply.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @param {string} sortBy - The field to sort by.
   * @param {string} sortType - The sort type ('asc' or 'desc').
   * @returns {Promise<Object>} A promise that resolves to an object containing the data and pagination info.
   */
  static async getAll(
    filters = {},
    page = 1,
    size = 10,
    sortBy = '',
    sortType = 'asc'
  ) {
    const delegateNameFields = [
      'first_name',
      'last_name',
      'full_name',
      'delegate.first_name',
      'delegate.last_name',
      'delegate.full_name',
      'user_profiles.full_name',
      'user_profiles.first_name',
      'user_profiles.last_name'
    ];
    const hasNameFilter = delegateNameFields.some(
      field =>
        filters[field] ||
        (filters.delegate && filters.delegate[field.split('.')[1]])
    );

    if (hasNameFilter) {
      filters.delegate = {
        operator: 'not',
        innerOperator: 'is',
        value: null
      };
    }

    return super.getAll(filters, page, size, sortBy, sortType);
  }
}

export default BookingSpace;
