import BaseModel from './BaseModel';
import { supabase } from '../utilities/supabase';

/**
 * Represents a Course in the system.
 * @extends BaseModel
 */
class Course extends BaseModel {
  /** @type {string} The name of the database table for Courses */
  static table = 'courses';

  /** @type {string} The SQL query to select Course data with related information */
  static selectQuery = `
    *,
    status:core_entity_types!status (id, type),
    accreditation:core_entity_types!accreditation (id, type)
  `;

  /**
   * Creates a Course instance from database format data
   * @param {Object} data - The database format course data
   * @returns {Course} A new Course instance
   */
  static fromDatabase(data = {}) {
    const course = new Course();
    course.id = data.id;
    course.createdDate = data.created_date;
    course.updatedDate = data.updated_date;
    course.name = data.name;
    course.attendance = data.attendance;
    course.objectives = data.objectives;
    course.content = data.content;
    course.preReqs = data.pre_reqs;
    course.entryReqs = data.entry_reqs;
    course.passReqs = data.pass_reqs;
    course.furtherDev = data.further_dev;
    course.grant = data.grant;
    course.accreditation = data.accreditation
      ? { id: data.accreditation.id, type: data.accreditation.type }
      : null;
    course.industries = data.industries || [];
    course.websiteDetails = data.website_details || {
      visible: false,
      header_image: ''
    };
    course.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    return course;
  }

  /**
   * Creates an instance of Course.
   * @param {Object} data - The course data.
   */
  constructor(data = {}) {
    super(data);
    this.name = data.name ?? this.name ?? null;
    this.attendance = data.attendance ?? this.attendance ?? null;
    this.objectives = data.objectives ?? this.objectives ?? null;
    this.content = data.content ?? this.content ?? null;
    this.preReqs = data.preReqs ?? this.preReqs ?? null;
    this.entryReqs = data.entryReqs ?? this.entryReqs ?? null;
    this.passReqs = data.passReqs ?? this.passReqs ?? null;
    this.furtherDev = data.furtherDev ?? this.furtherDev ?? null;
    this.grant = data.grant ?? this.grant ?? false;
    this.accreditation = data.accreditation ?? this.accreditation ?? null;
    this.industries = data.industries ?? this.industries ?? [];
    this.websiteDetails = data.websiteDetails ?? this.websiteDetails ?? null;
    this.status = data.status ?? this.status ?? null;
  }

  /**
   * Converts the Course instance to a database-friendly format.
   * @returns {Object} The course data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.name,
      attendance: this.attendance,
      objectives: this.objectives,
      content: this.content,
      pre_reqs: this.preReqs,
      entry_reqs: this.entryReqs,
      pass_reqs: this.passReqs,
      further_dev: this.furtherDev,
      grant: this.grant,
      accreditation: this.accreditation.id,
      industries: this.industries,
      website_details: this.websiteDetails,
      status: this.status ? this.status.id : null
    };
  }

  /**
   * Get all courses by trainer ID using a specific function with filtering, sorting, and pagination.
   * @param {Object} filters - The filters to apply.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @param {string} sortBy - The field to sort by.
   * @param {string} sortType - The sort type ('asc' or 'desc').
   * @returns {Promise<Object>} A promise that resolves to an object containing the data and pagination info.
   */
  static async getAllByFunction(
    filters = {},
    page = 1,
    size = 10,
    sortBy = 'id',
    sortType = 'asc'
  ) {
    try {
      const { trainerId, ...remainingFilters } = filters;

      let query = supabase.rpc(
        'get_courses_by_trainer',
        {
          p_trainer_id: trainerId
        },
        { count: 'exact' }
      );

      query = this.filters(query, remainingFilters);
      query = this.sorting(query, sortBy, sortType);
      query = this.range(query, page, size);

      const { data, error, count } = await query.select(this.selectQuery);

      if (error) throw error;

      return {
        data: data.map(item => Course.fromDatabase(item)),
        count: count,
        page: page,
        size: size,
        total: Math.ceil(count / size)
      };
    } catch (error) {
      console.error(`[Error] Fetching All by Function ${this.name}: `, error);
      throw error;
    }
  }
}

export default Course;
