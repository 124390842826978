import React, { useState, useEffect } from 'react';
import { supabase } from '../../../utilities/supabase';
import UserProfile from '../../../models/UserProfile';
import DOMPurify from 'dompurify';
import Accordion from '../../entity/Accordion';
import {
  FilledButton,
  OutlineButton,
  NotificationItem,
  Input,
  MultiSelectBox
} from 'core';
import { FilterOperatorEnum } from '../../../utilities/Enumerables';
import { LoaderCircle } from 'lucide-react';

const StageTwo = ({ selectedTemplate, onBack, onClose, entity }) => {
  const [mergeTags, setMergeTags] = useState({});
  const [to, setTo] = useState([]);
  const [from, setFrom] = useState('noreply@bohsa.co.uk');
  const [subject, setSubject] = useState('');
  const [errors, setErrors] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationConfig, setNotificationConfig] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [databaseEntity, setDatabaseEntity] = useState({});

  const extractMergeTags = html => {
    const regex = /\{\{(.*?)\}\}/g;
    const matches = [...html.matchAll(regex)];
    const tags = matches.map(match => match[1].trim());
    return tags;
  };

  const formatDateValue = value => {
    if (!value) return '';
    if (typeof value === 'string' && value.includes('-')) {
      const date = new Date(value);
      return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      });
    }
    return value;
  };

  useEffect(() => {
    const tags = extractMergeTags(selectedTemplate.html);
    const reducedTags = tags.reduce((acc, tag) => {
      let value = '';
      if (entity && typeof entity.toDatabase === 'function') {
        value = entity.toDatabase()[tag] || '';
        // Format date values
        if (tag.toLowerCase().includes('date')) {
          value = formatDateValue(value);
        }
      }
      return { ...acc, [tag]: value };
    }, {});
    setMergeTags(reducedTags);
    setSubject(selectedTemplate.subject);

    // Convert entity to database format
    if (entity && typeof entity.toDatabase === 'function') {
      setDatabaseEntity(entity.toDatabase());
    }
  }, [selectedTemplate, entity]);

  const handleMergeTagChange = (tag, value) => {
    setMergeTags(prev => ({ ...prev, [tag]: value }));
  };

  const renderPreview = () => {
    let preview = selectedTemplate.html;
    Object.entries(mergeTags).forEach(([tag, value]) => {
      preview = preview.replace(
        new RegExp(`\\{\\{\\s*${tag}\\s*\\}\\}`, 'g'),
        value
      );
    });
    return DOMPurify.sanitize(preview);
  };

  const loadUserOptions = async inputValue => {
    try {
      const { data } = await UserProfile.getAll(
        { email: { value: inputValue, operator: FilterOperatorEnum.ILIKE } },
        1,
        10
      );
      return data.map(user => ({
        value: user.email,
        label: `${user.firstName} ${user.lastName} (${user.email})`
      }));
    } catch (error) {
      console.error('Error loading user options:', error);
      return [];
    }
  };

  const validateField = (field, value) => {
    let error = '';
    switch (field) {
      case 'to':
        if (value.length === 0) {
          error = 'Please specify at least one recipient';
        } else {
          for (const recipient of value) {
            if (!/\S+@\S+\.\S+/.test(recipient.value)) {
              error = 'Invalid email format for one or more recipients';
              break;
            }
          }
        }
        break;
      case 'from':
        if (!value.trim()) {
          error = 'From email is required';
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = 'Invalid email format';
        }
        break;
      case 'subject':
        if (!value.trim()) {
          error = 'Subject is required';
        }
        break;
      default:
        break;
    }
    setErrors(prev => ({ ...prev, [field]: error }));
    return !error;
  };

  const handleToChange = newTo => {
    setTo(newTo);
    validateField('to', newTo);
  };

  const handleFromChange = e => {
    const newFrom = e.target.value;
    setFrom(newFrom);
    validateField('from', newFrom);
  };

  const handleSubjectChange = e => {
    const newSubject = e.target.value;
    setSubject(newSubject);
    validateField('subject', newSubject);
  };

  const sendEmail = async () => {
    setIsSending(true);
    const isToValid = validateField('to', to);
    const isFromValid = validateField('from', from);
    const isSubjectValid = validateField('subject', subject);

    if (!isToValid || !isFromValid || !isSubjectValid) {
      setNotificationConfig({
        type: 'error',
        title: 'Form Validation Error',
        description: 'Please check and complete all required fields.'
      });
      setShowNotification(true);
      setIsSending(false);
      return;
    }

    try {
      const { error } = await supabase.functions.invoke(
        'integrations/add-ai/send-email',
        {
          body: {
            to: to.map(recipient => recipient.value).join(', '),
            from: {
              email: from
            },
            data: Object.fromEntries(
              Object.entries(mergeTags).map(([key, value]) => [key, value])
            ),
            templateId: selectedTemplate.id
          }
        }
      );

      if (error) throw error;

      setNotificationConfig({
        type: 'success',
        title: 'Email Sent',
        description: 'Your email has been sent successfully.'
      });
      setShowNotification(true);

      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error sending email:', error);
      setErrors(prev => ({
        ...prev,
        general: 'Failed to send email. Please try again.'
      }));
    } finally {
      setIsSending(false);
    }
  };

  const accordionItems = [
    {
      title: 'Email Details',
      content: (
        <>
          <div className='w-full mb-4'>
            <MultiSelectBox
              label='To'
              loadOptions={loadUserOptions}
              selectedOptions={to}
              onChange={handleToChange}
              placeholder='Enter email or search for user'
              allowCreate={true}
              error={errors.to}
            />
          </div>
          <Input
            label='From'
            value={from}
            onChange={handleFromChange}
            className='mb-4'
            error={errors.from}
          />
          <Input
            label='Subject'
            value={subject}
            onChange={handleSubjectChange}
            error={errors.subject}
          />
        </>
      )
    },
    {
      title: 'Merge Tags',
      content: (
        <>
          {Object.entries(mergeTags).map(([tag, value]) => (
            <div key={tag} className='mb-4'>
              <Input
                label={tag}
                id={tag}
                value={value}
                onChange={e => handleMergeTagChange(tag, e.target.value)}
              />
            </div>
          ))}
        </>
      )
    }
  ];

  return (
    <div className='flex flex-col'>
      {showNotification && (
        <NotificationItem
          type={notificationConfig.type}
          title={notificationConfig.title}
          description={notificationConfig.description}
          onClose={() => setShowNotification(false)}
          offset={{ top: 20, right: 20 }}
        />
      )}
      <div className='flex'>
        <div className='w-1/3 pr-1 flex flex-col'>
          <h3 className='font-semibold mb-4 text-lg'>Content</h3>
          <div>
            <Accordion
              items={accordionItems}
              showTopBorder={false}
              singleOpen={true}
            />
          </div>
        </div>
        <div className='w-2/3 pl-4 flex flex-col'>
          <h3 className='font-semibold mb-4 text-lg'>Preview</h3>
          <div className='border p-4 rounded-lg bg-white shadow-inner'>
            <div dangerouslySetInnerHTML={{ __html: renderPreview() }} />
          </div>
        </div>
      </div>
      {errors.general && (
        <p className='text-danger-600 mt-4'>{errors.general}</p>
      )}
      <div className='mt-8 flex justify-end'>
        <OutlineButton colour='base' onClick={onBack} className='mr-4'>
          Back
        </OutlineButton>
        <FilledButton
          colour='primary'
          onClick={sendEmail}
          disabled={isSending}
          leftIcon={
            isSending ? <LoaderCircle className='animate-spin' /> : null
          }
        >
          {isSending ? 'Sending...' : 'Send Email'}
        </FilledButton>
      </div>
    </div>
  );
};

export default StageTwo;
