import {
  User as UserIcon,
  Mail,
  Phone,
  Plus,
  Trash,
  Building,
  Link,
  Key
} from 'lucide-react';
import { TextButton, Modal, Input, DropdownInput, Field, Alert } from 'core';
import { useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import Entity from '../../../components/entity/Entity';
import UserProfile from '../../../models/UserProfile';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import DateCard from '../../../components/entity/DateCard';
import UserBookings from './tabs/UserBookings';
import UserSpaces from './tabs/UserSpaces';
import Company from '../../../models/Company';
import { UserProfileContext } from '../../../App';
import { PERMISSIONS } from '../../../utilities/Permissions';

const UserDetails = () => {
  const { id } = useParams();
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [editingPhone, setEditingPhone] = useState(null);
  const [phoneType, setPhoneType] = useState('mobile');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authId, setAuthId] = useState('');
  const [showWarningModal, setShowWarningModal] = useState(false);

  const { userProfile: currentUser } = useContext(UserProfileContext);

  if (
    !currentUser.hasPermission(
      EntityTypeEnum.User,
      PERMISSIONS[EntityTypeEnum.User].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view users.'
        style='danger'
      />
    );
  }

  const userCanEdit = currentUser.hasPermission(
    EntityTypeEnum.User,
    PERMISSIONS[EntityTypeEnum.User].EDIT
  );

  const userCanDelete = currentUser.hasPermission(
    EntityTypeEnum.User,
    PERMISSIONS[EntityTypeEnum.User].DELETE
  );

  const infoItems = [
    {
      icon: UserIcon,
      label: 'First Name',
      field: 'firstName',
      isEditable: userCanEdit
    },
    {
      icon: UserIcon,
      label: 'Last Name',
      field: 'lastName',
      isEditable: userCanEdit
    },
    { icon: Mail, label: 'Email', field: 'email', isEditable: userCanEdit },
    {
      icon: Building,
      label: 'Company',
      editField: 'companyId',
      displayField: 'company.name',
      dataType: 'search',
      loadOptions: async query => {
        const { data: companies } = await Company.getAll({
          fts: {
            operator: FilterOperatorEnum.TEXTSEARCH,
            value: query
          }
        });
        return companies.map(company => ({
          value: company.id,
          label: company.name
        }));
      },
      isEditable: userCanEdit
    }
  ];

  const handleEditPhone = (phone, index) => {
    setEditingPhone(index);
    setPhoneType(phone.type);
    setPhoneNumber(phone.number);
    setShowPhoneModal(true);
  };

  const handleAddPhone = () => {
    setEditingPhone(null);
    setPhoneType('mobile');
    setPhoneNumber('');
    setShowPhoneModal(true);
  };

  const handleAuthLinkEdit = () => {
    setAuthId('');
    setShowAuthModal(true);
  };

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data, onUpdate }) => (
        <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
          <DateCard
            date={data.createdDate}
            label='Created Date'
            isEditable={false}
          />
          <DateCard
            date={data.updatedDate}
            label='Updated Date'
            isEditable={false}
          />
          <DateCard
            date={data.metrics.last_chase}
            label='Last Chase'
            isEditable={userCanEdit}
            editField='metrics.last_chase'
            onUpdate={(field, value) =>
              onUpdate('metrics', { ...data.metrics, last_chase: value })
            }
          />
          <DateCard
            date={data.metrics.next_chase}
            label='Next Chase'
            isEditable={userCanEdit}
            editField='metrics.next_chase'
            onUpdate={(field, value) =>
              onUpdate('metrics', { ...data.metrics, next_chase: value })
            }
          />
        </div>
      )
    },
    {
      title: 'Contact Details',
      content: ({ data, onUpdate }) => (
        <div>
          {userCanEdit && (
            <TextButton
              leftIcon={<Plus size={16} />}
              colour='primary'
              onClick={handleAddPhone}
            >
              Add Phone
            </TextButton>
          )}

          {data.phones &&
            data.phones.map((phone, index) => (
              <div key={index} className='flex items-center gap-x-2 mt-2'>
                <div className='flex-1 flex items-center gap-x-2 p-1 rounded '>
                  <Phone size={16} className='text-primary-600' />
                  <div>
                    <div className='text-sm font-medium text-primary-900'>
                      {phone.type.charAt(0).toUpperCase() + phone.type.slice(1)}
                    </div>
                    <div className='text-sm text-primary-600'>
                      {phone.number}
                    </div>
                  </div>
                </div>
                {userCanEdit && (
                  <TextButton
                    colour='primary'
                    onClick={() => handleEditPhone(phone, index)}
                  >
                    Edit
                  </TextButton>
                )}
                {userCanDelete && (
                  <TextButton
                    colour='danger'
                    onClick={() => {
                      const updatedPhones = data.phones.filter(
                        (_, i) => i !== index
                      );
                      onUpdate('phones', updatedPhones);
                    }}
                  >
                    <Trash size={16} />
                  </TextButton>
                )}
              </div>
            ))}

          <Modal
            isOpen={showPhoneModal}
            onClose={() => setShowPhoneModal(false)}
            title={editingPhone !== null ? 'Edit Phone' : 'Add Phone'}
            actions={[
              {
                label: 'Save',
                onClick: () => {
                  if (editingPhone !== null) {
                    const updatedPhones = [...(data.phones || [])];
                    updatedPhones[editingPhone] = {
                      type: phoneType,
                      number: phoneNumber
                    };
                    onUpdate('phones', updatedPhones);
                  } else {
                    const updatedPhones = [
                      ...(data.phones || []),
                      { type: phoneType, number: phoneNumber }
                    ];
                    onUpdate('phones', updatedPhones);
                  }
                  setShowPhoneModal(false);
                }
              },
              {
                label: 'Cancel',
                style: 'base',
                onClick: () => setShowPhoneModal(false)
              }
            ]}
          >
            <div className='space-y-4'>
              <DropdownInput
                label='Phone Type'
                value={phoneType}
                onChange={e => setPhoneType(e.target.value)}
                options={[
                  { value: 'mobile', label: 'Mobile' },
                  { value: 'work', label: 'Work' },
                  { value: 'home', label: 'Home' }
                ]}
              />
              <Input
                type='tel'
                label='Phone Number'
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </div>
          </Modal>
        </div>
      )
    },
    currentUser.bohsaAdmin && {
      title: 'Admin',
      content: ({ data, onUpdate }) => (
        <div className='space-y-2'>
          <Field
            Icon={Key}
            label='BOHSA Admin'
            dataType='select'
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
            value={data.bohsaAdmin ? 'Yes' : 'No'}
            isEditable={true}
          />
          <Field
            Icon={Key}
            label='Company Admin'
            dataType='select'
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
            value={data.companyAdmin ? 'Yes' : 'No'}
            isEditable={true}
          />
          <div className='flex items-center'>
            <Field
              Icon={Link}
              label='Auth Link'
              value={data.auth || 'Not linked'}
              isEditable={false}
              extension={() => (
                <TextButton
                  onClick={handleAuthLinkEdit}
                  colour='base'
                  size='sm'
                >
                  Edit
                </TextButton>
              )}
            />
          </div>
          <Field Icon={Key} label='ID' value={data.id} isEditable={false} />

          <Modal
            isOpen={showAuthModal}
            onClose={() => setShowAuthModal(false)}
            title='Edit Auth Link'
            actions={[
              {
                label: 'Save',
                onClick: () => {
                  onUpdate('auth', authId || null);
                  setShowAuthModal(false);
                }
              },
              {
                label: 'Cancel',
                style: 'base',
                onClick: () => setShowAuthModal(false)
              }
            ]}
          >
            <div className='space-y-4'>
              <div className='flex items-center space-x-1'>
                <Input
                  type='text'
                  label='Auth ID'
                  value={authId}
                  placeholder={data.auth}
                  onChange={e => setAuthId(e.target.value)}
                />
                <TextButton
                  colour='danger'
                  className='min-w-32 mt-6'
                  onClick={() => setShowWarningModal(true)}
                >
                  Remove Link
                </TextButton>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={showWarningModal}
            onClose={() => setShowWarningModal(false)}
            title='Warning'
            actions={[
              {
                label: 'Cancel',
                style: 'base',
                onClick: () => setShowWarningModal(false)
              },
              {
                label: 'Confirm',
                style: 'danger',
                onClick: () => {
                  onUpdate('auth', null);
                  setShowWarningModal(false);
                  setShowAuthModal(false);
                }
              }
            ]}
          >
            <div className='space-y-4'>
              <p>
                Are you sure you want to remove the auth link? This action
                cannot be undone.
              </p>
            </div>
          </Modal>
        </div>
      )
    }
  ];

  const additionalTabs = [
    {
      label: 'Bookings',
      path: 'bookings',
      content: <UserBookings userId={id} />
    },
    {
      label: 'Spaces',
      path: 'spaces',
      content: <UserSpaces userId={id} />
    }
  ];

  return (
    <Entity
      currentUser={currentUser}
      entityType={EntityTypeEnum.User}
      model={UserProfile}
      infoItems={infoItems}
      accordionItems={accordionItems}
      additionalTabs={additionalTabs}
    />
  );
};

export default UserDetails;
