import {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  useCallback
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  LogoLoader,
  Breadcrumbs,
  TextButton,
  Modal,
  Input,
  SoftButton
} from 'core';
import { TextEditor } from 'core';
import { Pencil, Trash2, Paperclip, X, Plus, Copy, Check } from 'lucide-react';
import { FilledButton } from 'core';
import Guide from '../../../../models/Guide';
import Document from '../../../../models/Document';
import {
  formatDate,
  formatProfile,
  getIconComponent
} from '../../../../utilities/Formatting';
import { UserProfileContext } from '../../../../App';
import { PERMISSIONS } from '../../../../utilities/Permissions';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';
import { common, createLowlight } from 'lowlight';
import { toHtml } from 'hast-util-to-html';
import { supabase } from '../../../../utilities/supabase';
import axios from 'axios';

const lowlight = createLowlight(common);

const Doc = () => {
  const navigate = useNavigate();
  const { category, slug } = useParams();
  const [guide, setGuide] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [headings, setHeadings] = useState([]);
  const [activeHeading, setActiveHeading] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [pendingFiles, setPendingFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});
  const [copySuccess, setCopySuccess] = useState({});
  const [editorInstance, setEditorInstance] = useState(null);

  const [aiResponseText, setAiResponseText] = useState('');

  const contentRef = useRef(null);
  const { userProfile } = useContext(UserProfileContext);

  const handleEditorReady = editor => {
    setEditorInstance(editor);
  };

  const handleFileSelect = async e => {
    const selectedFiles = Array.from(e.target.files);
    if (!selectedFiles.length) return;

    const newPendingFiles = selectedFiles.map(file => ({
      file,
      name: file.name,
      uploaded: false
    }));

    setPendingFiles(prev => [...prev, ...newPendingFiles]);
  };

  const handleFileUpload = async index => {
    const pendingFile = pendingFiles[index];
    if (!pendingFile || pendingFile.uploaded) return;

    setUploadingFiles(prev => ({ ...prev, [index]: true }));
    setUploadProgress(prev => ({ ...prev, [index]: 0 }));

    try {
      const { data: signedUrlData, error: signedUrlError } =
        await supabase.functions.invoke('storage/getSigned/upload', {
          body: {
            fileName: pendingFile.file.name,
            originalFileName: pendingFile.name,
            destinationFolder: 'guides'
          }
        });

      if (signedUrlError) throw signedUrlError;

      await axios.put(signedUrlData.url, pendingFile.file, {
        headers: {
          'Content-Type': pendingFile.file.type
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(prev => ({ ...prev, [index]: percentCompleted }));
        }
      });

      const document = await Document.getById(signedUrlData.file.id);
      await document.update({ filePath: signedUrlData.file.key });

      setAttachments(prev => [...prev, document]);

      // Mark file as uploaded
      const newPendingFiles = [...pendingFiles];
      newPendingFiles[index].uploaded = true;
      setPendingFiles(newPendingFiles);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setUploadingFiles(prev => ({ ...prev, [index]: false }));
      setUploadProgress(prev => ({ ...prev, [index]: 0 }));
    }
  };

  const handleRemoveFile = index => {
    setPendingFiles(prev => prev.filter((_, i) => i !== index));
    setAttachments(prev => prev.filter((_, i) => i !== index));
  };

  const handleRemovePendingFile = index => {
    setPendingFiles(prev => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const loadGuide = async () => {
      try {
        const { data, error } = await Guide.getAll({
          slug: { value: slug }
        });
        if (error) throw error;
        if (data && data.length > 0) {
          setGuide(data[0]);
          setContent(data[0].content);
          setTitle(data[0].title);
          setDescription(data[0].description);

          // Load attachments if guide has any
          if (data[0].attachments && data[0].attachments.length > 0) {
            const attachmentDocs = await Promise.all(
              data[0].attachments.map(id => Document.getById(id))
            );
            setAttachments(attachmentDocs.filter(doc => doc)); // Filter out any null results
          }

          // Parse headings from content
          const parser = new DOMParser();
          const doc = parser.parseFromString(data[0].content, 'text/html');
          const headingElements = doc.querySelectorAll(
            'h1, h2, h3, h4, h5, h6'
          );
          const headingsList = Array.from(headingElements).map(
            (heading, index) => ({
              id: heading.id || `heading-${index}`,
              text: heading.textContent,
              level: parseInt(heading.tagName[1])
            })
          );

          // Add IDs to headings if they don't exist
          headingsList.forEach(heading => {
            if (!heading.id) {
              const element = headingElements[heading.index];
              element.id = heading.id;
            }
          });

          setHeadings(headingsList);
        }
      } catch (err) {
        setError('Failed to load document');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    loadGuide();
  }, [slug]);

  useEffect(() => {
    if (!contentRef.current) return;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveHeading(entry.target.id);
          }
        });
      },
      {
        rootMargin: '-20% 0px -80%',
        threshold: 0
      }
    );

    const headingElements = contentRef.current.querySelectorAll(
      'h1, h2, h3, h4, h5, h6'
    );
    headingElements.forEach(element => observer.observe(element));

    return () => observer.disconnect();
  }, [guide]);

  useEffect(() => {
    if (!editMode && contentRef.current) {
      // Find all code blocks
      const codeBlocks = contentRef.current.querySelectorAll('pre code');
      codeBlocks.forEach(block => {
        // Get the language class if it exists
        const langClass = Array.from(block.classList).find(className =>
          className.startsWith('language-')
        );
        const language = langClass ? langClass.replace('language-', '') : null;

        if (language) {
          const code = block.textContent;
          const tree = lowlight.highlight(language, code);
          block.innerHTML = toHtml(tree);
        }
        // Add copy button
        const pre = block.parentElement;
        pre.style.position = 'relative';

        const copyButton = document.createElement('button');
        copyButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>`;
        copyButton.className =
          'absolute right-2 top-2 p-1.5 rounded-md hover:bg-base-200/50 transition-colors';
        copyButton.addEventListener('click', () => {
          navigator.clipboard.writeText(block.textContent);
          copyButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-green-500"><polyline points="20 6 9 17 4 12"></polyline></svg>`;
          setTimeout(() => {
            copyButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>`;
          }, 1000);
        });
        pre.appendChild(copyButton);
      });
    }
  }, [editMode, guide]);

  // Memoize the profile formatting
  const formattedProfile = useMemo(() => {
    const profileData = {
      firstName: guide?.updatedBy?.first_name || guide?.createdBy?.first_name,
      lastName: guide?.updatedBy?.last_name || guide?.createdBy?.last_name,
      profilePhoto:
        guide?.updatedBy?.profile_photo || guide?.createdBy?.profile_photo
    };
    return formatProfile(profileData, 'sm');
  }, [guide?.updatedBy, guide?.createdBy]);

  const scrollToHeading = headingId => {
    const element = document.getElementById(headingId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSave = async () => {
    try {
      // Create a temporary div to parse the content HTML
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;

      // Find all heading elements
      const headings = tempDiv.querySelectorAll('h1, h2, h3, h4, h5, h6');

      // Set id for each heading based on text content
      headings.forEach(heading => {
        const headingText = heading.textContent;
        // Create URL-friendly id from heading text
        const headingId = headingText
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, '-')
          .replace(/(^-|-$)/g, '');
        heading.id = headingId;
      });

      // Get the updated content with heading ids
      const contentWithIds = tempDiv.innerHTML;

      await guide.update({
        content: contentWithIds,
        title,
        description,
        attachments: attachments.map(doc => doc.id),
        updatedBy: userProfile.id
      });
      setEditMode(false);

      // Reload guide to refresh headings
      const { data } = await Guide.getAll({ slug: { value: slug } });
      if (data && data.length > 0) {
        setGuide(data[0]);
      }
    } catch (err) {
      console.error('Failed to save document:', err);
    }
  };

  const handleDelete = async () => {
    try {
      await guide.delete();
      navigate(`/help/docs/${category}`);
    } catch (err) {
      console.error('Failed to delete document:', err);
    }
  };

  const handleCopyLink = (url, fileId) => {
    navigator.clipboard.writeText(url);
    setCopySuccess(prev => ({ ...prev, [fileId]: true }));
    setTimeout(() => {
      setCopySuccess(prev => ({ ...prev, [fileId]: false }));
    }, 1000);
  };

  async function handleAiRequest({ template, customPrompt, selectedText }) {
    try {
      // First insert the AI content node
      editorInstance.commands.createAiNode({
        originalContent: selectedText,
        content: '',
        status: 'starting'
      });

      const { data: response } = await supabase.functions.invoke(
        'integrations/add-ai/text-completion',
        {
          body: {
            model: {
              provider: 1,
              id: 28
            },
            details: {
              template: template
            },
            input: {
              content: selectedText,
              prompt: customPrompt
            }
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch AI response');
      }

      const reader = response.body.getReader();
      let accumulatedText = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          // Update AI content node status to ready when stream is complete
          console.log('stream done');
          editorInstance.commands.updateAiContent({
            originalContent: selectedText,
            content: accumulatedText,
            status: 'completed'
          });
          setAiResponseText('DONE');

          break;
        }

        const decodedChunk = new TextDecoder().decode(value, { stream: true });
        const lines = decodedChunk.split('\n');

        for (const line of lines) {
          if (line.trim() === '' || line === 'data: [DONE]') continue;

          try {
            const parsedLine = JSON.parse(line.replace('data: ', ''));
            if (parsedLine.choices[0]?.delta?.content) {
              accumulatedText += parsedLine.choices[0].delta.content;
              setAiResponseText(accumulatedText);
            }
          } catch (e) {
            console.error('Failed to parse chunk:', e);
          }
        }
      }
    } catch (error) {
      console.error('Error during AI request:', error);
      // Remove AI content node on error
      editorInstance.commands.rejectAiContent();
    }
  }

  useEffect(() => {
    if (aiResponseText) {
      if (aiResponseText === 'DONE') {
        editorInstance.commands.updateAiContent({
          status: 'completed'
        });
      } else {
        editorInstance.commands.updateAiContent({
          content: aiResponseText,
          status: 'running'
        });
      }
    }
  }, [aiResponseText, editorInstance]);

  if (loading) {
    return <LogoLoader logo='/logo-star.png' />;
  }

  if (error) {
    return <div className='p-4 text-red-500'>{error}</div>;
  }

  if (!guide) {
    return <div className='p-4'>Document not found</div>;
  }

  const CategoryIcon = guide.category?.metadata?.icon
    ? getIconComponent(guide.category.metadata.icon)
    : null;

  return (
    <div className='max-w-7xl mx-auto p-6'>
      <Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title='Delete Guide'
        style='danger'
        actions={[
          {
            label: 'Delete',
            onClick: handleDelete,
            style: 'danger'
          },
          {
            label: 'Cancel',
            onClick: () => setShowDeleteModal(false),
            style: 'base'
          }
        ]}
      >
        <p className='text-base-600'>
          Are you sure you want to delete this guide? This action cannot be
          undone.
        </p>
      </Modal>

      <div className='mb-6 w-full'>
        <Breadcrumbs
          pages={[
            { name: 'Help', href: '/help' },
            { name: 'Documentation', href: '/help/docs' },
            {
              name: guide.category?.type || 'Uncategorized',
              href: `/help/docs/${category}`
            },
            {
              name: title,
              href: `/help/docs/${category}/${slug}`,
              current: true
            }
          ]}
        />
      </div>

      <div className='mb-8'>
        <div className='flex items-center gap-3 mb-2'>
          {CategoryIcon && <CategoryIcon className='w-5 h-5 text-base-700' />}
          <span className='text-sm text-base-700'>
            {guide.category?.type || 'Uncategorized'}
          </span>
        </div>
        <div className='flex justify-between items-center'>
          <h1 className='text-3xl font-medium mb-4 text-base-950'>{title}</h1>
          <div className='flex gap-2'>
            {editMode && (
              <>
                <FilledButton
                  colour='base'
                  onClick={() => {
                    setEditMode(false);
                    setTitle(guide.title);
                    setDescription(guide.description);
                    setContent(guide.content);
                  }}
                >
                  Cancel
                </FilledButton>
                {userProfile.hasPermission(
                  EntityTypeEnum.Guides,
                  PERMISSIONS[EntityTypeEnum.Guides].DELETE
                ) && (
                  <TextButton
                    onClick={() => setShowDeleteModal(true)}
                    leftIcon={<Trash2 />}
                    colour='danger'
                  >
                    Delete
                  </TextButton>
                )}
              </>
            )}
            {userProfile.hasPermission(
              EntityTypeEnum.Guides,
              PERMISSIONS[EntityTypeEnum.Guides].EDIT
            ) && (
              <FilledButton
                onClick={() => {
                  if (editMode) {
                    handleSave();
                  } else {
                    setEditMode(true);
                  }
                }}
                leftIcon={<Pencil />}
                colour='primary'
              >
                {editMode ? 'Save' : 'Edit'}
              </FilledButton>
            )}
          </div>
        </div>
        <div className='flex items-center space-x-3'>
          <div className='flex items-center text-sm text-base-800'>
            <span className='mr-2 font-medium'>
              {guide.updatedBy ? 'Last updated by' : 'Created by'}
            </span>
            {formattedProfile}
          </div>
          <div className='flex items-center text-sm'>
            <span className='text-base-800 mr-2'>at</span>
            <span className='text-base-500'>
              {formatDate(guide.updatedDate || guide.createdDate)}
            </span>
          </div>
        </div>
      </div>

      <div className='flex gap-8 lg:gap-16 border-t pt-8 w-full'>
        <div className={`w-full h-full overflow-y-auto`} ref={contentRef}>
          {editMode ? (
            <>
              <div className='w-full'>
                <Input
                  label='Title'
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  className='text-3xl font-medium mb-2'
                  placeholder='Guide Title'
                />
                <Input
                  label='Description'
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  className='mb-2'
                  placeholder='Guide Description'
                />
              </div>
              <TextEditor
                label='Content'
                value={content}
                onEditorReady={handleEditorReady}
                onChange={setContent}
                onAiRequest={handleAiRequest}
                className='min-h-[500px] max-h-[calc(100vh-300px)] w-full'
                // characterLimit={1000}
              />
              <div className='mt-8'>
                <label className='block text-sm font-medium mb-2'>
                  Attachments
                </label>
                <div className='max-h-[200px] overflow-y-auto'>
                  {attachments.map((file, index) => (
                    <div key={index} className='flex gap-2 mb-2'>
                      <div className='relative flex-1'>
                        <span className='py-2 px-3 bg-gray-50 rounded-md block'>
                          {file.fileName}
                        </span>
                      </div>
                      <TextButton
                        key={file.id}
                        onClick={() =>
                          handleCopyLink(
                            `https://storage.googleapis.com/bohsa-docs/${file.filePath}`,
                            file.id
                          )
                        }
                      >
                        {copySuccess[file.id] ? <Check /> : <Copy />}
                      </TextButton>
                      <TextButton
                        onClick={() => handleRemoveFile(index)}
                        colour='base'
                      >
                        <X size={20} />
                      </TextButton>
                    </div>
                  ))}
                  {pendingFiles
                    .filter(file => !file.uploaded)
                    .map((file, index) => (
                      <div key={index} className='flex gap-2 mb-2'>
                        <div className='relative flex-1'>
                          <Input
                            value={file.name}
                            onChange={e => {
                              const newPendingFiles = [...pendingFiles];
                              newPendingFiles[index].name = e.target.value;
                              setPendingFiles(newPendingFiles);
                            }}
                            placeholder='File name'
                          />
                          {uploadProgress[index] > 0 &&
                            uploadProgress[index] < 100 && (
                              <div className='absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center rounded-md'>
                                <span className='text-sm text-gray-700'>
                                  {uploadProgress[index]}%
                                </span>
                              </div>
                            )}
                        </div>
                        <SoftButton
                          onClick={() => handleFileUpload(index)}
                          colour='primary'
                          disabled={uploadingFiles[index]}
                        >
                          Upload
                        </SoftButton>
                        <TextButton
                          onClick={() => handleRemovePendingFile(index)}
                          colour='base'
                        >
                          <X size={20} />
                        </TextButton>
                      </div>
                    ))}
                </div>
                <div className='relative'>
                  <SoftButton
                    onClick={() => document.getElementById('fileInput').click()}
                    colour='base'
                    className='mt-2'
                  >
                    <Plus size={20} className='mr-2' />
                    Add File
                  </SoftButton>
                  <input
                    id='fileInput'
                    type='file'
                    onChange={handleFileSelect}
                    className='hidden'
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className='prose prose-neutral max-w-none content'
                dangerouslySetInnerHTML={{ __html: guide.content }}
              />
              {attachments.length > 0 && (
                <div className='mt-8 border-t pt-8'>
                  <h3
                    className='text-lg font-medium flex items-center gap-2 mb-4'
                    id='attachments'
                  >
                    <Paperclip className='w-5 h-5' />
                    Attachments
                  </h3>
                  <div className='space-y-2'>
                    {attachments.map(attachment => (
                      <a
                        key={attachment.id}
                        href={`https://storage.googleapis.com/bohsa-docs/${attachment.filePath}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='flex items-center gap-2 text-base-600 hover:text-base-900 transition-colors'
                      >
                        <Paperclip className='w-4 h-4' />
                        {attachment.fileName}
                      </a>
                    ))}
                  </div>
                </div>
              )}
              <div></div>
            </>
          )}
        </div>

        {!editMode && (headings.length > 0 || attachments.length > 0) && (
          <div className='w-1/4'>
            <div className='sticky top-6'>
              <h3 className='text-sm font-medium text-base-950 mb-4'>
                On this page
              </h3>
              <nav className='space-y-2'>
                {headings.map(heading => (
                  <a
                    key={heading.id}
                    href={`#${heading.id}`}
                    onClick={e => {
                      e.preventDefault();
                      scrollToHeading(heading.id);
                    }}
                    className={`block text-sm hover:text-base-900 transition-colors duration-150
                      ${
                        activeHeading === heading.id
                          ? 'text-base-900 font-medium'
                          : 'text-base-500'
                      }`}
                    style={{ paddingLeft: `${(heading.level - 1) * 0.75}rem` }}
                  >
                    {heading.text}
                  </a>
                ))}
                {attachments.length > 0 && (
                  <a
                    href='#attachments'
                    onClick={e => {
                      e.preventDefault();
                      scrollToHeading('attachments');
                    }}
                    className={`block text-sm hover:text-base-900 transition-colors duration-150
                      ${
                        activeHeading === 'attachments'
                          ? 'text-base-900 font-medium'
                          : 'text-base-500'
                      }`}
                  >
                    Attachments
                  </a>
                )}
              </nav>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Doc;
