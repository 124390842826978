import React, { useState, useEffect } from 'react';
import { supabase } from '../../../utilities/supabase';
import Accordion from '../../entity/Accordion';
import { LogoLoader, FilledButton, OutlineButton, Input } from 'core';
import { useNotification } from 'core';

const StageTwo = ({
  selectedTemplate,
  onBack,
  onClose,
  entity,
  selectedRun,
  entityType,
  setRefresh
}) => {
  const [mergeTags, setMergeTags] = useState({});
  const [errors, setErrors] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const { addNotification } = useNotification();

  useEffect(() => {
    const tags = selectedTemplate.merge.tags.reduce((acc, tag) => {
      let value = '';
      if (entity) {
        const entityData = entity.toDatabase();
        console.log('Entity Data:', entityData); // In-depth log of entity data
        if (tag.name.startsWith('entity.')) {
          const entityKey = tag.name.split('entity.')[1];
          console.log(`Processing entity key: ${entityKey}`); // Log the entity key being processed
          value = entityKey.split('.').reduce((obj, key) => {
            if (key.includes('[') && key.includes(']')) {
              const [arrayKey, index] = key.split(/[\[\]]/).filter(Boolean);
              console.log(`Array Key: ${arrayKey}, Index: ${index}`); // Log array key and index
              return obj && obj[arrayKey] && Array.isArray(obj[arrayKey])
                ? obj[arrayKey][parseInt(index, 10)]
                : '';
            }
            console.log(`Current Key: ${key}, Current Object:`, obj); // Log current key and object
            return obj && Object.prototype.hasOwnProperty.call(obj, key)
              ? obj[key]
              : '';
          }, entityData);
        } else {
          value = entityData[tag.name] || '';
          console.log(`Non-entity tag: ${tag.name}, Value: ${value}`); // Log non-entity tag and value
        }
        if (tag.name.toLowerCase().includes('date')) {
          value = formatDateValue(value);
          console.log(
            `Formatted Date Value for tag: ${tag.name}, Value: ${value}`
          ); // Log formatted date value
        }
      }
      console.log(`Final Tag: ${tag.name}, Final Value: ${value}`); // In-depth log of final tag and value
      return { ...acc, [tag.name]: value };
    }, {});
    setMergeTags(tags);
  }, [selectedTemplate, entity]);

  const formatDateValue = value => {
    if (!value) return '';
    if (typeof value === 'string' && value.includes('-')) {
      const date = new Date(value);
      return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      });
    }
    return value;
  };

  const handleMergeTagChange = (tag, value) => {
    setMergeTags(prev => ({ ...prev, [tag]: value }));
  };

  const processAction = async () => {
    setIsProcessing(true);

    try {
      const object = {
        entityId: entity.displayId || entity.id,
        entityType,
        tags: {
          ...selectedTemplate.merge.tags.reduce(
            (acc, tag) => ({
              ...acc,
              [tag.value]: mergeTags[tag.name] || ''
            }),
            {}
          )
        },
        images: selectedTemplate.merge.images
          .map((imageTag, index) => {
            const value = mergeTags[imageTag.name] || '';
            return value ? { index: index, value: value } : null;
          })
          .filter(image => image !== null)
      };

      if (selectedRun.single) {
        // Create run with initial data
        const { data, error } = await supabase.functions.invoke(
          'audience/docflows/runs/create',
          {
            body: {
              templateId: selectedRun.templateId,
              initialData: object
            }
          }
        );

        if (error) throw error;

        // Start the run
        supabase.functions.invoke('audience/docflows/runs/start', {
          body: {
            runId: data[0].id
          }
        });

        addNotification({
          type: 'success',
          title: 'Started Run',
          description:
            'You have started a run for the template and data you entered.'
        });
      } else {
        // Add object to existing run
        const { error } = await supabase.functions.invoke(
          'audience/docflows/runs/add-object',
          {
            body: {
              runId: selectedRun.id,
              object
            }
          }
        );

        if (error) throw error;

        addNotification({
          type: 'success',
          title: 'Added To Run',
          description: 'You have added the merge to the next run.'
        });
      }

      onClose();
      setRefresh('tab,icon');
    } catch (error) {
      console.error('Error processing action:', error);
      setErrors(prev => ({
        ...prev,
        general: 'Failed to process action. Please try again.'
      }));
    } finally {
      setIsProcessing(false);
    }
  };

  const accordionItems = [
    {
      title: 'Merge Tags',
      content: (
        <>
          {selectedTemplate.merge.tags.map(tag => (
            <div key={tag.name} className='mb-4'>
              <Input
                label={tag.name}
                id={tag.name}
                value={mergeTags[tag.name] || ''}
                onChange={e => handleMergeTagChange(tag.name, e.target.value)}
              />
            </div>
          ))}
        </>
      )
    },
    {
      title: 'Images',
      content: (
        <>
          {selectedTemplate.merge.images.map((imageTag, index) => {
            const imageValue = mergeTags[imageTag.name] || '';
            console.log(`Image Tag: ${imageTag.name}, Value: ${imageValue}`); // Debugging log
            return (
              <div key={imageTag.name} className='mb-4'>
                <Input
                  label={imageTag.name}
                  id={imageTag.name}
                  value={imageValue}
                  onChange={e =>
                    handleMergeTagChange(imageTag.name, e.target.value)
                  }
                />
                <img
                  src={selectedTemplate.metadata.images[index]}
                  alt={`${imageTag.name} preview`}
                  className='w-16 h-16 mt-2'
                />
              </div>
            );
          })}
        </>
      )
    }
  ];

  return (
    <div className='flex flex-col'>
      <div className='flex'>
        <div className='w-1/3 pr-1 flex flex-col'>
          <h3 className='font-semibold mb-4 text-lg'>Content</h3>
          <div>
            <Accordion
              items={accordionItems}
              showTopBorder={false}
              singleOpen={true}
            />
          </div>
        </div>
        <div className='w-2/3 pl-4 flex flex-col'>
          <h3 className='font-semibold mb-4 text-lg'>Preview</h3>
          <div className='border p-4 rounded-lg bg-white shadow-inner'>
            <img
              src={selectedTemplate.metadata.preview}
              alt='Template Preview'
              className='w-full h-auto'
            />
          </div>
        </div>
      </div>
      {errors.general && (
        <p className='text-danger-600 mt-4'>{errors.general}</p>
      )}
      <div className='mt-8 flex justify-end'>
        <OutlineButton colour='base' onClick={onBack} className='mr-4'>
          Back
        </OutlineButton>
        <FilledButton
          colour='primary'
          onClick={processAction}
          disabled={isProcessing}
        >
          {isProcessing
            ? 'Processing...'
            : selectedRun.single
            ? 'Create Document'
            : 'Add to Run'}
        </FilledButton>
      </div>
    </div>
  );
};

export default StageTwo;
