import React, { useState, useMemo } from 'react';
import { Search, Edit2, Save } from 'lucide-react';
import { Badge, Input, MultiSelectBox } from 'core';
import { supabase } from '../../../utilities/supabase';

const StageRuns = ({ runs, onRunSelect, setRuns, selectedTemplate }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreatingRun, setIsCreatingRun] = useState(false);
  const [editingRunId, setEditingRunId] = useState(null);
  const [editingName, setEditingName] = useState('');

  const filteredRuns = useMemo(() => {
    return runs.filter(run =>
      run.id.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [runs, searchQuery]);

  const handleNewRunClick = async () => {
    setIsCreatingRun(true);
    try {
      const { data, error } = await supabase.functions.invoke(
        'audience/docflows/runs/create',
        {
          body: {
            templateId: selectedTemplate.id
          }
        }
      );
      if (error) throw error;

      // Recalling the invoke to fetch updated runs
      const { data: updatedRuns, error: fetchError } =
        await supabase.functions.invoke('audience/docflows/runs', {
          body: {
            templateId: selectedTemplate.id
          }
        });

      if (fetchError) throw fetchError;

      setRuns(updatedRuns);
    } catch (error) {
      console.error('Error creating new run or fetching updated runs:', error);
    } finally {
      setIsCreatingRun(false);
    }
  };

  const handleSingleRunClick = async () => {
    setIsCreatingRun(true);
    try {
      onRunSelect({ templateId: selectedTemplate.id, single: true });
    } catch (error) {
      console.error(
        'Error creating single run or fetching updated runs:',
        error
      );
    } finally {
      setIsCreatingRun(false);
    }
  };

  const handleEditName = run => {
    setEditingRunId(run.id);
    setEditingName(run.name || run.id.toString());
  };

  const handleSaveName = async run => {
    try {
      const { error } = await supabase.functions.invoke(
        'audience/docflows/runs/name',
        {
          body: {
            runId: run.id,
            name: editingName
          }
        }
      );
      if (error) throw error;

      // Update local state
      setRuns(
        runs.map(r => (r.id === run.id ? { ...r, name: editingName } : r))
      );
    } catch (error) {
      console.error('Error updating run name:', error);
    } finally {
      setEditingRunId(null);
      setEditingName('');
    }
  };

  return (
    <>
      <div className='mb-6 flex items-center space-x-4'>
        <div className='relative flex-grow'>
          <div className='absolute inset-y-0 left-0 pl-2 flex items-center'>
            <Search className='h-4 w-4 text-gray-400' />
          </div>
          <Input
            id='runSearch'
            name='runSearch'
            type='text'
            placeholder='Search runs...'
            className='bg-white text-gray-500 pl-8 pr-8 w-full'
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
        {filteredRuns.map(run => (
          <div
            key={run.id}
            className='border border-neutral-200 p-6 rounded-lg shadow-sm cursor-pointer hover:shadow-md transition-shadow duration-200 ease-in-out bg-white'
            onClick={() => editingRunId !== run.id && onRunSelect(run)}
          >
            <div className='flex justify-between items-start mb-2'>
              {editingRunId === run.id ? (
                <div
                  className='flex items-center w-full'
                  onClick={e => e.stopPropagation()}
                >
                  <Input
                    value={editingName}
                    onChange={e => setEditingName(e.target.value)}
                    className='mr-2'
                    autoFocus
                  />
                  <button
                    onClick={() => handleSaveName(run)}
                    className='text-brand-600 hover:text-brand-700 ml-3'
                  >
                    <Save></Save>
                  </button>
                </div>
              ) : (
                <>
                  <h3 className='font-semibold text-lg text-neutral-800'>
                    {run.name ||
                      `${run.id} (
                    ${new Date(run.created_date).toLocaleString()})`}
                  </h3>
                  <button
                    onClick={e => {
                      e.stopPropagation();
                      handleEditName(run);
                    }}
                    className='text-neutral-400 hover:text-neutral-600'
                  >
                    <Edit2 size={16} className='mt-1' />
                  </button>
                </>
              )}
            </div>
            <p className='text-sm text-neutral-600 mb-4'>
              {run.data.length} currently in run.
            </p>
          </div>
        ))}
        <div
          className={`border border-dashed border-neutral-300 p-6 rounded-lg cursor-pointer transition-colors duration-200 ease-in-out flex items-center justify-center hover:border-brand-500 hover:bg-brand-50 ${
            isCreatingRun ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={!isCreatingRun ? handleNewRunClick : null}
        >
          <div className='flex flex-col items-center justify-center'>
            <span className='text-lg font-semibold text-brand-600'>
              {isCreatingRun ? 'Creating...' : '+ New Run'}
            </span>
          </div>
        </div>
        <div
          className={`border border-dashed border-neutral-300 p-6 rounded-lg cursor-pointer transition-colors duration-200 ease-in-out flex items-center justify-center hover:border-brand-500 hover:bg-brand-50 ${
            isCreatingRun ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={!isCreatingRun ? handleSingleRunClick : null}
        >
          <div className='flex flex-col items-center justify-center'>
            <span className='text-lg font-semibold text-brand-600'>
              {isCreatingRun ? 'Creating...' : 'Single Run'}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default StageRuns;
