import React, { useState, useEffect } from 'react';
import {
  Pencil,
  SquareArrowOutUpRight,
  Trash,
  Star,
  Check
} from 'lucide-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import CoreEntityType from '../../../../models/CoreEntityType';
import Trainer from '../../../../models/Trainer';
import { formatDate } from '../../../../utilities/Formatting';

const CourseTrainers = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const { data: statuses } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.TrainerStatus }
        });

        setStatusOptions(
          statuses.map(status => ({ key: status.id, value: status.type }))
        );
      } catch (error) {
        console.error('Error fetching trainer options:', error);
      }
    };

    fetchOptions();
  }, [id]);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/trainers/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/trainers/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  return (
    <EntityTable
      name='Trainers'
      filter={[
        {
          field: 'id',
          label: 'ID',
          type: 'text',
          operator: FilterOperatorEnum.EQUALS
        },
        {
          field: 'name',
          label: 'Trainer Name',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'status',
          label: 'Status',
          type: 'select',
          options: statusOptions,
          operator: FilterOperatorEnum.EQUALS
        },
        {
          field: 'approved',
          label: 'Approved',
          type: 'select',
          options: [
            { value: 'Yes', key: true },
            { value: 'No', key: false }
          ],
          operator: FilterOperatorEnum.IS
        },
        {
          field: 'rating',
          label: 'Rating',
          type: 'rating',
          operator: FilterOperatorEnum.GREATER_THAN_OR_EQUAL
        },
        {
          field: 'updated_date',
          label: 'Updated Date',
          type: 'daterange',
          operator: FilterOperatorEnum.BETWEEN
        }
      ]}
      sort={[
        { value: 'name', label: 'Trainer Name' },
        { value: 'updated_date', label: 'Last Modified' },
        { value: 'rating', label: 'Rating' }
      ]}
      columns={[
        {
          label: 'ID',
          field: 'id',
          cell: value => (
            <Link
              to={`/trainers/${value}`}
              className='text-brand-700 hover:text-brand-800'
            >
              {value}
            </Link>
          )
        },
        { label: 'Trainer Name', field: 'name' },
        {
          label: 'Status',
          field: 'status.name',
          foreignKey: { table: 'core_entity_types', column: 'type' }
        },
        {
          label: 'Approved',
          field: 'approved',
          cell: value => (value ? 'Yes' : 'No')
        },
        {
          label: 'Rating',
          field: 'rating',
          cell: value => {
            const stars = [];
            for (let i = 0; i < 5; i++) {
              stars.push(
                i < value ? (
                  <Star key={i} size={18} fill='#FFD700' color='#FFD700' />
                ) : (
                  <Star key={i} size={18} color='#D3D3D3' />
                )
              );
            }
            return <div className='flex'>{stars}</div>;
          }
        },
        {
          label: 'NVQ',
          field: 'options.nvq',
          sortable: false,
          cell: (value, row) => (row.options.nvq ? <Check size={18} /> : null)
        },
        {
          label: 'Onsite',
          field: 'options.onsite',
          sortable: false,
          cell: (value, row) =>
            row.options.onsite ? <Check size={18} /> : null
        },
        {
          label: 'Remote',
          field: 'options.remote',
          sortable: false,
          cell: (value, row) =>
            row.options.remote ? <Check size={18} /> : null
        },
        {
          label: 'Classroom',
          field: 'options.classroom',
          sortable: false,
          cell: (value, row) =>
            row.options.classroom ? <Check size={18} /> : null
        },
        {
          label: 'E-learning',
          field: 'options.elearning',
          sortable: false,
          cell: (value, row) =>
            row.options.elearning ? <Check size={18} /> : null
        },
        {
          label: 'Venue Count',
          field: 'options.venue_count',
          sortable: false
        },
        {
          label: 'Updated Date',
          field: 'updatedDate',
          cell: value => formatDate(value)
        }
      ]}
      menuItems={menuItems}
      model={Trainer}
      fromFunction={true}
      entityType={EntityTypeEnum.Trainer}
      defaultFilters={{
        courseId: id
      }}
      canCreate={false}
    />
  );
};

export default CourseTrainers;
