import BaseModel from './BaseModel';

/**
 * Represents a Task Template in the system.
 * @extends BaseModel
 */
class TaskTemplate extends BaseModel {
  /** @type {string} The name of the database table for Task Templates */
  static table = 'task_templates';

  /** @type {string} The SQL query to select Task Template data with related information */
  static selectQuery = `
    *,
    owner:user_profiles(id, first_name, last_name, profile_photo)
  `;

  /**
   * Creates a TaskTemplate instance from database format data
   * @param {Object} data - The database format task template data
   * @returns {TaskTemplate} A new TaskTemplate instance
   */
  static fromDatabase(data = {}) {
    const taskTemplate = new TaskTemplate();
    taskTemplate.id = data.id;
    taskTemplate.createdDate = data.created_date;
    taskTemplate.updatedDate = data.updated_date;
    taskTemplate.owner = data.owner
      ? {
          id: data.owner.id,
          firstName: data.owner.first_name,
          lastName: data.owner.last_name,
          profilePhoto: data.owner.profile_photo
        }
      : null;
    taskTemplate.title = data.title;
    taskTemplate.template = data.template || {};
    taskTemplate.entityType = data.entity_type;
    return taskTemplate;
  }

  /**
   * Creates an instance of TaskTemplate.
   * @param {Object} data - The task template data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner ?? this.owner ?? null;
    this.title = data.title ?? this.title ?? null;
    this.template = data.template ?? this.template ?? {};
    this.entityType = data.entityType ?? this.entityType ?? null;
  }

  /**
   * Converts the TaskTemplate instance to a database-friendly format.
   * @returns {Object} The task template data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner ? this.owner.id : null,
      title: this.title,
      template: this.template,
      entity_type: this.entityType
    };
  }
}

export default TaskTemplate;
