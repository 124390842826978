import React, { useState, useEffect } from 'react';
import ManagePipelines from '../popups/pipelines/ManagePipelines';
import CreateNote from '../popups/notes/CreateNote';
import CreateMeeting from '../popups/meetings/CreateMeeting';
import SendEmail from '../popups/email/SendEmailPopup';
import FavouriteActionsPopup from './actions/FavouriteActionsPopup';
import CreateTask from '../popups/tasks/CreateTask';
import ManageTags from '../popups/tags/ManageTags';
import CreateAutomation from '../popups/automations/Create';
import SelectAutomation from '../popups/automations/Select';
import ViewAudience from '../popups/audience/View';
import ViewVideo from '../popups/video/View';
import SendSMS from '../popups/sms/SendEmailPopup';
import DocflowPopup from '../popups/docflows/DocFlowsPopup';
import ECardPopup from '../popups/ecards/ECardPopup';

const EntityPopupHandler = ({
  entityType,
  entityId,
  entity,
  onUpdate,
  handler,
  onClose,
  setRefresh
}) => {
  const [activePopup, setActivePopup] = useState(null);

  useEffect(() => {
    setActivePopup(handler);
  }, [handler]);

  const handleClose = () => {
    setActivePopup(null);
    onClose();
  };

  const handleSubmit = data => {
    console.log(`${activePopup} submitted:`, data);
    handleClose();
  };

  const renderPopup = () => {
    switch (activePopup) {
      case 'handlePipelinesClick':
        return (
          <ManagePipelines
            isOpen={true}
            onClose={handleClose}
            entityId={entityId}
            entityType={entityType}
            entity={entity}
          />
        );
      case 'handleNoteClick':
        return (
          <CreateNote
            entityType={entityType}
            entityId={entityId}
            onClose={handleClose}
            onSubmit={handleSubmit}
          />
        );
      case 'handleMeetingClick':
        return (
          <CreateMeeting
            isOpen={true}
            onClose={handleClose}
            onSubmit={handleSubmit}
            entityType={entityType}
            entityId={entityId}
          />
        );
      case 'handleAutomationClick':
        return (
          <CreateAutomation
            isOpen={true}
            onClose={handleClose}
            onSubmit={handleSubmit}
            entityType={entityType}
            entityId={entityId}
          />
        );
      case 'handleAiFlowsClick':
        return (
          <SelectAutomation
            isOpen={true}
            onClose={handleClose}
            onSubmit={handleSubmit}
            entityType={entityType}
            entityId={entityId}
          />
        );
      case 'handleEmailClick':
        return <SendEmail onClose={handleClose} entity={entity} />;
      case 'handleSMSClick':
        return <SendSMS onClose={handleClose} />;
      case 'handleDocFlowsClick':
        return (
          <DocflowPopup
            onClose={handleClose}
            entity={entity}
            entityType={entityType}
            setRefresh={setRefresh}
          />
        );
      case 'handleAudienceClick':
        return (
          <ViewAudience
            isOpen={true}
            onClose={handleClose}
            entityType={entityType}
            entityId={entityId}
            setRefresh={setRefresh}
          />
        );
      case 'handleVideoClick':
        return (
          <ViewVideo isOpen={true} onClose={handleClose} entityData={entity} />
        );
      case 'handleTaskClick':
        return (
          <CreateTask
            isOpen={true}
            onClose={handleClose}
            onSubmit={handleSubmit}
            entityType={entityType}
            entityId={entityId}
          />
        );
      case 'handleTagsClick':
        return (
          <ManageTags
            isOpen={true}
            onClose={handleClose}
            onSubmit={handleSubmit}
            entityType={entityType}
            entityId={entityId}
          />
        );
      case 'handleECardClick':
        return <ECardPopup onClose={handleClose} entity={entity} />;
      case 'handleMoreClick':
        return (
          <FavouriteActionsPopup
            isOpen={true}
            entity={entity}
            entityType={entityType}
            entityId={entityId}
            onClose={handleClose}
            onUpdate={onUpdate}
            onHandlerClicked={handler => {
              handleActionClick(handler);
              handleClose();
            }}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderPopup()}</>;
};

export default EntityPopupHandler;
