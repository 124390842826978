import Document from '../../../models/Document';
import EntityTable from '../../../components/tables/EntityTable';
import { supabase } from '../../../utilities/supabase';
import { formatDate } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import {
  Pencil,
  Trash,
  Download,
  UserRound,
  Edit2,
  Save,
  Workflow
} from 'lucide-react';
import { useState, useEffect, useContext } from 'react';
import FileUploader from '../../../components/popups/files/FileUploader';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import { UserProfileContext } from '../../../App';
import Accordion from '../../../components/entity/Accordion';
import { FilledButton, Input, Popup, SoftButton, useNotification } from 'core';
import AiLogo from '../../../assets/+Ai.png';
import FileConnections from '../../../components/popups/files/FileConnections';

const Documents = () => {
  document.title = 'Documents | CRM | BOHSA LTD';
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingRunId, setEditingRunId] = useState(null);
  const [editingName, setEditingName] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedRunId, setSelectedRunId] = useState(null);
  const [isConnectionsOpen, setIsConnectionsOpen] = useState(false);
  const [documentData, setDocument] = useState(null);

  const userProfileContext = useContext(UserProfileContext);
  const currentUser = userProfileContext.userProfile;
  const { addNotification } = useNotification();

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setLoading(true);
        const { data: templatesData, error: templatesError } =
          await supabase.functions.invoke('audience/docflows/templates');
        if (templatesError) throw templatesError;

        const templatesWithRuns = await Promise.all(
          templatesData.map(async template => {
            const { data: runsData, error: runsError } =
              await supabase.functions.invoke('audience/docflows/runs', {
                body: { templateId: template.id }
              });
            if (runsError) throw runsError;
            return { ...template, runs: runsData };
          })
        );

        // Only keep templates that have runs
        const templatesWithActiveRuns = templatesWithRuns.filter(
          template => template.runs && template.runs.length > 0
        );
        setTemplates(templatesWithActiveRuns);
      } catch (error) {
        console.error('Error fetching templates or runs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  const handleProcessRun = async runId => {
    const run = templates
      .find(t => t.runs.some(r => r.id === runId))
      ?.runs.find(r => r.id === runId);

    if (!run?.data?.length) {
      addNotification({
        type: 'error',
        title: 'Cannot Process',
        description: 'Cannot process a run with 0 items.'
      });
      return;
    }

    setSelectedRunId(runId);
    setShowConfirmDialog(true);
  };

  const confirmProcessRun = async () => {
    try {
      console.log(`Processing run with ID: ${selectedRunId}`);
      supabase.functions.invoke('audience/docflows/runs/start', {
        body: { runId: selectedRunId }
      });

      // Remove the run from local state after starting processing
      setTemplates(prevTemplates => {
        const updatedTemplates = prevTemplates.map(template => ({
          ...template,
          runs: template.runs.filter(run => run.id !== selectedRunId)
        }));
        // Only keep templates that still have runs
        return updatedTemplates.filter(template => template.runs.length > 0);
      });

      addNotification({
        type: 'success',
        title: 'Processing Started',
        description: 'The document processing has been started successfully.'
      });
    } catch (error) {
      console.error('Error processing run:', error);
    } finally {
      setShowConfirmDialog(false);
      setSelectedRunId(null);
    }
  };

  const handleEditName = run => {
    setEditingRunId(run.id);
    setEditingName(run.name || run.id.toString());
  };

  const handleSaveName = async (run, templateId) => {
    try {
      const { error } = await supabase.functions.invoke(
        'audience/docflows/runs/name',
        {
          body: {
            runId: run.id,
            name: editingName
          }
        }
      );
      if (error) throw error;

      // Update local state
      setTemplates(prevTemplates =>
        prevTemplates.map(template => {
          if (template.id === templateId) {
            return {
              ...template,
              runs: template.runs.map(r =>
                r.id === run.id ? { ...r, name: editingName } : r
              )
            };
          }
          return template;
        })
      );
    } catch (error) {
      console.error('Error updating run name:', error);
    } finally {
      setEditingRunId(null);
      setEditingName('');
    }
  };

  const menuItems = [
    {
      label: 'View Connections',
      icon: <Workflow size={14} />,
      onClick: data => {
        setDocument(data);
        setIsConnectionsOpen(true);
      }
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => (window.location.href = `/documents/${data.id}`)
    },
    {
      label: 'Download',
      icon: <Download size={14} />,
      onClick: async document => {
        console.log(document);
        try {
          const baseUrl = 'https://storage.googleapis.com/bohsa-docs';
          const filePath = document.filePath;
          const link = window.document.createElement('a');
          link.href = `${baseUrl}/${filePath}`;
          link.download =
            `${document.fileName}` || `document.${document.fileType}`;
          console.log(link);
          window.document.body.appendChild(link);

          link.click();
          window.document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading document:', error);
        }
      }
    }
    // {
    //   label: 'Delete',
    //   icon: <Trash size={14} className='text-danger-700' />,
    //   onClick: data => {
    //     console.log('Delete clicked', data.owner.id);
    //     console.log('Current user:', currentUser.id);
    //   },
    //   condition: data => data.owner.id === currentUser.id
    // }
  ];

  const handleNewClick = () => {
    setIsUploadOpen(true);
  };

  const handleUploadSuccess = () => {
    setIsUploadOpen(false);
  };

  return (
    <div className='p-4'>
      {templates.length > 0 ? (
        <div className='p-4 bg-gray-100 rounded-lg mb-4'>
          <div className='flex items-center gap-2 mb-4'>
            <img src={AiLogo} className='w-8 h-8' alt='AI Logo' />
            <span className='text-lg font-semibold text-black'>DocFlows</span>
          </div>
          <div className='overflow-x-auto'>
            {loading
              ? [...Array(1)].map((_, templateIndex) => (
                  <div key={templateIndex}>
                    <div className='h-6 w-48 bg-gray-200 rounded animate-pulse mb-2'></div>
                    <div className='flex overflow-x-auto gap-4 pb-4'>
                      {[...Array(3)].map((_, runIndex) => (
                        <div
                          key={runIndex}
                          className='p-5 border rounded-lg shadow-sm bg-white min-w-[310px] flex-shrink-0'
                        >
                          <div className='flex justify-between items-start'>
                            <div>
                              <div className='h-4 w-36 bg-gray-200 rounded animate-pulse mb-5'></div>
                              <div className='h-4 w-48 bg-gray-200 rounded animate-pulse mb-2'></div>
                            </div>
                            <div className='h-8 w-20 bg-gray-200 rounded animate-pulse'></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              : templates.map(template => (
                  <div key={template.name}>
                    <h3 className='text-lg font-semibold mb-2'>
                      {template.name}
                    </h3>
                    <div className='flex overflow-x-auto gap-4 pb-4'>
                      {template.runs.map(run => (
                        <div
                          key={run.id}
                          className='p-5 border rounded-lg shadow-sm bg-white min-w-[310px]'
                        >
                          <div className='flex justify-between items-start'>
                            <div className='flex-grow'>
                              {editingRunId === run.id ? (
                                <div className='flex items-center w-full'>
                                  <Input
                                    value={editingName}
                                    onChange={e =>
                                      setEditingName(e.target.value)
                                    }
                                    className='mr-2 mb-5'
                                    autoFocus
                                  />
                                  <button
                                    onClick={() =>
                                      handleSaveName(run, template.id)
                                    }
                                    className='text-brand-600 hover:text-brand-700 ml-2 mb-5'
                                  >
                                    <Save size={22} />
                                  </button>
                                </div>
                              ) : (
                                <div className='flex items-center justify-between mb-5'>
                                  <span className='block text-black'>
                                    {run.name
                                      ? run.name
                                      : `${run.id} (${new Date(
                                          run.created_date
                                        ).toLocaleString()})`}
                                  </span>
                                  <button
                                    onClick={() => handleEditName(run)}
                                    className='text-neutral-400 hover:text-neutral-600 ml-2 mt-1'
                                  >
                                    <Edit2 size={20} />
                                  </button>
                                </div>
                              )}
                              <span className='block text-gray-700'>
                                {run.data.length} are currently in the run.
                              </span>
                            </div>
                            <FilledButton
                              onClick={() => handleProcessRun(run.id)}
                              className='ml-4'
                            >
                              Process
                            </FilledButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      ) : (
        <div className='p-4 bg-gray-100 rounded-lg mb-4'>
          <div className='flex items-center gap-2 mb-4'>
            <img src={AiLogo} className='w-8 h-8' alt='AI Logo' />
            <span className='text-lg font-semibold text-black'>DocFlows</span>
          </div>
          <p className='text-gray-600'>No current active runs</p>
        </div>
      )}
      <EntityTable
        name='Documents'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          {
            field: 'file_name',
            label: 'File Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'owner',
            label: 'Created By',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          { field: 'created_date', label: 'Created Date', type: 'date' },
          { field: 'updated_date', label: 'Updated Date', type: 'date' }
        ]}
        sort={[
          { value: 'id', label: 'Document ID' },
          { value: 'file_name', label: 'File Name' },
          { value: 'created_date', label: 'Date Created' },
          { value: 'updated_date', label: 'Last Modified' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/documents/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'File Name', field: 'fileName' },
          {
            label: 'Created By',
            field: 'owner',
            cell: value => (
              <div className='flex items-center'>
                {value?.profilePhoto ? (
                  <img
                    src={value.profilePhoto}
                    alt={`${value.firstName} ${value.lastName}'s profile`}
                    className='w-8 h-8 rounded-full mr-2'
                  />
                ) : value ? (
                  <div className='w-6 h-6 rounded-full mr-2 bg-neutral-200 flex items-center justify-center'>
                    <UserRound size={16} className='text-white' />
                  </div>
                ) : null}
                <span>
                  {value?.firstName} {value?.lastName}
                </span>
              </div>
            )
          },
          {
            label: 'File Path',
            field: 'filePath'
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        onNewClick={handleNewClick}
        model={Document}
        entityType={EntityTypeEnum.Document}
        canCreate={true}
      />
      {isUploadOpen && (
        <FileUploader
          onClose={() => setIsUploadOpen(false)}
          onUploadSuccess={handleUploadSuccess}
          destinationFolder={'general'}
        />
      )}
      {isConnectionsOpen && (
        <FileConnections
          document={documentData}
          onClose={() => {
            setDocument(null);
            setIsConnectionsOpen(false);
          }}
        />
      )}
      <Popup
        isOpen={showConfirmDialog}
        title='Confirm Process'
        size='sm'
        onClose={() => setShowConfirmDialog(false)}
        footer={
          <div className='flex justify-end gap-4'>
            <SoftButton onClick={() => setShowConfirmDialog(false)}>
              Cancel
            </SoftButton>
            <FilledButton onClick={confirmProcessRun}>Confirm</FilledButton>
          </div>
        }
      >
        <p>
          Are you sure you want to start processing the template{' '}
          <b>
            {
              templates.find(t => t.runs.some(r => r.id === selectedRunId))
                ?.name
            }{' '}
          </b>
          on run{' '}
          <b>
            {(() => {
              const run = templates
                .find(t => t.runs.some(r => r.id === selectedRunId))
                ?.runs.find(r => r.id === selectedRunId);

              if (run?.name) {
                return `${run.name}`;
              }
              return `${run?.id} (${new Date(
                run?.created_date
              ).toLocaleString()})`;
            })()}{' '}
          </b>{' '}
          with{' '}
          <b>
            {
              templates
                .find(t => t.runs.some(r => r.id === selectedRunId))
                ?.runs.find(r => r.id === selectedRunId)?.data.length
            }
          </b>{' '}
          in the run?
        </p>
      </Popup>
    </div>
  );
};

export default Documents;
