import React, { useState, useEffect, useRef } from 'react';
import { Clock } from 'lucide-react';
import { Input } from '../forms/Input';

const TimePicker = ({
  label,
  value,
  onChange,
  placeholder = 'HH:MM',
  className,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const validateTimeInput = input => {
    // Allow empty input
    if (!input) return '';

    // Remove any non-digit characters
    const digits = input.replace(/\D/g, '');

    // Handle hours
    let hours = parseInt(digits.substring(0, 2) || '0');
    if (hours > 23) hours = 23;

    // Handle minutes
    let minutes = parseInt(digits.substring(2, 4) || '0');
    if (minutes > 59) minutes = 59;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  };

  const handleInputChange = e => {
    const rawInput = e.target.value;
    const validTime = validateTimeInput(rawInput);
    setInputValue(validTime);
    onChange(validTime);
  };

  const handleTimeSelect = (hours, minutes) => {
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
    setInputValue(formattedTime);
    onChange(formattedTime);
    setIsOpen(false);
  };

  const renderTimeOptions = () => {
    const options = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        options.push(
          <button
            key={`${h}:${m}`}
            className='block w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none focus:bg-gray-100'
            onClick={() => handleTimeSelect(h, m)}
          >
            {`${h.toString().padStart(2, '0')}:${m
              .toString()
              .padStart(2, '0')}`}
          </button>
        );
      }
    }
    return options;
  };

  return (
    <div className='relative' ref={dropdownRef}>
      <Input
        label={label}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={className}
        rightIcon={Clock}
        onClick={() => setIsOpen(!isOpen)}
        {...props}
      />
      {isOpen && (
        <div className='absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg max-h-60 overflow-auto'>
          <div className='py-1'>{renderTimeOptions()}</div>
        </div>
      )}
    </div>
  );
};

export { TimePicker };
