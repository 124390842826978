import React, { useState, useEffect } from 'react';
import Invoice from '../../../models/Invoice';
import CoreEntityType from '../../../models/CoreEntityType';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate } from '../../../utilities/Formatting';
import { Link, useNavigate } from 'react-router-dom';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { useContext } from 'react';
import { UserProfileContext } from '../../../App';
import UserProfile from '../../../models/UserProfile';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { Alert } from 'core';
import { formatCurrency } from '../../../utilities/Formatting';

const Invoices = () => {
  //Set the page title
  document.title = 'Invoices | CRM | BOHSA LTD';
  const [statusOptions, setStatusOptions] = useState([]);
  const navigate = useNavigate();

  const currentUser = new UserProfile(
    useContext(UserProfileContext).userProfile
  );

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const { data: statuses } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.InvoiceStatus }
        });
        setStatusOptions(
          statuses.map(status => ({ key: status.id, value: status.type }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/invoices/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/invoices/${data.id}`)
    }
    // {
    //   label: 'Delete',
    //   icon: <Trash size={14} className='text-danger-700' />,
    //   onClick: data => console.log('Delete clicked', data)
    // }
  ];

  const handleRowClick = data => {
    navigate(`/invoices/${data.id}`);
  };

  //Test user permissions for view access
  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Invoice,
      PERMISSIONS[EntityTypeEnum.Invoice].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view invoices.'
        style='danger'
      />
    );
  }

  return (
    <div className='p-4'>
      <EntityTable
        name='Invoices'
        filter={[
          { field: 'id', label: 'Invoice Number', type: 'number' },
          { field: 'xero->>number', label: 'Xero Number', type: 'number' },
          {
            field: 'companies.name',
            label: 'Company Name',
            type: 'text',
            operator: 'ilike'
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions
          },
          { field: 'issue_date', label: 'Issue Date', type: 'daterange' },
          { field: 'due_date', label: 'Due Date', type: 'daterange' }
        ]}
        columns={[
          {
            label: 'Invoice Number',
            field: 'id',
            cell: value => (
              <Link
                to={`/invoices/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          {
            label: 'Xero Number',
            field: 'xero.number',
            cell: (value, row) => (
              <Link
                to={`https://go.xero.com/app/!hK!m-/invoicing/view/${row.xero?.id}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {row.xero?.number}
              </Link>
            )
          },
          {
            label: 'Company',
            field: 'company.name',
            foreignKey: { table: 'companies', column: 'name' }
          },
          {
            label: 'Status',
            field: 'status.type',
            foreignKey: { table: 'status', column: 'type' }
          },
          {
            label: 'Issue Date',
            field: 'issueDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Due Date',
            field: 'dueDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Total Amount',
            field: 'total',
            cell: value => formatCurrency(value)
          }
        ]}
        menuItems={menuItems}
        model={Invoice}
        entityType={EntityTypeEnum.Invoice}
        onRowClick={handleRowClick}
        canCreate={false}
      />
    </div>
  );
};

export default Invoices;
