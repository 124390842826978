import UserProfile from './UserProfile';

/**
 * Represents an Imported User Profile in the system.
 * @extends UserProfile
 */
class ImportedUserProfile extends UserProfile {
  /** @type {string} The name of the database table for Imported User Profiles */
  static table = 'imported_user_profiles';

  /** @type {string} The SQL query to select Imported User Profile data */
  static selectQuery = '*';

  /**
   * Creates an ImportedUserProfile instance from database format data
   * @param {Object} data - The database format imported user profile data
   * @returns {ImportedUserProfile} A new ImportedUserProfile instance
   */
  static fromDatabase(data = {}) {
    const importedUserProfile = new ImportedUserProfile();
    importedUserProfile.id = data.id;
    importedUserProfile.createdDate = data.created_date;
    importedUserProfile.updatedDate = data.updated_date;
    importedUserProfile.readOnly = data.read_only;
    return importedUserProfile;
  }

  /**
   * Creates an instance of ImportedUserProfile.
   * @param {Object} data - The imported user profile data.
   */
  constructor(data = {}) {
    super(data);
    this.readOnly = data.read_only ?? null;
    // Add any additional properties specific to imported user profiles here
  }

  /**
   * Get a record by its ID.
   * @param {string|number} id - The ID of the record to fetch.
   * @returns {Promise<BaseModel>} A promise that resolves to a new instance of the model.
   */
  static async getById(id) {
    return this.fetchOne('display_id', id);
  }

  /**
   * Converts the ImportedUserProfile instance to a database-friendly format.
   * @returns {Object} The imported user profile data ready for database operations.
   */
  toDatabase() {
    const baseData = super.toDatabase();
    // Add or override properties as needed for imported user profiles
    return {
      ...baseData,
      read_only: this.readOnly
    };
  }
}

export default ImportedUserProfile;
