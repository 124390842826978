import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Course from '../../../models/Course';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { Pencil, Trash } from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import CoreEntityType from '../../../models/CoreEntityType';
import { UserProfileContext } from '../../../App';
import { Alert, useNotification } from 'core';
import { PERMISSIONS } from '../../../utilities/Permissions';

const Courses = () => {
  //Set the page title
  document.title = 'Courses | CRM | BOHSA LTD';
  const navigate = useNavigate();
  const [statusOptions, setStatuses] = useState([]);
  const [accreditationOptions, setAccreditations] = useState([]);

  const { addNotification } = useNotification();

  const { userProfile: currentUser } = useContext(UserProfileContext);
  //Test user permissions for view access
  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Course,
      PERMISSIONS[EntityTypeEnum.Course].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view courses.'
        style='danger'
      />
    );
  }

  const userCanEdit = currentUser.hasPermission(
    EntityTypeEnum.Course,
    PERMISSIONS[EntityTypeEnum.Course].EDIT
  );

  const userCanDelete = currentUser.hasPermission(
    EntityTypeEnum.Course,
    PERMISSIONS[EntityTypeEnum.Course].DELETE
  );

  useEffect(() => {
    const fetchData = async () => {
      const { data: statusData } = await CoreEntityType.getAll({
        entity_id: {
          value: EntityTypeEnum.CourseStatus,
          operator: FilterOperatorEnum.EQUALS
        }
      });
      const { data: accreditationData } = await CoreEntityType.getAll(
        {
          entity_id: {
            value: EntityTypeEnum.AccreditationBody,
            operator: FilterOperatorEnum.EQUALS
          }
        },
        1,
        100,
        'type'
      );
      setStatuses(
        statusData.map(status => ({ key: status.id, value: status.type }))
      );
      setAccreditations(
        accreditationData.map(acc => ({
          key: acc.id,
          value: acc.type
        }))
      );
    };
    fetchData();
  }, []);

  const menuItems = [
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/courses/${data.id}`)
    },
    userCanDelete && {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: async data => {
        try {
          const course = await Course.getById(data.id);
          course.status = { id: 83 };
          await course.update();
          console.log('Course status set to archived', data);
          addNotification({
            type: 'success',
            title: 'Course Archived',
            description: 'The course status was set to archived.',
            duration: 4000
          });
        } catch (error) {
          console.error('Error setting course status to archived:', error);
        }
      }
    }
  ];

  const handleRowClick = data => {
    navigate(`/courses/${data.id}`);
  };

  const handleCreate = () => {
    navigate('/courses/create');
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Courses'
        filter={[
          {
            field: 'id',
            label: 'ID',
            type: 'text',
            operator: FilterOperatorEnum.TEXTSEARCH
          },
          {
            field: 'name',
            label: 'Course Name',
            type: 'text',
            operator: FilterOperatorEnum.TEXTSEARCH
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            operator: FilterOperatorEnum.EQUALS,
            options: statusOptions
          },
          {
            field: 'accreditation',
            label: 'Accreditation',
            type: 'select',
            operator: FilterOperatorEnum.EQUALS,
            options: accreditationOptions
          },
          {
            field: 'website_details->>visible',
            label: 'On Website',
            type: 'select',
            operator: FilterOperatorEnum.EQUALS,
            options: [
              { key: 'true', value: 'Yes' },
              { key: 'false', value: 'No' }
            ]
          },
          {
            field: 'grant',
            label: 'Grant',
            type: 'boolean',
            operator: FilterOperatorEnum.IS
          },
          {
            field: 'pre_reqs',
            label: 'Pre-Reqs',
            type: 'boolean',
            operator: FilterOperatorEnum.IS
          },
          { field: 'updated_date', label: 'Updated Date', type: 'daterange' }
        ]}
        sort={[
          { value: 'id', label: 'Course ID' },
          { value: 'name', label: 'Course Name' },
          { value: 'created_date', label: 'Date Created' },
          { value: 'updated_date', label: 'Last Modified' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/courses/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Course Name', field: 'name' },
          {
            label: 'Accreditation',
            field: 'accreditation.type'
          },
          {
            label: 'Status',
            field: 'status.name'
          },
          {
            label: 'On Website',
            field: 'websiteDetails',
            cell: value => {
              return value && value.visible ? 'Yes' : 'No';
            }
          },
          {
            label: 'Grant',
            field: 'grant',
            cell: value => (value ? 'Yes' : 'No')
          },
          {
            label: 'Pre-Reqs',
            field: 'pre_reqs',
            cell: value => (value ? 'Yes' : 'No')
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Course}
        entityType={EntityTypeEnum.Course}
        onRowClick={handleRowClick}
        canCreate={true}
        onNewClick={handleCreate}
      />
    </div>
  );
};

export default Courses;
