import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Company from '../../../models/Company';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate, formatProfile } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { Pencil, Trash } from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import { UserProfileContext } from '../../../App';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { useNotification, Alert } from 'core';
import CoreEntityType from '../../../models/CoreEntityType';

const Companies = () => {
  //Set the page title
  document.title = 'Companies | CRM | BOHSA LTD';
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const { userProfile: currentUser } = useContext(UserProfileContext);
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const loadStatusOptions = async () => {
      try {
        const { data: statuses } = await CoreEntityType.getAll({
          entity_id: { value: EntityTypeEnum.CompanyStatus }
        });
        setStatusOptions(
          statuses.map(status => ({
            key: status.id,
            value: status.type
          }))
        );
      } catch (err) {
        console.error('Error loading status options:', err);
      }
    };
    loadStatusOptions();
  }, []);

  //Test user permissions for view access
  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Company,
      PERMISSIONS[EntityTypeEnum.Company].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view companies.'
        style='danger'
      />
    );
  }

  const userCanEdit = currentUser.hasPermission(
    EntityTypeEnum.Company,
    PERMISSIONS[EntityTypeEnum.Company].EDIT
  );

  const userCanDelete = currentUser.hasPermission(
    EntityTypeEnum.Company,
    PERMISSIONS[EntityTypeEnum.Company].DELETE
  );

  const menuItems = [
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/companies/${data.id}`)
    },
    userCanDelete && {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: async data => {
        try {
          const company = await Company.getById(data.id);
          await company.update({ status: { id: 88 } });
          addNotification({
            type: 'success',
            title: 'The company status was set to archived.'
          });
        } catch (err) {
          addNotification({
            type: 'error',
            title: 'Error deleting company',
            description: err.message
          });
        }
      },
      show: userCanDelete
    }
  ];

  const handleRowClick = data => {
    navigate(`/companies/${data.id}`);
  };

  const handleCreate = () => {
    navigate('/companies/create');
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Companies'
        filter={[
          {
            field: 'id',
            label: 'ID',
            type: 'text',
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'name',
            label: 'Company Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'user_profiles.first_name',
            label: 'Account Manager',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'on_account',
            label: 'On Account',
            type: 'select',
            operator: FilterOperatorEnum.EQUALS,
            options: [
              { key: 'true', value: 'Yes' },
              { key: 'false', value: 'No' }
            ]
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            operator: FilterOperatorEnum.EQUALS,
            options: statusOptions
          },
          {
            field: 'tradex',
            label: 'Tradex',
            type: 'select',
            operator: FilterOperatorEnum.EQUALS,
            options: [
              { key: 'true', value: 'Yes' },
              { key: 'false', value: 'No' }
            ]
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'last_booked',
            label: 'Last Booking Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/companies/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Company Name', field: 'name' },
          {
            label: 'Account Manager',
            field: 'accountManager.name',
            foreignKey: { table: 'user_profiles', column: 'first_name' },
            cell: value => formatProfile(value)
          },
          {
            label: 'On Account',
            field: 'onAccount',
            cell: value => (value ? 'Yes' : 'No')
          },
          {
            label: 'Status',
            field: 'status.name',
            foreignKey: { table: 'core_entity_types', column: 'type' }
          },
          {
            label: 'Tradex',
            field: 'tradex',
            cell: value => (value ? 'Yes' : 'No')
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Last Booked',
            field: 'lastBooked',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Company}
        entityType={EntityTypeEnum.Company}
        onRowClick={handleRowClick}
        canCreate={true}
        onNewClick={handleCreate}
      />
    </div>
  );
};

export default Companies;
