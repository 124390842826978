import BaseModel from './BaseModel';

/**
 * Represents an Agreed Rate in the system.
 * @extends BaseModel
 */
class AgreedRate extends BaseModel {
  /** @type {string} The name of the database table for Agreed Rates */
  static table = 'agreed_rates';

  /** @type {string} The SQL query to select Agreed Rate data with related information */
  static selectQuery = `
    *,
    companies (id, name),
    delivery:core_entity_types!delivery_id (id, type),
    courses (id, name),
    trainers (id, name),
    status:core_entity_types!status (id, type)
  `;

  /**
   * Creates an AgreedRate instance from database format data
   * @param {Object} data - The database format agreed rate data
   * @returns {AgreedRate} A new AgreedRate instance
   */
  static fromDatabase(data = {}) {
    const agreedRate = new AgreedRate();
    agreedRate.id = data.id;
    agreedRate.createdDate = data.created_date;
    agreedRate.updatedDate = data.updated_date;
    agreedRate.company = data.companies
      ? { id: data.company_id, name: data.companies.name }
      : null;
    agreedRate.delivery = data.delivery
      ? { id: data.delivery_id, type: data.delivery.type }
      : null;
    agreedRate.course = data.courses
      ? { id: data.course_id, name: data.courses.name }
      : null;
    agreedRate.trainer = data.trainers
      ? { id: data.trainer_id, name: data.trainers.name }
      : null;
    agreedRate.price = data.price;
    agreedRate.status = data.status
      ? { id: data.status.id, type: data.status.type }
      : null;
    return agreedRate;
  }

  /**
   * Creates an instance of AgreedRate.
   * @param {Object} data - The agreed rate data.
   */
  constructor(data = {}) {
    super(data);
    this.company = data.company ?? this.company ?? null;
    this.delivery = data.delivery ?? this.delivery ?? null;
    this.course = data.course ?? this.course ?? null;
    this.trainer = data.trainer ?? this.trainer ?? null;
    this.price = data.price ?? this.price ?? null;
    this.status = data.status ?? this.status ?? null;
  }

  /**
   * Converts the AgreedRate instance to a database-friendly format.
   * @returns {Object} The agreed rate data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      company_id: this.company ? this.company.id : null,
      delivery_id: this.delivery ? this.delivery.id : null,
      course_id: this.course ? this.course.id : null,
      trainer_id: this.trainer ? this.trainer.id : null,
      price: this.price,
      status: this.status?.id ?? this.status,
      created_date: this.createdDate,
      updated_date: this.updatedDate
    };
  }
}

export default AgreedRate;
