import BaseModel from './BaseModel';

/**
 * Represents a Guide in the system.
 * @extends BaseModel
 */
class Guide extends BaseModel {
  /** @type {string} The name of the database table for Guides */
  static table = 'guides';

  /** @type {string} The SQL query to select Guide data */
  static selectQuery =
    '*, category:core_entity_types!category(*), createdBy:user_profiles!guides_created_by_fkey(*), updatedBy:user_profiles!guides_updated_by_fkey(*)';

  /**
   * Creates a Guide instance from database format data
   * @param {Object} data - The database format guide data
   * @returns {Guide} A new Guide instance
   */
  static fromDatabase(data = {}) {
    const guide = new Guide();
    guide.id = data.id;
    guide.createdDate = data.created_date;
    guide.updatedDate = data.updated_date;
    guide.title = data.title;
    guide.description = data.description;
    guide.slug = data.slug;
    guide.content = data.content;
    guide.category = data.category || null;
    guide.createdBy = data.createdBy || null;
    guide.updatedBy = data.updatedBy || null;
    guide.attachments = data.attachments;
    return guide;
  }

  /**
   * Creates an instance of Guide.
   * @param {Object} data - The guide data.
   */
  constructor(data = {}) {
    super(data);
    this.title = data.title ?? this.title ?? null;
    this.description = data.description ?? this.description ?? null;
    this.slug = data.slug ?? this.slug ?? null;
    this.content = data.content ?? this.content ?? null;
    this.category = data.category ?? this.category ?? null;
    this.createdBy = data.createdBy ?? this.createdBy ?? null;
    this.updatedBy = data.updatedBy ?? this.updatedBy ?? null;
    this.attachments = data.attachments ?? this.attachments ?? null;
  }

  /**
   * Converts the Guide instance to a database-friendly format.
   * @returns {Object} The guide data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      title: this.title,
      description: this.description,
      slug: this.slug,
      content: this.content,
      category: this.category?.id || null,
      created_by: this.createdBy.id,
      updated_by: this.updatedBy.id,
      attachments: this.attachments
    };
  }
}

export default Guide;
