import BaseModel from './BaseModel';

/**
 * Represents a Comment in the system.
 * @extends BaseModel
 */
class Comment extends BaseModel {
  /** @type {string} The name of the database table for Comments */
  static table = 'comments';

  /** @type {string} The SQL query to select Comment data with related information */
  static selectQuery = `
		*,
		owner:user_profiles(id, first_name, last_name, profile_photo)
	`;

  /**
   * Creates a Comment instance from database format data
   * @param {Object} data - The database format comment data
   * @returns {Comment} A new Comment instance
   */
  static fromDatabase(data = {}) {
    const comment = new Comment();
    comment.id = data.id;
    comment.createdDate = data.created_date;
    comment.updatedDate = data.updated_date;
    comment.owner = data.owner
      ? {
          id: data.owner.id,
          firstName: data.owner.first_name,
          lastName: data.owner.last_name,
          profilePhoto: data.owner.profile_photo
        }
      : null;
    comment.entityType = data.entity_type;
    comment.entityId = data.entity_id;
    comment.comment = data.comment;
    return comment;
  }

  /**
   * Creates an instance of Comment.
   * @param {Object} data - The comment data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner ?? this.owner ?? null;
    this.entityType = data.entityType ?? this.entityType ?? null;
    this.entityId = data.entityId ?? this.entityId ?? null;
    this.comment = data.comment ?? this.comment ?? null;
  }

  /**
   * Converts the Comment instance to a database-friendly format.
   * @returns {Object} The comment data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      entity_type: this.entityType,
      entity_id: this.entityId,
      comment: this.comment,
      owner: this.owner ? this.owner.id : null
    };
  }
}

export default Comment;
