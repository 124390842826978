import BaseModel from './BaseModel';

/**
 * Represents a Resource in the system.
 * @extends BaseModel
 */
class Resource extends BaseModel {
  /** @type {string} The name of the database table for Resources */
  static table = 'resources';

  /** @type {string} The SQL query to select Resource data */
  static selectQuery =
    '*, owner:user_profiles!inner (id, auth, first_name, last_name, email, profile_photo)';

  /**
   * Creates a Resource instance from database format data
   * @param {Object} data - The database format resource data
   * @returns {Resource} A new Resource instance
   */
  static fromDatabase(data = {}) {
    const resource = new Resource();
    resource.id = data.id;
    resource.createdDate = data.created_date;
    resource.updatedDate = data.updated_date;
    resource.image = data.image;
    resource.name = data.name;
    resource.description = data.description;
    resource.content = data.content;
    resource.tags = data.tags || [];
    resource.files = data.files || [];
    resource.links = data.links || {};
    resource.owner = data.owner
      ? {
          id: data.owner.id,
          auth: data.owner.auth,
          firstName: data.owner.first_name,
          lastName: data.owner.last_name,
          fullName: `${data.owner.first_name} ${data.owner.last_name}`,
          email: data.owner.email,
          profilePhoto: data.owner.profile_photo
        }
      : null;
    return resource;
  }

  /**
   * Creates an instance of Resource.
   * @param {Object} data - The resource data.
   */
  constructor(data = {}) {
    super(data);
    this.image = data.image ?? this.image ?? null;
    this.name = data.name ?? this.name ?? null;
    this.description = data.description ?? this.description ?? null;
    this.content = data.content ?? this.content ?? null;
    this.tags = data.tags ?? this.tags ?? [];
    this.files = data.files ?? this.files ?? [];
    this.links = data.links ?? this.links ?? {};
    this.owner = data.owner ?? this.owner ?? null;
  }

  /**
   * Converts the Resource instance to a database-friendly format.
   * @returns {Object} The resource data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      image: this.image,
      name: this.name,
      description: this.description,
      content: this.content,
      tags: this.tags,
      files: this.files,
      links: this.links,
      owner: this.owner ? this.owner.id : null
    };
  }
}

export default Resource;
