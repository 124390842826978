import React, { useState, useEffect } from 'react';

/**
 * Tooltip Component
 *
 * @param {React.ReactNode} props.children - The element that triggers the tooltip
 * @param {string|React.ReactNode} props.content - The content to show in the tooltip
 * @param {string} [props.size='md'] - The size of the tooltip ('xs', 'sm', 'md', 'lg', 'xl')
 * @param {string} [props.className] - Additional CSS classes
 */
const Tooltip = ({ children, content, size = 'md', className = '' }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const sizeClasses = {
    xs: 'px-1.5 py-1 text-xs',
    sm: 'px-2 py-1 text-xs',
    md: 'px-3 py-2 text-sm',
    lg: 'px-3 py-2 text-base',
    xl: 'px-4 py-3 text-base'
  };

  useEffect(() => {
    let timeoutId;
    if (isVisible) {
      timeoutId = setTimeout(() => setOpacity(1), 10);
    } else {
      setOpacity(0);
    }
    return () => clearTimeout(timeoutId);
  }, [isVisible]);

  return (
    <div className='relative inline-block'>
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div
          className={`absolute z-10 font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 transition-opacity duration-300 ease-in-out -translate-y-1 ${sizeClasses[size]} ${className}`}
          style={{ opacity }}
        >
          {content}
          <div className='tooltip-arrow' data-popper-arrow></div>
        </div>
      )}
    </div>
  );
};

export { Tooltip };
