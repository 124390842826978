import BaseModel from './BaseModel';

class ActivityTemplate extends BaseModel {
  static table = 'activity_templates';

  /**
   * Creates an ActivityTemplate instance from database format data
   * @param {Object} data - The database format activity template data
   * @returns {ActivityTemplate} A new ActivityTemplate instance
   */
  static fromDatabase(data = {}) {
    const template = new ActivityTemplate();
    template.id = data.id;
    template.createdDate = data.created_date;
    template.updatedDate = data.updated_date;
    template.owner = data.owner;
    template.title = data.title;
    template.icon = data.icon;
    template.colour = data.colour;
    template.content = data.content;
    template.type = data.type;
    return template;
  }

  /**
   * Creates an instance of ActivityTemplate.
   * @param {Object} data - The activity template data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner ?? this.owner ?? null;
    this.title = data.title ?? this.title ?? null;
    this.icon = data.icon ?? this.icon ?? null;
    this.colour = data.colour ?? this.colour ?? null;
    this.content = data.content ?? this.content ?? null;
    this.type = data.type ?? this.type ?? null;
  }

  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner,
      title: this.title,
      icon: this.icon,
      colour: this.colour,
      content: this.content,
      type: this.type
    };
  }
}

export default ActivityTemplate;
