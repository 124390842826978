import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { FilledButton, SoftButton, Input, DropdownInput, ComboBox } from 'core';
import CourseOption from '../../../models/CourseOption';
import CoreEntityType from '../../../models/CoreEntityType';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import Course from '../../../models/Course';
import Trainer from '../../../models/Trainer';
import Location from '../../../models/Location';

const CreateCourseOption = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const trainerId = searchParams.get('trainer');
  const courseId = searchParams.get('course');

  const [currentStep, setCurrentStep] = useState(0);
  const [courseOptionData, setCourseOptionData] = useState({
    trainer: null,
    course: null,
    deliveryType: null,
    location: null,
    price: '',
    cost: '',
    duration: '',
    validFor: ''
  });

  const [options, setOptions] = useState({
    deliveryType: [],
    location: []
  });

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const { data: deliveryTypes } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.CourseDelivery }
        });

        setOptions(prevOptions => ({
          ...prevOptions,
          deliveryType: deliveryTypes.map(type => ({
            value: type.id,
            label: type.type
          }))
        }));

        if (trainerId) {
          const trainer = await Trainer.getById(trainerId);
          setCourseOptionData(prev => ({
            ...prev,
            trainer: { value: trainer.id, label: trainer.name }
          }));
        }

        if (courseId) {
          const course = await Course.getById(courseId);
          setCourseOptionData(prev => ({
            ...prev,
            course: { value: course.id, label: course.name }
          }));
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, [trainerId, courseId]);

  useEffect(() => {
    const fetchLocations = async () => {
      if (courseOptionData.trainer?.value) {
        try {
          const { data: locations } = await Location.getAll({
            entity_id: {
              operator: 'eq',
              value: courseOptionData.trainer.value
            },
            entity_type: { value: EntityTypeEnum.Trainer }
          });

          setOptions(prev => ({
            ...prev,
            location: locations.map(location => ({
              value: location.id,
              label: location.name,
              secondaryLabel: location.fullAddress
            }))
          }));
        } catch (error) {
          console.error('Error fetching locations:', error);
        }
      }
    };

    fetchLocations();
  }, [courseOptionData.trainer]);

  const handleInputChange = useCallback((field, value) => {
    setCourseOptionData(prev => ({ ...prev, [field]: value }));
  }, []);

  const loadTrainers = async (query, page) => {
    const { data } = await Trainer.getAll({
      name: { operator: FilterOperatorEnum.TEXTSEARCH, value: query }
    });
    return data.map(trainer => ({
      value: trainer.id,
      label: trainer.name
    }));
  };

  const loadLocations = async (query, page) => {
    const { data } = await Location.getAll({
      name: { operator: FilterOperatorEnum.TEXTSEARCH, value: query },
      entity_id: { operator: 'eq', value: courseOptionData.trainer?.value },
      entity_type: { operator: 'eq', value: EntityTypeEnum.Trainer }
    });
    return data.map(location => ({
      value: location.id,
      label: location.name,
      secondaryLabel: location.fullAddress
    }));
  };

  const loadCourses = async (query, page) => {
    const { data } = await Course.getAll({
      name: { operator: FilterOperatorEnum.TEXTSEARCH, value: query }
    });
    return data.map(course => ({
      value: course.id,
      label: course.name
    }));
  };

  const handleSubmit = async (navigateToCourseOption = false) => {
    try {
      const newCourseOption = new CourseOption({
        trainerId: courseOptionData.trainer?.value,
        courseId: courseOptionData.course?.value,
        courseDelivery: courseOptionData.deliveryType?.value,
        locationId: courseOptionData.location?.value,
        price: parseFloat(courseOptionData.price),
        cost: parseFloat(courseOptionData.cost),
        duration: courseOptionData.duration,
        validFor: courseOptionData.validFor,
        status: { id: 98 } // Active status
      });

      const insertedCourseOption = await newCourseOption.insert();

      if (navigateToCourseOption) {
        navigate(`/course-options/${insertedCourseOption.id}`);
      } else {
        if (trainerId) {
          navigate(`/trainers/${trainerId}/course-options`);
        } else if (courseId) {
          navigate(`/courses/${courseId}/course-options`);
        } else {
          navigate('/course-options');
        }
      }
    } catch (error) {
      console.error('Error creating course option:', error);
    }
  };

  const steps = [
    {
      title: 'Details',
      render: () => (
        <div className='space-y-6'>
          <ComboBox
            label='Trainer'
            loadOptions={loadTrainers}
            value={courseOptionData.trainer}
            onChange={value => handleInputChange('trainer', value)}
            placeholder='Select a trainer'
          />

          <ComboBox
            label='Course'
            loadOptions={loadCourses}
            value={courseOptionData.course}
            onChange={value => handleInputChange('course', value)}
            placeholder='Select a course'
            isDisabled={!!courseId}
          />

          <DropdownInput
            label='Delivery Type'
            options={options.deliveryType}
            value={courseOptionData.deliveryType?.value || ''}
            onChange={e =>
              handleInputChange('deliveryType', {
                value: e.target.value,
                label: e.target.options[e.target.selectedIndex].text
              })
            }
            placeholder='Select delivery type'
          />

          {courseOptionData.deliveryType?.label === 'Classroom' && (
            <ComboBox
              label='Location'
              loadOptions={loadLocations}
              value={courseOptionData.location}
              onChange={value => handleInputChange('location', value)}
              placeholder='Select location'
            />
          )}
        </div>
      )
    },
    {
      title: 'Options',
      render: () => (
        <div className='space-y-6'>
          <Input
            type='number'
            label='Price'
            value={courseOptionData.price}
            onChange={e => handleInputChange('price', e.target.value)}
          />

          <Input
            type='number'
            label='Cost'
            value={courseOptionData.cost}
            onChange={e => handleInputChange('cost', e.target.value)}
          />

          <Input
            type='text'
            label='Duration'
            value={courseOptionData.duration}
            onChange={e => handleInputChange('duration', e.target.value)}
          />

          <Input
            type='text'
            label='Valid For'
            value={courseOptionData.validFor}
            onChange={e => handleInputChange('validFor', e.target.value)}
          />
        </div>
      )
    }
  ];

  return (
    <div className='bg-base-100 h-full overflow-y-auto'>
      <div className='max-w-4xl mx-auto px-4 py-8'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={() => {
            if (trainerId) {
              navigate(`/trainers/${trainerId}`);
            } else if (courseId) {
              navigate(`/courses/${courseId}/course-options`);
            } else {
              navigate('/course-options');
            }
          }}
          className='mb-8'
        >
          {trainerId
            ? 'Back to Trainer'
            : courseId
            ? 'Back to Course'
            : 'Back to Course Options'}
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-visible'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>Create New Course Option</h2>
          </div>

          <div className='p-6'>
            <div className='mb-8'>
              <div className='flex justify-between'>
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${
                      index <= currentStep ? 'text-brand-700' : 'text-base-400'
                    }`}
                  >
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        index <= currentStep
                          ? 'bg-brand-700 text-white'
                          : 'bg-base-200'
                      }`}
                    >
                      {index + 1}
                    </div>
                    <span className='ml-2 text-sm font-medium'>
                      {step.title}
                    </span>
                  </div>
                ))}
              </div>
              <div className='mt-4 h-2 bg-base-200 rounded-full'>
                <div
                  className='h-full bg-brand-700 rounded-full transition-all duration-300 ease-in-out'
                  style={{
                    width: `${((currentStep + 1) / steps.length) * 100}%`
                  }}
                ></div>
              </div>
            </div>

            {steps[currentStep].render()}

            <div className='mt-8 flex justify-between'>
              <SoftButton
                colour='primary'
                size='lg'
                leftIcon={<ArrowLeft size={18} />}
                onClick={() => setCurrentStep(prev => Math.max(0, prev - 1))}
                disabled={currentStep === 0}
              >
                Previous
              </SoftButton>
              {currentStep < steps.length - 1 ? (
                <FilledButton
                  colour='primary'
                  size='lg'
                  rightIcon={<ArrowRight size={18} />}
                  onClick={() =>
                    setCurrentStep(prev => Math.min(steps.length - 1, prev + 1))
                  }
                >
                  Next
                </FilledButton>
              ) : (
                <div className='flex flex-row space-x-4'>
                  <FilledButton
                    colour='base'
                    size='lg'
                    onClick={() => handleSubmit(false)}
                  >
                    Finish
                  </FilledButton>
                  <FilledButton
                    colour='primary'
                    size='lg'
                    onClick={() => handleSubmit(true)}
                  >
                    Finish and Open
                  </FilledButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCourseOption;
