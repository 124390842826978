import React, { useState } from 'react';
import { SoftButton, TextButton, Popup, Input } from 'core';
import Trainer from '../../../models/Trainer';
import { supabase } from '../../../utilities/supabase';

const LinkToXero = ({ trainerId, isOpen, onClose }) => {
  const [xeroContacts, setXeroContacts] = useState([]);
  const [showXeroResults, setShowXeroResults] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [companyName, setCompanyName] = useState('');

  const searchXeroContacts = async () => {
    try {
      const { data, error } = await supabase.functions.invoke(
        'xero/searchContacts',
        {
          body: { searchTerm: companyName, isSupplier: true }
        }
      );

      if (error) throw error;
      console.log('Xero API response:', data);
      setXeroContacts(data);
      setShowXeroResults(true);
    } catch (error) {
      console.error('Error fetching Xero contacts:', error);
    }
  };

  const selectXeroContact = contact => {
    setSelectedContact(contact);
    setShowXeroResults(false);
  };

  const handleLink = async () => {
    if (!selectedContact) return;

    try {
      const trainer = await Trainer.getById(trainerId);
      trainer.xeroId = selectedContact.ContactID;
      trainer.accountNumber =
        selectedContact.AccountNumber || trainer.accountNumber;
      await trainer.update();

      onClose();
    } catch (error) {
      console.error('Error linking Xero contact:', error);
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title='Link Trainer to Xero'
      size='sm'
    >
      <div className='space-y-4'>
        <div>
          <Input
            type='text'
            placeholder='Enter Company Name'
            value={companyName}
            onChange={e => setCompanyName(e.target.value)}
          />
          <TextButton size='sm' onClick={searchXeroContacts}>
            Search Xero Contacts
          </TextButton>
          {showXeroResults && xeroContacts.length > 0 && (
            <div className='mt-2 p-2 border border-base-200 rounded-lg max-h-40 overflow-y-auto'>
              {xeroContacts.map(contact => (
                <div
                  key={contact.ContactID}
                  className='p-2 hover:bg-base-100 cursor-pointer text-sm'
                  onClick={() => selectXeroContact(contact)}
                >
                  {contact.Name} - {contact.AccountNumber}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className='mt-6 flex justify-end space-x-4'>
        <SoftButton colour='base' size='lg' onClick={onClose}>
          Cancel
        </SoftButton>
        <SoftButton
          colour='primary'
          size='lg'
          onClick={handleLink}
          disabled={!selectedContact}
        >
          Link
        </SoftButton>
      </div>
    </Popup>
  );
};

export default LinkToXero;
