import React, { useState, useEffect } from 'react';
import { SoftButton, Modal, PageHeading, FilledButton } from 'core';
import { supabase } from '../../../../utilities/supabase';
import { Pencil, Trash2, GripVertical, User, MoreVertical } from 'lucide-react';
import AddRole from '../../../../components/popups/settings/roles/AddRole';
import EditRole from '../../../../components/popups/settings/roles/EditRole';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useNotification } from 'core';
import { Menu, MenuItem, MenuButton, MenuItems } from '@headlessui/react';

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { addNotification } = useNotification();

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const { data, error } = await supabase
        .from('permission_groups')
        .select(
          `
          *,
          users:user_profiles(
            id,
            email,
            profile_photo
          )
        `
        )
        .order('hierarchy', { ascending: false });

      if (error) throw error;
      setRoles(data || []);
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = role => {
    setRoleToDelete(role);
    setIsDeleteModalOpen(true);
  };

  const handleEditClick = role => {
    setRoleToEdit(role);
    setIsEditModalOpen(true);
  };

  const deleteRole = async () => {
    try {
      const { error } = await supabase
        .from('permission_groups')
        .delete()
        .eq('id', roleToDelete.id);

      if (error) throw error;
      await fetchRoles();
      setIsDeleteModalOpen(false);
      setRoleToDelete(null);
    } catch (error) {
      console.error('Error deleting role:', error);
      if (error.code === '23503') {
        addNotification({
          type: 'error',
          title: 'Could not delete role',
          description:
            'This role is currently assigned to users and cannot be deleted.',
          duration: 4000
        });
      } else {
        addNotification({
          type: 'error',
          title: 'Could not delete role',
          description: 'An error occurred while trying to delete this role.',
          duration: 4000
        });
      }
    }
  };

  const handleDragEnd = async result => {
    if (!result.destination) return;

    const items = Array.from(roles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update the hierarchy values based on new positions
    const updatedItems = items.map((item, index) => ({
      ...item,
      hierarchy: items.length - index // Reverse index for hierarchy (highest at top)
    }));

    setRoles(updatedItems);

    // Update the database
    try {
      const updates = updatedItems.map(item => ({
        id: item.id,
        label: item.label,
        hierarchy: item.hierarchy
      }));

      const { error } = await supabase
        .from('permission_groups')
        .upsert(updates);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating hierarchies:', error);
      // Revert to original order if update fails
      await fetchRoles();
    }
  };

  return (
    <div className='container mx-auto flex flex-col h-full'>
      <PageHeading
        title='Roles & Permissions'
        pages={[
          { name: 'Settings', href: '/settings' },
          { name: 'Roles & Permissions', href: '/settings/roles' }
        ]}
        actions={
          <FilledButton onClick={() => setIsAddModalOpen(true)}>
            Add Role
          </FilledButton>
        }
      />

      <div className=' py-6'>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='roles'>
            {provided => (
              <ul
                className='divide-y divide-gray-200 bg-white shadow rounded-lg'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {roles.map((role, index) => (
                  <Draggable
                    key={role.id}
                    draggableId={role.id.toString()}
                    index={index}
                  >
                    {provided => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className='p-4 sm:p-6 flex flex-row items-start sm:items-center gap-4 sm:gap-6'
                      >
                        <div {...provided.dragHandleProps}>
                          <GripVertical className='h-5 w-5 text-gray-400' />
                        </div>

                        <div className='flex-1 flex flex-col sm:flex-row sm:items-center gap-4'>
                          <div className='min-w-[200px] md:w-96'>
                            <h3 className='text-sm font-medium text-gray-900'>
                              {role.label}
                            </h3>
                            <p className='text-xs text-gray-500'>
                              Hierarchy: {role.hierarchy}
                            </p>
                          </div>

                          <div className='flex items-center gap-4'>
                            <div className='flex -space-x-2'>
                              {role.users?.slice(0, 5).map((user, i) => (
                                <div
                                  key={user.id}
                                  className='relative inline-block h-6 w-6 sm:h-8 sm:w-8 rounded-full bg-gray-100 ring-2 ring-white'
                                >
                                  {user.profile_photo ? (
                                    <img
                                      src={user.profile_photo}
                                      alt={user.email}
                                      className='h-full w-full rounded-full object-cover'
                                    />
                                  ) : (
                                    <div className='flex h-full w-full items-center justify-center rounded-full bg-gray-100'>
                                      <User className='h-3 w-3 sm:h-4 sm:w-4 text-gray-500' />
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                            {role.users?.length > 5 && (
                              <span className='text-xs md:text-sm text-gray-500 whitespace-nowrap'>
                                + {role.users.length - 5} others
                              </span>
                            )}
                          </div>
                        </div>

                        <Menu as='div' className='relative flex-none'>
                          <MenuButton className='-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900'>
                            <span className='sr-only'>Open options</span>
                            <MoreVertical
                              className='h-5 w-5'
                              aria-hidden='true'
                            />
                          </MenuButton>
                          <MenuItems className='absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                            <MenuItem>
                              {({ active }) => (
                                <button
                                  onClick={() => handleEditClick(role)}
                                  className={`block w-full px-3 py-1 text-sm/6 text-gray-900 ${
                                    active ? 'bg-gray-50' : ''
                                  }`}
                                >
                                  <div className='flex items-center gap-2'>
                                    <Pencil className='h-4 w-4' />
                                    Edit
                                  </div>
                                </button>
                              )}
                            </MenuItem>
                            <MenuItem>
                              {({ active }) => (
                                <button
                                  onClick={() => handleDeleteClick(role)}
                                  className={`block w-full px-3 py-1 text-sm/6 text-red-600 ${
                                    active ? 'bg-gray-50' : ''
                                  }`}
                                >
                                  <div className='flex items-center gap-2'>
                                    <Trash2 className='h-4 w-4' />
                                    Delete
                                  </div>
                                </button>
                              )}
                            </MenuItem>
                          </MenuItems>
                        </Menu>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setRoleToDelete(null);
        }}
        title='Delete Role'
        style='danger'
        actions={[
          {
            label: 'Delete',
            onClick: deleteRole,
            style: 'danger'
          },
          {
            label: 'Cancel',
            onClick: () => {
              setIsDeleteModalOpen(false);
              setRoleToDelete(null);
            },
            style: 'base'
          }
        ]}
      >
        <p className='text-sm text-gray-500'>
          Are you sure you want to delete the role "{roleToDelete?.label}"? This
          action cannot be undone.
        </p>
      </Modal>

      <AddRole
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSuccess={fetchRoles}
      />

      <EditRole
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setRoleToEdit(null);
        }}
        onSuccess={fetchRoles}
        role={roleToEdit}
      />
    </div>
  );
};

export default Roles;
