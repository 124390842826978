import React, { useState, useEffect } from 'react';
import Location from '../../../../models/Location';
import InvoiceLine from '../../../../models/InvoiceLine';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';

const Preview = ({ invoice }) => {
  const [billToLocation, setBillToLocation] = useState(null);
  const [invoiceLines, setInvoiceLines] = useState([]);

  //console.log(invoice);

  useEffect(() => {
    const fetchLocations = async () => {
      if (invoice && invoice.status.id === 220) {
        setBillToLocation(invoice.billingAddress);
      } else if (
        invoice &&
        invoice.company &&
        invoice.company.id &&
        invoice.addressId
      ) {
        try {
          const locations = await Location.getAll({
            id: {
              operator: FilterOperatorEnum.EQUALS,
              value: invoice.addressId
            }
          });
          if (locations.data && locations.data.length > 0) {
            setBillToLocation(locations.data[0]);
          }
        } catch (error) {
          console.error('Error fetching locations:', error);
        }
      }
    };

    const fetchInvoiceLines = async () => {
      if (invoice && invoice.id) {
        try {
          const lines = await InvoiceLine.getAll({
            invoice_id: { operator: 'eq', value: invoice.id }
          });
          if (lines.data) {
            setInvoiceLines(lines.data);
          }
        } catch (error) {
          console.error('Error fetching invoice lines:', error);
        }
      }
    };

    fetchLocations();
    fetchInvoiceLines();

    //console.log(invoiceLines);
  }, [invoice]);

  return (
    invoice && (
      <div className='bg-white shadow-sm p-10 border rounded-lg m-8'>
        <div className='flex justify-between items-start mb-8'>
          <div>
            <div className='text-lg font-semibold text-base-800'>
              Invoice
              <span className='ml-2 text-base font-normal text-base-400'>
                #{invoice.id}
              </span>
            </div>
          </div>
          <div className='text-right'>
            <img
              src='/bohsa-creditsafe.jpg'
              alt='Creditsafe Logo'
              className='w-40 h-auto mb-1'
            />
          </div>
        </div>

        <div className='flex justify-between mb-8'>
          <div>
            <h4 className='text-base-800 font-semibold text-sm'>From</h4>
            <p className='text-base-800 text-sm leading-6 font-medium mt-2'>
              BOHSA Ltd
            </p>
            <p className='text-base-500 text-sm leading-6'>
              The Business Lodge
            </p>
            <p className='text-base-500 text-sm leading-6'>Trent House</p>
            <p className='text-base-500 text-sm leading-6'>234 Victoria Road</p>
            <p className='text-base-500 text-sm leading-6'>Stoke on Trent</p>
            <p className='text-base-500 text-sm leading-6'>Staffordshire</p>
            <p className='text-base-500 text-sm leading-6'>ST4 2LW</p>
          </div>
          <div>
            <h4 className='text-base-800 font-semibold text-sm'>To</h4>
            <p className='text-base-800 text-sm leading-6 font-medium mt-2'>
              {invoice.company.name}
            </p>
            {billToLocation && (
              <>
                <p className='text-base-500 text-sm leading-6'>
                  {billToLocation.address1}
                </p>
                <p className='text-base-500 text-sm leading-6'>
                  {billToLocation.address2}
                </p>
                <p className='text-base-500 text-sm leading-6'>
                  {billToLocation.city}, {billToLocation.postcode}
                </p>
              </>
            )}
          </div>
          <div className='text-right'>
            <p className='text-base-500'>
              <span className='font-medium text-sm'>Issued on</span>{' '}
              <span className='text-base-700 text-sm font-medium'>
                {new Date(invoice.issueDate).toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })}
              </span>
            </p>
            <p className='text-base-500'>
              <span className='font-medium text-sm'>Due on</span>{' '}
              <span className='text-base-700 text-sm font-medium'>
                {new Date(invoice.dueDate).toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })}
              </span>
            </p>
          </div>
        </div>

        <table className='w-full mb-8'>
          <thead>
            <tr className='border-b border-base-200'>
              <th className='text-left py-2 text-sm font-semibold leading-6 max-w-64'>
                Description
              </th>
              <th className='text-right py-2 text-sm font-semibold leading-6'>
                Qty
              </th>
              <th className='text-right py-2 text-sm font-semibold leading-6'>
                Price
              </th>
              <th className='text-right py-2 text-sm font-semibold leading-6'>
                Tax Rate
              </th>
              <th className='text-right py-2 text-sm font-semibold leading-6'>
                Tax Amount
              </th>
              <th className='text-right py-2 text-sm font-semibold leading-6'>
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {invoiceLines
              .filter(line => line.additionalId === null)
              .map((line, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td className='py-2 text-sm font-normal leading-5 word-wrap max-w-64 text-left align-top'>
                      {line.description.split('\n').map((text, index) => (
                        <React.Fragment key={index}>
                          {text}
                          {index < line.description.split('\n').length - 1 && (
                            <br />
                          )}
                        </React.Fragment>
                      ))}
                    </td>
                    <td className='text-right py-2 text-sm font-normal leading-6 tabular-nums align-top'>
                      {line.quantity === null ? '-' : `${line.quantity}`}
                    </td>
                    <td className='text-right py-2 text-sm font-normal leading-6 tabular-nums align-top'>
                      {line.price === null ? '-' : `£${line.price.toFixed(2)}`}
                    </td>
                    <td className='text-right py-2 text-sm font-normal leading-6 tabular-nums align-top'>
                      {line.tax.rate === null
                        ? '-'
                        : `${line.tax.rate.toFixed(2)}%`}
                    </td>
                    <td className='text-right py-2 text-sm font-normal leading-6 tabular-nums align-top'>
                      {line.tax.amount === null
                        ? '-'
                        : `£${line.tax.amount.toFixed(2)}`}
                    </td>
                    <td className='text-right py-2 text-sm font-normal leading-6 tabular-nums align-top'>
                      {line.amount === null
                        ? '-'
                        : `£${line.amount.toFixed(2)}`}
                    </td>
                  </tr>
                  {invoiceLines
                    .filter(
                      additionalLine =>
                        additionalLine.additionalId !== null &&
                        additionalLine.bookingId === line.bookingId
                    )
                    .map((additionalLine, additionalIndex) => (
                      <tr
                        key={`${index}-${additionalIndex}`}
                        className={`${
                          additionalIndex ===
                          invoiceLines.filter(
                            additionalLine =>
                              additionalLine.additionalId !== null &&
                              additionalLine.bookingId === line.bookingId
                          ).length -
                            1
                            ? 'border-b'
                            : ''
                        }`}
                      >
                        <td className='py-1 text-xs font-normal leading-5 word-wrap pl-8 text-base-500 text-left align-top'>
                          {additionalLine.description}
                        </td>
                        <td className='text-right py-1 text-xs font-normal leading-6 tabular-nums text-base-500 align-top'>
                          {additionalLine.quantity === null
                            ? '-'
                            : `${additionalLine.quantity}`}
                        </td>
                        <td className='text-right py-1 text-xs font-normal leading-6 tabular-nums text-base-500 align-top'>
                          {additionalLine.price === null
                            ? '-'
                            : `£${additionalLine.price.toFixed(2)}`}
                        </td>
                        <td className='text-right py-1 text-xs font-normal leading-6 tabular-nums text-base-500 align-top'>
                          {additionalLine.tax.rate === null
                            ? '-'
                            : `${additionalLine.tax.rate.toFixed(2)}%`}
                        </td>
                        <td className='text-right py-1 text-xs font-normal leading-6 tabular-nums text-base-500 align-top'>
                          {additionalLine.tax.amount === null
                            ? '-'
                            : `£${additionalLine.tax.amount.toFixed(2)}`}
                        </td>
                        <td className='text-right py-1 text-xs font-normal leading-6 tabular-nums text-base-500 align-top'>
                          {additionalLine.amount === null
                            ? '-'
                            : `£${additionalLine.amount.toFixed(2)}`}
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
          </tbody>
        </table>

        <div className='flex justify-end'>
          <div className='w-1/2'>
            <div className='flex justify-between py-2 text-sm font-normal leading-6 text-base-700'>
              <span className='font-normal text-base-700'>Subtotal:</span>
              <span className='tabular-nums'>
                £{invoice.subtotal?.toFixed(2)}
              </span>
            </div>
            <div className='flex justify-between py-2 text-sm font-normal leading-6 text-base-700'>
              <span className='font-normal text-base-700'>Total VAT:</span>
              <span className='tabular-nums'>
                £{invoice.totalTax?.toFixed(2)}
              </span>
            </div>
            <div className='flex justify-between py-2 border-t border-base-200'>
              <span className='text-sm font-semibold leading-6 text-base-900'>
                Total:
              </span>
              <span className='text-sm font-semibold leading-6 text-base-900 tabular-nums'>
                £{invoice.total?.toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Preview;
