import BaseModel from './BaseModel';

/**
 * Represents an InvoiceLine in the system.
 * @extends BaseModel
 */
class InvoiceLine extends BaseModel {
  /** @type {string} The name of the database table for InvoiceLines */
  static table = 'invoice_lines';

  /** @type {string} The SQL query to select InvoiceLine data with related information */
  static selectQuery = `
    *,
    invoices!inner (id)
  `;

  /**
   * Creates an InvoiceLine instance from database format data
   * @param {Object} data - The database format invoice line data
   * @returns {InvoiceLine} A new InvoiceLine instance
   */
  static fromDatabase(data = {}) {
    const invoiceLine = new InvoiceLine();
    invoiceLine.id = data.id;
    invoiceLine.createdDate = data.created_date;
    invoiceLine.updatedDate = data.updated_date;
    invoiceLine.invoiceId = data.invoice_id;
    invoiceLine.description = data.description;
    invoiceLine.bookingId = data.booking_id;
    invoiceLine.additionalId = data.additional_id;
    invoiceLine.tax = {
      rate: data.tax_rate,
      amount: data.tax_amount
    };
    invoiceLine.quantity = data.quantity;
    invoiceLine.price = data.price;
    invoiceLine.amount = data.amount;
    return invoiceLine;
  }

  /**
   * Creates an instance of InvoiceLine.
   * @param {Object} data - The invoice line data.
   */
  constructor(data = {}) {
    super(data);
    this.invoiceId = data.invoiceId ?? this.invoiceId ?? null;
    this.description = data.description ?? this.description ?? null;
    this.createdDate = data.createdDate ?? this.createdDate ?? null;
    this.updatedDate = data.updatedDate ?? this.updatedDate ?? null;
    this.bookingId = data.bookingId ?? this.bookingId ?? null;
    this.additionalId = data.additionalId ?? this.additionalId ?? null;
    this.tax = data.tax ??
      this.tax ?? {
        rate: null,
        amount: null
      };
    this.quantity = data.quantity ?? this.quantity ?? null;
    this.price = data.price ?? this.price ?? null;
    this.amount = data.amount ?? this.amount ?? null;
  }

  /**
   * Converts the InvoiceLine instance to a database-friendly format.
   * @returns {Object} The invoice line data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      invoice_id: this.invoiceId,
      description: this.description,
      created_date: this.createdDate,
      updated_date: this.updatedDate,
      booking_id: this.bookingId,
      additional_id: this.additionalId,
      tax_rate: this.tax.rate,
      quantity: this.quantity,
      price: this.price
    };
  }
}

export default InvoiceLine;
