import React, { useState, useEffect } from 'react';
import {
  Popup,
  Input,
  DropdownInput,
  DatePicker,
  FilledButton,
  ComboBox
} from 'core';
import BookingSpace from '../../../models/BookingSpace';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { formatDate } from '../../../utilities/Formatting';
import UserProfile from '../../../models/UserProfile';

const EditSpace = ({ isOpen, onClose, bookingSpaceId }) => {
  const [bookingSpace, setBookingSpace] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [levyStatusOptions, setLevyStatusOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (bookingSpaceId) {
        const space = await BookingSpace.getById(bookingSpaceId);
        setBookingSpace(space);

        const [{ data: statuses }, { data: levyStatuses }] = await Promise.all([
          CoreEntityType.getAll({
            entity_id: {
              operator: 'eq',
              value: EntityTypeEnum.BookingSpaceStatus
            }
          }),
          CoreEntityType.getAll({
            entity_id: {
              operator: 'eq',
              value: EntityTypeEnum.BookingSpaceLevyType
            }
          })
        ]);

        setStatusOptions(
          statuses.map(status => ({ value: status.id, label: status.type }))
        );
        setLevyStatusOptions(
          levyStatuses.map(status => ({ value: status.id, label: status.type }))
        );
      }
    };

    fetchData();
  }, [bookingSpaceId]);

  const loadUserOptions = async (searchQuery, page) => {
    try {
      if (!bookingSpace?.companyId) return [];

      const { data: fetchedUsers } = await UserProfile.getAll({
        fts: {
          operator: 'textSearch',
          value: searchQuery
        },
        company_id: {
          operator: 'eq',
          value: bookingSpace.companyId
        }
      });

      return fetchedUsers.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
        secondaryLabel: user.email
      }));
    } catch (err) {
      console.error('Failed to load user options:', err);
      return [];
    }
  };

  const handleSave = async () => {
    try {
      const updatedSpace = new BookingSpace({
        ...bookingSpace,
        booking: bookingSpace.booking,
        delegate: bookingSpace.delegate,
        status: bookingSpace.status,
        levyStatus: bookingSpace.levyStatus,
        levy: bookingSpace.levy,
        completionDate: bookingSpace.completionDate,
        expiryDate: bookingSpace.expiryDate
      });
      await updatedSpace.update();
      onClose();
    } catch (error) {
      console.error('Error updating booking space:', error);
    }
  };

  const footer = (
    <div className='flex justify-between items-center'>
      <FilledButton colour='base' onClick={onClose} className='mr-2'>
        Cancel
      </FilledButton>
      <div className='flex'>
        <FilledButton colour='primary' onClick={handleSave}>
          Save Changes
        </FilledButton>
      </div>
    </div>
  );

  if (!bookingSpace) return null;

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title={`Edit Space ${bookingSpaceId}`}
      size='sm'
      footer={footer}
    >
      <div className='space-y-4'>
        <ComboBox
          label='Delegate'
          loadOptions={loadUserOptions}
          value={
            bookingSpace.delegate
              ? {
                  value: bookingSpace.delegate.id,
                  label: `${bookingSpace.delegate.firstName} ${bookingSpace.delegate.lastName}`,
                  secondaryLabel: bookingSpace.delegate.email
                }
              : null
          }
          onChange={selected =>
            setBookingSpace({
              ...bookingSpace,
              delegate: selected
                ? {
                    id: selected.value,
                    firstName: selected.label.split(' ')[0],
                    lastName: selected.label.split(' ')[1],
                    email: selected.secondaryLabel
                  }
                : null
            })
          }
          placeholder='Search for a delegate...'
        />
        <Input
          type='text'
          label='Levy'
          value={bookingSpace.levy}
          onChange={e =>
            setBookingSpace({ ...bookingSpace, levy: e.target.value })
          }
        />

        <DropdownInput
          label='Levy Status'
          options={levyStatusOptions}
          value={bookingSpace.levyStatus?.id}
          onChange={e =>
            setBookingSpace({
              ...bookingSpace,
              levyStatus: {
                id: e.target.value,
                name: levyStatusOptions.find(
                  opt => opt.value === e.target.value
                )?.label
              }
            })
          }
        />

        <DatePicker
          label='Completion Date'
          value={formatDate(bookingSpace.completionDate, false, true)}
          onChange={date =>
            setBookingSpace({ ...bookingSpace, completionDate: date })
          }
        />

        <DatePicker
          label='Expiry Date'
          value={formatDate(bookingSpace.expiryDate, false, true)}
          onChange={date =>
            setBookingSpace({ ...bookingSpace, expiryDate: date })
          }
        />

        <DropdownInput
          label='Status'
          options={statusOptions}
          value={bookingSpace.status?.id}
          onChange={e =>
            setBookingSpace({
              ...bookingSpace,
              status: {
                id: e.target.value,
                name: statusOptions.find(opt => opt.value === e.target.value)
                  ?.label
              }
            })
          }
        />
      </div>
    </Popup>
  );
};

export default EditSpace;
