import BaseModel from './BaseModel';

/**
 * Represents a Compliance record in the system.
 * @extends BaseModel
 */
class Compliance extends BaseModel {
  /** @type {string} The name of the database table for Compliance */
  static table = 'compliance';

  /** @type {string} The SQL query to select Compliance data with related information */
  static selectQuery = `
    *,
    trainer:trainers!trainer_id (id, name),
    document:documents!document_id (id, file_name, file_path, file_type, created_date),
    status:core_entity_types!status (id, type, metadata),
    accreditation:core_entity_types!accreditation (id, type),
    type:core_entity_types!type (id, type, metadata)
  `;

  /**
   * Creates a Compliance instance from database format data
   * @param {Object} data - The database format compliance data
   * @returns {Compliance} A new Compliance instance
   */
  static fromDatabase(data = {}) {
    const compliance = new Compliance();
    compliance.id = data.id;
    compliance.createdDate = data.created_date;
    compliance.updatedDate = data.updated_date;
    compliance.trainerId = data.trainer_id;
    compliance.document = data.document
      ? {
          id: data.document.id,
          name: data.document.file_name,
          path: data.document.file_path,
          type: data.document.file_type,
          createdDate: data.document.created_date
        }
      : null;
    compliance.status = data.status
      ? {
          id: data.status.id,
          type: data.status.type,
          colour: data.status.metadata?.colour
        }
      : null;
    compliance.accreditation = data.accreditation
      ? { id: data.accreditation.id, type: data.accreditation.type }
      : null;
    compliance.type = data.type
      ? { id: data.type.id, type: data.type.type }
      : null;
    compliance.expiryDate = data.expiry_date;
    return compliance;
  }

  /**
   * Creates an instance of Compliance.
   * @param {Object} data - The compliance data.
   */
  constructor(data = {}) {
    super(data);
    this.trainerId = data.trainerId ?? this.trainerId ?? null;
    this.document = data.document ?? this.document ?? null;
    this.status = data.status ?? this.status ?? null;
    this.accreditation = data.accreditation ?? this.accreditation ?? null;
    this.type = data.type ?? this.type ?? null;
    this.expiryDate = data.expiryDate ?? this.expiryDate ?? null;
  }

  /**
   * Converts the Compliance instance to a database-friendly format.
   * @returns {Object} The compliance data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      trainer_id: this.trainerId,
      document_id: this.document.id,
      status: this.status ? this.status.id : null,
      accreditation: this.accreditation ? this.accreditation.id : null,
      type: this.type ? this.type.id : null,
      expiry_date: this.expiryDate
    };
  }
}

export default Compliance;
