import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import EntityCard from './EntityCard';
import TabView from '../navigation/TabView';
import Feed from '../feeds/Feed';
import ActivityLog from '../../models/ActivityLog';
import Meeting from '../../models/Meeting';
import { formatDate } from '../../utilities/Formatting';
import TaskOverview from './overviews/TaskOverview';
import MeetingOverview from './overviews/MeetingOverview';
import Pagination from '../navigation/Pagination';
import {
  Alert,
  LogoLoader,
  ProgressBar,
  CircularIconButton,
  SoftButton,
  OutlineButton
} from 'core';
import FileOverview from './overviews/FileOverview';
import {
  MoreHorizontal,
  Zap,
  KanbanSquare,
  Megaphone,
  Tags,
  FileInput,
  FileOutput,
  FileBadge,
  FileEdit,
  FileText
} from 'lucide-react';
import { getIconComponent } from '../../utilities/Formatting';
import PipelinesTab from './actions/PipelinesTab';
import EntityPopupHandler from './EntityPopupHandler';
import { useNotification } from 'core';
import TagsTab from './actions/TagsTab';
import { supabase } from '../../utilities/supabase';
import AutomationRun from '../../models/AutomationRun';
import AutomationsTab from './actions/AutomationsTab';
import AudiencesTab from './actions/AudiencesTab';
import DocflowsTab from './actions/DocFlowsTab';

const Entity = ({
  currentUser,
  entityType,
  model,
  infoItems,
  accordionItems,
  additionalTabs = [],
  progressItems,
  alertItems
}) => {
  const { id } = useParams();
  const [entity, setEntity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [automations, setAutomations] = useState([]);
  const [error, setError] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [feedItems, setFeedItems] = useState([]);
  const [noteFeedItems, setNoteFeedItems] = useState([]);
  const [currentFeedPage, setCurrentFeedPage] = useState(1);
  const [currentNoteFeedPage, setCurrentNoteFeedPage] = useState(1);
  const [pinnedFeedItem, setPinnedFeedItem] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [totalFeedPages, setTotalFeedPages] = useState(1);
  const [totalNoteFeedPages, setTotalNoteFeedPages] = useState(1);
  const itemsPerFeedPage = 10;
  const [quickActions, setQuickActions] = useState([]);
  const [activeActionTab, setActiveActionTab] = useState('automations');
  const [entityTags, setEntityTags] = useState(null);
  const [entityPipelines, setEntityPipelines] = useState(null);

  const [refresh, setRefresh] = useState('');

  const [audienceTotal, setAudienceTotal] = useState(0);

  const [docflowsTotal, setDocflowsTotal] = useState(0);

  const [activePopup, setActivePopup] = useState(null);

  const { addNotification } = useNotification();

  const logTypes = {
    115: { title: 'Info' },
    116: { title: 'Warning' },
    117: { title: 'Danger' },
    118: { title: 'Changed' },
    119: { title: 'Added a Note to' },
    120: { title: 'Email Added' },
    121: { title: 'SMS Added' },
    122: { title: 'Call Added' }
  };

  const loadQuickActions = useCallback(() => {
    const userPreferences = JSON.parse(
      localStorage.getItem('user_preferences') || '{}'
    );
    const defaultActions = [
      {
        icon: 'Edit',
        label: 'Note',
        handler: 'handleNoteClick',
        favourited: true,
        order: 1,
        row: 1
      },
      {
        icon: 'Mail',
        label: 'Email',
        handler: 'handleEmailClick',
        favourited: true,
        order: 2,
        row: 1
      },
      {
        icon: 'CheckSquare',
        label: 'Task',
        handler: 'handleTaskClick',
        favourited: true,
        order: 3,
        row: 1
      },
      {
        icon: 'Calendar',
        label: 'Meeting',
        handler: 'handleMeetingClick',
        favourited: true,
        order: 4,
        row: 1
      },
      {
        icon: 'KanbanSquare',
        label: 'Pipelines',
        handler: 'handlePipelinesClick',
        favourited: true,
        order: 5,
        row: 1
      },
      {
        icon: 'PhoneCall',
        label: 'Call',
        handler: 'handleCallClick',
        favourited: false,
        order: 6,
        row: 1
      },
      {
        icon: 'MessageSquare',
        label: 'SMS',
        handler: 'handleSMSClick',
        favourited: false,
        order: 7,
        row: 1
      },
      {
        icon: 'Headphones',
        label: 'Audio',
        handler: 'handleAudioClick',
        favourited: false,
        order: 8,
        row: 1
      },
      {
        icon: 'Video',
        label: 'Video',
        handler: 'handleVideoClick',
        favourited: false,
        order: 9,
        row: 1
      },
      {
        icon: 'MessageCircle',
        label: 'Ai Chat',
        handler: 'handleAiChatClick',
        favourited: false,
        order: 10,
        row: 1
      },
      {
        icon: 'Phone',
        label: 'Ai Call',
        handler: 'handleAiCallClick',
        favourited: false,
        order: 11,
        row: 1
      },
      {
        icon: 'Cpu',
        label: 'AiFlows',
        handler: 'handleAiFlowsClick',
        favourited: false,
        order: 12,
        row: 1
      },
      {
        icon: 'FileText',
        label: 'DocFlows',
        handler: 'handleDocFlowsClick',
        favourited: false,
        order: 13,
        row: 1
      },
      {
        icon: 'Tag',
        label: 'Tags',
        handler: 'handleTagsClick',
        favourited: false,
        order: 14,
        row: 1
      },
      {
        icon: 'CreditCard',
        label: 'E-Card',
        handler: 'handleECardClick',
        favourited: false,
        order: 15,
        row: 1
      },
      {
        icon: 'Users',
        label: 'Audience',
        handler: 'handleAudienceClick',
        favourited: false,
        order: 16,
        row: 1
      },
      {
        icon: 'File',
        label: 'Page',
        handler: 'handlePageClick',
        favourited: false,
        order: 17,
        row: 1
      },
      {
        icon: 'Link',
        label: 'Links',
        handler: 'handleLinksClick',
        favourited: false,
        order: 18,
        row: 1
      },
      {
        icon: 'Gift',
        label: 'Gift',
        handler: 'handleGiftClick',
        favourited: false,
        order: 19,
        row: 1
      }
    ];

    if (userPreferences.quick_actions) {
      if (
        !userPreferences.quick_actions_last_updated ||
        new Date(userPreferences.quick_actions_last_updated) <
          new Date(import.meta.env.VITE_LAST_ACTIONS_BUILD)
      ) {
        setQuickActions(defaultActions);
        userPreferences.quick_actions = defaultActions;
        userPreferences.quick_actions_last_updated = new Date().toISOString();
        localStorage.setItem(
          'user_preferences',
          JSON.stringify(userPreferences)
        );
      } else {
        setQuickActions(userPreferences.quick_actions);
      }
    } else {
      setQuickActions(defaultActions);
      userPreferences.quick_actions = defaultActions;
      userPreferences.quick_actions_last_updated = new Date().toISOString();
      localStorage.setItem('user_preferences', JSON.stringify(userPreferences));
    }
  }, []);

  useEffect(() => {
    loadQuickActions();
  }, [loadQuickActions]);

  const fetchEntityData = useCallback(async () => {
    try {
      setLoading(false);
      const entityData = await model.getById(id);
      setEntity(entityData);

      const [
        { data: activityLogs, count: feedcount },
        { data: noteActivityLogs, count: notecount },
        { data: pinnedItems },
        { data: meetingsData },
        { data: entityTagData },
        { data: automations },
        { data: entityPipelinesData }
      ] = await Promise.all([
        ActivityLog.getAll(
          { entity_type: { value: entityType }, entity_id: { value: id } },
          currentFeedPage,
          itemsPerFeedPage,
          'created_date',
          'desc'
        ),
        ActivityLog.getAll(
          {
            entity_type: { value: entityType },
            entity_id: { value: id },
            audit_type: { value: 119 }
          },
          currentNoteFeedPage,
          itemsPerFeedPage,
          'created_date',
          'desc'
        ),
        ActivityLog.getAll(
          {
            entity_type: { value: entityType },
            entity_id: { value: id },
            pinned: { value: true }
          },
          1,
          1,
          'created_date',
          'desc'
        ),
        Meeting.getAll(
          {
            entity_type: { value: entityType },
            entity_id: { value: id }
          },
          1,
          10,
          'start_date',
          'asc'
        ),
        supabase
          .from('tagged_entities')
          .select('*,tags(*,category:core_entity_types(*))')
          .eq('entity_type', entityType)
          .eq('entity_id', id),
        AutomationRun.getAll(
          {
            entity_type: { operator: 'eq', value: entityType },
            entity_id: { operator: 'eq', value: id },
            status: { operator: 'eq', value: 223 }
          },
          1,
          100,
          'created_date',
          'desc'
        ),
        supabase
          .from('pipeline_entities')
          .select('*,pipelines(*)')
          .eq('entity_type', entityType)
          .eq('entity_id', id)
      ]);

      setFeedItems(activityLogs);
      setTotalFeedPages(Math.ceil(feedcount / itemsPerFeedPage));
      setNoteFeedItems(noteActivityLogs);
      setTotalNoteFeedPages(Math.ceil(notecount / itemsPerFeedPage));
      setPinnedFeedItem(pinnedItems[0] || null);
      setMeetings(meetingsData);
      setEntityTags(entityTagData);
      setAutomations(automations);
      setEntityPipelines(entityPipelinesData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [id, model, entityType, currentFeedPage, currentNoteFeedPage]);

  // Add subscription cleanup function
  useEffect(() => {
    // Subscribe to changes on the main entity table
    const entitySubscription = supabase
      .channel(`public:${model.table}:id=eq.${id}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: model.table,
          filter: `id=eq.${id}`
        },
        payload => {
          // Update entity state when changes occur
          if (payload.new) {
            setEntity(new model(payload.new));
          }
        }
      )
      .subscribe();

    // Subscribe to activity logs
    const activitySubscription = supabase
      .channel(`public:activity_logs:entity_id=eq.${id}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'activity_logs',
          filter: `entity_id=eq.${id}`
        },
        () => {
          // Refresh feed data when activities change
          fetchEntityData();
        }
      )
      .subscribe();

    // Subscribe to tasks
    const tasksSubscription = supabase
      .channel(`public:tasks:entity_id=eq.${id}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'tasks',
          filter: `entity_id=eq.${id}`
        },
        () => {
          fetchEntityData();
        }
      )
      .subscribe();

    // Subscribe to meetings
    const meetingsSubscription = supabase
      .channel(`public:meetings:entity_id=eq.${id}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'meetings',
          filter: `entity_id=eq.${id}`
        },
        () => {
          fetchEntityData();
        }
      )
      .subscribe();

    // Subscribe to documents
    const documentsSubscription = supabase
      .channel(`public:documents:entity_id=eq.${id}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'documents',
          filter: `entity_id=eq.${id}`
        },
        () => {
          fetchEntityData();
        }
      )
      .subscribe();

    // Cleanup subscriptions on unmount
    return () => {
      entitySubscription.unsubscribe();
      activitySubscription.unsubscribe();
      tasksSubscription.unsubscribe();
      meetingsSubscription.unsubscribe();
      documentsSubscription.unsubscribe();
    };
  }, [id, model, fetchEntityData]);

  useEffect(() => {
    fetchEntityData();
    fetchAudiences();
    fetchDocFlows();
  }, [fetchEntityData]);

  useEffect(() => {
    if (!refresh.includes('icon')) return;

    fetchAudiences();
    fetchDocFlows();
  }, [refresh]);

  const handleUpdate = useCallback(
    async (field, value) => {
      try {
        if (entity?.readOnly) {
          addNotification({
            type: 'error',
            title: 'Read Only Mode',
            description:
              'This entity is in READ ONLY mode, changes have not been saved.',
            duration: 4000
          });
          return;
        }
        let updateData;
        if (field.includes('.')) {
          updateData = field
            .split('.')
            .reduceRight((acc, key) => ({ [key]: acc }), value);
        } else {
          updateData = { [field]: value };
        }
        const updatedEntity = await entity.update(updateData);
        setEntity(updatedEntity);
        setUpdateTrigger(prev => prev + 1);
      } catch (err) {
        //setError(err.message);
        console.error(err);
        setEntity(entity);
        addNotification({
          type: 'error',
          title: err.message,
          description: err.details,
          duration: 4000
        });
      }
    },
    [entity]
  );

  const fetchAudiences = async () => {
    try {
      const { data, error } = await supabase.functions.invoke(
        'audience/search',
        {
          body: { reference: `${entityType}:${id}` }
        }
      );

      if (error) throw error;

      setAudienceTotal(data.length);

      setRefresh('');
    } catch (error) {
      console.error('Error fetching audiences:', error);
    }
  };

  const fetchDocFlows = async () => {
    try {
      const { data, error } = await supabase.functions.invoke(
        'audience/docflows/runs/search',
        {
          body: { entityType, entityId: id }
        }
      );

      if (error) throw error;

      setDocflowsTotal(data.length);

      setRefresh('');
    } catch (error) {
      console.error('Error fetching audiences:', error);
    }
  };

  const handlePin = useCallback(
    async itemId => {
      try {
        const activityLog = await ActivityLog.getById(itemId);
        await activityLog.update({ pinned: true });
        setPinnedFeedItem(activityLog);
        await fetchEntityData();
      } catch (err) {
        setError(err.message);
      }
    },
    [fetchEntityData]
  );

  const handleUnpin = useCallback(
    async itemId => {
      try {
        const activityLog = await ActivityLog.getById(itemId);
        await activityLog.update({ pinned: false });
        setPinnedFeedItem(null);
        await fetchEntityData();
      } catch (err) {
        setError(err.message);
      }
    },
    [fetchEntityData]
  );

  const handleFeedPageChange = useCallback(page => {
    setCurrentFeedPage(page);
  }, []);

  const handleNoteFeedPageChange = useCallback(page => {
    setCurrentNoteFeedPage(page);
  }, []);

  const handleFavouriteActionsUpdate = useCallback(updatedActions => {
    const userPreferences = JSON.parse(
      localStorage.getItem('user_preferences') || '{}'
    );
    userPreferences.quick_actions = updatedActions;
    localStorage.setItem('user_preferences', JSON.stringify(userPreferences));
    setQuickActions(updatedActions);
    //loadQuickActions();
  }, []);

  const handleTabUpdate = useCallback(() => {
    fetchEntityData();
  }, [fetchEntityData]);

  const defaultTabs = [
    {
      label: 'Activities',
      path: 'activities',
      content: (
        <>
          {progressItems && (
            <div className='mb-6'>
              <ProgressBar
                title={progressItems.title}
                steps={progressItems.steps}
                progress={progressItems.getValue(entity)}
                colour='brand'
              />
            </div>
          )}
          {pinnedFeedItem && (
            <Alert
              className='mb-2'
              key={pinnedFeedItem.id}
              title='Pinned Item'
              description={`${pinnedFeedItem.owner.firstName} ${
                pinnedFeedItem.owner.lastName
              } on ${formatDate(pinnedFeedItem.createdDate, false, true)}
            ${pinnedFeedItem.content}`}
              style='warning'
              actions={[
                {
                  text: 'Unpin',
                  onClick: () => handleUnpin(pinnedFeedItem.id)
                }
              ]}
            />
          )}
          <div className='py-2' />
          <TabView
            default={'overview'}
            variant='pill'
            tabs={[
              {
                label: 'Overview',
                path: 'overview',
                content: (
                  <>
                    <Feed
                      items={feedItems}
                      onPin={handlePin}
                      onUnpin={handleUnpin}
                      logTypes={logTypes}
                    />
                    <Pagination
                      currentPage={currentFeedPage}
                      totalPages={totalFeedPages}
                      onPageChange={handleFeedPageChange}
                    />
                  </>
                )
              },
              {
                label: 'Notes',
                path: 'notes',
                content: (
                  <>
                    <Feed
                      items={noteFeedItems}
                      onPin={handlePin}
                      onUnpin={handleUnpin}
                    />
                    <Pagination
                      currentPage={currentNoteFeedPage}
                      totalPages={totalNoteFeedPages}
                      onPageChange={handleNoteFeedPageChange}
                    />
                  </>
                )
              },
              {
                label: 'Tasks',
                path: 'tasks',
                content: <TaskOverview entityType={entityType} entityId={id} />
              },
              {
                label: 'Meetings',
                path: 'meetings',
                content: (
                  <MeetingOverview
                    meetings={meetings}
                    entityType={entityType}
                    entityId={id}
                  />
                )
              },
              {
                label: 'Files',
                path: 'files',
                content: (
                  <FileOverview
                    entityType={entityType}
                    id={id}
                    currentUser={currentUser}
                  />
                )
              }
            ]}
          />
        </>
      )
    }
  ];

  const allTabs = [...defaultTabs, ...additionalTabs];

  const defaultAccordions = [
    {
      title: 'Actions',
      content: () => (
        <div>
          <div className='p-4 border border-base-100 rounded-xl'>
            <div className='grid grid-cols-6 gap-2'>
              {quickActions
                .filter(action => action.favourited)
                .map((action, index) => (
                  <CircularIconButton
                    key={index}
                    Icon={getIconComponent(action.icon)}
                    label={action.label}
                    onClick={() => {
                      if (entity?.readOnly) {
                        addNotification({
                          type: 'error',
                          title: 'Read Only Mode',
                          description:
                            'This entity is in READ ONLY mode, changes have not been saved.',
                          duration: 4000
                        });
                        return;
                      } else {
                        setActivePopup(action.handler);
                      }
                    }}
                  />
                ))}
              <CircularIconButton
                key={11}
                Icon={MoreHorizontal}
                label={'More'}
                onClick={() => setActivePopup('handleMoreClick')} // This will be handled by EntityPopupHandler
              />
            </div>
          </div>
          <div className='mt-4'>
            <div className='flex justify-start space-x-2 mb-4'>
              {[
                'automations',
                'pipelines',
                'tags',
                'docflows',
                ...([4, 25, 49, 50].includes(entityType) ? ['audiences'] : [])
              ].map(tab => {
                const isActive = activeActionTab === tab;
                const Icon = {
                  automations: Zap,
                  pipelines: KanbanSquare,
                  tags: Tags,
                  docflows: FileText,
                  audiences: Megaphone
                }[tab];

                const ButtonComponent = isActive ? SoftButton : OutlineButton;

                return (
                  <ButtonComponent
                    key={tab}
                    onClick={() => setActiveActionTab(tab)}
                    colour={isActive ? 'primary' : 'base'}
                    rightIcon={
                      <span
                        className={`
                        inline-flex items-center justify-center
                        w-5 h-5 rounded-full text-xs font-medium
                        ${
                          isActive
                            ? 'bg-primary-900 text-primary-100'
                            : 'bg-primary-100 text-primary-900'
                        }
                      `}
                      >
                        {tab === 'automations' && entity.automations
                          ? entity.automations?.length
                          : tab === 'pipelines' && entityPipelines
                          ? entityPipelines?.length
                          : tab === 'tags' && entityTags
                          ? entityTags?.length
                          : tab === 'campaigns' && entity.campaigns
                          ? entity.campaigns?.length
                          : tab === 'automations' && automations
                          ? automations?.length
                          : tab === 'docflows' && docflowsTotal
                          ? docflowsTotal
                          : tab === 'audiences'
                          ? audienceTotal
                          : '0'}
                      </span>
                    }
                  >
                    <Icon />
                  </ButtonComponent>
                );
              })}
            </div>
            <div>
              {activeActionTab === 'automations' && (
                <>
                  <AutomationsTab
                    entityType={entityType}
                    entity={entity}
                    onUpdate={handleUpdate}
                    setAutomations={setAutomations}
                  />
                </>
              )}
              {activeActionTab === 'pipelines' && (
                <PipelinesTab
                  pipelineData={entityPipelines}
                  onUpdate={handleTabUpdate}
                />
              )}
              {activeActionTab === 'tags' && (
                <TagsTab
                  tags={entityTags}
                  onUpdate={() => {
                    handleTabUpdate();
                  }}
                />
              )}
              {activeActionTab === 'docflows' && (
                <DocflowsTab
                  entityId={id}
                  entityType={entityType}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              )}
              {activeActionTab === 'audiences' && (
                <AudiencesTab
                  entityId={id}
                  entityType={entityType}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              )}
            </div>
          </div>
        </div>
      )
    }
  ];

  const allAccordions = [...defaultAccordions, ...accordionItems];

  if (loading || !entity) return <LogoLoader logo={'/logo-star.png'} />;
  if (error) return <div>Error: {error}</div>;

  const renderSystemAlert = () => {
    if (!alertItems) return null;

    for (const alertItem of alertItems) {
      if (alertItem.condition(entity)) {
        return alertItem.content(entity);
      }
    }
    return null;
  };

  return (
    <div className='flex flex-col h-[calc(100vh-48px)]'>
      {renderSystemAlert()}
      <div className='flex flex-col lg:flex-row overflow-auto lg:overflow-hidden'>
        <div className='w-full lg:w-112 mb-4 lg:mb-0 flex-shrink-0'>
          <EntityCard
            accordionItems={allAccordions}
            infoItems={infoItems}
            data={entity}
            onUpdate={handleUpdate}
            entityType={entityType}
            entityId={id}
            key={updateTrigger}
          />
        </div>
        <div className='lg:hidden h-8 w-full bg-base-50'></div>
        <div className='w-full lg:flex-1 lg:overflow-y-auto bg-white'>
          <TabView
            tabs={allTabs}
            key={updateTrigger}
            variant='underlined'
            default={'activities'}
          />
        </div>
        <EntityPopupHandler
          entityType={entityType}
          entityId={id}
          entity={entity}
          onUpdate={handleFavouriteActionsUpdate}
          handler={activePopup}
          onClose={() => {
            handleTabUpdate();
            setActivePopup(null);
          }}
          setRefresh={setRefresh}
        />
      </div>
    </div>
  );
};

export default Entity;
