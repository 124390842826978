import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Pencil, Download, Trash, Workflow } from 'lucide-react';
import EntityTable from '../../tables/EntityTable';
import { formatDate, formatProfile } from '../../../utilities/Formatting';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import Document from '../../../models/Document';
import FileUploader from '../../popups/files/FileUploader';
import FileConnections from '../../popups/files/FileConnections';

const FileOverview = ({ entityType, id, currentUser }) => {
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isConnectionsOpen, setIsConnectionsOpen] = useState(false);
  const [documentData, setDocument] = useState(null);

  const handleNewFileClick = () => {
    setIsUploadOpen(true);
  };

  const handleUploadSuccess = () => {
    // Logic to handle successful upload
    setIsUploadOpen(false);
  };

  return (
    <div>
      <EntityTable
        name='Documents'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          {
            field: 'file_name',
            label: 'File Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'owner.full_name',
            label: 'Created By',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'date'
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'date'
          }
        ]}
        sort={[
          { value: 'id', label: 'Document ID' },
          { value: 'file_name', label: 'File Name' },
          { value: 'created_date', label: 'Date Created' },
          { value: 'updated_date', label: 'Last Modified' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id'
          },
          { label: 'File Name', field: 'fileName' },
          {
            label: 'Created By',
            field: 'owner',
            cell: value => formatProfile(value)
          },
          {
            label: 'File Path',
            field: 'filePath'
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        model={Document}
        entityType={EntityTypeEnum.Document}
        onNewClick={handleNewFileClick}
        canCreate={true}
        menuItems={[
          {
            label: 'View Connections',
            icon: <Workflow size={14} />,
            onClick: data => {
              setDocument(data);
              setIsConnectionsOpen(true);
            }
          },
          {
            label: 'Edit',
            icon: <Pencil size={14} />,
            onClick: data => (window.location.href = `/documents/${data.id}`)
          },
          {
            label: 'Download',
            icon: <Download size={14} />,
            onClick: async document => {
              console.log(document);
              try {
                const baseUrl = 'https://storage.googleapis.com/bohsa-docs';
                const filePath = document.filePath; // Assuming filePath contains the path to the file
                const link = window.document.createElement('a');
                link.href = `${baseUrl}/${filePath}`;
                link.target = '_blank'; // Open in new tab
                link.download =
                  `${document.fileName}` || `document.${document.fileType}`;
                console.log(link);
                window.document.body.appendChild(link);

                // Trigger the download
                link.click();

                // Clean up
                window.document.body.removeChild(link);
              } catch (error) {
                console.error('Error downloading document:', error);
                // Handle error (e.g., show an error message to the user)
              }
            }
          },
          {
            label: 'Delete',
            icon: <Trash size={14} className='text-danger-700' />,
            onClick: data => {
              console.log('Delete clicked', data.owner.id);
              console.log('Current user:', currentUser.id);
            },
            condition: data => data.owner?.id === currentUser?.id
          }
        ]}
        defaultFilters={{
          connections: {
            operator: FilterOperatorEnum.CONTAINS,
            value: `"${entityType}": ["${id}"]`
          }
        }}
      />
      {isUploadOpen && (
        <FileUploader
          onClose={() => setIsUploadOpen(false)}
          onUploadSuccess={handleUploadSuccess}
          destinationFolder={'general-docs'}
          connections={{
            [entityType]: [id]
          }}
        />
      )}
      {isConnectionsOpen && (
        <FileConnections
          document={documentData}
          onClose={() => {
            setDocument(null);
            setIsConnectionsOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default FileOverview;
