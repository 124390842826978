import BaseModel from './BaseModel';
import UserProfile from './UserProfile';
import { supabase } from '../utilities/supabase';

/**
 * Represents a Booking in the system.
 * @extends BaseModel
 */
class Booking extends BaseModel {
  /** @type {string} The name of the database table for Bookings */
  static table = 'bookings';

  /** @type {string} The SQL query to select Booking data with related information */
  static selectQuery = `
          *,
          companies!inner (name),
          trainers!inner (name),
          locations (name, address_1, address_2, city, county, postcode),
          courses!inner (name),
          delivery_type_data:core_entity_types!delivery_type (id, type),
          status:core_entity_types!status (id, type),
          sub_status:core_entity_types!sub_status (id, type),
          owner:user_profiles!inner (id, auth, first_name, last_name, email, profile_photo),
          booking_items_count,
          booking_items_incomplete,
          booking_items_sum,
          booking_spaces_fulfilled
        `;

  /**
   * Creates a Booking instance from database format data
   * @param {Object} data - The database format booking data
   * @returns {Booking} A new Booking instance
   */
  static fromDatabase(data = {}) {
    const booking = new Booking();
    booking.id = data.id;
    booking.createdDate = data.created_date;
    booking.updatedDate = data.updated_date;
    booking.parentId = data.parent_id;
    booking.quoteId = data.quote_id;
    booking.company = data.companies
      ? { id: data.company_id, name: data.companies.name }
      : null;
    booking.trainer = data.trainers
      ? { id: data.trainer_id, name: data.trainers.name }
      : null;
    booking.venue = data.locations
      ? {
          id: data.venue_id,
          name: data.locations.name,
          address_1: data.locations.address_1,
          address_2: data.locations.address_2,
          city: data.locations.city,
          county: data.locations.county,
          postcode: data.locations.postcode
        }
      : null;
    booking.course = data.courses
      ? { id: data.course_id, name: data.courses.name }
      : null;
    booking.deliveryType = data.delivery_type_data
      ? { id: data.delivery_type, name: data.delivery_type_data.type }
      : null;
    booking.quotedDate = data.quoted_date;
    booking.bookingDate = data.booking_date;
    booking.startDate = data.start_date;
    booking.endDate = data.end_date;
    booking.paymentDate = data.payment_date;
    booking.paidDate = data.paid_date;
    booking.invoiceId = data.invoice_id;
    booking.invoiceAmount = data.invoice_amount;
    booking.tpoNumber = data.tpo_number;
    booking.tpoAmount = data.tpo_amount;
    booking.cpoNumber = data.cpo_number;
    booking.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    booking.subStatus = data.sub_status
      ? { id: data.sub_status.id, name: data.sub_status.type }
      : null;
    booking.spaces = data.spaces;
    booking.spaceRate = data.space_rate;
    booking.vatRate = data.vat_rate;
    booking.levy = data.levy;
    booking.customReference = data.custom_reference;
    booking.invoiceSent = data.invoice_sent;
    booking.owner = data.owner
      ? {
          id: data.owner.id,
          auth: data.owner?.auth,
          firstName: data.owner?.first_name,
          lastName: data.owner?.last_name,
          fullName: `${data.owner?.first_name} ${data.owner?.last_name}`,
          email: data.owner?.email,
          profilePhoto: data.owner?.profile_photo
        }
      : null;
    booking.followers = data.followers || [];
    booking.contacts = data.contacts || [];
    booking.metrics = {
      fulfilledSpaces: data.booking_spaces_fulfilled,
      items: {
        count: data.booking_items_count,
        incomplete: data.booking_items_incomplete,
        total: data.booking_items_sum
      }
    };
    return booking;
  }

  /**
   * Creates an instance of Booking.
   * @param {Object} data - The booking data.
   */
  constructor(data = {}) {
    super(data);
    this.parentId = data.parentId ?? this.parentId ?? null;
    this.quoteId = data.quoteId ?? this.quoteId ?? null;
    this.company = data.company ?? this.company ?? null;
    this.trainer = data.trainer ?? this.trainer ?? null;
    this.venue = data.venue ?? this.venue ?? null;
    this.course = data.course ?? this.course ?? null;
    this.deliveryType = data.deliveryType ?? this.deliveryType ?? null;
    this.quotedDate = data.quotedDate ?? this.quotedDate ?? null;
    this.bookingDate = data.bookingDate ?? this.bookingDate ?? null;
    this.startDate = data.startDate ?? this.startDate ?? null;
    this.endDate = data.endDate ?? this.endDate ?? null;
    this.paymentDate = data.paymentDate ?? this.paymentDate ?? null;
    this.paidDate = data.paidDate ?? this.paidDate ?? null;
    this.invoiceId = data.invoiceId ?? this.invoiceId ?? null;
    this.invoiceAmount = data.invoiceAmount ?? this.invoiceAmount ?? null;
    this.tpoNumber = data.tpoNumber ?? this.tpoNumber ?? null;
    this.tpoAmount = data.tpoAmount ?? this.tpoAmount ?? null;
    this.cpoNumber = data.cpoNumber ?? this.cpoNumber ?? null;
    this.status = data.status ?? this.status ?? null;
    this.subStatus = data.subStatus ?? this.subStatus ?? null;
    this.spaces = data.spaces ?? this.spaces ?? null;
    this.spaceRate = data.spaceRate ?? this.spaceRate ?? null;
    this.vatRate = data.vatRate ?? this.vatRate ?? null;
    this.levy = data.levy ?? this.levy ?? null;
    this.customReference = data.customReference ?? this.customReference ?? null;
    this.invoiceSent = data.invoiceSent ?? this.invoiceSent ?? false;
    this.owner = data.owner ?? this.owner ?? null;
    this.followers = data.followers ?? this.followers ?? [];
    this.contacts = data.contacts ?? this.contacts ?? [];
    this.metrics = data.metrics ??
      this.metrics ?? {
        fulfilledSpaces: null,
        items: {
          count: null,
          incomplete: null,
          total: null
        }
      };
  }

  /**
   * Converts the Booking instance to a database-friendly format.
   * @returns {Object} The booking data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      quote_id: this.quoteId,
      company_id: this.company ? this.company.id : null,
      trainer_id: this.trainer ? this.trainer.id : null,
      venue_id: this.venue ? this.venue.id : null,
      course_id: this.course ? this.course.id : null,
      delivery_type: this.deliveryType ? this.deliveryType.id : null,
      quoted_date: this.quotedDate,
      booking_date: this.bookingDate,
      start_date: this.startDate,
      end_date: this.endDate,
      payment_date: this.paymentDate,
      paid_date: this.paidDate,
      invoice_id: this.invoiceId,
      invoice_amount: this.invoiceAmount,
      tpo_number: this.tpoNumber,
      tpo_amount: this.tpoAmount,
      cpo_number: this.cpoNumber,
      status: this.status ? this.status.id : null,
      sub_status: this.subStatus ? this.subStatus.id : null,
      spaces: this.spaces,
      space_rate: this.spaceRate,
      vat_rate: this.vatRate,
      levy: this.levy,
      custom_reference: this.customReference,
      invoice_sent: this.invoiceSent,
      owner: this.owner ? this.owner.id : null,
      followers: this.followers,
      contacts: this.contacts,
      parent_id: this.parentId
    };
  }

  /**
   * Retrieves the user profiles for all contacts associated with this booking.
   * @returns {Promise<Array>} A promise that resolves to an array of UserModel instances.
   */
  async getContactProfiles() {
    if (!this.contacts || this.contacts.length === 0) {
      return [];
    }

    try {
      const { data: contactProfiles } = await UserProfile.getAll({
        id: {
          operator: 'in',
          value: this.contacts.map(contact => contact.user_id)
        }
      });
      return contactProfiles;
    } catch (error) {
      console.error('[Error] Fetching contact profiles:', error);
      throw error;
    }
  }

  /**
   * Removes this booking from its associated invoice by calling the remove_booking_from_invoice RPC function
   * @returns {Promise<void>} A promise that resolves when the booking has been removed from the invoice
   * @throws {Error} If there is an error removing the booking from the invoice
   */
  async removeBookingFromInvoice() {
    try {
      await supabase.rpc('remove_booking_from_invoice', {
        p_booking_id: this.id
      });
    } catch (error) {
      console.error('[Error] Removing booking from invoice:', error);
      throw error;
    }
  }
}

export default Booking;
