import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { TextButton, DropdownInput } from 'core';
import { PrimaryInput } from '../inputs/Forms';

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage = 10,
  onItemsPerPageChange
}) => {
  const [goToPage, setGoToPage] = useState('');

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const noPages = totalPages === 0;

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
      setGoToPage('');
    }
  };

  return (
    <nav
      className={`flex items-center justify-between border-t border-neutral-200 px-4 py-3 sm:px-6 ${
        noPages ? 'opacity-50 pointer-events-none' : ''
      }`}
      aria-label='Pagination'
    >
      <div className='hidden sm:block'>
        <div className='flex items-center gap-4'>
          <p className='text-sm text-neutral-700'>
            {noPages ? (
              'No pages to display'
            ) : (
              <>
                Showing page <span className='font-medium'>{currentPage}</span>{' '}
                of <span className='font-medium'>{totalPages}</span>
              </>
            )}
          </p>
          <div className='flex items-center gap-2'>
            <span className='text-sm text-neutral-700 min-w-32'>
              Items per page:
            </span>
            <DropdownInput
              value={itemsPerPage.toString()}
              onChange={e => onItemsPerPageChange(Number(e.target.value))}
              options={[
                { value: '10', label: '10' },
                { value: '25', label: '25' },
                { value: '50', label: '50' },
                { value: '100', label: '100' }
              ]}
              size='sm'
              className='max-w-20'
            />
          </div>
        </div>
      </div>
      <div className='flex flex-1 justify-between sm:justify-end items-center'>
        <TextButton
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1 || noPages}
          leftIcon={<ChevronLeft size={20} />}
          colour='base'
          className='mr-3'
        >
          Previous
        </TextButton>
        <div className='hidden md:flex'>
          {getPageNumbers().map(number => (
            <TextButton
              key={number}
              onClick={() => onPageChange(number)}
              disabled={noPages}
              colour='base'
              className={`mx-1 ${
                currentPage === number
                  ? 'bg-brand-100 text-brand-700'
                  : 'text-neutral-500 hover:bg-neutral-50'
              }`}
            >
              {number}
            </TextButton>
          ))}
          <PrimaryInput
            type='number'
            min='1'
            max={totalPages}
            value={goToPage}
            onChange={e => setGoToPage(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                let page = parseInt(e.target.value, 10);
                if (page < 1) {
                  page = 1;
                } else if (page > totalPages) {
                  page = totalPages;
                }
                setGoToPage(page); // Update the input value to match
                onPageChange(page);
              }
            }}
            className='w-16 px-2 py-1 border border-neutral-300 rounded-md text-sm'
            placeholder='Page'
          />
        </div>
        <TextButton
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages || noPages}
          rightIcon={<ChevronRight size={20} />}
          colour='base'
          className='ml-3'
        >
          Next
        </TextButton>
      </div>
    </nav>
  );
};

export default Pagination;
