import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Search, Pencil, Trash2 } from 'lucide-react';
import {
  LogoLoader,
  Breadcrumbs,
  Input,
  FilledButton,
  SoftButton,
  TextButton
} from 'core';
import Guide from '../../../../models/Guide';
import {
  formatDate,
  formatProfile,
  getIconComponent
} from '../../../../utilities/Formatting';
import CoreEntityType from '../../../../models/CoreEntityType';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import EditDocCategory from '../../../../components/popups/help/EditDocCategory';
import { PERMISSIONS } from '../../../../utilities/Permissions';
import { UserProfileContext } from '../../../../App';

const Category = () => {
  const { category: categorySlug } = useParams();
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);
  const [guides, setGuides] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    const loadCategoryAndGuides = async () => {
      try {
        const categories = await CoreEntityType.getByEntityId(
          EntityTypeEnum.GuideCategories
        );
        const currentCategory = categories.find(
          cat => cat.metadata?.slug == categorySlug
        );

        if (!currentCategory) {
          throw new Error('Category not found');
        }

        setCategory(currentCategory);

        const { data: guidesData, error: guidesError } = await Guide.getAll({
          category: { value: currentCategory.id }
        });

        if (guidesError) throw guidesError;

        setGuides(guidesData);
      } catch (err) {
        setError(err.message || 'Failed to load category');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    loadCategoryAndGuides();
  }, [categorySlug]);

  const handleSearchChange = async e => {
    const value = e.target.value;
    setSearchQuery(value);

    if (!value.trim()) {
      setSearchResults([]);
      return;
    }

    try {
      const { data: guides } = await Guide.getAll({
        content: {
          operator: FilterOperatorEnum.TEXTSEARCH,
          value: value
        },
        category: {
          value: category?.id
        }
      });

      setSearchResults(guides);
    } catch (error) {
      console.error('Error searching guides:', error);
      setSearchResults([]);
    }
  };

  const CategoryIcon = category?.metadata?.icon
    ? getIconComponent(category.metadata.icon)
    : null;

  if (loading) {
    return <LogoLoader logo='/logo-star.png' />;
  }

  if (error) {
    return <div className='p-4 text-red-500'>{error}</div>;
  }

  return (
    <div className='bg-white'>
      <div className='mx-auto max-w-6xl px-6'>
        <div className='mt-2 mb-6'>
          <Breadcrumbs
            pages={[
              { name: 'Help', href: '/help' },
              { name: 'Documentation', href: '/help/docs' },
              {
                name: category?.type || 'Uncategorized',
                href: `/help/docs/${categorySlug}`,
                current: true
              }
            ]}
          />
        </div>

        <div className='mx-auto '>
          <div className='flex items-center gap-3 mb-2'>
            {CategoryIcon && <CategoryIcon className='w-5 h-5 text-base-700' />}
            <span className='text-sm text-base-700'>
              {category?.type || 'Uncategorized'}
            </span>
          </div>

          <div className='flex justify-between items-center'>
            <h2 className='text-4xl font-semibold tracking-tight text-base-950 sm:text-5xl'>
              {category?.type}
            </h2>
            <div className='flex gap-2'>
              {userProfile.hasPermission(
                EntityTypeEnum.GuideCategories,
                PERMISSIONS[EntityTypeEnum.GuideCategories].EDIT
              ) && (
                <SoftButton onClick={() => setShowEditModal(true)}>
                  Edit Category
                </SoftButton>
              )}
              {userProfile.hasPermission(
                EntityTypeEnum.Guides,
                PERMISSIONS[EntityTypeEnum.Guides].CREATE
              ) && (
                <FilledButton
                  colour='primary'
                  onClick={() =>
                    navigate(`/help/docs/create?category=${categorySlug}`)
                  }
                >
                  Create Guide
                </FilledButton>
              )}
            </div>
          </div>

          {category?.description && (
            <p className='mt-2 text-lg/8 text-base-500'>
              {category.description}
            </p>
          )}

          <div className='relative my-6'>
            <Input
              placeholder='Search documentation...'
              value={searchQuery}
              onChange={handleSearchChange}
              leftIcon={Search}
              size='lg'
            />
          </div>

          <div className='mt-10 mb-8 space-y-16 border-t border-base-200 pt-10 sm:mt-16 sm:pt-16'>
            {(!searchQuery ? guides : searchResults).map(guide => (
              <article
                key={guide.id}
                className='flex flex-col items-start justify-between cursor-pointer'
                onClick={() =>
                  navigate(`/help/docs/${categorySlug}/${guide.slug}`)
                }
              >
                <div className='group relative'>
                  <time
                    dateTime={guide.updatedDate}
                    className='text-xs text-base-500'
                  >
                    {formatDate(guide.updatedDate)}
                  </time>
                  <h3 className='mt-3 text-lg/6 font-semibold text-base-950 group-hover:text-base-600'>
                    <span className='absolute inset-0' />
                    {guide.title}
                  </h3>
                  <p className='mt-3 line-clamp-3 text-sm/6 text-base-600'>
                    {guide.description}
                  </p>
                </div>
                <div className='relative mt-4 flex items-center gap-x-4'>
                  <div className='text-sm/6'>
                    <p className='text-base-500'>
                      {formatProfile(
                        {
                          firstName:
                            guide.updatedBy?.first_name ||
                            guide.createdBy?.first_name,
                          lastName:
                            guide.updatedBy?.last_name ||
                            guide.createdBy?.last_name,
                          profilePhoto:
                            guide.updatedBy?.profile_photo ||
                            guide.createdBy?.profile_photo
                        },
                        'md'
                      )}
                    </p>
                  </div>
                </div>
              </article>
            ))}
            {(!searchQuery ? guides : searchResults).length === 0 && (
              <div className='text-center py-8 text-base-500'>
                No documentation found
              </div>
            )}
          </div>
        </div>
      </div>

      <EditDocCategory
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        category={category}
      />
    </div>
  );
};

export default Category;
