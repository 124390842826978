import { useContext, useEffect, useState } from 'react';
import {
  Popup,
  Input,
  TextEditor,
  SoftButton as Button,
  TextButton,
  ComboBox,
  Badge,
  DropdownInput,
  FilledButton,
  SoftButton
} from 'core';
import { Plus, X, Upload } from 'lucide-react';
import Resource from '../../../models/Resources';
import Cropper from 'react-easy-crop';
import Tag from '../../../models/Tag';
import {
  FilterOperatorEnum,
  EntityTypeEnum
} from '../../../utilities/Enumerables';
import { supabase } from '../../../utilities/supabase';
import axios from 'axios';
import Document from '../../../models/Document';
import CoreEntityType from '../../../models/CoreEntityType';
import { UserProfileContext } from '../../../App';

const CreateResource = ({ isOpen, onClose, onSuccess, resource }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState(resource?.name || '');
  const [description, setDescription] = useState(resource?.description || '');
  const [content, setContent] = useState(resource?.content || '');
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [files, setFiles] = useState([]);
  const [pendingFiles, setPendingFiles] = useState([]);
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [croppedImage, setCroppedImage] = useState(resource?.image || null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploadingFiles, setUploadingFiles] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});
  const [validationError, setValidationError] = useState('');
  const [errorField, setErrorField] = useState('');
  const { userProfile } = useContext(UserProfileContext);

  useEffect(() => {
    if (isOpen) {
      const fetchCategories = async () => {
        try {
          const { data } = await CoreEntityType.getAll({
            entity_id: {
              value: EntityTypeEnum.TagCategory,
              operator: FilterOperatorEnum.EQUALS
            }
          });
          setCategories(data || []);
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      };

      fetchCategories();
    }
  }, [isOpen]);

  useEffect(() => {
    // Reset form when resource prop changes
    if (resource) {
      setName(resource.name || '');
      setDescription(resource.description || '');
      setContent(resource.content || '');
      setCroppedImage(resource.image || null);

      // Fetch linked files
      const fetchFiles = async () => {
        try {
          const { data } = await Document.getAll({
            id: {
              value: resource.files,
              operator: FilterOperatorEnum.IN
            }
          });

          console.log(data);
          setFiles(data || []);
        } catch (error) {
          console.error('Error fetching files:', error);
        }
      };

      fetchFiles();

      // Fetch tags for the resource
      const fetchTags = async () => {
        try {
          const { data: tags } = await supabase
            .from('tagged_entities')
            .select('*, tag:tags(*)')
            .eq('entity_id', resource.id)
            .eq('entity_type', 63);

          if (tags) {
            console.log(tags.map(te => te.tag));
            setTags(tags.map(te => te.tag) || []);
          }
        } catch (error) {
          console.error('Error fetching tags:', error);
        }
      };

      fetchTags();
    }
  }, [resource]);

  useEffect(() => {
    // Reset form state when popup closes
    if (!isOpen) {
      setCurrentPage(1);
      setValidationError('');
      setErrorField('');
      setPendingFiles([]);
      setUploadingFiles({});
      setUploadProgress({});

      if (!resource) {
        setName('');
        setDescription('');
        setContent('');
        setTags([]);
        setFiles([]);
        setCroppedImage(null);
      }
    }
  }, [isOpen, resource]);

  const validateFirstPage = () => {
    if (!name.trim()) {
      setValidationError('Name is required');
      setErrorField('name');
      return false;
    }

    if (description.length > 100) {
      setValidationError('Description must be less than 100 characters');
      setErrorField('description');
      return false;
    }

    setValidationError('');
    setErrorField('');
    return true;
  };

  const validateSecondPage = () => {
    const hasContent = content.trim().length > 0;
    const hasFiles = files.length > 0;

    if (!hasContent && !hasFiles) {
      setValidationError('At least one of content or files is required');
      setErrorField('content');
      return false;
    }

    // Check if any files are pending upload
    const hasPendingFiles = pendingFiles.some(file => !file.uploaded);
    if (hasPendingFiles) {
      setValidationError('Please upload all pending files before continuing');
      setErrorField('files');
      return false;
    }

    setValidationError('');
    setErrorField('');
    return true;
  };

  const handleNext = () => {
    if (validateFirstPage()) {
      setCurrentPage(2);
    }
  };

  const handleCancel = () => {
    if (currentPage === 2) {
      setCurrentPage(1);
    } else {
      onClose();
    }
  };

  const handleDelete = async () => {
    try {
      if (resource) {
        await supabase.from('resources').delete().eq('id', resource.id);
        onSuccess?.();
        onClose();
      }
    } catch (error) {
      console.error('Error deleting resource:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!validateSecondPage()) {
        return;
      }

      const resourceData = {
        name,
        description,
        content,
        files: files.map(f => f.id),
        image: croppedImage,
        owner: userProfile.id
      };

      let id;
      if (resource) {
        // Update existing resource
        await resource.update(resourceData);
        id = resource.id;

        // Delete existing tag relationships
        await supabase.from('tagged_entities').delete().match({
          entity_type: 63,
          entity_id: id
        });
      } else {
        // Create new resource
        const newResource = new Resource(resourceData);
        const result = await newResource.insert();
        id = result.id;
      }

      // Get existing tags for this resource
      const { data: existingTags } = await supabase
        .from('tagged_entities')
        .select('tag_id')
        .eq('entity_id', id)
        .eq('entity_type', 63);

      // Find tags that were removed
      const existingTagIds = existingTags?.map(t => t.tag_id) || [];
      const newTagIds = tags.map(t => t.id);
      const removedTagIds = existingTagIds.filter(
        id => !newTagIds.includes(id)
      );

      // Remove links for removed tags
      if (removedTagIds.length > 0) {
        await supabase
          .from('tagged_entities')
          .delete()
          .eq('entity_type', 63)
          .eq('entity_id', id)
          .in('tag_id', removedTagIds);
      }

      // Insert new tag relationships
      const tagPromises = tags.map(tag => {
        return supabase.from('tagged_entities').insert({
          tag_id: tag.id,
          entity_type: 63,
          entity_id: id
        });
      });

      await Promise.all(tagPromises);

      onSuccess?.();
      onClose();
    } catch (error) {
      console.error('Error saving resource:', error);
    }
  };

  const loadTagOptions = async (query, page) => {
    try {
      const filters = {
        name: { value: query, operator: FilterOperatorEnum.ILIKE },
        id: {
          value: `(${tags.map(tag => tag.id).join(',')})`,
          operator: FilterOperatorEnum.NOT_IN
        }
      };

      if (selectedCategory) {
        filters.category_id = {
          value: selectedCategory,
          operator: FilterOperatorEnum.EQ
        };
      }

      const { data } = await Tag.getAll(filters, page, 10);

      return data.map(tag => ({
        value: tag.id,
        label: tag.name,
        secondaryLabel: (
          <Badge
            size='xs'
            colour={
              tag.metadata?.colour ||
              tag.category?.metadata?.colour ||
              'primary'
            }
          >
            {tag.category?.type}
          </Badge>
        ),
        category: tag.category,
        colour: tag.metadata?.colour || tag.category?.metadata?.colour
      }));
    } catch (error) {
      console.error('Error loading tag options:', error);
      return [];
    }
  };

  const handleAddTag = selectedOption => {
    if (selectedOption && !tags.some(t => t.id === selectedOption.value)) {
      setTags(prevTags => [
        ...prevTags,
        {
          id: selectedOption.value,
          name: selectedOption.label,
          metadata: { colour: selectedOption.colour },
          category: selectedOption.category
        }
      ]);
      setSelectedTag(null);
    }
  };

  const handleRemoveTag = tagId => {
    setTags(prevTags => prevTags.filter(tag => tag.id !== tagId));
  };

  const handleImageUpload = e => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setShowCropper(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const createImage = url =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', error => reject(error));
      image.src = url;
    });

  const getCroppedImg = async () => {
    try {
      const img = await createImage(image);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      ctx.drawImage(
        img,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      const base64Image = canvas.toDataURL('image/jpeg');
      setCroppedImage(base64Image);
      setShowCropper(false);
    } catch (e) {
      console.error('Error getting cropped image:', e);
    }
  };

  const handleFileSelect = async e => {
    const selectedFiles = Array.from(e.target.files);
    if (!selectedFiles.length) return;

    const newPendingFiles = selectedFiles.map(file => ({
      file,
      name: file.name,
      uploaded: false
    }));

    setPendingFiles(prev => [...prev, ...newPendingFiles]);
  };

  const handleFileUpload = async index => {
    const pendingFile = pendingFiles[index];
    if (!pendingFile || pendingFile.uploaded) return;

    setUploadingFiles(prev => ({ ...prev, [index]: true }));
    setUploadProgress(prev => ({ ...prev, [index]: 0 }));

    try {
      const { data: signedUrlData, error: signedUrlError } =
        await supabase.functions.invoke('storage/getSigned/upload', {
          body: {
            fileName: pendingFile.file.name,
            originalFileName: pendingFile.name,
            destinationFolder: 'resources'
          }
        });

      if (signedUrlError) throw signedUrlError;

      await axios.put(signedUrlData.url, pendingFile.file, {
        headers: {
          'Content-Type': pendingFile.file.type
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(prev => ({ ...prev, [index]: percentCompleted }));
        }
      });

      const document = await Document.getById(signedUrlData.file.id);
      await document.update({ filePath: signedUrlData.file.key });

      setFiles(prev => [...prev, document]);

      // Mark file as uploaded
      const newPendingFiles = [...pendingFiles];
      newPendingFiles[index].uploaded = true;
      setPendingFiles(newPendingFiles);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setUploadingFiles(prev => ({ ...prev, [index]: false }));
      setUploadProgress(prev => ({ ...prev, [index]: 0 }));
    }
  };

  const handleRemoveFile = index => {
    // Remove both pending and uploaded files
    setPendingFiles(prev => prev.filter((_, i) => i !== index));
    setFiles(prev => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await CoreEntityType.getAll({
          entity_id: {
            value: EntityTypeEnum.TagCategory,
            operator: FilterOperatorEnum.EQUALS
          }
        });
        setCategories(data || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen]);

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title={resource ? 'Edit Resource' : 'Create Resource'}
      size='lg'
      footer={
        <div className={`flex justify-end`}>
          {currentPage === 1 && resource && (
            <TextButton onClick={handleDelete} colour='danger' className='mr-2'>
              Delete
            </TextButton>
          )}
          <div className='flex justify-end gap-2'>
            {currentPage === 2 && (
              <>
                <SoftButton onClick={handleCancel} colour='base'>
                  Back
                </SoftButton>
                <FilledButton onClick={handleSubmit} colour='primary'>
                  {resource ? 'Save' : 'Create'}
                </FilledButton>
              </>
            )}
            {currentPage === 1 && (
              <>
                <SoftButton onClick={handleCancel} colour='base'>
                  Cancel
                </SoftButton>
                <FilledButton onClick={handleNext} colour='primary'>
                  Next
                </FilledButton>
              </>
            )}
          </div>
        </div>
      }
    >
      {currentPage === 1 ? (
        <div className='space-y-6'>
          <div className='flex gap-6'>
            <div className='w-full max-w-xs'>
              <div className='h-72 relative mt-2'>
                {croppedImage ? (
                  <img
                    src={croppedImage}
                    alt='Preview'
                    className='w-full h-full object-cover rounded-lg'
                  />
                ) : (
                  <div className='w-full h-full border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center'>
                    <div className='text-center'>
                      <Upload className='mx-auto h-12 w-12 text-gray-400' />
                      <p className='mt-3 text-sm text-gray-500'>
                        Upload Thumbnail
                      </p>
                    </div>
                  </div>
                )}
                <input
                  type='file'
                  accept='image/*'
                  onChange={handleImageUpload}
                  className='absolute inset-0 w-full h-full opacity-0 cursor-pointer'
                />
              </div>
            </div>

            <div className='flex-1 space-y-4'>
              <div>
                <Input
                  label='Name'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  required
                />
                {errorField === 'name' && (
                  <span className='text-red-500 text-sm mt-1'>
                    {validationError}
                  </span>
                )}
              </div>
              <div>
                <label className='block text-sm font-medium mb-2'>
                  Description
                </label>
                <textarea
                  className={`block w-full min-w-0 border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 px-2.5 py-1.5 text-sm sm:leading-6 rounded-md max-h-[75px]`}
                  placeholder='Description'
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  rows={3}
                />
              </div>
              <div>
                <label className='block text-sm font-medium mb-2'>Tags</label>
                <div className='flex gap-2 items-center'>
                  <div className='w-48'>
                    <DropdownInput
                      placeholder='Select category'
                      options={[
                        { value: '', label: 'All Categories' },
                        ...categories.map(cat => ({
                          value: cat.id,
                          label: cat.type
                        }))
                      ]}
                      onChange={e => setSelectedCategory(e.target.value)}
                      value={selectedCategory}
                    />
                  </div>
                  <div className='flex-1'>
                    <ComboBox
                      loadOptions={loadTagOptions}
                      placeholder='Search for tags'
                      onChange={handleAddTag}
                      value={selectedTag}
                    />
                  </div>
                </div>
                <div className='mt-2 flex flex-wrap gap-2'>
                  {tags.map(tag => (
                    <Badge
                      key={tag.id}
                      colour={tag?.metadata?.colour}
                      size='sm'
                      onRemove={() => handleRemoveTag(tag.id)}
                    >
                      {tag.name}
                    </Badge>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='space-y-6'>
          <div>
            <label className='block text-sm font-medium mb-2'>Content</label>
            <TextEditor
              placeholder='Enter content, such as install instructions or usage guidelines.'
              value={content}
              onChange={setContent}
              className={'min-h-[200px] max-h-[calc(100vh-400px)] w-full'}
            />
            {errorField === 'content' && (
              <span className='text-red-500 text-sm mt-1'>
                {validationError}
              </span>
            )}
          </div>

          <div className='flex gap-6'>
            <div className='flex-1'>
              <label className='block text-sm font-medium mb-2'>Files</label>
              <div className='max-h-[200px] overflow-y-auto'>
                {files.map((file, index) => (
                  <div key={index} className='flex gap-2 mb-2'>
                    <div className='relative flex-1'>
                      <span className='py-2 px-3 bg-gray-50 rounded-md block'>
                        {file.fileName}
                      </span>
                    </div>
                    <Button
                      onClick={() => handleRemoveFile(index)}
                      colour='base'
                    >
                      <X size={20} />
                    </Button>
                  </div>
                ))}
                {pendingFiles
                  .filter(file => !file.uploaded)
                  .map((file, index) => (
                    <div key={index} className='flex gap-2 mb-2'>
                      <div className='relative flex-1'>
                        <Input
                          value={file.name}
                          onChange={e => {
                            const newPendingFiles = [...pendingFiles];
                            newPendingFiles[index].name = e.target.value;
                            setPendingFiles(newPendingFiles);
                          }}
                          placeholder='File name'
                        />
                        {uploadProgress[index] > 0 &&
                          uploadProgress[index] < 100 && (
                            <div className='absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center rounded-md'>
                              <span className='text-sm text-gray-700'>
                                {uploadProgress[index]}%
                              </span>
                            </div>
                          )}
                      </div>
                      <Button
                        onClick={() => handleFileUpload(index)}
                        colour='primary'
                        disabled={uploadingFiles[index]}
                      >
                        Upload
                      </Button>
                      <Button
                        onClick={() => handleRemovePendingFile(index)}
                        colour='base'
                      >
                        <X size={20} />
                      </Button>
                    </div>
                  ))}
              </div>
              <div className='relative'>
                <Button
                  onClick={() => document.getElementById('fileInput').click()}
                  colour='base'
                  className='mt-2'
                >
                  <Plus size={20} className='mr-2' />
                  Add File
                </Button>
                <input
                  id='fileInput'
                  type='file'
                  onChange={handleFileSelect}
                  className='hidden'
                />
              </div>
              {errorField === 'files' && (
                <span className='text-red-500 text-sm mt-1'>
                  {validationError}
                </span>
              )}
            </div>
          </div>
        </div>
      )}

      {showCropper && (
        <div className='fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center'>
          <div className='bg-white p-4 rounded-lg w-[500px] h-[500px] relative'>
            <div className='relative h-[400px]'>
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <div className='flex justify-end gap-2 mt-4'>
              <Button onClick={() => setShowCropper(false)} colour='base'>
                Cancel
              </Button>
              <Button onClick={getCroppedImg} colour='primary'>
                Crop
              </Button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default CreateResource;
