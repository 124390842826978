import { useState, useEffect, useCallback } from 'react';
import {
  FilledButton,
  SoftButton,
  TextButton,
  Toggle,
  Input,
  DropdownInput,
  RangeInput
} from 'core';
import { X, Filter, Save, Eye, EyeOff, Trash2 } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import SubTabs from '../navigation/FilterTabs';
import FilterModel from '../../models/Filter';
import { realtime } from '../../utilities/supabase';
import { useContext } from 'react';
import { UserProfileContext } from '../../App';
import { PERMISSIONS } from '../../utilities/Permissions';
import { FilterOperatorEnum } from '../../utilities/Enumerables';
import { debounce } from 'lodash';

const FiltersMenu = ({
  isOpen,
  onClose,
  onApply,
  filter,
  filters,
  setFilters,
  entityType
}) => {
  const [localFilters, setLocalFilters] = useState({});
  const [globalFilters, setGlobalFilters] = useState([]);
  const [myFilters, setMyFilters] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [showSavePopup, setShowSavePopup] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { userProfile: currentUser } = useContext(UserProfileContext);

  const fetchFilters = async (params, setter) => {
    try {
      const { data } = await FilterModel.getAll(params, 1, 100, 'created_date');
      setter(data);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const initialFilters = filter.reduce((acc, { field }) => {
      const param = searchParams.get(field);
      if (param) {
        if (field === 'distance') {
          const [operator, value] = param.split('|');
          const lat = searchParams.get('lat');
          const lng = searchParams.get('lng');
          acc[field] = {
            value: value,
            operator: operator,
            lat: lat ?? 53.00630079443634,
            lng: lng ?? -2.1651132351130746
          };
        } else {
          const [operator, value] = param.split('|');
          acc[field] = { value: value.trim(), operator: operator || 'eq' };
        }
      } else if (filters[field]) {
        acc[field] = { ...filters[field] };
      }
      return acc;
    }, {});
    setLocalFilters(initialFilters);
  }, [filter, filters, location.search, entityType, currentUser?.id]);

  useEffect(() => {
    fetchFilters(
      { entity_type: { value: entityType }, status: { value: 123 } },
      setGlobalFilters
    );

    if (currentUser)
      fetchFilters(
        {
          entity_type: { value: entityType },
          owner: { operator: 'equals', value: currentUser.id }
        },
        setMyFilters
      );

    const filtersSubscription = realtime('*', 'filters', () => {
      fetchFilters(
        { entity_type: { value: entityType }, status: { value: 123 } },
        setGlobalFilters
      );
      if (currentUser) {
        fetchFilters(
          {
            entity_type: { value: entityType },
            owner: { operator: 'equals', value: currentUser.id }
          },
          setMyFilters
        );
      }
    });

    return () => filtersSubscription.unsubscribe();
  }, [entityType, currentUser?.id]);

  const updateUrlParams = newFilters => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(newFilters).forEach(([key, val]) => {
      if (val.value) {
        if (key === 'distance') {
          searchParams.set(key, `${val.operator}|${val.value}`);
          searchParams.set('lat', val.metadata.lat ?? 53.00630079443634);
          searchParams.set('lng', val.metadata.lng ?? -2.1651132351130746);
        } else {
          searchParams.set(key, `${val.operator}|${val.value}`);
        }
      } else {
        searchParams.delete(key);
        if (key === 'distance') {
          searchParams.delete('lat');
          searchParams.delete('lng');
        }
      }
    });
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true
    });
  };

  const handleInputChange = (field, value, operator = 'eq') => {
    setLocalFilters(prev => {
      const newFilters = { ...prev };
      if (typeof value === 'object' && value !== null) {
        newFilters[field] = {
          value: { ...newFilters[field]?.value, ...value },
          operator
        };
      } else if (value) {
        newFilters[field] = { value: value, operator };
      } else {
        delete newFilters[field];
      }
      if (Object.keys(newFilters).length === 0) setShowSavePopup(false);
      return newFilters;
    });
  };

  const handleApply = filterData => {
    const appliedFilters = Object.entries(filterData).reduce(
      (acc, [key, value]) => {
        const filterItem = filter.find(f => f.field === key);
        if (key === 'distance') {
          acc[key] = {
            operator: 'lte',
            value: value.value.distance || value.value,
            label: filterItem ? filterItem.label : key,
            metadata: {
              lat: value.lat || 53.00630079443634,
              lng: value.lng || -2.1651132351130746
            }
          };
        } else {
          acc[key] = {
            operator: value.operator || filterItem?.operator || 'eq',
            value:
              typeof value.value === 'object'
                ? JSON.stringify(value.value)
                : value.value,
            label: filterItem ? filterItem.label : key
          };
        }
        return acc;
      },
      {}
    );

    onApply(appliedFilters);
    onClose();
    updateUrlParams(appliedFilters);
    setFilters(appliedFilters);
  };

  const handleConfirmSave = async () => {
    try {
      if (currentUser && Object.keys(localFilters).length > 0) {
        const newFilter = new FilterModel({
          name: filterName,
          status: 124,
          entityType: entityType,
          owner: currentUser.id,
          filterData: localFilters
        });
        await newFilter.insert();
        setFilterName('');
        setShowSavePopup(false);
      }
    } catch (error) {
      console.error('Error saving filter:', error);
    }
  };

  const handleApplyFilter = filterData => {
    setLocalFilters(filterData);
    handleApply(filterData);
  };

  const handleToggleFilterVisibility = async (filter, currentStatus) => {
    try {
      const newStatus = currentStatus === 123 ? 124 : 123;
      await filter.update({ status: newStatus });
    } catch (error) {
      console.error('Error updating filter visibility:', error);
    }
  };

  const handleDeleteFilter = async filter => {
    try {
      await filter.delete();
    } catch (error) {
      console.error('Error deleting filter:', error);
    }
  };

  const renderFilterList = (filterList, isGlobal = false) => (
    <div className='space-y-2'>
      {filterList.length > 0 ? (
        filterList.map(savedFilter => (
          <div
            key={savedFilter.id}
            className='p-2 bg-neutral-50 rounded flex justify-between items-center cursor-pointer transition-all duration-200'
            onClick={() => handleApplyFilter(savedFilter.filterData)}
          >
            <div>
              <h3 className='font-medium'>
                {savedFilter.name || 'Unnamed Filter'}
              </h3>
              <p className='text-sm text-neutral-600'>
                {new Date(savedFilter.createdDate).toLocaleDateString()}
              </p>
            </div>
            <div className='flex items-center space-x-2'>
              {!isGlobal && (
                <>
                  <TextButton
                    onClick={e => {
                      e.stopPropagation();
                      handleToggleFilterVisibility(
                        savedFilter,
                        savedFilter.status
                      );
                    }}
                    colour='primary'
                    size='sm'
                    leftIcon={
                      savedFilter.status === 123 ? (
                        <Eye size={18} />
                      ) : (
                        <EyeOff size={18} />
                      )
                    }
                  />
                  <TextButton
                    onClick={e => {
                      e.stopPropagation();
                      handleDeleteFilter(savedFilter);
                    }}
                    colour='primary'
                    size='sm'
                    leftIcon={<Trash2 size={18} />}
                  />
                </>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className='py-8 text-center text-neutral-600'>
          <div className='flex flex-col items-center'>
            <Filter size={38} className='text-neutral-300 mb-2' />
            <p className='text-neutral-300 font-medium'>No filters found</p>
          </div>
        </div>
      )}
    </div>
  );

  const canViewDeleted = PERMISSIONS[entityType]?.VIEW_DELETED
    ? currentUser.hasPermission(
        entityType,
        PERMISSIONS[entityType].VIEW_DELETED
      )
    : false;

  const geocodeAddress = async address => {
    if (!window.google?.maps) {
      console.error('Google Maps library not loaded');
      return { lat: null, lng: null };
    }

    try {
      const { Geocoder } = await google.maps.importLibrary('geocoding');
      const geocoder = new Geocoder();

      return new Promise((resolve, reject) => {
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === 'OK' && results[0]) {
            const { lat, lng } = results[0].geometry.location.toJSON();
            console.log('resolving', lat, lng);
            resolve({ lat: lat, lng: lng });
          } else {
            console.error('Error geocoding address:', status);
            resolve({ lat: null, lng: null });
          }
        });
      });
    } catch (error) {
      console.error('Error geocoding address:', error);
      return { lat: null, lng: null };
    }
  };

  const debouncedGeocodeAddress = useCallback(
    debounce(async (address, field, operator) => {
      const coords = await geocodeAddress(address);
      console.log('coords', coords);
      handleInputChange(field, { ...coords }, operator);
    }, 1000),
    []
  );

  const renderFilterInput = ({
    field,
    label,
    hint,
    type,
    operator,
    options
  }) => {
    const value = localFilters[field]?.value || '';

    if (field === 'showArchived' && !canViewDeleted) {
      return null;
    }

    if (field === 'status' && type === 'select' && !canViewDeleted) {
      options = options.filter(option => option.value !== 'Archived');
    }

    if (type === 'select') {
      return (
        <DropdownInput
          key={field}
          id={field}
          name={field}
          label={label}
          value={value}
          onChange={e => handleInputChange(field, e.target.value, operator)}
          options={[
            { value: '', label: 'Select...' },
            ...options.map(option => ({
              value: option.key,
              key: option.key,
              label: option.value
            }))
          ]}
        />
      );
    } else if (type === 'daterange') {
      return (
        <div className='space-y-2'>
          <Input
            key={`${field}__from`}
            label={`${label} From`}
            value={value ? value.from : ''}
            onChange={e =>
              handleInputChange(field, { from: e.target.value }, operator)
            }
            type='date'
          />
          <Input
            key={`${field}__to`}
            label={`${label} To`}
            value={value ? value.to : ''}
            onChange={e =>
              handleInputChange(field, { to: e.target.value }, operator)
            }
            type='date'
          />
        </div>
      );
    } else if (type === 'toggle') {
      return (
        <Toggle
          key={field}
          checked={value}
          onChange={e => handleInputChange(field, e.target.checked, operator)}
          label={label}
          id={field}
          description={hint}
        />
      );
    } else if (type === 'conditional_toggle') {
      const currentValue = localFilters[field]?.value || '';
      return (
        <Toggle
          key={field}
          checked={currentValue === options.value}
          description={hint}
          onChange={checked => {
            if (checked) {
              handleInputChange(field, options.value, operator);
            } else {
              handleInputChange(field, '', operator);
            }
          }}
          label={label}
          id={field}
        />
      );
    } else if (type === 'distance') {
      return (
        <div className='space-y-2'>
          <Input
            key={`${field}__address`}
            label={`${label} Address`}
            onChange={e =>
              debouncedGeocodeAddress(e.target.value, field, operator)
            }
            type='text'
          />
          <RangeInput
            key={field}
            label={label}
            labelHint={`${value.distance || value || 50} miles`}
            value={value.distance || value || 50}
            intervals={[0, 5, 10, 15, 20, 30, 40, 50, 75, 100]}
            onChange={val =>
              handleInputChange(field, { distance: val }, operator)
            }
            min={0}
            max={100}
            step={1}
            hint={hint}
          />
        </div>
      );
    } else {
      return (
        <Input
          key={field}
          label={label}
          value={value}
          onChange={e => handleInputChange(field, e.target.value, operator)}
          type='text'
        />
      );
    }
  };

  const tabs = [
    {
      label: 'Filters',
      content: <div className='space-y-4'>{filter.map(renderFilterInput)}</div>
    },
    {
      label: 'Quick Filters',
      content: renderFilterList(globalFilters, true)
    },
    {
      label: 'My Filters',
      content: renderFilterList(myFilters)
    }
  ];

  return (
    <div
      className={`fixed z-20 top-0 right-0 h-full w-96 bg-white shadow-lg transform ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } transition-transform duration-300 ease-in-out overflow-y-auto`}
    >
      <div className='p-4'>
        <div className='flex flex-col mb-3 sticky top-0 bg-white z-30'>
          <div className='flex justify-between items-center mb-2'>
            <h2 className='text-xl font-semibold text-neutral-800'>Filters</h2>
            <TextButton onClick={onClose} colour='base' size='sm'>
              <X size={24} />
            </TextButton>
          </div>
          <div className='flex space-x-2'>
            <FilledButton
              onClick={() => handleApply(localFilters)}
              colour='primary'
              className='flex-grow justify-center'
            >
              Apply
            </FilledButton>
            <SoftButton
              onClick={() => setShowSavePopup(!showSavePopup)}
              colour='primary'
              disabled={Object.keys(localFilters).length === 0}
            >
              <Save size={18} />
            </SoftButton>
          </div>
          {showSavePopup && (
            <div className='mt-2 bg-neutral-50 p-3 rounded-md'>
              <Input
                label='Filter Name'
                value={filterName}
                onChange={e => setFilterName(e.target.value)}
              />
              <div className='mt-2 flex justify-end space-x-2'>
                <TextButton
                  onClick={() => setShowSavePopup(false)}
                  colour='base'
                >
                  Cancel
                </TextButton>
                <FilledButton onClick={handleConfirmSave} colour='primary'>
                  Save
                </FilledButton>
              </div>
            </div>
          )}
        </div>
        <SubTabs tabs={tabs} />
      </div>
    </div>
  );
};

export default FiltersMenu;
