import { useState, useEffect } from 'react';
import { FileText } from 'lucide-react';
import { SoftButton, FilledButton, Popup } from 'core';
import Resource from '../../../../models/Resources';
import Document from '../../../../models/Document';

const View = ({ isOpen, onClose, resourceId }) => {
  const [resource, setResource] = useState(null);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const fetchResource = async () => {
      try {
        const data = await Resource.getById(resourceId);
        setResource(data);

        if (data?.files?.length) {
          const docs = await Promise.all(
            data.files.map(fileId => Document.getById(fileId))
          );
          setDocuments(docs.filter(doc => doc)); // Filter out any null results
        }
      } catch (error) {
        console.error('Error fetching resource:', error);
      }
    };

    if (resourceId) {
      fetchResource();
    }
  }, [resourceId]);

  if (!resource) {
    return null;
  }

  return (
    <Popup isOpen={isOpen} onClose={onClose} title={resource.name} size='lg'>
      <div className='flex gap-6'>
        {/* Left container - Instructions */}
        <div className='flex-grow w-2/3'>
          {resource.content ? (
            <div
              className='prose prose-neutral max-w-none content'
              dangerouslySetInnerHTML={{ __html: resource.content }}
            />
          ) : (
            <p className='text-gray-500 italic'>
              No additional instructions provided for this resource.
            </p>
          )}
        </div>

        {/* Right container - Resource details */}
        <div className='w-1/3 flex flex-col gap-6'>
          {resource.image && (
            <img
              src={resource.image}
              alt={resource.name}
              className='w-full h-48 object-cover rounded-lg'
            />
          )}

          <div className='bg-gray-50 p-4 rounded-lg'>
            <h3 className='font-medium text-gray-900 mb-2'>Resource Details</h3>
            <div className='text-sm text-gray-600 space-y-2'>
              <p>
                Created: {new Date(resource.createdDate).toLocaleDateString()}
              </p>
              <p>Owner: {resource.owner?.fullName}</p>
              {resource.description && (
                <p className='text-gray-700'>{resource.description}</p>
              )}
            </div>
          </div>

          <div className='space-y-3'>
            {resource.url && (
              <FilledButton
                as='a'
                href={resource.url}
                target='_blank'
                className='w-full justify-center'
              >
                Download Resource
              </FilledButton>
            )}

            {resource.files?.length > 0 && (
              <div className='space-y-2'>
                <h3 className='font-medium text-gray-900'>Attachments</h3>
                {documents.map(doc => (
                  <a
                    key={doc.id}
                    href={`https://storage.googleapis.com/bohsa-docs/${doc.filePath}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='p-3 bg-white border border-gray-200 rounded flex items-center gap-3 hover:bg-gray-50 transition-colors'
                  >
                    <FileText size={20} className='text-gray-400' />
                    <span className='text-sm text-gray-600'>
                      {doc.fileName || `Attachment ${doc.id}`}
                    </span>
                  </a>
                ))}
              </div>
            )}

            {Object.entries(resource.links || {}).length > 0 && (
              <div className='space-y-2'>
                <h3 className='font-medium text-gray-900'>Related Links</h3>
                {Object.entries(resource.links).map(([key, value]) => (
                  <SoftButton
                    key={key}
                    as='a'
                    href={value}
                    target='_blank'
                    className='w-full justify-center text-sm'
                  >
                    {key}
                  </SoftButton>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default View;
