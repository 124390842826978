import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  GraduationCap,
  CheckCircle,
  Building,
  Users,
  MapPin,
  FileText,
  Truck,
  Calendar,
  PoundSterling,
  Percent,
  CreditCard,
  Clock,
  Map as MapIcon,
  Inbox,
  Tag,
  Phone,
  Home,
  Briefcase,
  Mail,
  Plus,
  Trash,
  PlusCircle
} from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import Booking from '../../../models/Booking';
import DateCard from '../../../components/entity/DateCard';
import CoreEntityTypes from '../../../models/CoreEntityType';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import UserProfile from '../../../models/UserProfile';
import {
  SoftButton,
  TextButton,
  Map,
  StackedList,
  Input,
  Badge,
  DropdownInput,
  Alert,
  Field
} from 'core';
import Location from '../../../models/Location';
import AddContact from '../../../components/popups/contacts/AddContact';
import AdditionalItems from './tabs/AdditionalItems';
import BookingSpaces from './tabs/BookingSpaces';
import Invoice from '../../../models/Invoice';
import AdditionalItem from '../../../models/AdditionalItem';
import VerifyBooking from './VerifyBooking';
import { UserProfileContext } from '../../../App';

const BookingPage = ({ currentUser }) => {
  const [deliveryTypeOptions, setDeliveryTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [subStatusOptions, setSubStatusOptions] = useState([]);
  const [contactTypeOptions, setContactTypeOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [mapData, setMapData] = useState({ latitude: 0, longitude: 0 });
  const { id } = useParams();
  const navigate = useNavigate();

  const { userProfile } = useContext(UserProfileContext);

  const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

  // New state for contact details
  const [contactProfiles, setContactProfiles] = useState([]);
  const [showAddContact, setShowAddContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showInvoicePopup, setShowInvoicePopup] = useState(false);
  const [bookingData, setBookingData] = useState(null);

  const loadLocationOptions = async (inputValue, page = 1) => {
    try {
      const { data: locations } = await Location.getAll(
        { name: { operator: FilterOperatorEnum.ILIKE, value: inputValue } },
        page,
        10,
        'name'
      );
      return locations.map(location => ({
        label: location.name,
        secondaryLabel: `${location.address1}, ${location.city}, ${location.postcode}`,
        value: location.id
      }));
    } catch (error) {
      console.error('Error loading location options:', error);
      return [];
    }
  };

  const fetchInvoiceOptions = async (query, page = 1) => {
    try {
      if (query) {
        const { data: invoices } = await Invoice.getAll(
          { id: { operator: FilterOperatorEnum.EQUALS, value: query } },
          page
        );
        return invoices.map(invoice => ({
          value: invoice.id,
          label: `ID: ${invoice.id} - Ref: ${invoice.reference}`
        }));
      } else {
        const { data: invoices } = await Invoice.getAll({}, page);
        return invoices.map(invoice => ({
          value: invoice.id,
          label: `ID: ${invoice.id} - Ref: ${invoice.reference}`
        }));
      }
    } catch (error) {
      console.error('Error fetching invoice options:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      // Set the document title
      document.title = `Booking #${id} | CRM | BOHSA LTD`;
      try {
        const deliveryTypes = await CoreEntityTypes.getByEntityId(6);
        const statuses = await CoreEntityTypes.getByEntityId(29);
        const subStatuses = await CoreEntityTypes.getByEntityId(30);
        const contactTypes = await CoreEntityTypes.getByEntityId(45);
        const { data: fetchedUsers } = await UserProfile.getAll();

        setDeliveryTypeOptions(
          deliveryTypes.map(option => ({
            label: option.type,
            value: option.id
          }))
        );

        setStatusOptions(
          statuses.map(option => ({
            label: option.type,
            value: option.id
          }))
        );

        setSubStatusOptions(
          subStatuses.map(option => ({
            label: option.type,
            value: option.id
          }))
        );

        setContactTypeOptions(
          contactTypes.map(option => ({
            label: option.type,
            value: option.id
          }))
        );

        setUsers(fetchedUsers);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const handleShowMap = venue => {
    setMapData({
      address: `${venue.address1}, ${venue.address2}, ${venue.city}, ${venue.postcode}`
    });
    setShowMap(!showMap);
  };

  // New function to fetch contact profiles
  const fetchContactProfiles = async data => {
    const profiles = await data.getContactProfiles();
    setContactProfiles(profiles);
  };

  // New function to handle adding a contact
  const handleAddContact = async (profileId, contactType, data) => {
    try {
      // Extract the label from the contactType object
      const contactTypeLabel = contactTypeOptions.find(
        option => option.value == contactType
      );

      const updatedBooking = await data.update({
        contacts: [
          ...data.contacts,
          { user_id: profileId, contact_type: contactTypeLabel.label }
        ]
      });
      const updatedProfiles = await updatedBooking.getContactProfiles();
      setContactProfiles(updatedProfiles);
      setShowAddContact(false);
    } catch (error) {
      console.error('Error adding contact:', error);
    }
  };

  // New function to handle contact search
  const handleContactSearch = (searchTerm, data) => {
    if (searchTerm === '') {
      fetchContactProfiles(data);
    } else {
      const filteredProfiles = contactProfiles.filter(
        contact =>
          contact.firstName.toLowerCase().includes(searchTerm) ||
          contact.lastName.toLowerCase().includes(searchTerm) ||
          contact.email.toLowerCase().includes(searchTerm) ||
          (contact.phones &&
            contact.phones.some(phone =>
              phone.number.toLowerCase().includes(searchTerm)
            ))
      );
      setContactProfiles(filteredProfiles);
    }
  };

  // New function to handle contact type filter
  const handleContactTypeFilter = async (selectedLabel, data) => {
    const allProfiles = await data.getContactProfiles();

    if (selectedLabel === 'All') {
      setContactProfiles(allProfiles);
    } else {
      const filteredContactIds = data.contacts
        .filter(
          c => c.contact_type.toLowerCase() === selectedLabel.toLowerCase()
        )
        .map(c => c.user_id);

      const filteredProfiles = allProfiles.filter(contact =>
        filteredContactIds.includes(contact.id)
      );
      setContactProfiles(filteredProfiles);
    }
  };

  // Move fetchContactProfiles useEffect here
  useEffect(() => {
    if (id) {
      const fetchContacts = async () => {
        const booking = await Booking.getById(id);
        const profiles = await booking.getContactProfiles();
        setContactProfiles(profiles);
      };
      fetchContacts();
    }
  }, [id]);

  // New function to handle viewing a contact
  const handleViewContact = userId => {
    navigate(`/user-profiles/${userId}`);
  };

  // New function to handle removing a contact
  const handleRemoveContact = async (userId, data) => {
    try {
      const updatedContacts = data.contacts.filter(
        contact => contact.user_id !== userId
      );
      const updatedBooking = await data.update({
        contacts: updatedContacts
      });
      const updatedProfiles = await updatedBooking.getContactProfiles();
      setContactProfiles(updatedProfiles);
    } catch (error) {
      console.error('Error removing contact:', error);
    }
  };

  const handleCreateInvoicePopup = async data => {
    if (!data) {
      console.error('Booking Data is not available');
      return;
    }
    setShowInvoicePopup(true);
  };
  // Function to handle creating an invoice
  const handleCreateInvoice = async data => {
    if (!data) {
      console.error('Booking Data is not available');
      return;
    }
    console.log(data);
    const tempInvoice = new Invoice({
      company: { id: data.company.id },
      addressId: data.invoiceAddress.id,
      reference: data.cpoNumber,
      dueDate: data.paymentDate,
      status: { id: 217 }
    });

    const savedInvoice = await tempInvoice.insert();
    const tempBooking = await Booking.getById(data.id);
    const updatedBooking = await tempBooking.update({
      invoiceId: savedInvoice.id
    });

    // Fetch additional items for this booking
    const { data: additionalItems } = await AdditionalItem.getAll({
      booking: {
        operator: FilterOperatorEnum.EQUALS,
        value: data.id
      }
    });

    // Update each additional item with the new invoice ID
    if (additionalItems && additionalItems.length > 0) {
      const updatePromises = additionalItems.map(item =>
        item.update({ invoiceId: savedInvoice.id })
      );
      await Promise.all(updatePromises);
    }

    try {
    } catch (error) {
      console.error(error);
    }
  };

  const infoItems = [
    {
      icon: GraduationCap,
      label: 'Course',
      field: 'course.name',
      isEditable: false,
      inlineLabel: false,
      inlineEditor: false
    },
    {
      icon: Building,
      label: 'Company',
      field: 'company.name',
      isEditable: false,
      inlineLabel: false,
      inlineEditor: false
    },
    {
      icon: CheckCircle,
      label: 'Status',
      displayField: 'status.name',
      editField: 'status.id',
      isEditable: true,
      dataType: 'select',
      options: statusOptions,
      inlineLabel: true,
      inlineEditor: true
    },
    {
      icon: CheckCircle,
      label: 'Sub Status',
      displayField: 'subStatus.name',
      editField: 'subStatus.id',
      isEditable: true,
      dataType: 'select',
      options: subStatusOptions,
      inlineLabel: true,
      inlineEditor: true
    },
    {
      icon: Users,
      label: 'Owner',
      displayField: 'owner',
      editField: 'owner',
      isEditable: true,
      dataType: 'user-select',
      options: users,
      inlineLabel: true,
      inlineEditor: false
    },
    {
      icon: Users,
      label: 'Followers',
      displayField: 'followers',
      editField: 'followers',
      isEditable: false,
      dataType: 'followers',
      inlineLabel: true,
      inlineEditor: false
    }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data, onUpdate }) => (
        <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
          <DateCard
            date={data.quotedDate}
            label='Quoted'
            isEditable={true}
            editField='quotedDate'
            onUpdate={onUpdate}
          />
          <DateCard
            date={data.bookingDate}
            label='Booked'
            isEditable={true}
            editField='bookingDate'
            onUpdate={onUpdate}
          />
          <DateCard
            date={data.paidDate}
            label='Paid'
            isEditable={true}
            editField='paidDate'
            onUpdate={onUpdate}
          />
          <DateCard
            date={data.startDate}
            label='Start'
            isEditable={true}
            editField='startDate'
            onUpdate={onUpdate}
          />
          <DateCard
            date={data.endDate}
            label='End'
            isEditable={true}
            editField='endDate'
            onUpdate={onUpdate}
          />
        </div>
      )
    },
    {
      title: 'Details',
      content: ({ data, onUpdate }) => (
        <div>
          <Field
            Icon={FileText}
            label='Quote ID'
            value={data.quoteId || ''}
            editField='quoteId'
            isEditable={true}
            dataType='text'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
          <Field
            Icon={Users}
            label='Spaces'
            value={data.spaces || ''}
            editField='spaces'
            isEditable={true}
            dataType='number'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
          <Field
            Icon={Truck}
            label='Delivery'
            value={data.deliveryType?.name || ''}
            editField='deliveryType.id'
            isEditable={true}
            dataType='select'
            options={deliveryTypeOptions}
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
          <Field
            Icon={GraduationCap}
            label='Trainer'
            value={data.trainer ? data.trainer.name : 'Not assigned'}
            valueMetadata={{ url: `/trainers/${data.trainer?.id}` }}
            editField='trainer.id'
            isEditable={false}
            inlineLabel={true}
            dataType='link'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
          <Field
            Icon={MapPin}
            label='Venue'
            value={
              data.venue?.name ||
              (data.venue?.address1 && data.venue?.city
                ? `${data.venue.address1}, ${data.venue.city}`
                : '') ||
              ''
            }
            editField='venue.id'
            isEditable={true}
            inlineEditor={false}
            onUpdate={onUpdate}
            dataType='search'
            loadOptions={loadLocationOptions}
            extension={value => (
              <SoftButton
                onClick={() => handleShowMap(data.venue)}
                colour='base'
                size='sm'
              >
                <MapIcon size={18} />
              </SoftButton>
            )}
            userProfile={userProfile}
          />
          {showMap && (
            <Map
              address={mapData.address}
              zoom={15}
              width='100%'
              height='200px'
              apiKey={apiKey}
            />
          )}
          <Field
            Icon={Inbox}
            label='Central Inbox'
            value={data.centralInbox || ''}
            editField='centralInbox'
            isEditable={true}
            dataType='text'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
          <Field
            Icon={FileText}
            label='Levy'
            value={data.levy || ''}
            editField='levy'
            isEditable={true}
            dataType='text'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />

          <Field
            Icon={Tag}
            label='Custom Ref'
            value={data.customReference || ''}
            editField='customReference'
            isEditable={true}
            dataType='text'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
        </div>
      )
    },
    {
      title: 'Contact Details',
      content: ({ data }) => (
        <div>
          <div className='flex gap-x-1 mb-4'>
            <Input
              placeholder='Search contacts...'
              onChange={e =>
                handleContactSearch(e.target.value.toLowerCase(), data)
              }
            />
            <DropdownInput
              placeholder='Filter by type'
              options={[{ label: 'All', value: '' }, ...contactTypeOptions]}
              onChange={e => {
                const selectedOption = e.target.options[e.target.selectedIndex];
                const selectedLabel = selectedOption.label;
                handleContactTypeFilter(selectedLabel, data);
              }}
            />
            <SoftButton onClick={() => setShowAddContact(true)}>
              <Plus size={18} />
            </SoftButton>
          </div>
          <StackedList
            items={contactProfiles.map(contact => ({
              title: `${contact.firstName} ${contact.lastName}`,
              titleBadge: (
                <Badge colour='info' shape='pill' size='xs'>
                  {
                    data.contacts.find(c => c.user_id === contact.id)
                      ?.contact_type
                  }
                </Badge>
              ),
              description: (
                <>
                  <Mail className='inline-block w-4 h-4 mr-1' /> {contact.email}
                  {contact.phones && contact.phones.length > 0 && (
                    <>
                      <br />
                      {contact.phones.map((phone, index) => (
                        <span key={index} className='mr-2'>
                          {phone.type === 'mobile' && (
                            <Phone className='inline-block w-4 h-4 mr-1' />
                          )}
                          {phone.type === 'home' && (
                            <Home className='inline-block w-4 h-4 mr-1' />
                          )}
                          {phone.type === 'work' && (
                            <Briefcase className='inline-block w-4 h-4 mr-1' />
                          )}
                          {phone.number}
                        </span>
                      ))}
                    </>
                  )}
                </>
              ),
              action: (
                <div className='flex flex-row gap-2'>
                  <TextButton
                    size='sm'
                    colour='base'
                    onClick={() => handleViewContact(contact.id)}
                  >
                    View
                  </TextButton>
                  <TextButton
                    size='sm'
                    colour='danger'
                    onClick={() => handleRemoveContact(contact.id, data)}
                  >
                    <Trash />
                  </TextButton>
                </div>
              )
            }))}
          />
          <AddContact
            isOpen={showAddContact}
            onClose={() => setShowAddContact(false)}
            entityId={data.id}
            entityType={EntityTypeEnum.Booking}
            onAddContact={(profileId, contactType) =>
              handleAddContact(profileId, contactType, data)
            }
            contactTypeOptions={contactTypeOptions}
          />
        </div>
      )
    },
    {
      title: 'Accounts',
      content: ({ data, onUpdate }) => (
        <div>
          <Field
            Icon={PoundSterling}
            label='Cost / Delegate'
            value={
              data.deliveryType === 62
                ? data.invoiceAmount / data.spaces
                : data.spaceRate || data.invoiceAmount / data.spaces || ''
            }
            editField='spaceRate'
            isEditable={true}
            dataType='number'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
          <Field
            Icon={FileText}
            label='TPO Number'
            value={data.tpoNumber || ''}
            editField='tpoNumber'
            isEditable={true}
            dataType='text'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
          <Field
            Icon={PoundSterling}
            label='TPO Amount'
            value={data.tpoAmount || ''}
            editField='tpoAmount'
            isEditable={true}
            dataType='number'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
          <Field
            Icon={FileText}
            label='CPO Number'
            value={data.cpoNumber || ''}
            editField='cpoNumber'
            isEditable={true}
            dataType='text'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
          <Field
            Icon={FileText}
            label='Invoice Number'
            value={data.invoiceId || ''}
            editField='invoiceId'
            isEditable={true}
            dataType='search'
            loadOptions={fetchInvoiceOptions}
            onUpdate={onUpdate}
            extension={value =>
              data.invoiceId ? (
                <SoftButton
                  onClick={() =>
                    navigate(`/invoices/${data.invoiceId}/preview`)
                  }
                  colour='base'
                  size='xs'
                >
                  View Invoice
                </SoftButton>
              ) : (
                <SoftButton
                  onClick={() => {
                    setBookingData(data);
                    handleCreateInvoicePopup(data);
                  }}
                  colour='base'
                  size='xs'
                >
                  Create Invoice
                </SoftButton>
              )
            }
            userProfile={userProfile}
          />

          <Field
            Icon={PoundSterling}
            label='Invoice Amount'
            value={data.invoiceAmount || ''}
            editField='invoiceAmount'
            isEditable={true}
            dataType='number'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
          {data.metrics.items.count > 0 && (
            <Field
              Icon={PoundSterling}
              label='Invoice + Items'
              value={
                (data.invoiceAmount || 0) + (data.metrics.items.total || 0)
              }
              editField='totalInvoice'
              isEditable={false}
              dataType='number'
              extension={value => (
                <SoftButton
                  onClick={() => navigate(`/bookings/${data.id}/items`)}
                  colour='base'
                  size='xs'
                >
                  View Items ({data.metrics.items.count})
                </SoftButton>
              )}
              userProfile={userProfile}
            />
          )}
          <Field
            Icon={Calendar}
            label='Payment Due'
            value={data.paymentDueDate || ''}
            editField='paymentDueDate'
            isEditable={true}
            dataType='date'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
          <Field
            Icon={PoundSterling}
            label='Profit £'
            value={(data.invoiceAmount - data.tpoAmount).toFixed(2) || ''}
            editField='profit'
            isEditable={false}
            dataType='number'
            userProfile={userProfile}
          />
          <Field
            Icon={Percent}
            label='Profit %'
            value={`${
              (
                (data.invoiceAmount - data.tpoAmount).toFixed(2) /
                data.invoiceAmount
              ).toFixed(2) * 100
            }%`}
            editField='profitPercentage'
            isEditable={false}
            dataType='number'
            userProfile={userProfile}
          />
          <Field
            Icon={CreditCard}
            label='On Account'
            value={data.onAccount ? 'Yes' : 'No'}
            editField='onAccount'
            isEditable={true}
            dataType='boolean'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
          <Field
            Icon={Clock}
            label='Terms'
            value={`${data.terms?.days || ''} days ${
              data.terms?.daysAfter || ''
            } ${data.terms?.type?.type || ''}`}
            editField='terms'
            isEditable={false}
            dataType='object'
            onUpdate={onUpdate}
            userProfile={userProfile}
          />
        </div>
      )
    }
  ];

  const progressItems = {
    title: 'Booking Progress',
    steps: statusOptions.map(status => ({
      label: status.label,
      status: 'upcoming'
    })),
    getValue: entity => {
      const currentStatusIndex = statusOptions.findIndex(
        status => status.value === entity?.status.id
      );
      return currentStatusIndex + 1;
    }
  };

  const additionalTabs = [
    {
      label: 'Booking Spaces',
      path: 'spaces',
      content: <BookingSpaces bookingId={id} />
    },
    {
      label: 'Additional Items',
      path: 'items',
      content: <AdditionalItems bookingId={id} />
    }
  ];

  const alertItems = [
    {
      condition: entity => entity?.parentId != null,
      content: entity => (
        <Alert
          title='Booking Resit'
          style='info'
          description='This booking is a resit of a previous booking, you can view the original booking by clicking the link in this notice.'
          inline={true}
          actions={[
            {
              text: 'View details',
              onClick: () => navigate(`/bookings/${entity.parentId}`)
            }
          ]}
        />
      )
    },
    {
      condition: entity => entity?.metrics.items.incomplete > 0,
      content: entity => (
        <Alert
          title='Incomplete Items'
          style='warning'
          description='This booking has additional items which are not linked to an invoice and are therefore incomplete. 
          Please ensure all items are correctly invoiced.'
        />
      )
    }
  ];

  return (
    <>
      <Entity
        currentUser={currentUser}
        entityType={EntityTypeEnum.Booking}
        model={Booking}
        infoItems={infoItems}
        accordionItems={accordionItems}
        additionalTabs={additionalTabs}
        progressItems={progressItems}
        actions={[
          {
            label: 'Create Invoice',
            onClick: handleCreateInvoicePopup,
            icon: <PlusCircle size={16} />
          }
        ]}
        alertItems={alertItems}
      />
      <VerifyBooking
        showInvoicePopup={showInvoicePopup}
        setShowInvoicePopup={setShowInvoicePopup}
        bookingData={bookingData}
        onCreateInvoice={handleCreateInvoice}
      />
    </>
  );
};

export default BookingPage;
