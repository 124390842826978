import React, { useState, useEffect } from 'react';
import { Popup, FilledButton, Input, DropdownInput, ComboBox } from 'core';
import AgreedRate from '../../../models/AgreedRate';
import Course from '../../../models/Course';
import Trainer from '../../../models/Trainer';
import Company from '../../../models/Company';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const EditRate = ({
  isOpen,
  onClose,
  rateId,
  onEdit,
  companyId,
  trainerId,
  courseId
}) => {
  const initialFormData = {
    company: null,
    course: null,
    delivery: '',
    trainer: null,
    price: '',
    status: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [statusTypes, setStatusTypes] = useState([]);

  useEffect(() => {
    const fetchEntityTypes = async () => {
      try {
        const [deliveryData, statusData] = await Promise.all([
          CoreEntityType.getAll({
            entity_id: {
              operator: 'eq',
              value: EntityTypeEnum.CourseDelivery
            }
          }),
          CoreEntityType.getAll({
            entity_id: {
              operator: 'eq',
              value: EntityTypeEnum.AgreedRateStatus
            }
          })
        ]);

        setDeliveryTypes(
          deliveryData.data.map(type => ({ label: type.type, value: type.id }))
        );
        setStatusTypes(
          statusData.data.map(type => ({ label: type.type, value: type.id }))
        );
      } catch (error) {
        console.error('Error fetching entity types:', error);
      }
    };

    fetchEntityTypes();
  }, []);

  useEffect(() => {
    const fetchRate = async () => {
      if (rateId) {
        try {
          const rate = await AgreedRate.getById(rateId);
          setFormData({
            company:
              rate.company && !companyId
                ? {
                    label: rate.company.name,
                    value: rate.company.id,
                    data: rate.company
                  }
                : null,
            course:
              rate.course && !courseId
                ? {
                    label: rate.course.name,
                    value: rate.course.id,
                    data: rate.course
                  }
                : null,
            delivery: rate.delivery?.id || '',
            trainer:
              rate.trainer && !trainerId
                ? {
                    label: rate.trainer.name,
                    value: rate.trainer.id,
                    data: rate.trainer
                  }
                : null,
            price: rate.price?.toString() || '',
            status: rate.status?.id || ''
          });
        } catch (error) {
          console.error('Error fetching rate:', error);
        }
      }
    };

    fetchRate();
  }, [rateId, companyId, trainerId, courseId]);

  const loadCompanies = async (query, page) => {
    try {
      const { data } = await Company.getAll({
        fts: {
          operator: 'textSearch',
          value: query
        }
      });
      return data.map(company => ({
        label: company.name,
        value: company.id,
        data: company
      }));
    } catch (error) {
      console.error('Error fetching companies:', error);
      return [];
    }
  };

  const loadCourses = async (query, page) => {
    try {
      const { data } = await Course.getAll({
        fts: {
          operator: 'textSearch',
          value: query
        }
      });
      return data.map(course => ({
        label: course.name,
        value: course.id,
        data: course
      }));
    } catch (error) {
      console.error('Error fetching courses:', error);
      return [];
    }
  };

  const loadTrainers = async (query, page) => {
    try {
      const { data } = await Trainer.getAll({
        fts: {
          operator: 'textSearch',
          value: query
        }
      });
      return data.map(trainer => ({
        label: trainer.name,
        value: trainer.id,
        data: trainer
      }));
    } catch (error) {
      console.error('Error fetching trainers:', error);
      return [];
    }
  };

  const handleSubmit = async () => {
    try {
      const agreedRate = await AgreedRate.getById(rateId);
      await agreedRate.update({
        company: companyId
          ? { id: companyId }
          : formData?.company?.data || null,
        course: courseId ? { id: courseId } : formData?.course?.data || null,
        delivery: formData.delivery ? { id: formData.delivery } : null,
        trainer: trainerId
          ? { id: trainerId }
          : formData?.trainer?.data || null,
        price: parseFloat(formData.price),
        status: { id: formData.status }
      });
      onEdit();
      handleClose();
    } catch (error) {
      console.error('Error updating agreed rate:', error);
    }
  };

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleClose = () => {
    setFormData(initialFormData);
    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      title='Edit Agreed Rate'
      size='sm'
      footer={
        <div className='flex justify-end space-x-2'>
          <FilledButton onClick={handleClose} colour='secondary'>
            Cancel
          </FilledButton>
          <FilledButton onClick={handleSubmit} colour='primary'>
            Save
          </FilledButton>
        </div>
      }
    >
      <div className='space-y-4'>
        {!companyId && (
          <ComboBox
            label='Company'
            loadOptions={loadCompanies}
            value={formData.company}
            onChange={company => handleChange('company', company)}
            placeholder='Search companies...'
          />
        )}

        {!courseId && (
          <ComboBox
            label='Course'
            loadOptions={loadCourses}
            value={formData.course}
            onChange={course => handleChange('course', course)}
            placeholder='Search courses...'
          />
        )}

        <DropdownInput
          label='Delivery'
          options={deliveryTypes}
          value={formData.delivery}
          onChange={e => handleChange('delivery', e.target.value)}
        />

        {!trainerId && (
          <ComboBox
            label='Trainer'
            loadOptions={loadTrainers}
            value={formData.trainer}
            onChange={trainer => handleChange('trainer', trainer)}
            placeholder='Search trainers...'
          />
        )}

        <Input
          type='number'
          label='Price'
          value={formData.price || 0}
          onChange={e => handleChange('price', e.target.value)}
        />

        <DropdownInput
          label='Status'
          options={statusTypes}
          value={formData.status}
          onChange={e => handleChange('status', e.target.value)}
        />
      </div>
    </Popup>
  );
};

export default EditRate;
