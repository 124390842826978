import BaseModel from './BaseModel';

/**
 * Represents a Document in the system.
 * @extends BaseModel
 */
class Document extends BaseModel {
  /** @type {string} The name of the database table for Documents */
  static table = 'documents';

  /** @type {string} The SQL query to select Document data with related information */
  static selectQuery = `
    *,
    user_profiles:owner!inner (
      first_name,
      last_name,
      full_name,
      profile_photo
    )
  `;

  /**
   * Creates a Document instance from database format data
   * @param {Object} data - The database format document data
   * @returns {Document} A new Document instance
   */
  static fromDatabase(data = {}) {
    const document = new Document();
    document.id = data.id;
    document.createdDate = data.created_date;
    document.updatedDate = data.updated_date;
    document.owner = data.user_profiles
      ? {
          id: data.owner,
          firstName: data.user_profiles.first_name,
          lastName: data.user_profiles.last_name,
          profilePhoto: data.user_profiles.profile_photo
        }
      : null;
    document.fileName = data.file_name;
    document.filePath = data.file_path;
    document.fileType = data.file_type;
    document.connections = data.connections || [];
    return document;
  }

  /**
   * Creates an instance of Document.
   * @param {Object} data - The document data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner ?? this.owner ?? null;
    this.fileName = data.fileName ?? this.fileName ?? null;
    this.filePath = data.filePath ?? this.filePath ?? null;
    this.fileType = data.fileType ?? this.fileType ?? null;
    this.connections = data.connections ?? this.connections ?? [];
  }

  /**
   * Converts the Document instance to a database-friendly format.
   * @returns {Object} The document data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner ? this.owner.id : null,
      file_name: this.fileName,
      file_path: this.filePath,
      file_type: this.fileType,
      connections: this.connections
    };
  }
}

export default Document;
