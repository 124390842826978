import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Course from '../../../../models/Course';
import { TextEditor, TextButton, FilledButton } from 'core';

const CourseDetails = () => {
  const [data, setData] = useState({});
  const [originalValue, setOriginalValue] = useState(null);
  const [editField, setEditField] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const course = await Course.getById(id);
        setData(course);
      } catch (error) {
        console.error('Error fetching course:', error);
      }
    };

    fetchCourse();
  }, [id]);

  const handleSave = async (field, value) => {
    try {
      const updatedData = { ...data, [field]: value };
      const course = new Course(updatedData);
      const updatedCourse = await course.update();
      setData(updatedCourse);
      setOriginalValue(null);
      setEditField(null);
    } catch (error) {
      console.error('Error updating course:', error);
    }
  };

  const renderField = (field, label, value) => {
    const isEditing = editField === field;

    return (
      <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
        <dt className='text-sm/6 font-medium text-gray-900'>{label}</dt>
        <dd className='mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0 flex justify-between items-start'>
          {isEditing ? (
            <div className='w-full'>
              <TextEditor
                value={value || ''}
                onChange={newValue => setData({ ...data, [field]: newValue })}
              />
              <div className='mt-2 flex justify-end gap-2'>
                <FilledButton
                  colour='base'
                  onClick={() => {
                    setEditField(null);
                    setData({ ...data, [field]: originalValue }); // Restore original value
                    setOriginalValue(null);
                  }}
                >
                  Cancel
                </FilledButton>
                <FilledButton
                  colour='primary'
                  onClick={() => handleSave(field, data[field])}
                >
                  Save
                </FilledButton>
              </div>
            </div>
          ) : (
            <>
              <div
                className='prose prose-sm prose-neutral max-w-none content'
                dangerouslySetInnerHTML={{ __html: value || '' }}
              />
              <TextButton
                onClick={() => {
                  setEditField(field);
                  setOriginalValue(data[field]); // Store original value when entering edit mode
                }}
              >
                Edit
              </TextButton>
            </>
          )}
        </dd>
      </div>
    );
  };

  return (
    <div>
      <div className='px-4 sm:px-0'>
        <h3 className='text-base/7 font-semibold text-gray-900'>
          Course Information
        </h3>
        <p className='mt-1 max-w-2xl text-sm/6 text-gray-500'>
          Course details and requirements.
        </p>
      </div>
      <div className='mt-6 border-t border-gray-100'>
        <dl className='divide-y divide-gray-100'>
          {renderField('attendance', 'Attendance', data.attendance)}
          {renderField('objectives', 'Learning Objectives', data.objectives)}
          {renderField('content', 'Course Content', data.content)}
          {renderField('preReqs', 'Pre-Requisites', data.preReqs)}
          {renderField('entryReqs', 'Entry Requirements', data.entryReqs)}
          {renderField('passReqs', 'Pass Requirements', data.passReqs)}
          {renderField('furtherDev', 'Further Development', data.furtherDev)}
        </dl>
      </div>
    </div>
  );
};

export default CourseDetails;
