import { useState, useEffect, useRef } from 'react';
import Logo from '../../assets/logo.png';
import {
  Settings,
  UserRound,
  Sun,
  Moon,
  Monitor,
  Menu,
  X,
  ArrowRightLeft,
  Bug,
  CircleHelp
} from 'lucide-react';
import { supabase } from '../../utilities/supabase';
import { useNavigate, useLocation, redirect, replace } from 'react-router-dom';
import { FlatButton, ToggleGroup } from 'core';
import { useContext } from 'react';
import { UserProfileContext } from '../../App';
import ProfileSwitcher from './ProfileSwitcher';
import UserProfile from '../../models/UserProfile';
import { Sentry } from '../../utilities/sentry';
import GlobalSearch from '../globalsearch/GlobalSearch';

const Header = ({ isDarkMode, setIsDarkMode, sidebarItems }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isProfileSwitcherOpen, setIsProfileSwitcherOpen] = useState(false);
  const [userProfiles, setUserProfiles] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef(null);
  const drawerRef = useRef(null);
  const { userProfile: currentUser, setUserProfile } =
    useContext(UserProfileContext) || {};

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.ctrlKey && e.key === 'k') {
        e.preventDefault();
        const searchInput = document.getElementById('globalsearch');
        if (searchInput) {
          searchInput.focus();
        }
      }
    };

    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        isDrawerOpen
      ) {
        setIsDrawerOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDrawerOpen]);

  useEffect(() => {
    const fetchUserProfiles = async () => {
      if (currentUser && currentUser.auth) {
        try {
          const { data: profiles } = await UserProfile.getAll({
            auth: { value: currentUser.auth }
          });
          setUserProfiles(profiles);
        } catch (error) {
          console.error('Error fetching user profiles:', error);
        }
      }
    };

    fetchUserProfiles();
  }, [currentUser]);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/login');
  };

  const setTheme = theme => {
    setIsDarkMode(theme === 'dark');
    const userPreferences =
      JSON.parse(localStorage.getItem('user_preferences')) || {};
    userPreferences.theme = theme;
    localStorage.setItem('user_preferences', JSON.stringify(userPreferences));
  };

  const handleProfileChange = async newProfile => {
    try {
      // Set the new profile as active
      newProfile.active = true;
      await newProfile.update();

      // Set all other profiles as inactive
      for (const profile of userProfiles) {
        if (profile.id !== newProfile.id) {
          profile.active = false;
          profile.metrics.last_seen = Date.now();
          await profile.update();
        }
      }

      // Update the current user in the context
      setUserProfile(newProfile);

      // Close the profile switcher
      setIsProfileSwitcherOpen(false);

      // Optionally, you might want to refresh the page or update the UI
      // to reflect the new active profile
      //window.location.reload();
    } catch (error) {
      console.error('Error changing profile:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  return (
    <>
      <nav
        className={`bg-brand-700 lg:bg-white max-h-[48px] w-full px-4 shadow-sm border-b border-base-100 z-20 dark:bg-base-800 dark:text-white transition-colors duration-200`}
      >
        <div className='flex justify-between items-center h-12'>
          <div className='flex-shrink-0 flex items-center'>
            <button
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              className='lg:hidden p-2 rounded-md text-white lg:text-base-400 hover:text-base-100 lg:hover:text-base-500 hover:bg-brand-600 lg:hover:bg-base-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500'
            >
              <Menu className='h-6 w-6' />
            </button>
          </div>
          <div className='flex items-center'>
            <div className='relative flex space-x-0'>
              <GlobalSearch />
              <FlatButton
                colour='primary'
                size='lg'
                className='lg:text-primary-600 text-white'
                onClick={() => navigate('/help')}
              >
                <CircleHelp size={20} />
              </FlatButton>
              {/*User profile menu */}
              <div className='relative' ref={menuRef}>
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className='flex items-center bg-brand-700 lg:bg-white dark:bg-base-700 px-2 py-1 rounded-lg text-white lg:text-base-400 hover:text-base-100 lg:hover:text-base-500 dark:text-base-300 dark:hover:text-base-200 focus:outline-none'
                >
                  {currentUser && currentUser.photo ? (
                    <img
                      src={currentUser.photo}
                      alt='User'
                      className='h-8 w-8 rounded-full object-cover border dark:border-base-600 hover:border-base-200'
                    />
                  ) : (
                    <UserRound className='h-8 w-8 rounded-full text-white bg-brand-500 lg:bg-base-200 hover:bg-base-300 hover:border-base-200 dark:bg-base-600 p-1' />
                  )}
                </button>
                {isMenuOpen && (
                  <div className='origin-top-right  absolute right-0 w-52 rounded-md shadow-lg bg-white dark:bg-base-700 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <div className='px-4 py-3 border-b border-base-200 dark:border-base-600'>
                      <div className='flex justify-between items-start'>
                        <div>
                          <p className='text-sm font-medium text-base-900 dark:text-base-100'>{`${currentUser.firstName} ${currentUser.lastName}`}</p>
                          <p className='text-xs text-base-500 dark:text-base-400 truncate'>
                            {currentUser.email}
                          </p>
                        </div>
                        <button
                          className='p-1 text-base-500 hover:text-base-700 dark:text-base-400 dark:hover:text-base-200 focus:outline-none'
                          onClick={() => navigate('/settings')}
                        >
                          <Settings size={18} />
                        </button>
                      </div>
                      <div className='pt-3 pb-1'>
                        <ToggleGroup
                          options={[
                            { value: 'light', icon: Sun },
                            { value: 'dark', icon: Moon },
                            { value: 'system', icon: Monitor }
                          ]}
                          defaultOption={
                            JSON.parse(localStorage.getItem('user_preferences'))
                              ?.theme || 'system'
                          }
                          onChange={value => {
                            setTheme(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className='py-1'>
                      <button
                        onClick={() => navigate('/account')}
                        className='flex items-center px-4 py-2 text-sm text-base-700 dark:text-base-200 hover:bg-base-100 dark:hover:bg-base-600 w-full text-left'
                      >
                        <div className='flex items-center justify-between w-full'>
                          <span>Account settings</span>
                        </div>
                      </button>
                      <button
                        onClick={() => {
                          setIsProfileSwitcherOpen(true);
                          setIsMenuOpen(false);
                        }}
                        className='flex items-center px-4 py-2 text-sm text-base-700 dark:text-base-200 hover:bg-base-100 dark:hover:bg-base-600 w-full text-left'
                      >
                        <div className='flex items-center justify-between w-full'>
                          <span>Switch profile</span>
                          <ArrowRightLeft size={16} />
                        </div>
                      </button>
                      <button
                        id='sentry-feedback-action'
                        className='flex items-center px-4 py-2 text-sm text-base-700 dark:text-base-200 hover:bg-base-100 dark:hover:bg-base-600 w-full text-left'
                        onClick={async () => {
                          const feedback = Sentry.getFeedback();
                          const form = await feedback.createForm();
                          form.appendToDom();
                          form.open();
                        }}
                      >
                        <div className='flex items-center justify-between w-full'>
                          <span>Report a bug</span>
                          <Bug size={16} />
                        </div>
                      </button>
                      <button
                        onClick={() => {
                          handleLogout();
                          setIsMenuOpen(false);
                        }}
                        className='flex items-center px-4 py-2 text-sm text-base-700 dark:text-base-200 hover:bg-base-100 dark:hover:bg-base-600 w-full text-left'
                      >
                        Log out
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* Mobile Drawer */}
      <div
        ref={drawerRef}
        className={`fixed inset-y-0 left-0 z-20 w-64 bg-white dark:bg-base-800 shadow-lg transform ${
          isDrawerOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out lg:hidden`}
      >
        <div className='flex items-center justify-between p-4 border-b dark:border-base-700'>
          <img className='h-8 w-auto' src={Logo} alt='Logo' />
          <button
            onClick={() => setIsDrawerOpen(false)}
            className='text-base-500 hover:text-base-700 dark:text-base-400 dark:hover:text-base-200'
          >
            <X className='h-6 w-6' />
          </button>
        </div>
        <nav className='mt-5'>
          {sidebarItems.map(item => (
            <button
              key={item.name}
              onClick={() => {
                navigate(item.path);
                setIsDrawerOpen(false);
              }}
              className={`flex items-center w-full px-4 py-2 text-sm ${
                location.pathname === item.path
                  ? 'bg-brand-100 text-brand-700 dark:bg-brand-900 dark:text-brand-200'
                  : 'text-base-700 dark:text-base-200 hover:bg-base-100 dark:hover:bg-base-700'
              }`}
            >
              <item.icon
                className={`mr-3 h-5 w-5 ${
                  location.pathname === item.path
                    ? 'text-brand-600 dark:text-brand-300'
                    : 'text-base-400 dark:text-base-300'
                }`}
              />
              {item.name}
            </button>
          ))}
        </nav>
      </div>
      {isProfileSwitcherOpen && (
        <ProfileSwitcher
          profiles={userProfiles}
          currentProfile={currentUser}
          onProfileChange={handleProfileChange}
          onLogout={handleLogout}
          isOpen={isProfileSwitcherOpen}
          onClose={() => setIsProfileSwitcherOpen(false)}
        />
      )}
    </>
  );
};

export default Header;
