import React, { useState, useEffect } from 'react';
import { Popup } from 'core';
import { FilledButton, Input, Badge } from 'core';
import Compliance from '../../../models/Compliance';
import { Download } from 'lucide-react';
import { formatDate } from '../../../utilities/Formatting';

const PreviewCompliance = ({ isOpen, onClose, complianceId }) => {
  const [compliance, setCompliance] = useState(null);
  const STORAGE_BASE_URL = 'https://storage.googleapis.com/bohsa-docs/';

  useEffect(() => {
    const fetchData = async () => {
      if (complianceId) {
        const complianceData = await Compliance.getById(complianceId);
        setCompliance(complianceData);
      }
    };

    fetchData();
  }, [complianceId]);

  if (!compliance) return null;

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title='View Compliance'
      size='lg'
      footer={
        <div className='flex justify-end'>
          <FilledButton onClick={onClose} colour='primary'>
            Close
          </FilledButton>
        </div>
      }
    >
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4'>
        <div className='space-y-4 lg:space-y-6'>
          <div className='bg-white border rounded-lg p-4 lg:p-6'>
            <h3 className='text-base lg:text-lg font-semibold text-gray-900 mb-3 lg:mb-4'>
              Document Details
            </h3>
            <div className='space-y-2 lg:space-y-3'>
              <div className='flex flex-col lg:flex-row lg:items-center'>
                <span className='text-sm font-medium text-gray-500 lg:w-24 mb-1 lg:mb-0'>
                  Name
                </span>
                <span className='text-sm text-gray-900'>
                  {compliance.document?.name}
                </span>
              </div>
              <div className='flex flex-col lg:flex-row lg:items-center'>
                <span className='text-sm font-medium text-gray-500 lg:w-24 mb-1 lg:mb-0'>
                  Created Date
                </span>
                <span className='text-sm text-gray-900'>
                  {formatDate(compliance.document?.createdDate)}
                </span>
              </div>
              <div className='flex justify-end'>
                <FilledButton
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = STORAGE_BASE_URL + compliance.document?.path;
                    link.download = compliance.document?.name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                  colour='base'
                  size='md'
                >
                  <Download />
                </FilledButton>
              </div>
            </div>
          </div>

          <div className='bg-white border rounded-lg p-4 lg:p-6'>
            <h3 className='text-base lg:text-lg font-semibold text-gray-900 mb-3 lg:mb-4'>
              Compliance Details
            </h3>
            <div className='space-y-2 lg:space-y-3'>
              <div className='flex flex-col lg:flex-row lg:items-center'>
                <span className='text-sm font-medium text-gray-500 lg:w-24 mb-1 lg:mb-0'>
                  Status
                </span>
                <Badge colour={compliance.status?.colour} size='sm'>
                  {compliance.status?.type}
                </Badge>
              </div>
              <div className='flex flex-col lg:flex-row lg:items-center'>
                <span className='text-sm font-medium text-gray-500 lg:w-24 mb-1 lg:mb-0'>
                  Accreditation
                </span>
                <span className='text-sm text-gray-900'>
                  {compliance.accreditation?.type}
                </span>
              </div>
              <div className='flex flex-col lg:flex-row lg:items-center'>
                <span className='text-sm font-medium text-gray-500 lg:w-24 mb-1 lg:mb-0'>
                  Created Date
                </span>
                <span className='text-sm text-gray-900'>
                  {formatDate(compliance.createdDate)}
                </span>
              </div>
              <div className='flex flex-col lg:flex-row lg:items-center'>
                <span className='text-sm font-medium text-gray-500 lg:w-24 mb-1 lg:mb-0'>
                  Expiry Date
                </span>
                <span className='text-sm text-gray-900'>
                  {formatDate(compliance.expiryDate)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='bg-white border rounded-lg p-4 lg:p-6'>
          <h3 className='text-base lg:text-lg font-semibold text-gray-900 mb-3 lg:mb-4'>
            Document Preview
          </h3>
          <div className='h-[300px] lg:h-[600px] bg-gray-50 rounded-lg flex items-center justify-center border border-gray-200'>
            {compliance.document?.path ? (
              <iframe
                src={STORAGE_BASE_URL + compliance.document.path}
                className='w-full h-full rounded-lg scroller-brand'
                title='Document Preview'
              />
            ) : (
              <span className='text-sm text-gray-500'>
                Document preview not available
              </span>
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default PreviewCompliance;
