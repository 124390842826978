import React from 'react';

const StackedList = ({ items, style = 'divided' }) => {
  return (
    <ul
      role='list'
      className={style === 'divided' ? 'divide-y divide-base-200' : ''}
    >
      {items.map((item, index) => (
        <li
          key={index}
          className='flex items-center justify-between gap-x-6 py-2'
        >
          <div className='flex min-w-0 gap-x-4'>
            {item.icon &&
              React.createElement(item.icon, {
                className: 'h-12 w-12 text-base-400'
              })}
            {item.image && (
              <img
                src={item.image}
                alt={item.title}
                className='h-12 w-12 flex-none rounded-full bg-base-50'
              />
            )}
            <div className='flex-auto min-w-0'>
              <div className='flex items-center'>
                <span className='text-sm font-semibold leading-6 text-base-900'>
                  {item.title}
                </span>
                {item.titleBadge && (
                  <span className='ml-2'>{item.titleBadge}</span>
                )}
              </div>
              {item.description && (
                <span className='mt-1 truncate text-xs leading-5 text-base-500'>
                  {item.description}
                </span>
              )}
            </div>
          </div>
          <div className='flex'>{item.action ? item.action : null}</div>
        </li>
      ))}
    </ul>
  );
};

export { StackedList };
