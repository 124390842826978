import React, { useState, useEffect, useContext } from 'react';
import { Filter, Plus } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilledButton, OutlineButton, SoftButton, TextButton } from 'core';
import { DropdownInput } from '../inputs/Forms';
import Pagination from '../navigation/Pagination';
import Table from './Table';
import Filters from '../filters/FiltersMenu';
import ActiveFilters from '../filters/ActiveFilters';
import { realtime } from '../../utilities/supabase';
import { UserProfileContext } from '../../App';
import { PERMISSIONS } from '../../utilities/Permissions';

const EntityTable = ({
  name,
  filter,
  columns,
  model,
  entityType,
  fromFunction = false,
  menuItems,
  onNewClick,
  defaultFilters = {},
  flags,
  onRowClick,
  canCreate,
  sortDefault = { field: 'id', order: 'asc' }
}) => {
  const [loading, setLoading] = useState(true);
  const [entities, setEntities] = useState([]);
  const [filters, setFilters] = useState({});
  const [sortBy, setSortBy] = useState(sortDefault.field);
  const [sortOrder, setSortOrder] = useState(sortDefault.order);
  const [isError, setIsError] = useState(null);
  const [totalEntities, setTotalEntities] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const location = useLocation();
  const navigate = useNavigate();
  const { userProfile: currentUser } = useContext(UserProfileContext);

  const fetchData = async (
    currentFilters,
    page = 1,
    currentSortBy = sortBy,
    currentSortOrder = sortOrder,
    loading = true,
    perPage = itemsPerPage
  ) => {
    try {
      if (loading) setLoading(true);

      const searchParams = new URLSearchParams(location.search);
      const showArchived = searchParams.get('showArchived') === 'is.true';

      // Check if user has permission to view deleted items
      if (
        showArchived &&
        PERMISSIONS[entityType]?.VIEW_DELETED &&
        !currentUser.hasPermission(
          entityType,
          PERMISSIONS[entityType].VIEW_DELETED
        )
      ) {
        // Force a navigate to remove the archive url param
        searchParams.delete('showArchived');
        navigate(`${location.pathname}?${searchParams.toString()}`);
      }

      // Create a copy of defaultFilters, removing status if showArchived is true
      const defaultFiltersCopy = { ...defaultFilters };
      if (showArchived && 'status' in defaultFiltersCopy) {
        delete defaultFiltersCopy.status;
      }

      const filterParams = {
        ...defaultFiltersCopy,
        ...Object.fromEntries(
          Object.entries(currentFilters).filter(
            ([key]) => key !== 'showArchived'
          )
        )
      };

      // Add lat/lng to filterParams if distance filter exists
      if (currentFilters.distance?.metadata) {
        filterParams.lat = currentFilters.distance.metadata.lat;
        filterParams.lng = currentFilters.distance.metadata.lng;
      }

      if (fromFunction) {
        const { data, count, total } = await model.getAllByFunction(
          filterParams,
          page,
          perPage,
          currentSortBy,
          currentSortOrder
        );
        setEntities(data);
        setTotalEntities(count);
        setCurrentPage(page);
        setTotalPages(total);
      } else {
        const { data, count, total } = await model.getAll(
          filterParams,
          page,
          perPage,
          currentSortBy,
          currentSortOrder
        );
        setEntities(data);
        setTotalEntities(count);
        setCurrentPage(page);
        setTotalPages(total);
      }
      setIsError(false);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      if (loading) setLoading(false);
    }
  };

  useEffect(() => {
    const channel = realtime('*', model.table, () => {
      // Pass current filters, page, sort settings when reloading data
      fetchData(filters, currentPage, sortBy, sortOrder, false);
    });

    return () => channel.unsubscribe();
  }, [filters, currentPage, sortBy, sortOrder]); // Add dependencies to re-subscribe when these change

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const initialFilters = {};

    filter.forEach(filter => {
      const urlValue = searchParams.get(filter.field);
      if (urlValue) {
        if (filter.field === 'distance') {
          const [operator, value] = urlValue.split('|');
          const lat = searchParams.get('lat');
          const lng = searchParams.get('lng');
          initialFilters[filter.field] = {
            value: value,
            label: filter.label,
            operator: operator,
            metadata: {
              lat: parseFloat(lat) ?? 53.00630079443634,
              lng: parseFloat(lng) ?? -2.1651132351130746
            }
          };
        } else {
          const [operator, value] = urlValue.split('|');
          initialFilters[filter.field] = {
            value: value || urlValue,
            label: filter.label,
            operator: operator || 'eq'
          };
        }
      }
    });

    setFilters(initialFilters);

    const initialSortBy = searchParams.get('sortBy') || sortDefault.field;
    const initialSortOrder = searchParams.get('sortOrder') || sortDefault.order;
    setSortBy(initialSortBy);
    setSortOrder(initialSortOrder);

    fetchData(initialFilters, 1, initialSortBy, initialSortOrder);
  }, [location.search]);

  const applyFilters = newFilters => {
    setFilters(newFilters);
    setCurrentPage(1);
    updateUrlParams(newFilters, sortBy, sortOrder);
    fetchData(newFilters, 1, sortBy, sortOrder);
  };

  const removeFilter = key => {
    const newFilters = { ...filters };
    delete newFilters[key];

    setFilters(newFilters);
    updateUrlParams(newFilters, sortBy, sortOrder);
    fetchData(newFilters, 1, sortBy, sortOrder);
  };

  const removeAllFilters = () => {
    setFilters({});
    updateUrlParams({}, sortBy, sortOrder);
    fetchData({}, 1, sortBy, sortOrder);
  };

  const updateUrlParams = (newFilters, newSortBy, newSortOrder) => {
    const searchParams = new URLSearchParams();

    if (newSortBy) {
      searchParams.set('sortBy', newSortBy);
    }

    if (newSortOrder && newSortOrder !== 'asc') {
      searchParams.set('sortOrder', newSortOrder);
    }

    // Add filters to URL params
    Object.entries(newFilters).forEach(([key, filter]) => {
      if (key === 'distance' && filter.metadata) {
        searchParams.set(key, `${filter.operator}|${filter.value}`);
        searchParams.set('lat', filter.metadata.lat);
        searchParams.set('lng', filter.metadata.lng);
      } else if (filter.operator && filter.value) {
        searchParams.set(key, `${filter.operator}|${filter.value}`);
      }
    });

    const queryString = searchParams.toString();
    navigate(
      queryString ? `${location.pathname}?${queryString}` : location.pathname,
      { replace: true }
    );
  };

  const handleSort = field => {
    const column = columns.find(col => col.field === field);
    const sortField = column.foreignKey
      ? `${column.foreignKey.table}.${column.foreignKey.column}`
      : field;
    const newSortOrder =
      sortField === sortBy && sortOrder === 'asc' ? 'desc' : 'asc';

    setSortBy(sortField);
    setSortOrder(newSortOrder);
    updateUrlParams(filters, sortField, newSortOrder);
    fetchData(filters, currentPage, sortField, newSortOrder);
  };

  return (
    <>
      <div className='flex flex-col mb-3 w-full'>
        <div className='flex justify-between items-center'>
          <div className='flex space-x-4 items-center'>
            {canCreate && (
              <FilledButton
                colour='primary'
                leftIcon={<Plus size={18} />}
                onClick={onNewClick}
              >
                New
              </FilledButton>
            )}
            <SoftButton
              colour='primary'
              leftIcon={<Filter size={18} />}
              onClick={() => setIsFiltersOpen(!isFiltersOpen)}
            >
              Filter
            </SoftButton>
          </div>
          <div className='ml-auto'>
            <p className='text-neutral-600 text-sm px-2'>
              Total {name}: <span className='font-medium'>{totalEntities}</span>
            </p>
          </div>
        </div>
        <ActiveFilters
          filters={filters}
          removeFilter={removeFilter}
          removeAllFilters={removeAllFilters}
        />
      </div>
      <div className=''>
        <Table
          columns={columns.map(column => ({
            ...column,
            sortable: column.sortable ?? true,
            sortField: column.foreignKey
              ? `${column.foreignKey.table}.${column.foreignKey.column}`
              : column.field
          }))}
          data={entities}
          isLoading={loading}
          isError={isError}
          menuItems={menuItems}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={handleSort}
          onRowClick={onRowClick}
        />
      </div>
      <div className='mt-6'>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={newPage => {
            setCurrentPage(newPage);
            fetchData(filters, newPage, sortBy, sortOrder);
          }}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={newPerPage => {
            setItemsPerPage(newPerPage);
            fetchData(filters, 1, sortBy, sortOrder, true, newPerPage);
          }}
        />
      </div>
      <Filters
        isOpen={isFiltersOpen}
        onClose={() => setIsFiltersOpen(!isFiltersOpen)}
        onApply={applyFilters}
        filter={filter}
        filters={filters}
        setFilters={setFilters}
        entityType={entityType}
      />
    </>
  );
};

export default EntityTable;
