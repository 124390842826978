import React, { useState, useEffect } from 'react';
import { TextEditor, Input, MultiSelectBox, DropdownInput } from 'core';
import CoreEntityTypeModel from '../../../models/CoreEntityType';
import UserProfile from '../../../models/UserProfile';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';

const Details = ({ editedTask, setEditedTask }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const priorityOptions = [
    { value: 1, label: 'Low' },
    { value: 2, label: 'Medium' },
    { value: 3, label: 'High' },
    { value: 4, label: 'Urgent' }
  ];

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [categoryData, statusData, assigneeData] = await Promise.all([
          CoreEntityTypeModel.getAll({
            entity_id: { value: EntityTypeEnum.TaskCategory }
          }),
          CoreEntityTypeModel.getAll({
            entity_id: { value: EntityTypeEnum.TaskStatus }
          }),
          UserProfile.getAll({
            id: { operator: FilterOperatorEnum.IN, value: editedTask.assignees }
          })
        ]);

        setCategoryOptions(
          categoryData.data.map(option => ({
            value: option.id,
            label: option.type,
            type: option.type,
            colour: option.metadata?.colour
          }))
        );

        setStatusOptions(
          statusData.data.map(option => ({
            value: option.id,
            label: option.type,
            type: option.type,
            colour: option.metadata?.colour
          }))
        );

        setAssignees(
          assigneeData.data.map(assignee => ({
            value: assignee.id,
            label: `${assignee.firstName} ${assignee.lastName} [${assignee.email}]`
          }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const handleChange = e => {
    let { name, value } = e.target;
    if (value.target) value = value.target.value;
    setEditedTask(prevTask => ({
      ...prevTask,
      [name]: value
    }));
  };

  const handleDescriptionUpdate = content => {
    setEditedTask(prevTask => ({
      ...prevTask,
      description: content
    }));
  };

  const handleCategoryChange = e => {
    const { name, value } = e.target;
    const selectedCategory = categoryOptions.find(
      option => option.value == value
    );
    setEditedTask(prevTask => ({
      ...prevTask,
      category: {
        id: selectedCategory.value,
        name: selectedCategory.type,
        colour: selectedCategory.colour
      }
    }));
  };

  const handleStatusChange = e => {
    const { name, value } = e.target;
    const selectedStatus = statusOptions.find(option => option.value == value);
    setEditedTask(prevTask => ({
      ...prevTask,
      status: {
        id: selectedStatus.value,
        name: selectedStatus.type,
        colour: selectedStatus.colour
      }
    }));
  };

  const handleAssignedChange = selectedOptions => {
    setEditedTask(prevTask => ({
      ...prevTask,
      assignees: selectedOptions
    }));
  };

  const loadUserOptions = async query => {
    try {
      const { data } = await UserProfile.getAll({
        fts: { value: query, operator: FilterOperatorEnum.TEXTSEARCH }
      });
      return data.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName} [${user.email}]`
      }));
    } catch (error) {
      console.error('Error loading user options:', error);
      return [];
    }
  };

  return (
    <div className='space-y-4 w-full p-0.5'>
      <div className='flex items-center w-full'>
        <Input
          name='title'
          value={editedTask.title}
          onChange={handleChange}
          placeholder='Task Title'
          className='flex-grow'
          label='Title'
        />
      </div>
      <div className='flex items-start w-full'>
        <TextEditor
          value={editedTask.description}
          onChange={handleDescriptionUpdate}
          placeholder='Task Description'
          className='flex-grow'
          label='Description'
          type='standard'
        />
      </div>
      <div className='flex items-center w-full'>
        <DropdownInput
          options={categoryOptions}
          value={editedTask.category ? editedTask.category.id : ''}
          onChange={handleCategoryChange}
          placeholder='Select category'
          size='md'
          className='flex-grow'
          label='Category'
        />
      </div>
      <div className='flex items-center w-full'>
        <DropdownInput
          options={statusOptions}
          value={editedTask.status ? editedTask.status.id : ''}
          onChange={handleStatusChange}
          placeholder='Select status'
          size='md'
          className='flex-grow'
          label='Status'
        />
      </div>
      <div className='flex items-center w-full'>
        <DropdownInput
          options={priorityOptions}
          value={editedTask.priority || ''}
          onChange={value =>
            handleChange({
              target: { name: 'priority', value: value }
            })
          }
          placeholder='Select priority'
          size='md'
          className='flex-grow'
          label='Priority'
        />
      </div>
      <div className='flex items-center w-full'>
        <Input
          type='date'
          name='dueDate'
          value={editedTask.dueDate ? editedTask.dueDate.split('T')[0] : ''}
          onChange={handleChange}
          className='flex-grow'
          label='Due Date'
        />
      </div>
      <div className='flex items-center w-full mb-2'>
        <MultiSelectBox
          selectedOptions={assignees ?? editedTask.assignees}
          onChange={handleAssignedChange}
          placeholder='Search for users...'
          allowCreate={false}
          loadOptions={loadUserOptions}
          className='flex-grow'
          label='Assignees'
        />
      </div>
    </div>
  );
};

export default Details;
