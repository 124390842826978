import React, { useState, useEffect } from 'react';
import { Popup } from 'core';
import { Input, FilledButton, Checkbox } from 'core';
import { supabase } from '../../../../utilities/supabase';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';
import { PERMISSIONS } from '../../../../utilities/Permissions';

const AddRole = ({ isOpen, onClose, onSuccess }) => {
  const [label, setLabel] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [permissions, setPermissions] = useState({});

  // Initialize permissions state for each entity type
  useEffect(() => {
    const initialPermissions = {};
    Object.values(EntityTypeEnum).forEach(entity => {
      initialPermissions[entity] = 0;
    });
    setPermissions(initialPermissions);
  }, []);

  // Reset form when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setLabel('');
      setError(null);
      const initialPermissions = {};
      Object.values(EntityTypeEnum).forEach(entity => {
        initialPermissions[entity] = 0;
      });
      setPermissions(initialPermissions);
    }
  }, [isOpen]);

  const handlePermissionChange = (entity, permission) => {
    setPermissions(prev => ({
      ...prev,
      [entity]: prev[entity] ^ permission // Toggle the bit using XOR
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);

      // Validate inputs
      if (!label.trim()) {
        throw new Error('Role name is required');
      }

      // Insert new role
      const { error: insertError } = await supabase
        .from('permission_groups')
        .insert([
          {
            label: label.trim(),
            permissions
          }
        ]);

      if (insertError) throw insertError;

      onSuccess?.();
      onClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title='Add New Role'
      size='lg'
      footer={
        <div className='flex justify-end gap-3'>
          <FilledButton onClick={onClose} colour='base' disabled={loading}>
            Cancel
          </FilledButton>
          <FilledButton onClick={handleSubmit} loading={loading}>
            Create Role
          </FilledButton>
        </div>
      }
    >
      <div className='space-y-6'>
        {error && <div className='text-sm text-red-600'>{error}</div>}

        <div>
          <Input
            label='Role Name'
            value={label}
            onChange={e => setLabel(e.target.value)}
            placeholder='Enter role name'
            required
          />
        </div>

        <div>
          <h3 className='text-sm font-medium text-gray-900 mb-4'>
            Permissions
          </h3>
          <div className='grid grid-cols-2 gap-6'>
            {Object.entries(EntityTypeEnum).map(
              ([key, entity]) =>
                PERMISSIONS[entity] && (
                  <div key={entity} className='space-y-3'>
                    <h4 className='text-sm font-medium text-gray-700'>{key}</h4>
                    <div className='space-y-2'>
                      {Object.entries(PERMISSIONS[entity]).map(
                        ([name, value]) => (
                          <Checkbox
                            key={name}
                            label={name.replace(/_/g, ' ')}
                            checked={Boolean(permissions[entity] & value)}
                            onChange={() =>
                              handlePermissionChange(entity, value)
                            }
                          />
                        )
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default AddRole;
