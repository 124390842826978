import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Badge, Modal } from 'core';
import { supabase } from '../../../utilities/supabase';

const PipelinesTab = ({ pipelineData, onUpdate }) => {
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState(null);

  const handlePipelineClick = pipelineId => {
    navigate(`/pipelines/${pipelineId}`);
  };

  const handleRemoveFromPipeline = async (e, pipelineId) => {
    e.stopPropagation();
    setSelectedPipeline(pipelineId);
    setShowConfirmModal(true);
  };

  const confirmRemove = async () => {
    try {
      await supabase.from('pipeline_entities').delete().eq('id', selectedPipeline);
      onUpdate();
      setShowConfirmModal(false);
    } catch (error) {
      console.error('Error removing entity from pipeline:', error);
    }
  };

  return (
    <div className='space-y-2'>
      {pipelineData.map(item => {
        const currentGroup = item.pipelines.groups.find(
          group => group.id === item.group
        );
        return (
          <div
            key={item.id}
            className='flex items-center justify-between py-1 px-2 rounded-md bg-base-100 hover:bg-danger-100 transition-colors duration-200 cursor-pointer group'
            onClick={() => handlePipelineClick(item.pipeline_id)}
          >
            <div className='flex items-center space-x-2'>
              <span className='font-small text-sm text-base-900'>
                {item.pipelines.title}
              </span>
              {currentGroup && (
                <Badge size='sm' colour={currentGroup.colour}>
                  {currentGroup.label}
                </Badge>
              )}
            </div>
            <button
              className='text-base-600 hover:text-danger-600 transition-colors duration-200 opacity-0 group-hover:opacity-100'
              aria-label='Remove from pipeline'
              onClick={e => handleRemoveFromPipeline(e, item.id)}
            >
              <X size={16} className='text-danger-600' />
            </button>
          </div>
        );
      })}

      <Modal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title="Remove from Pipeline"
        style="danger"
        actions={[
          {
            label: 'Cancel',
            onClick: () => setShowConfirmModal(false),
            style: 'base'
          },
          {
            label: 'Remove',
            onClick: confirmRemove,
            style: 'danger'
          }
        ]}
      >
        <p className="text-sm text-base-600">
          Are you sure you want to remove this item from the pipeline? This action cannot be undone.
        </p>
      </Modal>
    </div>
  );
};

export default PipelinesTab;
