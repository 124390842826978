import BaseModel from './BaseModel';

/**
 * Represents an AutomationRun in the system.
 * @extends BaseModel
 */
class AutomationRun extends BaseModel {
  /** @type {string} The name of the database table for AutomationRuns */
  static table = 'automation_runs';

  /** @type {string} The SQL query to select AutomationRun data with related information */
  static selectQuery = `
    *,
    automation:automations (id)
  `;

  /**
   * Creates an AutomationRun instance from database format data
   * @param {Object} data - The database format automation run data
   * @returns {AutomationRun} A new AutomationRun instance
   */
  static fromDatabase(data = {}) {
    const automationRun = new AutomationRun();
    automationRun.id = data.id;
    automationRun.createdDate = data.created_date;
    automationRun.updatedDate = data.updated_date;
    automationRun.automation = data.automation || null;
    automationRun.entityType = data.entity_type;
    automationRun.entityId = data.entity_id;
    automationRun.responses = data.responses;
    automationRun.exitResponses = data.exit_responses || [];
    automationRun.status = data.status || 'pending';
    return automationRun;
  }

  /**
   * Creates an instance of AutomationRun.
   * @param {Object} data - The automation run data.
   */
  constructor(data = {}) {
    super(data);
    this.automation = data.automation ?? this.automation ?? null;
    this.entityType = data.entityType ?? this.entityType ?? null;
    this.entityId = data.entityId ?? this.entityId ?? null;
    this.createdDate = data.createdDate ?? this.createdDate ?? null;
    this.updatedDate = data.updatedDate ?? this.updatedDate ?? null;
    this.responses = data.responses ?? this.responses ?? null;
    this.exitResponses = data.exitResponses ?? this.exitResponses ?? [];
    this.status = data.status ?? this.status ?? 'pending';
  }

  /**
   * Converts the AutomationRun instance to a database-friendly format.
   * @returns {Object} The automation run data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      automation: this.automation,
      entity_type: this.entityType,
      entity_id: this.entityId,
      created_date: this.createdDate,
      updated_date: this.updatedDate,
      responses: this.responses,
      exit_responses: this.exitResponses,
      status: this.status
    };
  }
}

export default AutomationRun;
