import React, { useState, useEffect, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { GripVertical, Plus, X } from 'lucide-react';
import {
  FilledButton,
  OutlineButton,
  TextButton,
  Popup,
  Input,
  ColourPicker,
  Checkbox,
  DropdownInput
} from 'core';
import CoreEntity from '../../../models/CoreEntity';
import { UserProfileContext } from '../../../App';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const CreateBasicPipeline = ({ onClose, onSave }) => {
  const [title, setTitle] = useState('');
  const [columns, setColumns] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [linkedEntity, setLinkedEntity] = useState('');
  const [coreEntities, setCoreEntities] = useState([]);

  const userProfile = useContext(UserProfileContext).userProfile;

  useEffect(() => {
    const fetchCoreEntities = async () => {
      try {
        const { data: entities } = await CoreEntity.getAll(
          { 'metadata->pipelines->>enabled': { value: true } },
          1,
          100
        );
        setCoreEntities(entities);
      } catch (error) {
        console.error('Error fetching core entities:', error.message);
      }
    };

    fetchCoreEntities();
  }, []);

  const handleDragEnd = result => {
    if (!result.destination) return;

    const items = Array.from(columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedColumns = items.map((item, index) => ({
      ...item,
      order: index
    }));

    setColumns(updatedColumns);
  };

  const handleColumnChange = (index, field, value) => {
    const updatedColumns = [...columns];
    updatedColumns[index] = { ...updatedColumns[index], [field]: value };
    setColumns(updatedColumns);
  };

  const handleAddColumn = () => {
    const newColumnId = Math.max(...columns.map(col => col.id || 0), 0) + 1;
    const newColumn = {
      id: newColumnId,
      label: 'New Column',
      colour: 'gray',
      order: columns.length
    };
    setColumns([...columns, newColumn]);
  };

  const handleRemoveColumn = index => {
    const updatedColumns = columns
      .filter((_, i) => i !== index)
      .map((col, i) => ({ ...col, order: i }));
    setColumns(updatedColumns);
  };

  const getDefaultColumns = entityId => {
    const entity = coreEntities.find(e => e.id === Number(entityId));
    return entity?.metadata?.pipelines.default_columns || 'id,name';
  };

  const getDefaultConfig = entityId => {
    const entity = coreEntities.find(e => e.id === Number(entityId));
    return (
      entity?.metadata?.pipelines.default_config || {
        id: { icon: 'Hash', inline: true, labelStyle: 'iconOnly', order: 1 },
        name: {
          icon: 'Building',
          inline: false,
          labelStyle: 'iconOnly',
          order: 2
        },
        pipelines: {
          icon: 'GitBranch',
          inline: false,
          labelStyle: 'hidden',
          order: 3
        }
      }
    );
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSave({
      owner: userProfile.id,
      title,
      groups: columns,
      private: isPrivate,
      entity: linkedEntity,
      columns: getDefaultColumns(linkedEntity),
      config: getDefaultConfig(linkedEntity)
    });
    onClose();
  };

  const handleColourChange = (index, colour) => {
    const baseColour = colour.split('-')[0];
    handleColumnChange(index, 'colour', baseColour);
  };

  const renderContent = () => (
    <form onSubmit={handleSubmit}>
      <div className='flex'>
        <div className='w-1/2 pr-4'>
          <Input
            label='Pipeline Title'
            value={title}
            onChange={e => setTitle(e.target.value)}
            className='mb-4'
          />

          <DropdownInput
            label='Linked Entity'
            value={linkedEntity}
            onChange={e => setLinkedEntity(e.target.value)}
            className='mb-4'
            options={coreEntities.map(entity => ({
              value: entity.id,
              label: entity.displayName
            }))}
            placeholder='Select an entity'
          />

          <h3 className='text-lg font-semibold mb-2'>Pipeline Columns</h3>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='columns'>
              {provided => (
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className='space-y-2'
                >
                  {columns.map((column, index) => (
                    <Draggable
                      key={column.id}
                      draggableId={column.id.toString()}
                      index={index}
                    >
                      {provided => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className='flex items-center space-x-2 bg-gray-100 p-2 rounded'
                        >
                          <div {...provided.dragHandleProps}>
                            <GripVertical size={20} />
                          </div>
                          <Input
                            value={column.label}
                            onChange={e =>
                              handleColumnChange(index, 'label', e.target.value)
                            }
                            className='flex-grow'
                          />
                          <ColourPicker
                            showShades={false}
                            colour={column.colour}
                            onChange={colour =>
                              handleColourChange(index, colour)
                            }
                          />
                          <TextButton onClick={() => handleRemoveColumn(index)}>
                            <X size={20} />
                          </TextButton>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
          <TextButton onClick={handleAddColumn} className='mt-2'>
            <Plus size={20} /> Add Column
          </TextButton>
        </div>
        <div className='w-px border-r border-gray-200 my-4'></div>
        <div className='w-1/2 pl-4'>
          <Checkbox
            label='Private Pipeline'
            checked={isPrivate}
            onChange={e => setIsPrivate(e.target.checked)}
          />
        </div>
      </div>
    </form>
  );

  const renderFooter = () => (
    <div className='flex justify-end space-x-2'>
      <FilledButton colour='base' onClick={onClose}>
        Cancel
      </FilledButton>
      <FilledButton onClick={handleSubmit}>Create Pipeline</FilledButton>
    </div>
  );

  return (
    <Popup
      isOpen={true}
      onClose={onClose}
      title='Create Basic Pipeline'
      size='md'
      footer={renderFooter()}
    >
      {renderContent()}
    </Popup>
  );
};

export default CreateBasicPipeline;
