import { ChevronRight, Home } from 'lucide-react';
import { NavLink } from 'react-router-dom';

const Breadcrumbs = ({ pages = [], homeHref }) => {
  return (
    <nav aria-label='Breadcrumb' className='flex'>
      <ol
        role='list'
        className='flex items-center space-x-1 sm:space-x-2 md:space-x-4 list-none p-0 m-0'
      >
        {homeHref && (
          <li>
            <div>
              <NavLink
                to={homeHref}
                className='text-gray-400 hover:text-gray-500'
              >
                <Home
                  aria-hidden='true'
                  className='h-4 w-4 sm:h-5 sm:w-5 flex-shrink-0'
                />
                <span className='sr-only'>Home</span>
              </NavLink>
            </div>
          </li>
        )}
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className='flex items-center'>
              {(homeHref || index > 0) && (
                <ChevronRight
                  aria-hidden='true'
                  className='h-4 w-4 sm:h-5 sm:w-5 flex-shrink-0 text-gray-400'
                />
              )}
              <NavLink
                to={page.href}
                aria-current={page.current ? 'page' : undefined}
                className={`${
                  homeHref || index > 0 ? 'ml-1 sm:ml-2 md:ml-4' : ''
                } text-xs sm:text-sm font-medium text-gray-500 hover:text-gray-700`}
              >
                {page.name}
              </NavLink>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export { Breadcrumbs };
