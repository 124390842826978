import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  GraduationCap,
  CheckCircle,
  FileText,
  Users,
  BookOpen,
  Briefcase,
  Award,
  Layers,
  Target,
  List,
  PoundSterling,
  Globe,
  Plus
} from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import Course from '../../../models/Course';
import CoreEntityTypes from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { Field, Badge, ComboBox, DropdownInput, FilledButton } from 'core';
import { UserProfileContext } from '../../../App';
import CourseDetails from './tabs/CourseDetails';
import DateCard from '../../../components/entity/DateCard';
import CourseOptions from './tabs/CourseOptions';
import CourseTrainers from './tabs/CourseTrainers';
import { supabase } from '../../../utilities/supabase';
import CourseLocations from './tabs/CourseLocations';

const CoursePage = ({ currentUser }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [accreditationOptions, setAccreditationOptions] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  useEffect(() => {
    const fetchOptions = async () => {
      // Set document title
      document.title = `Course #${id} | CRM | BOHSA LTD`;

      try {
        const statuses = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.CourseStatus
        );
        const accreditations = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.AccreditationBody
        );
        const industryTypes = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.Industry
        );

        setStatusOptions(
          statuses.map(option => ({
            label: option.type,
            value: option.id
          }))
        );

        setAccreditationOptions(
          accreditations.map(option => ({
            label: option.type,
            value: option.id
          }))
        );

        setIndustries(industryTypes);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const infoItems = [
    {
      icon: GraduationCap,
      label: 'Course Name',
      field: 'name',
      isEditable: true,
      inlineLabel: false,
      inlineEditor: false
    },
    {
      icon: CheckCircle,
      label: 'Status',
      displayField: 'status.name',
      editField: 'status.id',
      isEditable: true,
      dataType: 'select',
      options: statusOptions,
      inlineLabel: true,
      inlineEditor: true
    },
    {
      icon: Award,
      label: 'Accreditation',
      displayField: 'accreditation.type',
      editField: 'accreditation.id',
      isEditable: true,
      dataType: 'select',
      options: accreditationOptions,
      inlineLabel: true,
      inlineEditor: true
    },
    {
      icon: PoundSterling,
      label: 'Grant Claimable',
      field: 'grant',
      isEditable: true,
      dataType: 'select',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ]
    },

    {
      icon: Globe,
      label: 'Show on Website',
      field: 'websiteDetails.visible',
      isEditable: true,
      dataType: 'select',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ]
    }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data }) => (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
          <DateCard date={data.createdDate} label='Created' />
          <DateCard date={data.updatedDate} label='Updated' />
        </div>
      )
    },
    {
      title: 'Industries',
      content: ({ data, onUpdate }) => {
        const handleRemoveIndustry = async industryId => {
          try {
            const updatedIndustries = data.industries.filter(
              id => id !== industryId
            );
            await onUpdate('industries', updatedIndustries);
          } catch (err) {
            console.error('Error removing industry:', err);
          }
        };

        const handleAddIndustry = async industry => {
          try {
            const updatedIndustries = [...(data.industries || []), industry];
            await onUpdate('industries', updatedIndustries);
            setSelectedIndustry('');
          } catch (err) {
            console.error('Error adding industry:', err);
          }
        };

        const availableIndustries = industries.filter(
          industry => !data.industries?.includes(industry.id)
        );

        return (
          <div className='space-y-4'>
            <div className='flex items-end gap-x-2'>
              <div className='flex-1'>
                <DropdownInput
                  size='sm'
                  value={selectedIndustry}
                  onChange={e => setSelectedIndustry(e.target.value)}
                  options={[
                    { value: '', label: 'Select...' },
                    ...availableIndustries.map(industry => ({
                      value: industry.id,
                      label: industry.type
                    }))
                  ]}
                />
              </div>
              <FilledButton
                size='md'
                disabled={!selectedIndustry}
                onClick={() => handleAddIndustry(selectedIndustry)}
              >
                <Plus size={18} />
              </FilledButton>
            </div>
            <div className='flex flex-wrap gap-2'>
              {data.industries?.length > 0 ? (
                data.industries.map(industryId => {
                  const industry = industries.find(i => i.id === industryId);
                  return industry ? (
                    <Badge
                      key={industry.id}
                      colour={industry.metadata?.colour || 'primary'}
                      size='sm'
                      onRemove={() => handleRemoveIndustry(industry.id)}
                    >
                      {industry.type}
                    </Badge>
                  ) : null;
                })
              ) : (
                <div className='w-full text-center text-base-500'>
                  No industries added yet
                </div>
              )}
            </div>
          </div>
        );
      }
    }
  ];

  const additionalTabs = [
    {
      label: 'Details',
      path: 'details',
      content: <CourseDetails />
    },
    {
      label: 'Options',
      path: 'options',
      content: <CourseOptions />
    },
    {
      label: 'Trainers',
      path: 'trainers',
      content: <CourseTrainers />
    },
    {
      label: 'Locations',
      path: 'locations',
      content: <CourseLocations />
    }
  ];

  return (
    <Entity
      currentUser={currentUser}
      entityType={EntityTypeEnum.Course}
      model={Course}
      infoItems={infoItems}
      accordionItems={accordionItems}
      additionalTabs={additionalTabs}
    />
  );
};

export default CoursePage;
