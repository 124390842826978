import BaseModel from './BaseModel';

/**
 * Represents an Additional Item in the system.
 * @extends BaseModel
 */
class AdditionalItem extends BaseModel {
  /** @type {string} The name of the database table for Additional Items */
  static table = 'additional_items';

  /** @type {string} The SQL query to select Additional Item data with related information */
  static selectQuery = `
    *,
    item_type:core_entity_types!item_type (id, type),
    owner:user_profiles (id, auth, first_name, last_name, email, profile_photo)
  `;

  /**
   * Creates an AdditionalItem instance from database format data
   * @param {Object} data - The database format additional item data
   * @returns {AdditionalItem} A new AdditionalItem instance
   */
  static fromDatabase(data = {}) {
    const additionalItem = new AdditionalItem();
    additionalItem.id = data.id;
    additionalItem.createdDate = data.created_date;
    additionalItem.updatedDate = data.updated_date;
    additionalItem.booking = data.booking;
    additionalItem.invoiceId = data.invoice_id;
    additionalItem.owner = data.owner
      ? {
          id: data.owner.id,
          auth: data.owner?.auth,
          firstName: data.owner?.first_name,
          lastName: data.owner?.last_name,
          fullName: `${data.owner?.first_name} ${data.owner?.last_name}`,
          email: data.owner?.email,
          profilePhoto: data.owner?.profile_photo
        }
      : null;
    additionalItem.description = data.description;
    additionalItem.itemType = data.item_type
      ? { id: data.item_type.id, name: data.item_type.type }
      : null;
    additionalItem.vatRate = data.vat_rate;
    additionalItem.amount = data.amount;
    return additionalItem;
  }

  /**
   * Creates an instance of AdditionalItem.
   * @param {Object} data - The additional item data.
   */
  constructor(data = {}) {
    super(data);
    this.booking = data.booking ?? this.booking ?? null;
    this.invoiceId = data.invoiceId ?? this.invoiceId ?? null;
    this.owner = data.owner ?? this.owner ?? null;
    this.description = data.description ?? this.description ?? null;
    this.itemType = data.itemType ?? this.itemType ?? null;
    this.vatRate = data.vatRate ?? this.vatRate ?? null;
    this.amount = data.amount ?? this.amount ?? null;
  }

  /**
   * Converts the AdditionalItem instance to a database-friendly format.
   * @returns {Object} The additional item data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      booking: Number(this.booking),
      invoice_id: this.invoiceId,
      owner: this.owner ? this.owner.id : null,
      description: this.description,
      item_type: this.itemType ? Number(this.itemType.id) : null,
      vat_rate: Number(this.vatRate),
      amount: Number(this.amount)
    };
  }
}

export default AdditionalItem;
