import { EntityTypeEnum } from './Enumerables';

// Permission mapping
export const PERMISSIONS = {
  [EntityTypeEnum.Booking]: {
    VIEW: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8,
    VIEW_DELETED: 16
  },
  [EntityTypeEnum.Quote]: {
    VIEW: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Pipelines]: {
    VIEW: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Invoice]: {
    VIEW: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Settings]: {
    VIEW_GLOBALS: 1,
    EDIT_GLOBALS: 2,
    VIEW_TEAMS: 4,
    EDIT_TEAMS: 8,
    CREATE_TEAMS: 16,
    DELETE_TEAMS: 32,
    VIEW_ROLES: 64,
    EDIT_ROLES: 128,
    CREATE_ROLES: 256,
    DELETE_ROLES: 512,
    VIEW_TAGS: 1024,
    EDIT_TAGS: 2048,
    CREATE_TAGS: 4096,
    DELETE_TAGS: 8192,
    VIEW_API: 16384,
    CREATE_API_KEYS: 32768,
    DELETE_API_KEYS: 65536,
    ENABLE_DISABLE_API: 131072
  },
  [EntityTypeEnum.Guides]: {
    CREATE: 1,
    EDIT: 2,
    DELETE: 4
  },
  [EntityTypeEnum.GuideCategories]: {
    CREATE: 1,
    EDIT: 2,
    DELETE: 4
  },
  [EntityTypeEnum.User]: {
    VIEW: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Trainer]: {
    VIEW: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Course]: {
    VIEW: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Company]: {
    VIEW: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8
  },
  [EntityTypeEnum.BookingSpace]: {
    VIEW: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8
  }
};
