import React, { useState, useEffect, useRef } from 'react';
import { Check, ChevronDown, X } from 'lucide-react';

const MultiSelectBox = ({
  options = [],
  selectedOptions,
  onChange,
  placeholder,
  allowCreate,
  loadOptions,
  debounceMs = 300,
  size = 'md',
  label,
  error,
  className
}) => {
  const [query, setQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState(selectedOptions || []);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableOptions, setAvailableOptions] = useState(options);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const sizeClasses = {
    xs: 'text-xs py-0.5 px-1 rounded',
    sm: 'text-sm py-1 px-2 rounded-md',
    md: 'text-sm py-1.5 px-2.5 rounded-md',
    lg: 'text-base py-2 px-3 rounded-lg',
    xl: 'text-lg py-3 px-4 rounded-lg'
  };

  useEffect(() => {
    setSelectedItems(selectedOptions || []);
  }, [selectedOptions]);

  useEffect(() => {
    if (!loadOptions && options.length > 0) {
      setAvailableOptions(options);
    }
  }, [options, loadOptions]);

  useEffect(() => {
    if (!loadOptions) return;

    const debounceTimer = setTimeout(() => {
      setLoading(true);
      loadOptions(query).then(results => {
        setAvailableOptions(results);
        setLoading(false);
      });
    }, debounceMs);

    return () => clearTimeout(debounceTimer);
  }, [query, loadOptions, debounceMs]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredOptions =
    query === ''
      ? availableOptions.filter(
          option => !selectedItems.some(item => item?.value === option?.value)
        )
      : availableOptions.filter(
          option =>
            !selectedItems.some(item => item.value === option.value) &&
            option &&
            option.label &&
            option.label.toLowerCase().includes(query.toLowerCase())
        );

  const handleSelect = selected => {
    if (selected) {
      const updatedItems = [...selectedItems, selected];
      setSelectedItems(updatedItems);
      onChange(updatedItems);
    }
    setQuery('');
    setIsOpen(false);
    inputRef.current.focus();
  };

  const handleRemove = itemToRemove => {
    const updatedItems = selectedItems.filter(
      item => item.value !== itemToRemove.value
    );
    setSelectedItems(updatedItems);
    onChange(updatedItems);
  };

  const handleCreate = () => {
    if (allowCreate && query.trim() !== '') {
      const newOption = { value: query, label: query };
      handleSelect(newOption);
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && filteredOptions.length === 0 && allowCreate) {
      handleCreate();
    }
  };

  return (
    <div className={`w-full relative ${className}`} ref={dropdownRef}>
      {label && (
        <label
          htmlFor={inputRef.current?.id}
          className='block text-sm font-medium leading-6 text-gray-900 mb-1'
        >
          {label}
        </label>
      )}
      <div
        className={`relative w-full cursor-default overflow-hidden bg-white text-left border ${
          error ? 'border-danger-700' : 'border-gray-300'
        } focus-within:border-primary-600 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-600 transition duration-150 ease-in-out ${
          sizeClasses[size]
        }`}
      >
        <div className='flex flex-wrap gap-1'>
          {selectedItems.map(item => (
            <span
              key={item.value}
              className='inline-flex items-center px-1 py-0.5 rounded-md text-sm font-medium bg-brand-100 text-brand-800'
            >
              {item.label}
              <button
                type='button'
                onClick={() => handleRemove(item)}
                className='ml-1 inline-flex items-center rounded-sm hover:bg-brand-200 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary-600'
              >
                <X size={14} />
              </button>
            </span>
          ))}
          <input
            ref={inputRef}
            className={`flex-grow border-none px-1 py-0.5 text-gray-900 focus:outline-none text-sm`}
            placeholder={placeholder}
            value={query}
            onChange={e => setQuery(e.target.value)}
            onFocus={() => setIsOpen(true)}
            onKeyDown={handleKeyDown}
            style={{ minWidth: '200px' }}
          />
        </div>
        <button
          type='button'
          className='absolute inset-y-0 right-0 flex items-center pr-[2px]'
          onClick={() => setIsOpen(!isOpen)}
        >
          <ChevronDown
            strokeWidth={3.5}
            className='h-3.5 w-3.5 text-black'
            aria-hidden='true'
          />
        </button>
      </div>
      {isOpen && (
        <div
          className={`absolute mt-1 max-h-60 w-full overflow-auto bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 ${sizeClasses[size]}`}
        >
          {loading ? (
            <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
              Loading...
            </div>
          ) : filteredOptions.length === 0 && query !== '' ? (
            <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
              Nothing found.
              {allowCreate && (
                <button
                  type='button'
                  onClick={handleCreate}
                  className='ml-2 text-brand-700 hover:text-brand-800 focus:outline-none'
                >
                  Create "{query}"
                </button>
              )}
            </div>
          ) : (
            filteredOptions.map(option => (
              <div
                key={option.value}
                className={`relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-brand-700 hover:text-white`}
                onClick={() => handleSelect(option)}
              >
                <span className='block truncate'>{option.label}</span>
                {selectedItems.some(item => item.value === option.value) && (
                  <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-brand-700'>
                    <Check className='h-5 w-5' aria-hidden='true' />
                  </span>
                )}
              </div>
            ))
          )}
        </div>
      )}
      {error && <p className='mt-1 text-sm text-danger-700'>{error}</p>}
    </div>
  );
};

export { MultiSelectBox };
