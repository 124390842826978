import React, { useState } from 'react';
import { Popup, Input, FilledButton, ComboBox } from 'core';
import UserProfile from '../../../models/UserProfile';
import Company from '../../../models/Company';
import { useNotification } from 'core';

const CreateUser = ({ isOpen, onClose, onSuccess, companyId }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: null
  });

  const { addNotification } = useNotification();

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const loadCompanyOptions = async searchQuery => {
    try {
      const { data: companies } = await Company.getAll({
        fts: {
          operator: 'textSearch',
          value: searchQuery
        }
      });

      return companies.map(company => ({
        value: company.id,
        label: company.name
      }));
    } catch (err) {
      console.error('Failed to load company options:', err);
      return [];
    }
  };

  const handleSubmit = async () => {
    try {
      const userProfile = new UserProfile({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        companyId: formData.company?.value || companyId,
        active: true
      });

      await userProfile.insert();
      onSuccess?.();
      onClose();
    } catch (err) {
      console.error('Failed to create user:', err);
      addNotification({
        type: 'error',
        title: 'Failed to create user profile',
        description: err.message,
        duration: 4000
      });
    }
  };

  const footer = (
    <div className='flex justify-between items-center'>
      <FilledButton colour='base' onClick={onClose} className='mr-2'>
        Cancel
      </FilledButton>
      <FilledButton colour='primary' onClick={handleSubmit}>
        Create User
      </FilledButton>
    </div>
  );

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title='Create New User'
      size='sm'
      footer={footer}
    >
      <div className='space-y-4'>
        <div className='flex flex-row space-x-4'>
          <Input
            label='First Name'
            name='firstName'
            value={formData.firstName}
            onChange={handleChange}
            required
          />

          <Input
            label='Last Name'
            name='lastName'
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>

        <Input
          label='Email'
          name='email'
          type='email'
          value={formData.email}
          onChange={handleChange}
          required
        />

        {!companyId && (
          <ComboBox
            label='Company'
            loadOptions={loadCompanyOptions}
            value={formData.company}
            onChange={selected =>
              setFormData(prev => ({ ...prev, company: selected }))
            }
            placeholder='Search for a company...'
            required
          />
        )}
      </div>
    </Popup>
  );
};

export default CreateUser;
