import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  User,
  CheckCircle,
  Calendar,
  PoundSterling,
  Clock,
  Hash,
  Building
} from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import BookingSpace from '../../../models/BookingSpace';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { DropdownInput, SoftButton } from 'core';
import { UserProfileContext } from '../../../App';
import DateCard from '../../../components/entity/DateCard';
import AssignUser from '../../../components/popups/booking-space/AssignUser';

const BookingSpacePage = ({ currentUser }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [levyStatusOptions, setLevyStatusOptions] = useState([]);
  const [showAssignUser, setShowAssignUser] = useState(false);
  const [bookingSpace, setBookingSpace] = useState(null);
  const { id } = useParams();
  const { userProfile } = useContext(UserProfileContext);

  useEffect(() => {
    const fetchData = async () => {
      // Set document title
      document.title = `Booking Space #${id} | CRM | BOHSA LTD`;

      try {
        const [statuses, levyStatuses, bookingSpaceData] = await Promise.all([
          CoreEntityType.getByEntityId(EntityTypeEnum.BookingSpaceStatus),
          CoreEntityType.getByEntityId(EntityTypeEnum.BookingSpaceLevyType),
          BookingSpace.getById(id)
        ]);

        setStatusOptions(
          statuses.map(option => ({
            label: option.type,
            value: option.id
          }))
        );

        setLevyStatusOptions(
          levyStatuses.map(option => ({
            label: option.type,
            value: option.id
          }))
        );

        setBookingSpace(bookingSpaceData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const handleAssignUser = () => {
    setShowAssignUser(true);
  };

  const infoItems = [
    {
      icon: Hash,
      label: 'Booking',
      field: 'booking.id',
      isEditable: false,
      inlineLabel: true
    },
    {
      icon: Building,
      label: 'Company',
      field: 'booking.company.name',
      isEditable: false,
      inlineLabel: true
    },
    {
      icon: User,
      label: 'Delegate',
      field: 'delegate.fullName',
      isEditable: false,
      inlineLabel: true,
      extension: () => {
        return (
          <SoftButton
            onClick={handleAssignUser}
            colour='info'
            size='xs'
            className='ml-2'
          >
            Assign
          </SoftButton>
        );
      }
    },
    {
      icon: CheckCircle,
      label: 'Status',
      displayField: 'status.name',
      editField: 'status.id',
      isEditable: true,
      dataType: 'select',
      options: statusOptions,
      inlineLabel: true,
      inlineEditor: true
    },
    {
      icon: PoundSterling,
      label: 'Levy Status',
      displayField: 'levyStatus.name',
      editField: 'levyStatus.id',
      isEditable: true,
      dataType: 'select',
      options: levyStatusOptions,
      inlineLabel: true,
      inlineEditor: true
    },
    {
      icon: PoundSterling,
      label: 'Levy Number',
      field: 'levy',
      isEditable: true,
      dataType: 'text',
      inlineLabel: true
    }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data }) => (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
          <DateCard date={data.createdDate} label='Created' />
          <DateCard date={data.updatedDate} label='Updated' />
          <DateCard date={data.completionDate} label='Completed' />
          <DateCard date={data.expiryDate} label='Expires' />
        </div>
      )
    }
  ];

  return (
    <>
      <Entity
        currentUser={currentUser}
        entityType={EntityTypeEnum.BookingSpace}
        model={BookingSpace}
        infoItems={infoItems}
        accordionItems={accordionItems}
      />
      {showAssignUser && (
        <AssignUser
          bookingSpaceId={id}
          onClose={() => setShowAssignUser(false)}
          onAssign={() => {
            setShowAssignUser(false);
          }}
        />
      )}
    </>
  );
};

export default BookingSpacePage;
