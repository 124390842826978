import React, { useEffect, useState } from 'react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { formatDate } from '../../../../utilities/Formatting';
import BookingSpace from '../../../../models/BookingSpace';
import { SquareArrowOutUpRight, Pencil, Trash } from 'lucide-react';
import UserProfile from '../../../../models/UserProfile';
import CoreEntityType from '../../../../models/CoreEntityType';

const UserSpaces = ({ userId }) => {
  const [uniqueUserId, setUniqueUserId] = useState(null);
  const [statusTypes, setStatusTypes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user ID
        if (userId) {
          const user = await UserProfile.getById(userId);
          setUniqueUserId(user.id);
        }

        // Fetch status types
        const { data: statusTypes } = await CoreEntityType.getAll({
          entity_id: {
            operator: FilterOperatorEnum.EQUALS,
            value: EntityTypeEnum.BookingSpaceStatus
          }
        });
        setStatusTypes(
          statusTypes.map(type => ({
            value: type.type,
            key: type.id
          }))
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userId]);

  if (!uniqueUserId) {
    return null;
  }

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/booking-spaces/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => (window.location.href = `/booking-spaces/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  return (
    <EntityTable
      name='User Spaces'
      filter={[
        {
          field: 'id',
          label: 'Space ID',
          type: 'number',
          operator: FilterOperatorEnum.EQUALS
        },
        {
          field: 'booking.course.name',
          label: 'Course Name',
          type: 'text',
          operator: FilterOperatorEnum.TEXTSEARCH
        },
        {
          field: 'booking.companies.name',
          label: 'Company Name',
          type: 'text',
          operator: FilterOperatorEnum.TEXTSEARCH
        },
        {
          field: 'status',
          label: 'Status',
          type: 'select',
          options: statusTypes
        },
        {
          field: 'booking.booking_date',
          label: 'Booking Date',
          type: 'daterange'
        }
      ]}
      columns={[
        { label: 'ID', field: 'id' },
        { label: 'Booking ID', field: 'booking.id' },
        { label: 'Course', field: 'booking.course.name' },
        { label: 'Company', field: 'booking.company.name' },
        //{ label: 'Delivery', field: 'booking.deliveryType.name' },
        {
          label: 'Booking Date',
          field: 'booking.bookingDate',
          cell: value => formatDate(value)
        },
        {
          label: 'Completion Date',
          field: 'completion_date',
          cell: value => formatDate(value)
        },
        {
          label: 'Expiry Date',
          field: 'expiry_date',
          cell: value => formatDate(value)
        },
        { label: 'Status', field: 'status.name' }
      ]}
      menuItems={menuItems}
      model={BookingSpace}
      entityType={EntityTypeEnum.BookingSpace}
      defaultFilters={{
        delegate_id: {
          value: uniqueUserId,
          operator: FilterOperatorEnum.EQUALS
        }
      }}
      canCreate={false}
    />
  );
};

export default UserSpaces;
