import React, { useState } from 'react';
import { Eye, Trash } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import Compliance from '../../../../models/Compliance';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import PreviewCompliance from '../../../../components/popups/compliance/PreviewCompliance';
import CreateCompliance from '../../../../components/popups/compliance/CreateCompliance';
import { Badge } from 'core';
import { formatDate } from '../../../../utilities/Formatting';

const ComplianceTab = ({ trainerId }) => {
  const [showPreviewCompliance, setShowPreviewCompliance] = useState(false);
  const [showCreateCompliance, setShowCreateCompliance] = useState(false);
  const [selectedComplianceId, setSelectedComplianceId] = useState(null);

  const menuItems = [
    {
      label: 'View',
      icon: <Eye size={14} />,
      onClick: data => {
        setSelectedComplianceId(data.id);
        setShowPreviewCompliance(true);
      }
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const columns = [
    { label: 'ID', field: 'id' },
    { label: 'Document', field: 'document.name' },
    { label: 'Accreditation', field: 'accreditation.type' },
    { label: 'Compliance Type', field: 'type.type' },
    {
      label: 'Status',
      field: 'status.type',
      cell: (value, row) => (
        <Badge colour={row.status?.colour} size='sm'>
          {row.status?.type}
        </Badge>
      )
    },
    {
      label: 'Expiry Date',
      field: 'expiryDate',
      cell: value => formatDate(value)
    }
  ];

  const filters = [
    { field: 'id', label: 'ID', type: 'number' },
    {
      field: 'document.file_name',
      label: 'Document',
      type: 'text',
      operator: FilterOperatorEnum.ILIKE
    },
    {
      field: 'status.type',
      label: 'Status',
      type: 'text',
      operator: FilterOperatorEnum.EQUALS
    },
    {
      field: 'accreditation.type',
      label: 'Accreditation',
      type: 'text',
      operator: FilterOperatorEnum.EQUALS
    },
    {
      field: 'type',
      label: 'Compliance Type',
      type: 'text',
      operator: FilterOperatorEnum.EQUALS
    },
    {
      field: 'type.type',
      label: 'Type',
      type: 'text',
      operator: FilterOperatorEnum.EQUALS
    },
    {
      field: 'expiryDate',
      label: 'Expiry Date',
      type: 'date',
      operator: FilterOperatorEnum.LESS_THAN_OR_EQUAL
    }
  ];

  return (
    <div className='space-y-4'>
      <EntityTable
        name='Compliance Records'
        filter={filters}
        columns={columns}
        menuItems={menuItems}
        model={Compliance}
        entityType={EntityTypeEnum.Compliance}
        defaultFilters={{
          trainer_id: { value: trainerId }
        }}
        canCreate={true}
        onRowClick={data => {
          setSelectedComplianceId(data.id);
          setShowPreviewCompliance(true);
        }}
        onNewClick={() => setShowCreateCompliance(true)}
      />
      <PreviewCompliance
        isOpen={showPreviewCompliance}
        onClose={() => setShowPreviewCompliance(false)}
        complianceId={selectedComplianceId}
      />
      <CreateCompliance
        isOpen={showCreateCompliance}
        onClose={() => setShowCreateCompliance(false)}
        trainerId={trainerId}
        onSuccess={() => {
          setShowCreateCompliance(false);
        }}
      />
    </div>
  );
};

export default ComplianceTab;
