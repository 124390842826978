import {
  Building,
  CheckCircle,
  Coins,
  ExternalLink,
  Hash,
  MapPin
} from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import InvoiceModel from '../../../models/Invoice';
import DateCard from '../../../components/entity/DateCard';
import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import Preview from './tabs/Preview';
import Bookings from './tabs/Bookings';
import Company from '../../../models/Company';
import Booking from '../../../models/Booking';
import Location from '../../../models/Location';
import { SoftButton, Modal } from 'core';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { UserProfileContext } from '../../../App';

const Invoice = () => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [company, setCompany] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { userProfile: currentUser } = useContext(UserProfileContext);
  //Test user permissions for view access
  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Invoice,
      PERMISSIONS[EntityTypeEnum.Invoice].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view invoices.'
        style='danger'
      />
    );
  }

  const userCanEdit = currentUser.hasPermission(
    EntityTypeEnum.Invoice,
    PERMISSIONS[EntityTypeEnum.Invoice].EDIT
  );

  const userCanDelete = currentUser.hasPermission(
    EntityTypeEnum.Invoice,
    PERMISSIONS[EntityTypeEnum.Invoice].DELETE
  );

  useEffect(() => {
    document.title = `Invoice #${id} | CRM | BOHSA LTD`;
    const fetchData = async () => {
      try {
        const invoiceData = await InvoiceModel.getById(id);

        setInvoice(invoiceData);
        if (invoiceData.companyId) {
          const companyData = await Company.getById(invoiceData.companyId);
          setCompany(companyData);
        }
        const fetchedBookings = await Booking.getAll({
          invoice_id: { operator: 'eq', value: id }
        });
        setBookings(fetchedBookings.data);
      } catch (error) {
        console.error('Error fetching invoice data:', error);
      }
    };

    fetchData();
  }, [id]);

  const handlePublishInvoice = async () => {
    try {
      const { data, error } = await supabase.functions.invoke(
        'xero/createInvoice',
        {
          body: { invoiceId: invoice.id }
        }
      );
      if (error) {
        console.error('Error publishing invoice:', error);
      } else {
        console.log('Invoice published successfully:', data);
        // Optionally, update the invoice state or refetch data
      }
    } catch (error) {
      console.error('Error invoking edge function:', error);
    }
    setIsPublishModalOpen(false);
  };

  const infoItems = [
    {
      icon: Hash,
      label: 'Xero Invoice',
      field: 'xero.number',
      dataType: 'text',
      isEditable: false,
      extension: () => {
        if (invoice?.status.id !== 219 && invoice?.status.id !== 220) {
          return (
            <SoftButton
              onClick={() => setIsPublishModalOpen(true)}
              colour='info'
              size='sm'
              className='ml-2'
            >
              Publish
            </SoftButton>
          );
        } else if (invoice.status.id === 219) {
          return (
            <>
              {invoice.updatedDate > invoice.publishedDate && (
                <SoftButton
                  onClick={() => setIsPublishModalOpen(true)}
                  colour='info'
                  size='sm'
                  className='ml-2'
                >
                  Republish
                </SoftButton>
              )}
              <SoftButton
                onClick={() =>
                  window.open(
                    `https://go.xero.com/app/!hK!m-/invoicing/view/${invoice.xero.id}`,
                    '_blank'
                  )
                }
                colour='base'
                size='sm'
                className='ml-2'
              >
                <ExternalLink size={18} />
              </SoftButton>
            </>
          );
        } else if (
          invoice.status.id === 220 &&
          invoice.xero &&
          invoice.xero.id
        ) {
          return (
            <>
              <SoftButton
                onClick={() =>
                  window.open(
                    `https://go.xero.com/app/!hK!m-/invoicing/view/${invoice.xero.id}`,
                    '_blank'
                  )
                }
                colour='base'
                size='sm'
                className='ml-2'
              >
                <ExternalLink size={18} />
              </SoftButton>
            </>
          );
        }
        return null;
      }
    },
    {
      icon: Building,
      label: 'Company',
      field: 'company.name',
      dataType: 'text',
      isEditable: false
    },
    // {
    //   icon: MapPin,
    //   label: 'Invoice Address',
    //   field: 'invoiceAddress.full_address',
    //   dataType: 'search',
    //   isEditable: false
    // },
    {
      icon: CheckCircle,
      label: 'Status',
      editField: 'status.id',
      displayField: 'status.type',
      dataType: 'text',
      isEditable: false,
      extension: () => {
        if (invoice?.status.id !== 220) {
          return (
            <SoftButton
              onClick={async () => {
                try {
                  invoice.status = { id: 220 };

                  const locationData = await Location.getById(
                    invoice.addressId
                  );
                  invoice.billingAddress = locationData;

                  await invoice.update();
                } catch (error) {
                  console.error('Error marking invoice as sent:', error);
                }
              }}
              colour='primary'
              size='xs'
              className='ml-2'
            >
              Mark as Sent
            </SoftButton>
          );
        }
        return null;
      }
    },
    {
      icon: Coins,
      label: 'Subtotal',
      field: 'subtotal',
      dataType: 'currency',
      isEditable: false
    },
    {
      icon: Coins,
      label: 'Tax',
      field: 'totalTax',
      dataType: 'currency',
      isEditable: false
    },
    {
      icon: Coins,
      label: 'Total',
      field: 'total',
      dataType: 'currency',
      isEditable: false
    }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data }) => (
        <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
          <DateCard date={data.issueDate} label='Issue Date' />
          <DateCard date={data.dueDate} label='Due Date' />
          <DateCard date={data.publishedDate} label='Published Date' />
        </div>
      )
    }
  ];

  const additionalTabs = [
    {
      label: 'Preview',
      path: 'preview',
      content: <Preview invoice={invoice} />
    },
    {
      label: 'Bookings',
      path: 'bookings',
      content: <Bookings invoiceId={id} />
    }
  ];

  return (
    <>
      <Entity
        entityType={EntityTypeEnum.Invoice}
        model={InvoiceModel}
        infoItems={infoItems}
        accordionItems={accordionItems}
        additionalTabs={additionalTabs}
      />
      <Modal
        isOpen={isPublishModalOpen}
        onClose={() => setIsPublishModalOpen(false)}
        title='Publish Invoice'
        style='warning'
        actions={[
          {
            label: 'Publish',
            onClick: handlePublishInvoice,
            style: 'primary'
          },
          {
            label: 'Cancel',
            onClick: () => setIsPublishModalOpen(false),
            style: 'base'
          }
        ]}
      >
        <p>Are you sure you want to publish this invoice to Xero?</p>
      </Modal>
    </>
  );
};

export default Invoice;
