import React, { useState, useEffect } from 'react';
import {
  Alert,
  Breadcrumbs,
  FilledButton,
  SoftButton,
  TextButton,
  Input
} from 'core';
import CoreEntityType from '../../../../models/CoreEntityType';
import Tag from '../../../../models/Tag';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';
import {
  Tags,
  Plus,
  Pencil,
  ArrowLeft,
  ChevronRight,
  ArrowRightLeft,
  Trash2,
  Search
} from 'lucide-react';
import { NavLink } from 'react-router-dom';
import DeleteTagModal from './tags/DeleteTagModal';
import MoveTagModal from './tags/MoveTagModal';
import EditTagModal from './tags/EditTagModal';
import AddTagModal from './tags/AddTagModal';
import DeleteCategoryModal from './tags/categories/DeleteCategoryModal';
import EditCategoryModal from './tags/categories/EditCategoryModal';
import AddCategoryModal from './tags/categories/AddCategoryModal';

const TagsSettings = () => {
  // State management
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categoryTags, setCategoryTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Modal states
  const [modalStates, setModalStates] = useState({
    addCategory: { isOpen: false, data: { name: '', color: 'zinc' } },
    editCategory: { isOpen: false, data: null },
    deleteCategory: { isOpen: false, data: null },
    addTag: {
      isOpen: false,
      data: { name: '', color: 'zinc', pipelines: false }
    },
    editTag: { isOpen: false, data: null },
    moveTag: { isOpen: false, data: null, targetCategoryId: null },
    deleteTag: { isOpen: false, data: null }
  });

  const closeModal = modalKey => {
    setModalStates(prev => ({
      ...prev,
      [modalKey]: { ...prev[modalKey], isOpen: false, data: null }
    }));
  };

  const openModal = (modalKey, data = null) => {
    setModalStates(prev => ({
      ...prev,
      [modalKey]: { ...prev[modalKey], isOpen: true, data }
    }));
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchCategoryTags();
    }
  }, [selectedCategory]);

  useEffect(() => {
    filterCategories();
  }, [searchQuery, categories]);

  const filterCategories = () => {
    if (!searchQuery.trim()) {
      setFilteredCategories(categories);
      return;
    }

    const filtered = categories.filter(category =>
      category.type.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCategories(filtered);
  };

  const handleSearchChange = e => {
    setSearchQuery(e.target.value);
  };

  const fetchCategoryTags = async () => {
    try {
      const { data } = await Tag.getAll({
        category_id: { operator: 'eq', value: selectedCategory.id }
      });
      setCategoryTags(data);
    } catch (err) {
      console.error('Error fetching category tags:', err);
      setError('Failed to load tags');
    }
  };

  const fetchCategories = async () => {
    try {
      const { data } = await CoreEntityType.getAll({
        entity_id: { operator: 'eq', value: EntityTypeEnum.TagCategory }
      });
      setCategories(data);
      setFilteredCategories(data);
    } catch (err) {
      console.error('Error fetching tag categories:', err);
      setError('Failed to load tag categories');
    } finally {
      setLoading(false);
    }
  };

  const handleAddCategory = async (
    name,
    color,
    description,
    showOnPipelines
  ) => {
    try {
      const coreEntityType = new CoreEntityType({
        type: name,
        description: description,
        entity_id: EntityTypeEnum.TagCategory,
        metadata: { colour: color, pipelines: showOnPipelines ?? false }
      });
      await coreEntityType.insert();
      closeModal('addCategory');
      fetchCategories();
    } catch (err) {
      console.error('Error creating category:', err);
      setError('Failed to create category');
    }
  };

  const handleEditCategory = async (
    name,
    color,
    description,
    showOnPipelines
  ) => {
    try {
      const category = new CoreEntityType(selectedCategory);
      category.type = name;
      category.description = description;
      category.metadata = {
        ...selectedCategory.metadata,
        colour: color,
        pipelines: showOnPipelines
      };
      await category.update();
      closeModal('editCategory');
      fetchCategories();
    } catch (err) {
      console.error('Error updating category:', err);
      setError('Failed to update category');
    }
  };

  const handleDeleteCategory = async () => {
    try {
      const categoryToDelete = modalStates.deleteCategory.data;
      const category = new CoreEntityType(categoryToDelete);
      await category.delete();
      closeModal('deleteCategory');
      if (selectedCategory?.id === categoryToDelete.id) {
        setSelectedCategory(null);
      }
      fetchCategories();
    } catch (err) {
      console.error('Error deleting category:', err);
      setError('Failed to delete category');
    }
  };

  const handleAddTag = async (name, color) => {
    try {
      const tag = new Tag({
        name,
        colour: color?.replace('-500', ''),
        category_id: selectedCategory.id,
        pipelines: false
      });
      await tag.insert();
      closeModal('addTag');
      fetchCategoryTags();
    } catch (err) {
      console.error('Error creating tag:', err);
      setError('Failed to create tag');
    }
  };

  const handleEditTag = async (name, color, pipelines) => {
    try {
      const tag = new Tag(modalStates.editTag.data);
      tag.name = name;
      tag.colour = color?.replace('-500', '');
      tag.categoryId = tag.category.id;
      tag.pipelines = pipelines ?? false;
      await tag.update();
      closeModal('editTag');
      fetchCategoryTags();
    } catch (err) {
      console.error('Error updating tag:', err);
      setError('Failed to update tag');
    }
  };

  const handleMoveTag = async targetCategoryId => {
    try {
      const tag = new Tag(modalStates.moveTag.data);
      tag.categoryId = targetCategoryId;
      await tag.update();
      closeModal('moveTag');
      fetchCategoryTags();
    } catch (err) {
      console.error('Error moving tag:', err);
      setError('Failed to move tag');
    }
  };

  const handleDeleteTag = async () => {
    try {
      const tag = new Tag(modalStates.deleteTag.data);
      await tag.delete();
      closeModal('deleteTag');
      fetchCategoryTags();
    } catch (err) {
      console.error('Error deleting tag:', err);
      setError('Failed to delete tag');
    }
  };

  if (error) {
    return <Alert title='Error' description={error} colour='danger' />;
  }

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-none border-b'>
        <div className='flex items-center justify-between '>
          <div>
            <Breadcrumbs
              homeHref='/'
              pages={[
                { name: 'Settings', href: '/settings' },
                {
                  name: 'Tags',
                  href: '/settings/tags',
                  current: true
                }
              ]}
            />
            <h1 className='text-2xl font-semibold text-base-900 mt-2'>
              Tag Management
            </h1>
          </div>
        </div>
      </div>

      <div className='flex flex-1 overflow-hidden'>
        <div className='w-72 overflow-y-auto p-4'>
          <div className='flex flex-row mb-4 space-x-1'>
            <Input
              type='text'
              placeholder='Search categories...'
              leftIcon={Search}
              value={searchQuery}
              onChange={handleSearchChange}
              className='w-full'
            />

            <FilledButton onClick={() => openModal('addCategory')}>
              <Plus size={16} />
            </FilledButton>
          </div>
          <nav className='space-y-1'>
            {filteredCategories.map(category => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category)}
                className={`w-full flex items-center justify-between px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                  selectedCategory?.id === category.id
                    ? 'bg-base-100 text-base-900'
                    : 'text-base-500 hover:bg-base-50 hover:text-base-900'
                }`}
              >
                <span>{category.type}</span>
                <ChevronRight size={16} />
              </button>
            ))}
          </nav>
        </div>

        <div className='flex-1 overflow-y-auto bg-base-50'>
          {selectedCategory ? (
            <div className='p-6'>
              <div className='bg-white rounded-lg shadow-sm border border-base-100'>
                <div className='p-4 border-b border-base-100'>
                  <div className='flex flex-col'>
                    <div className='flex items-center justify-between'>
                      <div className='flex items-center gap-3'>
                        <Tags
                          className={`w-8 h-8 bg-${selectedCategory.metadata.colour}-100 text-${selectedCategory.metadata.colour}-600 p-1.5 rounded-lg`}
                        />
                        <h4 className='text-lg font-medium'>
                          {selectedCategory.type}
                        </h4>
                      </div>
                      {selectedCategory.id !== 225 && (
                        <div className='flex items-center gap-2'>
                          <TextButton
                            onClick={() =>
                              openModal('editCategory', selectedCategory)
                            }
                          >
                            <Pencil size={16} />
                          </TextButton>
                          <TextButton
                            onClick={() =>
                              openModal('deleteCategory', selectedCategory)
                            }
                            colour='danger'
                          >
                            <Trash2 size={16} />
                          </TextButton>
                        </div>
                      )}
                    </div>
                    <p className='text-sm text-base-500 ml-11'>
                      {selectedCategory.description}
                    </p>
                  </div>
                </div>

                <div className='p-4'>
                  <div className='flex items-center justify-between mb-4'>
                    <h3 className='font-medium'>Tags</h3>
                    <FilledButton
                      onClick={() => openModal('addTag')}
                      leftIcon={<Plus size={16} />}
                      size='md'
                    >
                      Add Tag
                    </FilledButton>
                  </div>

                  <div className='space-y-2'>
                    {categoryTags.length > 0 ? (
                      categoryTags.map(tag => (
                        <div
                          key={tag.id}
                          className='flex items-center justify-between p-3 bg-base-50 border border-base-200 rounded-lg hover:bg-base-100 transition-colors'
                        >
                          <div className='flex items-center gap-3'>
                            <div className='w-3 h-3 rounded-full' />
                            <span className='text-sm font-medium'>
                              {tag.name}
                            </span>
                          </div>
                          <div className='flex items-center gap-1'>
                            <TextButton
                              onClick={() => openModal('editTag', tag)}
                              size='xs'
                            >
                              <Pencil size={14} />
                            </TextButton>
                            <TextButton
                              onClick={() => openModal('moveTag', tag)}
                              size='xs'
                            >
                              <ArrowRightLeft size={14} />
                            </TextButton>
                            <TextButton
                              onClick={() => openModal('deleteTag', tag)}
                              size='xs'
                              colour='danger'
                            >
                              <Trash2 size={14} />
                            </TextButton>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className='text-center py-8 text-base-500'>
                        No tags created yet in this category
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex items-center justify-center h-full text-base-500'>
              Select a category to view and manage its tags
            </div>
          )}
        </div>
      </div>

      <AddCategoryModal
        isOpen={modalStates.addCategory.isOpen}
        onClose={() => closeModal('addCategory')}
        onAdd={handleAddCategory}
      />

      <EditCategoryModal
        isOpen={modalStates.editCategory.isOpen}
        onClose={() => closeModal('editCategory')}
        onEdit={handleEditCategory}
        category={modalStates.editCategory.data}
      />

      <DeleteCategoryModal
        isOpen={modalStates.deleteCategory.isOpen}
        onClose={() => closeModal('deleteCategory')}
        onDelete={handleDeleteCategory}
      />

      <AddTagModal
        isOpen={modalStates.addTag.isOpen}
        onClose={() => closeModal('addTag')}
        onAdd={handleAddTag}
      />

      <EditTagModal
        isOpen={modalStates.editTag.isOpen}
        onClose={() => closeModal('editTag')}
        onEdit={handleEditTag}
        tag={modalStates.editTag.data}
      />

      {selectedCategory && (
        <MoveTagModal
          isOpen={modalStates.moveTag.isOpen}
          onClose={() => closeModal('moveTag')}
          onMove={handleMoveTag}
          categories={categories}
          selectedCategory={selectedCategory}
          tag={modalStates.moveTag.data}
        />
      )}

      <DeleteTagModal
        isOpen={modalStates.deleteTag.isOpen}
        onClose={() => closeModal('deleteTag')}
        onDelete={handleDeleteTag}
      />
    </div>
  );
};

export default TagsSettings;
