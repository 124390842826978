import React, { useEffect, useState } from 'react';
import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import * as Icons from 'lucide-react';

const IconPicker = ({ selectedIcon, onChange }) => {
  const iconOptions = [
    'Zap',
    'LogOut',
    'Home',
    'User',
    'Settings',
    'Bell',
    'Heart',
    'Star',
    'Cloud',
    'Sun',
    'Moon',
    'Coffee',
    'Book',
    'Camera',
    'Car',
    'Clock',
    'Code',
    'Compass',
    'DollarSign',
    'Feather',
    'Film',
    'Gift',
    'Shield',
    'Anchor',
    'Airplay',
    'AlertCircle',
    'AlignCenter',
    'AlignJustify',
    'AlignLeft',
    'AlignRight',
    'Aperture',
    'Archive',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'AtSign',
    'Award',
    'BarChart',
    'BarChart2',
    'Battery',
    'BatteryCharging',
    'BellOff',
    'Bluetooth',
    'Bold',
    'Bookmark',
    'Box',
    'Briefcase',
    'Calendar',
    'Cast',
    'Check',
    'CheckCircle',
    'ChevronDown',
    'ChevronLeft',
    'ChevronRight',
    'ChevronUp',
    'Circle',
    'Clipboard',
    'Clockwise',
    'CloudDrizzle',
    'CloudLightning',
    'CloudOff',
    'CloudRain',
    'CloudSnow',
    'Command',
    'Copy',
    'CornerDownLeft',
    'CornerDownRight',
    'CornerLeftDown',
    'CornerLeftUp',
    'CornerRightDown',
    'CornerRightUp',
    'CornerUpLeft',
    'CornerUpRight',
    'Cpu',
    'CreditCard',
    'Crop',
    'Crosshair',
    'Database',
    'Delete',
    'Disc',
    'Divide',
    'DivideCircle',
    'DivideSquare',
    'Download',
    'DownloadCloud',
    'Droplet',
    'Edit',
    'Edit2',
    'Edit3',
    'ExternalLink',
    'Eye',
    'EyeOff',
    'FastForward',
    'File',
    'FileMinus',
    'FilePlus',
    'FileText',
    'Filter',
    'Flag',
    'Folder',
    'FolderMinus',
    'FolderPlus',
    'Frown',
    'GitBranch',
    'GitCommit',
    'GitMerge',
    'GitPullRequest',
    'Globe',
    'Grid',
    'HardDrive',
    'Hash',
    'Headphones',
    'HelpCircle',
    'Hexagon',
    'Image',
    'Inbox',
    'Info',
    'Italic',
    'Key',
    'Layers',
    'Layout',
    'LifeBuoy',
    'Link',
    'Link2',
    'Linkedin',
    'List',
    'Loader',
    'Lock',
    'LogIn',
    'Mail',
    'Map',
    'MapPin',
    'Maximize',
    'Maximize2',
    'Meh',
    'MessageCircle',
    'MessageSquare',
    'Mic',
    'MicOff',
    'Minimize',
    'Minimize2',
    'Minus',
    'MinusCircle',
    'MinusSquare',
    'Monitor',
    'MoreHorizontal',
    'MoreVertical',
    'MousePointer',
    'Move',
    'Music',
    'Navigation',
    'Navigation2',
    'Octagon',
    'Package',
    'Paperclip',
    'Pause',
    'PauseCircle',
    'PenTool',
    'Percent',
    'Phone',
    'PhoneCall',
    'PhoneForwarded',
    'PhoneIncoming',
    'PhoneMissed',
    'PhoneOff',
    'PhoneOutgoing',
    'PieChart',
    'Play',
    'PlayCircle',
    'Plus',
    'PlusCircle',
    'PlusSquare',
    'Power',
    'Printer',
    'Radio',
    'RefreshCcw',
    'RefreshCw',
    'Repeat',
    'Rewind',
    'RotateCcw',
    'RotateCw',
    'Rss',
    'Save',
    'Scissors',
    'Search',
    'Send',
    'Server',
    'Share',
    'Share2',
    'ShieldOff',
    'ShoppingBag',
    'ShoppingCart',
    'Shuffle',
    'Sidebar',
    'SkipBack',
    'SkipForward',
    'Slack',
    'Slash',
    'Sliders',
    'Smartphone',
    'Smile',
    'Speaker',
    'Square',
    'StopCircle',
    'Sunrise',
    'Sunset',
    'Tablet',
    'Tag',
    'Target',
    'Terminal',
    'Thermometer',
    'ThumbsDown',
    'ThumbsUp',
    'ToggleLeft',
    'ToggleRight',
    'Tool',
    'Trash',
    'Trash2',
    'TrendingDown',
    'TrendingUp',
    'Triangle',
    'Truck',
    'Tv',
    'Type',
    'Umbrella',
    'Underline',
    'Unlock',
    'Upload',
    'UploadCloud',
    'UserCheck',
    'UserMinus',
    'UserPlus',
    'UserX',
    'Users',
    'Video',
    'VideoOff',
    'Voicemail',
    'Volume',
    'Volume1',
    'Volume2',
    'VolumeX',
    'Watch',
    'Wifi',
    'WifiOff',
    'Wind',
    'X',
    'XCircle',
    'XOctagon',
    'XSquare',
    'ZapOff',
    'ZoomIn',
    'ZoomOut'
  ];
  const [currentIcon, setCurrentIcon] = useState(
    selectedIcon || iconOptions[0]
  );

  useEffect(() => {
    if (selectedIcon) {
      setCurrentIcon(selectedIcon);
    }
  }, [selectedIcon]);

  const handleIconChange = icon => {
    setCurrentIcon(icon);
    onChange(icon);
  };

  const renderIcon = iconName => {
    const IconComponent = Icons[iconName] || Icons.Zap; // Default to Zap if not found
    return <IconComponent className='w-6 h-6' />;
  };

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <MenuButton className='w-8 h-8 flex items-center justify-center rounded-md border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'>
        {renderIcon(currentIcon)}
        <span className='sr-only'>Open icon picker</span>
      </MenuButton>
      <MenuItems className='absolute z-10 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
        <div className='py-1'>
          <div className='px-4 py-2'>
            <p className='text-sm font-medium text-gray-700 mb-1'>Icons:</p>
            <div className='grid grid-cols-4 gap-2 justify-center max-h-64 overflow-y-auto'>
              {iconOptions.map(iconOption => (
                <button
                  key={iconOption}
                  className={`w-8 h-8 flex items-center justify-center rounded-lg cursor-pointer transition-colors duration-300 ${
                    currentIcon === iconOption
                      ? 'bg-brand-700 text-white'
                      : 'bg-base-100'
                  }`}
                  onClick={() => handleIconChange(iconOption)}
                  title={iconOption}
                >
                  {renderIcon(iconOption)}
                </button>
              ))}
            </div>
            <p className='text-sm text-center pt-2'>{currentIcon}</p>
          </div>
        </div>
      </MenuItems>
    </Menu>
  );
};

const renderIcon = (iconName, colour, size = 18) => {
  const IconComponent = Icons[iconName] || Icons.Zap; // Default to Zap if not found
  return <IconComponent size={size} className={`text-${colour}`} />;
};

export { IconPicker, renderIcon };
