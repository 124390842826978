import React, { useState, useEffect } from 'react';
import { Popup } from 'core';
import { FilledButton, Input, DropdownInput } from 'core';
import Compliance from '../../../models/Compliance';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { supabase } from '../../../utilities/supabase';
import axios from 'axios';
import Document from '../../../models/Document';

const CreateCompliance = ({ isOpen, onClose, trainerId, onSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [accreditationTypes, setAccreditationTypes] = useState([]);
  const [statusTypes, setStatusTypes] = useState([]);
  const [complianceTypes, setComplianceTypes] = useState([]);
  const [formData, setFormData] = useState({
    accreditation: '',
    type: '',
    expiryDate: '',
    status: ''
  });

  useEffect(() => {
    const fetchAccreditationTypes = async () => {
      try {
        const types = await CoreEntityType.getByEntityId(
          EntityTypeEnum.AccreditationBody
        );
        const options = types.map(type => ({
          value: type.id,
          label: type.description || type.type
        }));
        setAccreditationTypes(options);
      } catch (error) {
        console.error('Error fetching accreditation types:', error);
      }
    };

    const fetchStatusTypes = async () => {
      try {
        const types = await CoreEntityType.getByEntityId(
          EntityTypeEnum.ComplianceStatus
        );
        const options = types.map(type => ({
          value: type.id,
          label: type.type
        }));
        setStatusTypes(options);
      } catch (error) {
        console.error('Error fetching status types:', error);
      }
    };

    const fetchComplianceTypes = async () => {
      try {
        const types = await CoreEntityType.getByEntityId(
          EntityTypeEnum.ComplianceType
        );
        const options = types.map(type => ({
          value: type.id,
          label: type.type
        }));
        setComplianceTypes(options);
      } catch (error) {
        console.error('Error fetching compliance types:', error);
      }
    };

    fetchAccreditationTypes();
    fetchStatusTypes();
    fetchComplianceTypes();
  }, []);

  useEffect(() => {
    if (file) {
      setFileName(file.name);
    }
  }, [file]);

  const handleFileChange = e => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // First upload the file
      const { data: signedUrlData, error: signedUrlError } =
        await supabase.functions.invoke('storage/getSigned/upload', {
          body: {
            fileName: fileName,
            originalFileName: file.name,
            destinationFolder: 'compliance'
          }
        });

      if (signedUrlError) throw signedUrlError;

      // Upload file using signed URL
      await axios.put(signedUrlData.url, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        }
      });

      // Update document record with file path
      const document = await Document.getById(signedUrlData.file.id);
      await document.update({ filePath: signedUrlData.file.key });

      // Create compliance record
      const compliance = new Compliance({
        trainerId: trainerId || null,
        document: {
          id: document.id || null,
          name: document.fileName || null
        },
        accreditation: {
          id: formData.accreditation || null
        },
        type: {
          id: formData.type || null
        },
        status: {
          id: formData.status || null
        },
        expiryDate: formData.expiryDate || null
      });

      const savedCompliance = await compliance.insert();

      if (onSuccess) {
        onSuccess(savedCompliance);
      }
      onClose();
    } catch (error) {
      console.error('Error creating compliance:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title='Create Compliance'
      size='md'
      footer={
        <div className='flex justify-end gap-2'>
          <FilledButton onClick={onClose} colour='base'>
            Cancel
          </FilledButton>
          <FilledButton
            onClick={handleSubmit}
            colour='primary'
            disabled={isSubmitting || !file}
          >
            {isSubmitting ? 'Creating...' : 'Create'}
          </FilledButton>
        </div>
      }
    >
      <form className='space-y-4' onSubmit={handleSubmit}>
        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>
            Document
          </label>
          <input
            type='file'
            onChange={handleFileChange}
            className='block w-full text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-md file:border-0
              file:text-sm file:font-medium
              file:bg-primary-50 file:text-primary-700
              hover:file:bg-primary-100'
            accept='.pdf,.doc,.docx'
          />
          {isSubmitting && uploadProgress > 0 && (
            <div className='mt-2'>
              <div className='bg-neutral-200 rounded-full h-2.5'>
                <div
                  className='bg-brand-600 h-2.5 rounded-full'
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <div className='text-sm text-neutral-600 mt-1'>
                {uploadProgress}% uploaded
              </div>
            </div>
          )}
        </div>

        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>
            Accreditation Type
          </label>
          <DropdownInput
            value={formData.accreditation}
            onChange={e =>
              setFormData(prev => ({ ...prev, accreditation: e.target.value }))
            }
            options={accreditationTypes}
          />
        </div>

        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>
            Compliance Type
          </label>
          <DropdownInput
            value={formData.type}
            onChange={e =>
              setFormData(prev => ({ ...prev, type: e.target.value }))
            }
            options={complianceTypes}
          />
        </div>

        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>
            Status
          </label>
          <DropdownInput
            value={formData.status}
            onChange={e =>
              setFormData(prev => ({ ...prev, status: e.target.value }))
            }
            options={statusTypes}
          />
        </div>

        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>
            Expiry Date
          </label>
          <Input
            type='date'
            value={formData.expiryDate}
            onChange={e =>
              setFormData(prev => ({ ...prev, expiryDate: e.target.value }))
            }
          />
        </div>
      </form>
    </Popup>
  );
};

export default CreateCompliance;
