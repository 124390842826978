import React, { useState, useEffect } from 'react';
import TaskList from '../tasks/TaskList';
import CreateTask from '../../popups/tasks/CreateTask';
import CreateChecklist from '../../popups/tasks/CreateChecklist';
import { FilledButton, TextButton, Checkbox, SoftButton } from 'core';
import { Input, DropdownInput } from 'core';
import {
  Plus,
  ListChecks,
  AlertCircle,
  Search,
  ChevronDown,
  Settings
} from 'lucide-react';
import TaskTemplate from '../../../models/TaskTemplate';
import { supabase, realtime } from '../../../utilities/supabase';
import { FilterOperatorEnum } from '../../../utilities/Enumerables';

const TaskOverview = ({ entityType, entityId }) => {
  const [showCreateTaskPopup, setShowCreateTaskPopup] = useState(false);
  const [showCreateChecklistPopup, setShowCreateChecklistPopup] =
    useState(false);
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [overdueTasks, setOverdueTasks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [showCompletedTasks, setShowCompletedTasks] = useState(() => {
    const preferences = JSON.parse(
      localStorage.getItem('user_preferences') || '{}'
    );
    return preferences.showCompletedTasks ?? true;
  });
  const [showOnlyAssignedTasks, setShowOnlyAssignedTasks] = useState(() => {
    const preferences = JSON.parse(
      localStorage.getItem('user_preferences') || '{}'
    );
    return preferences.showOnlyAssignedTasks ?? false;
  });
  const [totalTasks, setTotalTasks] = useState(0);

  const fetchTaskTemplates = async () => {
    try {
      const templates = await TaskTemplate.getAll({
        entity_type: { value: entityType },
        title: { value: searchTerm, operator: FilterOperatorEnum.ILIKE }
      });
      setTaskTemplates(templates.data);
    } catch (error) {
      console.error('Error fetching task templates:', error);
    }
  };

  useEffect(() => {
    const fetchOverdueTasks = async () => {
      try {
        const { data, error } = await supabase
          .from('tasks')
          .select('*')
          .eq('entity_type', entityType)
          .eq('entity_id', entityId)
          .lt('due_date', new Date().toISOString());
        if (error) throw error;
        setOverdueTasks(data);
      } catch (error) {
        console.error('Error fetching overdue tasks:', error);
      }
    };

    const fetchTotalTasks = async () => {
      try {
        const { count, error } = await supabase
          .from('tasks')
          .select('*', { count: 'exact', head: true })
          .eq('entity_type', entityType)
          .eq('entity_id', entityId);

        if (error) throw error;
        setTotalTasks(count);
      } catch (error) {
        console.error('Error fetching total tasks:', error);
      }
    };

    fetchTaskTemplates();
    fetchOverdueTasks();
    fetchTotalTasks();

    const realtimeSubscription = realtime(
      '*',
      'tasks',
      () => {
        fetchTaskTemplates();
        fetchTotalTasks();
      },
      `entity_type=eq.${entityType}&entity_id=eq.${entityId}`
    );

    return () => {
      if (realtimeSubscription) {
        realtimeSubscription.unsubscribe();
      }
    };
  }, [entityType, entityId, searchTerm]);

  const handleCreateTaskClick = () => {
    setShowCreateTaskPopup(true);
  };

  const handleCreateChecklistClick = () => {
    setShowCreateChecklistPopup(true);
  };

  const handleCreateTaskClose = newTask => {
    setShowCreateTaskPopup(false);
    // You might want to update the tasks list here if a new task was created
  };

  const handleCreateChecklistClose = newChecklist => {
    setShowCreateChecklistPopup(false);
    fetchTaskTemplates();
  };

  const handleTemplateSelect = async template => {
    if (template) {
      try {
        setSelectedTemplate(template);

        const taskPayload = JSON.stringify([
          {
            priority: 1,
            status: 161,
            title: template.template.title,
            description: template.template.description || '',
            category: template.template.category || null,
            entity_type: entityType,
            entity_id: entityId,
            daysAfterCreation: template.template.daysAfterCreation
          },
          ...template.template.children.map(child => ({
            priority: 1,
            status: 161,
            title: child.title,
            description: child.description || '',
            category: child.category || null,
            entity_type: entityType,
            entity_id: entityId,
            daysAfterCreation: template.template.daysAfterCreation
          }))
        ]);

        const { data, error } = await supabase.rpc('insert_tasks', {
          json_payload: taskPayload
        });

        if (error) throw error;

        console.log('Tasks created successfully. Parent task ID:', data);
        // You might want to refresh the tasks list or show a success message here
      } catch (error) {
        console.error('Error creating tasks from template:', error);
        // Handle error (e.g., show error message to user)
      }
    } else {
      setSelectedTemplate(null);
    }
    setIsDropdownOpen(false);
  };

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  const updateUserPreferences = (key, value) => {
    const preferences = JSON.parse(
      localStorage.getItem('user_preferences') || '{}'
    );
    preferences[key] = value;
    localStorage.setItem('user_preferences', JSON.stringify(preferences));
  };

  const handleShowCompletedTasksChange = () => {
    const newValue = !showCompletedTasks;
    setShowCompletedTasks(newValue);
    updateUserPreferences('showCompletedTasks', newValue);
  };

  const handleShowOnlyAssignedTasksChange = () => {
    const newValue = !showOnlyAssignedTasks;
    setShowOnlyAssignedTasks(newValue);
    updateUserPreferences('showOnlyAssignedTasks', newValue);
  };

  return (
    <div className='relative w-full'>
      <div className='flex justify-between items-center mb-4 space-x-2'>
        <div className='flex items-center space-x-2'>
          <FilledButton
            onClick={handleCreateTaskClick}
            leftIcon={<Plus size={16} />}
          >
            Create Task
          </FilledButton>
          <div className='relative'>
            <SoftButton
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              rightIcon={<ChevronDown size={16} />}
            >
              Add Checklist
            </SoftButton>
            {isDropdownOpen && (
              <div className='absolute z-10 w-64 mt-1 bg-white border border-gray-300 rounded-md shadow-lg p-2 right-0'>
                <Input
                  type='text'
                  placeholder='Search templates...'
                  value={searchTerm}
                  onChange={handleSearchChange}
                  leftIcon={Search}
                />
                <div className='max-h-60 overflow-y-auto mt-2'>
                  {taskTemplates.length > 0 ? (
                    taskTemplates.map(template => (
                      <TextButton
                        key={template.id}
                        onClick={() => handleTemplateSelect(template)}
                        className='w-full text-left mb-2'
                      >
                        {template.title}
                      </TextButton>
                    ))
                  ) : (
                    <div className='text-center text-sm text-base-500 py-4'>
                      No templates found
                    </div>
                  )}
                </div>
                <SoftButton
                  onClick={handleCreateChecklistClick}
                  leftIcon={<ListChecks size={16} />}
                  className='w-full mt-2'
                >
                  Create New Template
                </SoftButton>
              </div>
            )}
          </div>
        </div>
        <div className='flex items-center space-x-2'>
          <span className='hidden sm:block text-sm text-base-500'>
            Total Tasks: {totalTasks}
          </span>
          <div className='relative'>
            <TextButton
              onClick={() => setIsOptionsOpen(!isOptionsOpen)}
              rightIcon={<Settings size={16} />}
            >
              <span className='hidden sm:block'>Options</span>
            </TextButton>
            {isOptionsOpen && (
              <div className='absolute z-10 w-64 mt-1 bg-white border border-gray-300 rounded-md shadow-lg p-2 top-full right-0'>
                <div className='flex items-center justify-between mb-2'>
                  <span className='text-sm'>Show completed tasks</span>
                  <Checkbox
                    checked={showCompletedTasks}
                    onChange={handleShowCompletedTasksChange}
                  />
                </div>
                <div className='flex items-center justify-between'>
                  <span className='text-sm'>Show only my assigned tasks</span>
                  <Checkbox
                    checked={showOnlyAssignedTasks}
                    onChange={handleShowOnlyAssignedTasksChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <TaskList
        entityType={entityType}
        entityId={entityId}
        showCompletedTasks={showCompletedTasks}
        showOnlyAssignedTasks={showOnlyAssignedTasks}
      />
      {showCreateTaskPopup && (
        <CreateTask
          onClose={handleCreateTaskClose}
          entityType={entityType}
          entityId={entityId}
        />
      )}
      {showCreateChecklistPopup && (
        <CreateChecklist
          onClose={handleCreateChecklistClose}
          entityType={entityType}
          entityId={entityId}
          template={selectedTemplate}
        />
      )}
    </div>
  );
};

export default TaskOverview;
