import React, { useEffect, useState } from 'react';
import { Popup, Input, DropdownInput, FilledButton, OutlineButton } from 'core';
import CourseOption from '../../../models/CourseOption';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import CoreEntityType from '../../../models/CoreEntityType';

const EditOption = ({ isOpen, onClose, option, onOptionUpdated }) => {
  const [formData, setFormData] = useState({
    courseDelivery: '',
    trainerId: '',
    locationId: '',
    price: '',
    cost: '',
    duration: '',
    validFor: '',
    status: ''
  });

  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (option) {
      setFormData({
        courseDelivery: option.courseDelivery || '',
        trainerId: option.trainerId || '',
        locationId: option.locationId || '',
        price: option.price || '',
        cost: option.cost || '',
        duration: option.duration || '',
        validFor: option.validFor || '',
        status: option.status?.id || ''
      });
    }
  }, [option]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [deliveryTypes, statuses] = await Promise.all([
          CoreEntityType.getByEntityId(EntityTypeEnum.CourseDelivery),
          CoreEntityType.getByEntityId(EntityTypeEnum.CourseOptionStatus)
        ]);

        setDeliveryTypes(
          deliveryTypes.map(type => ({
            value: type.id,
            label: type.type
          }))
        );
        setStatusOptions(
          statuses.map(status => ({
            value: status.id,
            label: status.type
          }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const updatedOption = new CourseOption({
        ...option,
        courseId: option.courseId,
        courseDelivery: formData.courseDelivery,
        trainerId: formData.trainerId,
        locationId: formData.locationId,
        price: formData.price,
        cost: formData.cost,
        duration: formData.duration,
        validFor: formData.validFor,
        status: { id: formData.status }
      });

      await updatedOption.update();

      if (onOptionUpdated) {
        onOptionUpdated(updatedOption);
      }

      onClose();
    } catch (error) {
      console.error('Error updating course option:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const footer = (
    <div className='flex justify-end space-x-2'>
      <OutlineButton colour='base' onClick={onClose}>
        Cancel
      </OutlineButton>
      <FilledButton onClick={handleSubmit} disabled={isLoading}>
        {isLoading ? 'Saving...' : 'Save Changes'}
      </FilledButton>
    </div>
  );

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title='Edit Course Option'
      footer={footer}
    >
      <div className='space-y-4'>
        <div className='flex gap-4'>
          <Input
            label='Price'
            type='number'
            name='price'
            value={formData.price}
            onChange={handleInputChange}
            inlineLeftAddon='£'
          />
          <Input
            label='Cost'
            type='number'
            name='cost'
            value={formData.cost}
            onChange={handleInputChange}
            inlineLeftAddon='£'
          />
        </div>

        <div className='flex gap-4'>
          <Input
            label='Duration'
            type='text'
            name='duration'
            value={formData.duration}
            onChange={handleInputChange}
          />
          <Input
            label='Valid For'
            type='text'
            name='validFor'
            value={formData.validFor}
            onChange={handleInputChange}
          />
        </div>

        <DropdownInput
          label='Status'
          name='status'
          value={formData.status}
          onChange={handleInputChange}
          options={statusOptions}
          placeholder='Select Status'
        />
      </div>
    </Popup>
  );
};

export default EditOption;
