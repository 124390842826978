import { useState } from 'react';

/**
 * Range Input Component
 *
 * @param {number} [props.min=0] - Minimum value of the range
 * @param {number} [props.max=100] - Maximum value of the range
 * @param {number} [props.step=1] - Step increment value
 * @param {number} [props.value=0] - Current value
 * @param {number} [props.placeholder=0] - Default placeholder value
 * @param {Function} props.onChange - Callback when value changes
 * @param {string} props.label - Label text for the range input
 * @param {string} [props.hint] - Hint text to display below
 * @param {boolean} [props.disabled=false] - Whether the input is disabled
 * @param {string} [props.size='md'] - Size of the input ('xs', 'sm', 'md', 'lg', 'xl')
 * @param {number[]} [props.intervals] - Array of values to show as interval labels
 * @returns {React.ReactElement} A range slider input with label and value display
 */
const RangeInput = ({
  min = 0,
  max = 100,
  step = 1,
  value = 0,
  placeholder = 0,
  onChange,
  label,
  labelHint,
  hint,
  disabled = false,
  size = 'md',
  className,
  intervals,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState(value || placeholder);

  const handleChange = e => {
    const newValue = parseInt(e.target.value);
    setCurrentValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleIntervalClick = value => {
    setCurrentValue(value);
    if (onChange) onChange(value);
  };

  const percentage = ((currentValue - min) / (max - min)) * 100;

  const sizeClasses = {
    xs: 'h-1',
    sm: 'h-1.5',
    md: 'h-2',
    lg: 'h-2.5',
    xl: 'h-3'
  };

  // Default intervals if none provided
  const displayIntervals = intervals || [min, max];

  return (
    <div className='w-full'>
      <div className='flex justify-between'>
        <label className='block text-sm font-medium leading-6 text-base-900'>
          {label}{' '}
          {/* <span className='text-base-500 text-xs font-regular ml-2'>test</span> */}
        </label>
        {labelHint && (
          <span className='text-sm leading-6 text-gray-500'>{labelHint}</span>
        )}
      </div>
      <div className='relative w-full h-2'>
        <span
          className='absolute inset-0 z-10 rounded-l-full h-full pointer-events-none bg-current'
          style={{ width: `${percentage}%` }}
        ></span>
        <input
          type='range'
          min={min}
          max={max}
          step={step}
          value={currentValue}
          onChange={handleChange}
          disabled={disabled}
          className={`w-full absolute inset-0 bg-transparent cursor-pointer focus:outline-none focus:outline-0 appearance-none 
            [-webkit-appearance:none] 
            [&::-webkit-slider-runnable-track]:h-full [&::-moz-range-track]:h-full 
            [&::-webkit-slider-runnable-track]:rounded-full [&::-moz-range-track]:rounded-full 
            [&::-webkit-slider-runnable-track]:bg-gray-100 [&::-moz-range-track]:bg-gray-100 
            [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:[-webkit-appearance:none] 
            [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:[-webkit-appearance:none] 
            [&::-moz-range-thumb]:rounded-full [&::-webkit-slider-thumb]:rounded-full 
            [&::-moz-range-thumb]:border-0 [&::-webkit-slider-thumb]:border-0 
            [&::-moz-range-thumb]:ring-2 [&::-webkit-slider-thumb]:ring-2 
            [&::-moz-range-thumb]:ring-current [&::-webkit-slider-thumb]:ring-current 
            [&::-moz-range-thumb]:bg-white [&::-webkit-slider-thumb]:bg-white 
            [&::-moz-range-thumb]:relative [&::-webkit-slider-thumb]:relative 
            [&::-moz-range-thumb]:z-20 [&::-webkit-slider-thumb]:z-20 
            [&::-moz-range-thumb]:w-3.5 [&::-webkit-slider-thumb]:w-3.5 
            [&::-moz-range-thumb]:h-3.5 [&::-webkit-slider-thumb]:h-3.5 
            [&::-moz-range-thumb]:-mt-[3px] [&::-webkit-slider-thumb]:-mt-[3px]
            ${sizeClasses[size]}
            ${className}
          `}
          {...props}
        />
      </div>
      {hint && <p className='mt-1 text-sm text-gray-500'>{hint}</p>}
      <div className='mt-1 mx-1.5 flex justify-between relative'>
        {displayIntervals.map((interval, index) => (
          <span
            key={interval}
            className='text-xs text-gray-500 cursor-pointer hover:text-gray-700'
            style={{
              position: 'absolute',
              left: `${((interval - min) / (max - min)) * 100}%`,
              transform: 'translateX(-50%)'
            }}
            onClick={() => handleIntervalClick(interval)}
          >
            {interval}
          </span>
        ))}
      </div>
    </div>
  );
};

export { RangeInput };
