import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Popup,
  FilledButton,
  SoftButton,
  ComboBox,
  StackedList,
  OutlineButton,
  TextButton
} from 'core';
import { supabase } from '../../../utilities/supabase';
import { X } from 'lucide-react';

const ManagePipelines = ({
  isOpen,
  onClose,
  entityType,
  entityId,
  entity
}) => {
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [entityPipelines, setEntityPipelines] = useState([]);
  const navigate = useNavigate();

  const handleSave = async () => {
    try {
      // Delete existing pipeline entities
      await supabase
        .from('pipeline_entities')
        .delete()
        .eq('entity_type', entityType)
        .eq('entity_id', entityId);

      // Insert new pipeline entities
      const pipelineEntities = entityPipelines.map(pipeline => ({
        pipeline_id: pipeline.id,
        entity_type: entityType,
        entity_id: entityId,
        group: 1,
        order: 0
      }));

      await supabase.from('pipeline_entities').insert(pipelineEntities);
      onClose();
    } catch (err) {
      console.error('Error saving pipelines:', err);
    }
  };

  const handleRemovePipeline = async pipelineId => {
    try {
      const updatedPipelines = entityPipelines.filter(
        pipeline => pipeline.id !== pipelineId
      );
      setEntityPipelines(updatedPipelines);
    } catch (err) {
      console.error('Error removing pipeline:', err);
    }
  };

  const handleViewPipeline = pipelineId => {
    navigate(`/pipelines/${pipelineId}`);
  };

  useEffect(() => {
    const fetchEntityPipelines = async () => {
      try {
        const { data, error } = await supabase
          .from('pipeline_entities')
          .select('*,pipelines(*)')
          .eq('entity_type', entityType)
          .eq('entity_id', entityId);

        if (error) throw error;

        const formattedPipelines = data.map(item => ({
          id: item.pipeline_id,
          title: item.pipelines.title
        }));

        setEntityPipelines(formattedPipelines);
      } catch (error) {
        console.error('Error fetching entity pipelines:', error);
      }
    };

    fetchEntityPipelines();
  }, [entityType, entityId]);

  const loadPipelineOptions = useCallback(async (query, page) => {
    try {
      const { data, error } = await supabase
        .from('pipelines')
        .select('id, title')
        .ilike('title', `%${query}%`)
        .eq('entity', entityType)
        .range((page - 1) * 10, page * 10 - 1);

      if (error) throw error;
      return data.map(pipeline => ({
        value: pipeline.id,
        label: pipeline.title
      }));
    } catch (error) {
      console.error('Error loading pipeline options:', error);
      return [];
    }
  }, [entityType]);

  const handleAddPipeline = useCallback(
    selectedOption => {
      if (
        selectedOption &&
        !entityPipelines.some(p => p.id === selectedOption.value)
      ) {
        setEntityPipelines(prevPipelines => [
          ...prevPipelines,
          { id: selectedOption.value, title: selectedOption.label }
        ]);
        setSelectedPipeline(null);
      }
    },
    [entityPipelines]
  );

  return (
    <Popup 
      isOpen={isOpen} 
      onClose={onClose} 
      title='Manage Pipelines'
      footer={
        <div className='flex justify-end'>
          <SoftButton onClick={onClose} variant='secondary' className='mr-2'>
            Cancel
          </SoftButton>
          <FilledButton onClick={handleSave} variant='primary'>
            Save
          </FilledButton>
        </div>
      }
    >
      <ComboBox
        loadOptions={loadPipelineOptions}
        label='Add Pipeline'
        placeholder='Search for pipelines'
        onChange={handleAddPipeline}
        value={selectedPipeline}
      />
      <div className='mt-4 h-60 overflow-y-auto'>
        {entityPipelines.length > 0 ? (
          <StackedList
            items={entityPipelines.map(pipeline => ({
              title: pipeline.title,
              action: (
                <>
                  <OutlineButton
                    size='sm'
                    colour='base'
                    className='mr-2'
                    onClick={() => handleViewPipeline(pipeline.id)}
                  >
                    View
                  </OutlineButton>
                  <TextButton
                    onClick={() => handleRemovePipeline(pipeline.id)}
                    colour='base'
                    size='sm'
                  >
                    <X size={18} />
                  </TextButton>
                </>
              )
            }))}
          />
        ) : (
          <div className='flex items-center justify-center h-full'>
            <p className='text-gray-500 text-md italic'>
              This entity does not have any active pipelines.
            </p>
          </div>
        )}
      </div>
    </Popup>
  );
};

export default ManagePipelines;
