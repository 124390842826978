import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import {
  FilledButton,
  SoftButton,
  Input,
  DropdownInput,
  Checkbox,
  MultiSelectBox,
  TextEditor
} from 'core';
import Course from '../../../models/Course';
import { UserProfileContext } from '../../../App';
import CoreEntityType from '../../../models/CoreEntityType';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';

const CreateCourse = () => {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  const [courseData, setCourseData] = useState({
    name: '',
    attendance: '',
    objectives: '',
    content: '',
    preReqs: '',
    entryReqs: '',
    passReqs: '',
    furtherDev: '',
    grant: false,
    accreditation: '',
    industries: [],
    websiteDetails: {
      visible: false,
      header_image: ''
    },
    status: null
  });

  const [industryOptions, setIndustryOptions] = useState([]);
  const [accreditationOptions, setAccreditationOptions] = useState([]);

  useEffect(() => {
    const fetchIndustryAndAccreditationData = async () => {
      try {
        const { data: industryData } = await CoreEntityType.getAll(
          {
            entity_id: {
              value: EntityTypeEnum.Industry,
              operator: FilterOperatorEnum.EQUALS
            }
          },
          1,
          100,
          'type'
        );

        const { data: accreditationData } = await CoreEntityType.getAll(
          {
            entity_id: {
              value: EntityTypeEnum.AccreditationBody,
              operator: FilterOperatorEnum.EQUALS
            }
          },
          1,
          100,
          'type'
        );

        setIndustryOptions(
          industryData.map(industry => ({
            value: industry.id,
            label: industry.type
          }))
        );

        setAccreditationOptions(
          accreditationData.map(acc => ({
            value: acc.id,
            label: acc.type
          }))
        );
      } catch (error) {
        console.error('Error fetching industry or accreditation data:', error);
      }
    };

    fetchIndustryAndAccreditationData();
  }, []);

  const handleInputChange = (field, value) => {
    setCourseData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (navigateToCourse = false) => {
    try {
      const newCourse = new Course({
        name: courseData.name,
        attendance: courseData.attendance,
        objectives: courseData.objectives,
        content: courseData.content,
        preReqs: courseData.preReqs,
        entryReqs: courseData.entryReqs,
        passReqs: courseData.passReqs,
        furtherDev: courseData.furtherDev,
        grant: courseData.grant,
        accreditation: { id: courseData.accreditation },
        industries: courseData.industries.map(industry => industry.value),
        websiteDetails: courseData.websiteDetails,
        status: { id: 80 } //default to draft
      });

      const insertedCourse = await newCourse.insert();

      if (navigateToCourse) {
        navigate(`/courses/${insertedCourse.id}`);
      } else {
        navigate('/courses');
      }
    } catch (error) {
      console.error('Error creating course:', error);
    }
  };

  return (
    <div className='bg-base-100 h-full overflow-y-auto'>
      <div className='max-w-4xl mx-auto px-4 py-8'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={() => navigate('/courses')}
          className='mb-8'
        >
          Back to Courses
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-visible'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>Create New Course</h2>
          </div>

          {/* Stage 1: Basic Information */}
          <div className='p-6'>
            <div className='space-y-4'>
              <div>
                <Input
                  label='Course Title'
                  value={courseData.name}
                  onChange={e => handleInputChange('name', e.target.value)}
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500'
                />
              </div>

              <div>
                <DropdownInput
                  label='Accreditation'
                  options={accreditationOptions}
                  value={courseData.accreditation}
                  onChange={e =>
                    handleInputChange('accreditation', e.target.value)
                  }
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500'
                />
              </div>

              <div>
                <MultiSelectBox
                  label='Industries'
                  options={industryOptions}
                  selectedOptions={courseData.industries}
                  onChange={selectedItems =>
                    handleInputChange('industries', selectedItems)
                  }
                  placeholder='Select industries'
                  allowCreate={true}
                  size='sm'
                />
              </div>

              <div>
                <Checkbox
                  checked={courseData.grant}
                  onChange={e => handleInputChange('grant', e.target.checked)}
                  label='Grant Claimable'
                  description={
                    'Check this box if you are able to claim grants for this course.'
                  }
                  className='text-gray-700'
                />
              </div>

              <div>
                <Checkbox
                  checked={courseData.websiteDetails.visible}
                  onChange={e =>
                    handleInputChange('websiteDetails', {
                      ...courseData.websiteDetails,
                      visible: e.target.checked
                    })
                  }
                  label='Show on Website'
                  description={
                    'ADMIN: Please only check this box if you want this course to be visible on the public website.'
                  }
                  className='text-gray-700'
                />
              </div>
            </div>
          </div>

          {/* Stage 2: Detailed Information */}
          <div className='p-6'>
            <div className='space-y-4'>
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Content
                </label>
                <TextEditor
                  value={courseData.content}
                  onChange={newValue => handleInputChange('content', newValue)}
                  placeholder='Enter course content here...'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500'
                />
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Attendance
                </label>
                <TextEditor
                  value={courseData.attendance}
                  onChange={newValue =>
                    handleInputChange('attendance', newValue)
                  }
                  placeholder='Enter attendance details here...'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500'
                />
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Objectives
                </label>
                <TextEditor
                  value={courseData.objectives}
                  onChange={newValue =>
                    handleInputChange('objectives', newValue)
                  }
                  placeholder='Enter course objectives here...'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500'
                />
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Pre-Requisites
                </label>
                <TextEditor
                  value={courseData.preReqs}
                  onChange={newValue => handleInputChange('preReqs', newValue)}
                  placeholder='Enter pre-requisites here...'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500'
                />
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Entry Requirements
                </label>
                <TextEditor
                  value={courseData.entryReqs}
                  onChange={newValue =>
                    handleInputChange('entryReqs', newValue)
                  }
                  placeholder='Enter entry requirements here...'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500'
                />
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Pass Requirements
                </label>
                <TextEditor
                  value={courseData.passReqs}
                  onChange={newValue => handleInputChange('passReqs', newValue)}
                  placeholder='Enter pass requirements here...'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500'
                />
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Further Development
                </label>
                <TextEditor
                  value={courseData.furtherDev}
                  onChange={newValue =>
                    handleInputChange('furtherDev', newValue)
                  }
                  placeholder='Enter further development details here...'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500'
                />
              </div>
            </div>

            <div className='mt-8 flex justify-end space-x-4'>
              <SoftButton
                colour='base'
                size='lg'
                onClick={() => handleSubmit(false)}
              >
                Create
              </SoftButton>
              <FilledButton
                colour='primary'
                size='lg'
                onClick={() => handleSubmit(true)}
              >
                Create and Open
              </FilledButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCourse;
