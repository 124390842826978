import React, { useState, useEffect, useContext } from 'react';
import UserProfile from '../../../../models/UserProfile';
import {
  LogoLoader,
  Input,
  DropdownInput,
  ComboBox,
  Modal,
  FilledButton,
  SoftButton,
  PageHeading
} from 'core';
import {
  Search,
  MoreVertical,
  MessageSquare,
  User,
  UserPlus,
  XCircle
} from 'lucide-react';
import {
  FilterOperatorEnum,
  EntityTypeEnum
} from '../../../../utilities/Enumerables';
import { PERMISSIONS } from '../../../../utilities/Permissions';
import { Menu, MenuItem, MenuItems, MenuButton } from '@headlessui/react';
import { supabase } from '../../../../utilities/supabase';
import { UserProfileContext } from '../../../../App';

const TeamMembers = () => {
  const [loading, setLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { userProfile } = useContext(UserProfileContext);

  useEffect(() => {
    const loadTeamMembers = async () => {
      try {
        const { data } = await UserProfile.getAll({
          bohsa_admin: { value: true, operator: FilterOperatorEnum.IS }
        });
        setTeamMembers(data || []);
      } catch (err) {
        console.error('Failed to load team members:', err);
        setError('Failed to load team members. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    const loadPermissionGroups = async () => {
      try {
        const { data, error } = await supabase
          .from('permission_groups')
          .select('*');

        if (error) throw error;

        const groups = data.map(group => ({
          value: group.id,
          label: group.label,
          permissions: group.permissions,
          hierarchy: group.hierarchy
        }));

        setPermissionGroups(groups);
      } catch (err) {
        console.error('Failed to load permission groups:', err);
      }
    };

    loadTeamMembers();
    loadPermissionGroups();
  }, []);

  const handlePermissionChange = async (memberId, newPermissionGroupId) => {
    try {
      const permissionGroup = permissionGroups.find(
        g => g.value == newPermissionGroupId
      );
      if (!permissionGroup) throw new Error('Invalid permission group');

      const { data: userProfiles } = await UserProfile.getAll({
        id: { value: memberId }
      });
      const userProfile = userProfiles[0];
      if (!userProfile) throw new Error('User not found');
      userProfile.role = { id: newPermissionGroupId };
      await userProfile.update();

      // Refresh team members list
      const { data } = await UserProfile.getAll({
        bohsa_admin: { value: true, operator: FilterOperatorEnum.IS }
      });
      setTeamMembers(data || []);
    } catch (err) {
      console.error('Failed to update permissions:', err);
    }
  };

  const loadUserOptions = async (query, page) => {
    try {
      const { data } = await UserProfile.getAll(
        {
          fts: { value: query, operator: FilterOperatorEnum.TEXTSEARCH },
          id: {
            operator: FilterOperatorEnum.NOT_IN,
            value: `(${teamMembers.map(member => member.id).join(',')})`
          }
        },
        page
      );
      return data.map(user => ({
        value: user.id,
        label: user.fullName,
        secondaryLabel: user.email
      }));
    } catch (err) {
      console.error('Failed to load users:', err);
      return [];
    }
  };

  const handleInviteUsers = async () => {
    try {
      // Validate that all users have permission groups selected
      const hasInvalidUsers = selectedUsers.some(user => !user.permissionGroup);
      if (hasInvalidUsers) {
        throw new Error('Please select permission groups for all users');
      }

      // Update each user with their selected permission group
      for (const user of selectedUsers) {
        const { data: userProfiles } = await UserProfile.getAll({
          id: { value: user.value }
        });
        const userProfile = userProfiles[0];
        if (!userProfile) continue;

        userProfile.bohsaAdmin = true;
        userProfile.role = { id: user.permissionGroup };

        await userProfile.update();
      }

      // Refresh team members list
      const { data } = await UserProfile.getAll({
        bohsa_admin: { value: true, operator: FilterOperatorEnum.IS }
      });
      setTeamMembers(data || []);
      setIsInviteModalOpen(false);
      setSelectedUsers([]);
    } catch (err) {
      console.error('Failed to invite users:', err);
    }
  };

  const filteredMembers = teamMembers.filter(
    member =>
      member.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      member.email?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (error) {
    return <div className='p-4 text-red-500'>{error}</div>;
  }

  return (
    <div className='container mx-auto flex flex-col h-full'>
      <PageHeading
        title='Team Members'
        pages={[
          { name: 'Settings', href: '/settings' },
          { name: 'Team Members', href: '/settings/team-members' }
        ]}
        actions={
          userProfile.hasPermission(
            EntityTypeEnum.Settings,
            PERMISSIONS[EntityTypeEnum.Settings].CREATE_TEAMS
          ) && (
            <FilledButton onClick={() => setIsInviteModalOpen(true)}>
              Invite Users
            </FilledButton>
          )
        }
      />

      <div className='py-6'>
        <div className='mb-6'>
          <Input
            placeholder='Search team members...'
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            leftIcon={Search}
          />
        </div>

        <Modal
          isOpen={isInviteModalOpen}
          onClose={() => {
            setIsInviteModalOpen(false);
            setSelectedUsers([]);
          }}
          title='Invite Team Members'
          actions={[
            {
              label: 'Invite',
              onClick: handleInviteUsers,
              style: 'primary',
              disabled: selectedUsers.length === 0
            },
            {
              label: 'Cancel',
              onClick: () => {
                setIsInviteModalOpen(false);
                setSelectedUsers([]);
              },
              style: 'base'
            }
          ]}
        >
          <div className='space-y-4'>
            <ComboBox
              label='Search and Select Users'
              placeholder='Type to search users...'
              loadOptions={loadUserOptions}
              value={null}
              onChange={user => {
                if (!selectedUsers.find(u => u.value === user.value)) {
                  setSelectedUsers([
                    ...selectedUsers,
                    { ...user, permissionGroup: '' }
                  ]);
                }
              }}
              className='mb-4'
            />

            {selectedUsers.length > 0 && (
              <div className='space-y-3'>
                <label className='block text-sm font-medium text-gray-700'>
                  Selected Users
                </label>
                {selectedUsers.map((user, index) => (
                  <div
                    key={user.value}
                    className='flex items-center gap-x-3 p-3 bg-gray-50 rounded-md'
                  >
                    <div className='flex-1'>
                      <p className='text-sm font-medium text-gray-900'>
                        {user.label}
                      </p>
                      <p className='text-sm text-gray-500'>
                        {user.secondaryLabel}
                      </p>
                    </div>
                    <DropdownInput
                      options={permissionGroups.map(group => ({
                        ...group,
                        disabled: group.hierarchy > userProfile.role.hierarchy
                      }))}
                      value={user.permissionGroup}
                      onChange={e => {
                        const updatedUsers = [...selectedUsers];
                        updatedUsers[index] = {
                          ...user,
                          permissionGroup: e.target.value
                        };
                        setSelectedUsers(updatedUsers);
                      }}
                      size='sm'
                      className='w-48'
                    />
                    <button
                      onClick={() => {
                        setSelectedUsers(
                          selectedUsers.filter(u => u.value !== user.value)
                        );
                      }}
                      className='p-1 text-gray-400 hover:text-gray-500'
                    >
                      <XCircle className='w-5 h-5' />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Modal>

        <ul
          role='list'
          className='divide-y divide-gray-100 bg-white shadow rounded-lg'
        >
          {filteredMembers.length === 0 ? (
            <div className='text-center py-8 text-gray-500'>
              No team members found
            </div>
          ) : (
            filteredMembers.map(member => (
              <li
                key={member.id}
                className='flex justify-between gap-x-6 p-4 sm:p-6'
              >
                <div className='flex min-w-0 gap-x-4'>
                  {member.profilePhoto ? (
                    <img
                      src={member.profilePhoto}
                      alt=''
                      className='h-12 w-12 flex-none rounded-full bg-gray-50'
                    />
                  ) : (
                    <div className='h-12 w-12 flex-none rounded-full bg-base-200 flex items-center justify-center'>
                      {member.firstName?.charAt(0).toUpperCase()}
                    </div>
                  )}
                  <div className='min-w-0 flex-auto'>
                    <p className='text-sm/6 font-semibold text-gray-900'>
                      {member.fullName}
                    </p>
                    <p className='mt-1 flex text-xs/5 text-gray-500'>
                      <a
                        href={`mailto:${member.email}`}
                        className='truncate hover:underline'
                      >
                        {member.email}
                      </a>
                    </p>
                  </div>
                </div>
                <div className='flex shrink-0 items-center gap-x-6'>
                  <div className='hidden sm:flex sm:flex-col sm:items-end'>
                    <DropdownInput
                      disabled={
                        !userProfile.hasPermission(
                          EntityTypeEnum.Settings,
                          PERMISSIONS[EntityTypeEnum.Settings].EDIT_TEAMS
                        ) || userProfile.role.hierarchy < member.role.hierarchy
                      }
                      options={permissionGroups.map(group => ({
                        ...group,
                        disabled: group.hierarchy > userProfile.role.hierarchy
                      }))}
                      value={member.role.id}
                      onChange={e =>
                        handlePermissionChange(member.id, e.target.value)
                      }
                      size='sm'
                      className='max-w-48'
                    />
                  </div>
                  <Menu as='div' className='relative flex-none'>
                    <MenuButton className='-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900'>
                      <span className='sr-only'>Open options</span>
                      <MoreVertical className='h-5 w-5' aria-hidden='true' />
                    </MenuButton>
                    <MenuItems className='absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                      <MenuItem>
                        {({ active }) => (
                          <a
                            href='#'
                            className={`block px-3 py-1 text-sm/6 text-gray-900 ${
                              active ? 'bg-gray-50' : ''
                            }`}
                          >
                            <div className='flex items-center gap-2'>
                              <User className='h-4 w-4' />
                              View profile
                            </div>
                          </a>
                        )}
                      </MenuItem>
                      <MenuItem>
                        {({ active }) => (
                          <a
                            href='#'
                            className={`block px-3 py-1 text-sm/6 text-gray-900 ${
                              active ? 'bg-gray-50' : ''
                            }`}
                          >
                            <div className='flex items-center gap-2'>
                              <MessageSquare className='h-4 w-4' />
                              Message
                            </div>
                          </a>
                        )}
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                </div>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
};

export default TeamMembers;
