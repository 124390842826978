import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ExternalLink, Check, Upload } from 'lucide-react';
import { TextButton, Badge, Field } from 'core';
import Accordion from './Accordion';
import CoreEntity from '../../models/CoreEntity';
import { UserProfileContext } from '../../App';
import { EntityTypeEnum } from '../../utilities/Enumerables';

const EntityCard = ({
  entityId,
  entityType,
  infoItems,
  accordionItems,
  data,
  onUpdate
}) => {
  const navigate = useNavigate();

  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [coreEntity, setCoreEntity] = useState({});
  const userProfile = React.useContext(UserProfileContext);
  const [profileImage, setProfileImage] = useState(data?.profilePhoto || null);

  useEffect(() => {
    const fetchEntityType = async () => {
      try {
        const coreEntity = await CoreEntity.getById(entityType);
        setCoreEntity(coreEntity);
      } catch (error) {
        console.error('Error fetching entity type:', error);
        setCoreEntity('Entity');
      }
    };

    fetchEntityType();
  }, [entityType]);

  const handleCopyId = () => {
    navigator.clipboard.writeText(entityId);
    setShowCopySuccess(true);
    setTimeout(() => {
      setShowCopySuccess(false);
    }, 1000);
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const handleImageUpload = async e => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result;
        setProfileImage(base64String);
        await onUpdate('profilePhoto', base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className='bg-white dark:bg-base-900 border-r border-neutral-200 h-full overflow-y-auto flex flex-col w-full'>
      <div className='p-2 flex justify-between items-center mb-2'>
        <div className='flex items-center'>
          <TextButton
            onClick={() => {
              let path = `/${coreEntity.tableName.replaceAll('_', '-')}`;
              if (path.includes('/import-')) {
                path = `${path.replace('-', 's/')}`;
              }
              navigate(path);
            }}
            className='mr-3'
            leftIcon={<ArrowLeft size={20} />}
            colour='base'
          />
          <h3 className='font-regular m-0'>{coreEntity.displayName}</h3>
          <div className='cursor-pointer ml-2'>
            <Badge
              colour={showCopySuccess ? 'success' : 'primary'}
              size='sm'
              rightIcon={showCopySuccess ? Check : null}
              onClick={handleCopyId}
            >
              {showCopySuccess ? 'Copied!' : `ID: ${entityId}`}
            </Badge>
          </div>
        </div>
        <div className='flex items-center'>
          <TextButton
            onClick={() => window.open(window.location.href, '_blank')}
            className='hover:text-brand-700'
            leftIcon={<ExternalLink size={20} />}
            colour='base'
          />
        </div>
      </div>
      {entityType === EntityTypeEnum.User && (
        <div className='px-4 mb-4 flex flex-col items-center'>
          <div className='relative w-24 h-24 rounded-full overflow-hidden mb-2'>
            {profileImage ? (
              <img
                src={profileImage}
                alt='Profile'
                className='w-full h-full object-cover'
              />
            ) : (
              <div className='w-full h-full bg-gray-200 flex items-center justify-center'>
                <Upload size={24} className='text-gray-400' />
              </div>
            )}
          </div>
          <label className='cursor-pointer text-sm text-brand-600 hover:text-brand-700'>
            <input
              type='file'
              accept='image/*'
              className='hidden'
              onChange={handleImageUpload}
            />
            Change Photo
          </label>
        </div>
      )}
      <div className='px-4'>
        {infoItems.map((item, index) => (
          <Field
            key={index}
            Icon={item.icon}
            label={item.label}
            value={getNestedValue(data, item.displayField || item.field)}
            displayField={item.displayField || item.field}
            editField={item.editField || item.field}
            dataType={item.dataType}
            options={item.options}
            loadOptions={item.loadOptions}
            isEditable={item.isEditable}
            onUpdate={onUpdate}
            inlineLabel={item.inlineLabel}
            inlineEditor={item.inlineEditor}
            extension={item.extension}
            userProfile={userProfile}
          />
        ))}
      </div>
      <div className='mt-2'>
        <Accordion items={accordionItems} data={data} onUpdate={onUpdate} />
      </div>
    </div>
  );
};

export default EntityCard;
