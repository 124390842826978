import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { FilledButton, SoftButton, Input, Checkbox } from 'core';
import UserProfile from '../../../models/UserProfile';
import { UserProfileContext } from '../../../App';

const CreateUser = () => {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phones: [],
    active: true,
    bohsaAdmin: false,
    companyAdmin: false,
    metrics: {}
  });

  const handleInputChange = (field, value) => {
    setUserData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (navigateToUser = false) => {
    try {
      const newUser = new UserProfile({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phones: userData.phones,
        active: userData.active,
        bohsaAdmin: userData.bohsaAdmin,
        companyAdmin: userData.companyAdmin,
        metrics: userData.metrics,
        role: { id: 1 }
      });

      const insertedUser = await newUser.insert();

      if (navigateToUser) {
        navigate(`/user-profiles/${insertedUser.displayId}`);
      } else {
        navigate('/user-profiles');
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  return (
    <div className='bg-base-100 h-full overflow-y-auto'>
      <div className='max-w-4xl mx-auto px-4 py-8'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={() => navigate('/user-profiles')}
          className='mb-8'
        >
          Back to Users
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-visible'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>Create New User</h2>
          </div>

          <div className='p-6'>
            <div className='space-y-4'>
              <div>
                <Input
                  label='First Name'
                  value={userData.firstName}
                  onChange={e => handleInputChange('firstName', e.target.value)}
                  className='mt-1 block w-full'
                />
              </div>

              <div>
                <Input
                  label='Last Name'
                  value={userData.lastName}
                  onChange={e => handleInputChange('lastName', e.target.value)}
                  className='mt-1 block w-full'
                />
              </div>

              <div>
                <Input
                  label='Email'
                  type='email'
                  value={userData.email}
                  onChange={e => handleInputChange('email', e.target.value)}
                  className='mt-1 block w-full'
                />
              </div>

              <div>
                <Checkbox
                  checked={userData.bohsaAdmin}
                  onChange={e =>
                    handleInputChange('bohsaAdmin', e.target.checked)
                  }
                  label='BOHSA Admin'
                  className='text-gray-700'
                />
              </div>

              <div>
                <Checkbox
                  checked={userData.companyAdmin}
                  onChange={e =>
                    handleInputChange('companyAdmin', e.target.checked)
                  }
                  label='Company Admin'
                  className='text-gray-700'
                />
              </div>
            </div>

            <div className='mt-8 flex justify-end space-x-4'>
              <SoftButton
                colour='base'
                size='lg'
                onClick={() => handleSubmit(false)}
              >
                Create
              </SoftButton>
              <FilledButton
                colour='primary'
                size='lg'
                onClick={() => handleSubmit(true)}
              >
                Create and Open
              </FilledButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
