import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Trainer from '../../../models/Trainer';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import {
  Star,
  Pencil,
  Trash,
  SquareArrowOutUpRight,
  Check
} from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import CoreEntityType from '../../../models/CoreEntityType';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { UserProfileContext } from '../../../App';
import { Alert } from 'core';

const Trainers = () => {
  // Set the page title
  document.title = 'Trainers | CRM | BOHSA LTD';
  const navigate = useNavigate();
  const [statusOptions, setStatusOptions] = useState([]);

  const { userProfile: currentUser } = useContext(UserProfileContext);
  //Test user permissions for view access
  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Trainer,
      PERMISSIONS[EntityTypeEnum.Trainer].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view trainers.'
        style='danger'
      />
    );
  }

  const userCanEdit = currentUser.hasPermission(
    EntityTypeEnum.User,
    PERMISSIONS[EntityTypeEnum.Trainer].EDIT
  );

  const userCanDelete = currentUser.hasPermission(
    EntityTypeEnum.User,
    PERMISSIONS[EntityTypeEnum.Trainer].DELETE
  );

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const { data: statuses } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.TrainerStatus }
        });
        setStatusOptions(
          statuses.map(status => ({ key: status.id, value: status.type }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/trainers/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/trainers/${data.id}`)
    },
    userCanDelete && {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: async data => {
        try {
          const trainer = await Trainer.getById(data.id);
          trainer.status = { id: 107 };
          await trainer.update();
          console.log('Trainer status set to archived', data);
        } catch (error) {
          console.error('Error setting trainer status to archived:', error);
        }
      }
    }
  ];

  const handleRowClick = data => {
    navigate(`/trainers/${data.id}`);
  };

  const handleNewClick = data => {
    navigate(`/trainers/create`);
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Trainers'
        filter={[
          {
            field: 'id',
            label: 'ID',
            type: 'text',
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'name',
            label: 'Trainer Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions,
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'approved',
            label: 'Approved',
            type: 'boolean',
            operator: FilterOperatorEnum.IS
          },
          {
            field: 'updated_date_from',
            label: 'Updated From',
            type: 'date',
            operator: FilterOperatorEnum.GREATER_THAN_OR_EQUAL
          },
          {
            field: 'updated_date_to',
            label: 'Updated To',
            type: 'date',
            operator: FilterOperatorEnum.LESS_THAN_OR_EQUAL
          }
        ]}
        sort={[
          { value: 'name', label: 'Trainer Name' },
          { value: 'updated_date', label: 'Last Modified' },
          { value: 'rating', label: 'Rating' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/trainers/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Trainer Name', field: 'name' },
          {
            label: 'Status',
            field: 'status.name',
            foreignKey: { table: 'core_entity_types', column: 'type' }
          },
          {
            label: 'Approved',
            field: 'approved',
            cell: value => (value ? 'Yes' : 'No')
          },
          {
            label: 'Rating',
            field: 'rating',
            cell: value => {
              const stars = [];
              for (let i = 0; i < 5; i++) {
                stars.push(
                  i < value ? (
                    <Star key={i} size={18} fill='#FFD700' color='#FFD700' />
                  ) : (
                    <Star key={i} size={18} color='#D3D3D3' />
                  )
                );
              }
              return <div className='flex'>{stars}</div>;
            }
          },
          {
            label: 'NVQ',
            field: 'options.nvq',
            sortable: false,
            cell: (value, row) => (row.options.nvq ? <Check size={18} /> : null)
          },
          {
            label: 'Onsite',
            field: 'options.onsite',
            sortable: false,
            cell: (value, row) =>
              row.options.onsite ? <Check size={18} /> : null
          },
          {
            label: 'Remote',
            field: 'options.remote',
            sortable: false,
            cell: (value, row) =>
              row.options.remote ? <Check size={18} /> : null
          },
          {
            label: 'Classroom',
            field: 'options.classroom',
            sortable: false,
            cell: (value, row) =>
              row.options.classroom ? <Check size={18} /> : null
          },
          {
            label: 'E-learning',
            field: 'options.elearning',
            sortable: false,
            cell: (value, row) =>
              row.options.elearning ? <Check size={18} /> : null
          },
          {
            label: 'Venue Count',
            field: 'options.venue_count',
            sortable: false
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Trainer}
        entityType={EntityTypeEnum.Trainer}
        onRowClick={handleRowClick}
        canCreate={true}
        onNewClick={handleNewClick}
      />
    </div>
  );
};

export default Trainers;
