import BaseModel from './BaseModel';

/**
 * Represents a CoreEntity in the system.
 * @extends BaseModel
 */
class CoreEntity extends BaseModel {
  /** @type {string} The name of the database table for CoreEntities */
  static table = 'core_entities';

  /** @type {string} The SQL query to select CoreEntity data */
  static selectQuery = '*';

  /**
   * Creates a CoreEntity instance from database format data
   * @param {Object} data - The database format core entity data
   * @returns {CoreEntity} A new CoreEntity instance
   */
  static fromDatabase(data = {}) {
    const coreEntity = new CoreEntity();
    coreEntity.id = data.id;
    coreEntity.createdDate = data.created_date;
    coreEntity.updatedDate = data.updated_date;
    coreEntity.displayName = data.display_name;
    coreEntity.tableName = data.table_name;
    coreEntity.metadata = data.metadata || {};
    return coreEntity;
  }

  /**
   * Creates an instance of CoreEntity.
   * @param {Object} data - The core entity data.
   */
  constructor(data = {}) {
    super(data);
    this.displayName = data.display_name || null;
    this.tableName = data.table_name || null;
    this.metadata = data.metadata || {};
  }

  /**
   * Converts the CoreEntity instance to a database-friendly format.
   * @returns {Object} The core entity data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      display_name: this.displayName,
      table_name: this.tableName
    };
  }

  /**
   * Fetch a CoreEntity by its table name.
   * @param {string} tableName - The table name to fetch.
   * @returns {Promise<CoreEntity>} A promise that resolves to a new instance of CoreEntity.
   */
  static async getByTableName(tableName) {
    return this.fetchOne('table_name', tableName);
  }
}

export default CoreEntity;
