import BaseModel from './BaseModel';

/**
 * Represents a Filter in the system.
 * @extends BaseModel
 */
class Filter extends BaseModel {
  /** @type {string} The name of the database table for Filters */
  static table = 'filters';

  /** @type {string} The SQL query to select Filter data */
  static selectQuery = '*';

  /**
   * Creates a Filter instance from database format data
   * @param {Object} data - The database format filter data
   * @returns {Filter} A new Filter instance
   */
  static fromDatabase(data = {}) {
    const filter = new Filter();
    filter.id = data.id;
    filter.createdDate = data.created_date;
    filter.updatedDate = data.updated_date;
    filter.owner = data.owner;
    filter.status = data.status;
    filter.entityType = data.entity_type;
    filter.filterData = data.filter_data || {};
    filter.name = data.name;
    return filter;
  }

  /**
   * Creates an instance of Filter.
   * @param {Object} data - The filter data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner ?? this.owner ?? null;
    this.status = data.status ?? this.status ?? null;
    this.entityType = data.entityType ?? this.entityType ?? null;
    this.filterData = data.filterData ?? this.filterData ?? {};
    this.name = data.name ?? this.name ?? null;
  }

  /**
   * Converts the Filter instance to a database-friendly format.
   * @returns {Object} The filter data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner,
      status: this.status,
      entity_type: this.entityType,
      filter_data: this.filterData,
      name: this.name
    };
  }
}

export default Filter;
