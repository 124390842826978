import BaseModel from './BaseModel';
import UserProfile from './UserProfile';

/**
 * Represents an Invoice in the system.
 * @extends BaseModel
 */
class Invoice extends BaseModel {
  /** @type {string} The name of the database table for Invoices */
  static table = 'invoices';

  /** @type {string} The SQL query to select Invoice data with related information */
  static selectQuery = `
    *,
    status:core_entity_types!status(id,type,metadata),
    companies!inner(name),
    invoice_address:locations(*,full_address)
  `;

  /**
   * Creates an Invoice instance from database format data
   * @param {Object} data - The database format invoice data
   * @returns {Invoice} A new Invoice instance
   */
  static fromDatabase(data = {}) {
    const invoice = new Invoice();
    invoice.id = data.id;
    invoice.createdDate = data.created_date;
    invoice.updatedDate = data.updated_date;
    invoice.company = data.company_id
      ? { id: data.company_id, name: data.companies?.name }
      : null;
    invoice.status = data.status
      ? { id: data.status.id, type: data.status.type }
      : null;
    invoice.reference = data.reference;
    invoice.issueDate = data.issue_date;
    invoice.dueDate = data.due_date;
    invoice.publishedDate = data.published_date;
    invoice.xero = data.xero;
    invoice.addressId = data.address_id;
    invoice.invoiceAddress = data.invoice_address;
    invoice.billingAddress = data.billing_address;
    invoice.subtotal = data.subtotal;
    invoice.totalTax = data.total_tax;
    invoice.total = data.total;
    return invoice;
  }

  /**
   * Creates an instance of Invoice.
   * @param {Object} data - The invoice data.
   */
  constructor(data = {}) {
    super(data);
    this.company = data.company ?? this.company ?? null;
    this.status = data.status ?? this.status ?? null;
    this.reference = data.reference ?? this.reference ?? null;
    this.issueDate = data.issueDate ?? this.issueDate ?? null;
    this.dueDate = data.dueDate ?? this.dueDate ?? null;
    this.publishedDate = data.publishedDate ?? this.publishedDate ?? null;
    this.xero = data.xero ?? this.xero ?? null;
    this.addressId = data.addressId ?? this.addressId ?? null;
    this.billingAddress = data.billingAddress ?? this.billingAddress ?? null;
    this.subtotal = data.subtotal ?? this.subtotal ?? null;
    this.totalTax = data.totalTax ?? this.totalTax ?? null;
    this.total = data.total ?? this.total ?? null;
  }

  /**
   * Converts the Invoice instance to a database-friendly format.
   * @returns {Object} The invoice data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      company_id: this.company ? this.company.id : null,
      address_id: this.addressId,
      status: this.status?.id,
      billing_address: this.billingAddress,
      created_date: this.createdDate,
      updated_date: this.updatedDate
    };
  }
}

export default Invoice;
