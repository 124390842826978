import BaseModel from './BaseModel';
import UserProfile from './UserProfile';
import CoreEntityType from './CoreEntityType';
import CoreEntity from './CoreEntity';

/**
 * Represents a Meeting in the system.
 * @extends BaseModel
 */
class Meeting extends BaseModel {
  /** @type {string} The name of the database table for Meetings */
  static table = 'meetings';

  /** @type {string} The SQL query to select Meeting data with related information */
  static selectQuery = `
    *,
    owner:user_profiles(*),
    category:core_entity_types(*),
    entity_type:core_entities(*)
  `;

  /**
   * Creates a Meeting instance from database format data
   * @param {Object} data - The database format meeting data
   * @returns {Meeting} A new Meeting instance
   */
  static fromDatabase(data = {}) {
    const meeting = new Meeting();
    meeting.id = data.id;
    meeting.createdDate = data.created_date;
    meeting.updatedDate = data.updated_date;
    meeting.owner = data.owner ? UserProfile.fromDatabase(data.owner) : null;
    meeting.title = data.title;
    meeting.description = data.description;
    meeting.category = data.category
      ? CoreEntityType.fromDatabase(data.category)
      : null;
    meeting.status = data.status;
    meeting.entityType = data.entity_type
      ? CoreEntity.fromDatabase(data.entity_type)
      : null;
    meeting.entityId = data.entity_id;
    meeting.attendees = data.attendees || [];
    meeting.location = data.location;
    meeting.startDate = data.start_date;
    meeting.endDate = data.end_date;
    return meeting;
  }

  /**
   * Creates an instance of Meeting.
   * @param {Object} data - The meeting data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner ?? this.owner ?? null;
    this.title = data.title ?? this.title ?? null;
    this.description = data.description ?? this.description ?? null;
    this.category = data.category ?? this.category ?? null;
    this.status = data.status ?? this.status ?? null;
    this.entityType = data.entityType ?? this.entityType ?? null;
    this.entityId = data.entityId ?? this.entityId ?? null;
    this.attendees = data.attendees ?? this.attendees ?? [];
    this.location = data.location ?? this.location ?? null;
    this.startDate = data.startDate ?? this.startDate ?? null;
    this.endDate = data.endDate ?? this.endDate ?? null;
  }

  /**
   * Converts the Meeting instance to a database-friendly format.
   * @returns {Object} The meeting data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner ? this.owner.id : null,
      title: this.title,
      description: this.description,
      category: this.category ? this.category.id : null,
      status: this.status,
      entity_type: this.entityType ? this.entityType.id : null,
      entity_id: this.entityId,
      attendees: this.attendees,
      location: this.location,
      start_date: this.startDate,
      end_date: this.endDate
    };
  }
}

export default Meeting;
