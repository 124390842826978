import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { FilledButton, SoftButton, Input, TextButton } from 'core';
import Trainer from '../../../models/Trainer';
import { UserProfileContext } from '../../../App';
import { supabase } from '../../../utilities/supabase';

const CreateTrainer = () => {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  const [trainerData, setTrainerData] = useState({
    name: '',
    capsuleId: '',
    accountNumber: '',
    creditLimit: '',
    terms: {
      days: 0,
      type: { id: 90 }
    },
    contacts: [{}],
    status: null,
    approved: false,
    rating: null,
    options: {},
    xeroAccountId: ''
  });

  const [xeroContacts, setXeroContacts] = useState([]);
  const [showXeroResults, setShowXeroResults] = useState(false);

  const handleInputChange = (field, value) => {
    setTrainerData(prev => ({ ...prev, [field]: value }));
  };

  const searchXeroContacts = async () => {
    try {
      const { data, error } = await supabase.functions.invoke(
        'xero/searchContacts',
        {
          body: { searchTerm: trainerData.name, isSupplier: true }
        }
      );

      if (error) throw error;
      console.log('Xero API response:', data);
      setXeroContacts(data);
      setShowXeroResults(true);
    } catch (error) {
      console.error('Error fetching Xero contacts:', error);
    }
  };

  const selectXeroContact = contact => {
    handleInputChange('xeroAccountId', contact.ContactID);
    if (contact.AccountNumber) {
      handleInputChange('accountNumber', contact.AccountNumber);
    }
    if (contact.Name) {
      handleInputChange('name', contact.Name);
    }
    if (contact.ContactID) {
      handleInputChange('xeroId', contact.ContactID);
    }
    setShowXeroResults(false);
  };

  const handleSubmit = async (navigateToTrainer = false) => {
    try {
      const newTrainer = new Trainer({
        name: trainerData.name,
        capsuleId: trainerData.capsuleId,
        accountNumber: trainerData.accountNumber,
        creditLimit: trainerData.creditLimit,
        terms: trainerData.terms,
        contacts: trainerData.contacts,
        status: trainerData.status,
        approved: trainerData.approved,
        rating: trainerData.rating,
        options: trainerData.options,
        xeroAccountId: trainerData.xeroAccountId
      });

      const insertedTrainer = await newTrainer.insert();

      if (navigateToTrainer) {
        navigate(`/trainers/${insertedTrainer.id}`);
      } else {
        navigate('/trainers');
      }
    } catch (error) {
      console.error('Error creating trainer:', error);
    }
  };

  return (
    <div className='bg-base-100 h-full overflow-y-auto'>
      <div className='max-w-4xl mx-auto px-4 py-8'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={() => navigate('/trainers')}
          className='mb-8'
        >
          Back to Trainers
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-visible'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>Create New Trainer</h2>
          </div>

          <div className='p-6'>
            <div className='space-y-4'>
              <div>
                <Input
                  label='Name'
                  value={trainerData.name}
                  onChange={e => handleInputChange('name', e.target.value)}
                  className='mt-1 block w-full'
                />
                <div className='text-xs'>
                  <TextButton size='sm' onClick={searchXeroContacts}>
                    Search Xero by Name
                  </TextButton>
                </div>
                {showXeroResults && xeroContacts.length > 0 && (
                  <div className='mt-2 p-2 border border-base-200 rounded-lg max-h-40 overflow-y-auto'>
                    {xeroContacts.map(contact => (
                      <div
                        key={contact.ContactID}
                        className='p-2 hover:bg-base-100 cursor-pointer text-sm'
                        onClick={() => selectXeroContact(contact)}
                      >
                        {contact.Name} - {contact.AccountNumber}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div>
                <Input
                  label='Capsule ID'
                  value={trainerData.capsuleId}
                  onChange={e => handleInputChange('capsuleId', e.target.value)}
                  className='mt-1 block w-full'
                />
              </div>

              <div>
                <Input
                  label='Account Number'
                  value={trainerData.accountNumber}
                  hint={`Xero ID - ${trainerData.xeroId}`}
                  onChange={e =>
                    handleInputChange('accountNumber', e.target.value)
                  }
                  className='mt-1 block w-full'
                />
              </div>

              <div>
                <Input
                  label='Credit Limit'
                  type='number'
                  value={trainerData.creditLimit}
                  onChange={e =>
                    handleInputChange('creditLimit', e.target.value)
                  }
                  className='mt-1 block w-full'
                />
              </div>
            </div>

            <div className='mt-8 flex justify-end space-x-4'>
              <SoftButton
                colour='base'
                size='lg'
                onClick={() => handleSubmit(false)}
              >
                Create
              </SoftButton>
              <FilledButton
                colour='primary'
                size='lg'
                onClick={() => handleSubmit(true)}
              >
                Create and Open
              </FilledButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTrainer;
