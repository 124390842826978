import React from 'react';
import { X } from 'lucide-react';
import { TextButton } from '../inputs/buttons/Button';

const Popup = ({ isOpen, onClose, title, children, footer, size = 'md' }) => {
  if (!isOpen) return null;

  const sizeClasses = {
    xs: 'max-w-md w-full sm:w-[448px]',
    sm: 'max-w-lg w-full sm:w-[512px]',
    md: 'max-w-3xl w-full sm:w-[768px]',
    lg: 'max-w-5xl w-full sm:w-[1024px]',
    xl: 'max-w-7xl w-full sm:w-[1280px]'
  };

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center overflow-y-auto'>
      <div
        className='fixed inset-0 bg-black bg-opacity-50'
        onClick={onClose}
      ></div>
      <div
        className={`relative ${sizeClasses[size]} z-10 max-h-[100vh] m-2 inset-y-4`}
      >
        <div className='relative flex flex-col w-full h-full bg-white border-0 rounded-xl shadow-lg outline-none focus:outline-none'>
          <div className='flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t'>
            <h3 className='text-2xl font-semibold'>{title}</h3>
            <TextButton onClick={onClose} colour='base'>
              <X className='w-6 h-6' />
            </TextButton>
          </div>
          <div className='relative p-6 flex-auto'>{children}</div>
          {footer && <div className='relative p-6'>{footer}</div>}
        </div>
      </div>
    </div>
  );
};

export { Popup };
