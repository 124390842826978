import BaseModel from './BaseModel';

/**
 * Represents a CourseOption in the system.
 * @extends BaseModel
 */
class CourseOption extends BaseModel {
  /** @type {string} The name of the database table for CourseOptions */
  static table = 'course_options';

  /** @type {string} The SQL query to select CourseOption data with related information */
  static selectQuery = `
    *,
    course:courses!course_options_course_id_fkey (id, name),
    delivery:core_entity_types!course_options_course_delivery_fkey (id, type),
    trainer:trainers!course_options_trainer_id_fkey (id, name),
    location:locations!course_options_location_id_fkey (id, name),
    status:core_entity_types!course_options_status_fkey (id, type)
  `;

  /**
   * Creates a CourseOption instance from database format data
   * @param {Object} data - The database format course option data
   * @returns {CourseOption} A new CourseOption instance
   */
  static fromDatabase(data = {}) {
    const courseOption = new CourseOption();
    courseOption.id = data.id;
    courseOption.createdDate = data.created_date;
    courseOption.updatedDate = data.updated_date;
    courseOption.courseId = data.course_id;
    courseOption.courseDelivery = data.course_delivery;
    courseOption.trainerId = data.trainer_id;
    courseOption.locationId = data.location_id;
    courseOption.price = data.price;
    courseOption.cost = data.cost;
    courseOption.duration = data.duration;
    courseOption.validFor = data.valid_for;
    courseOption.status = data.status
      ? { id: data.status.id, type: data.status.type }
      : null;

    // Related data
    courseOption.course = data.course
      ? { id: data.course.id, name: data.course.name }
      : null;
    courseOption.delivery = data.delivery
      ? { id: data.delivery.id, type: data.delivery.type }
      : null;
    courseOption.trainer = data.trainer
      ? { id: data.trainer.id, name: data.trainer.name }
      : null;
    courseOption.location = data.location
      ? { id: data.location.id, name: data.location.name }
      : null;
    return courseOption;
  }

  /**
   * Creates an instance of CourseOption.
   * @param {Object} data - The course option data.
   */
  constructor(data = {}) {
    super(data);
    this.courseId = data.courseId ?? this.courseId ?? null;
    this.courseDelivery = data.courseDelivery ?? this.courseDelivery ?? null;
    this.trainerId = data.trainerId ?? this.trainerId ?? null;
    this.locationId = data.locationId ?? this.locationId ?? null;
    this.price = data.price ?? this.price ?? null;
    this.cost = data.cost ?? this.cost ?? null;
    this.duration = data.duration ?? this.duration ?? null;
    this.validFor = data.validFor ?? this.validFor ?? null;
    this.status = data.status ?? this.status ?? null;

    // Related data
    this.course = data.course ?? this.course ?? null;
    this.delivery = data.delivery ?? this.delivery ?? null;
    this.trainer = data.trainer ?? this.trainer ?? null;
    this.location = data.location ?? this.location ?? null;
  }

  /**
   * Converts the CourseOption instance to a database-friendly format.
   * @returns {Object} The course option data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      course_id: this.courseId,
      course_delivery: this.courseDelivery,
      trainer_id: this.trainerId,
      location_id: this.locationId,
      price: this.price,
      cost: this.cost,
      duration: this.duration,
      valid_for: this.validFor,
      status: this.status ? this.status.id : null
    };
  }
}

export default CourseOption;
