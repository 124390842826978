import React, { useState, useEffect } from 'react';
import { supabase } from '../../../utilities/supabase';
import { FileText, Play, X } from 'lucide-react';

const DocflowsTab = ({ entityId, entityType, refresh, setRefresh }) => {
  const [audiences, setAudiences] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!refresh.includes('tab')) return;

    setRefresh('');

    fetchAudiences();
  }, [refresh]);

  useEffect(() => {
    fetchAudiences();
  }, [entityId, entityType]);

  const fetchAudiences = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase.functions.invoke(
        'audience/docflows/runs/search',
        {
          body: { entityType, entityId }
        }
      );

      if (error) throw error;

      setAudiences(data);
    } catch (error) {
      console.error('Error fetching audiences:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveAudience = async (runId, id) => {
    try {
      setAudiences(prevAudiences =>
        prevAudiences.filter(run => run.id != runId)
      );

      const { error } = await supabase.functions.invoke(
        'audience/docflows/runs/remove-object',
        {
          body: { id, runId }
        }
      );

      if (error) throw error;

      console.log('Audience removed successfully');

      setRefresh('icon');
    } catch (error) {
      console.error('Error removing audience:', error);
    }
  };

  return (
    <div className='space-y-2'>
      {loading ? (
        <div className='grid grid-cols-1 gap-2'>
          {[...Array(3)].map((_, index) => (
            <div
              key={index}
              className='flex items-center justify-between text-xs text-base-600 group py-1 px-2 rounded-md bg-base-100 transition-colors duration-200 cursor-pointer animate-pulse'
            >
              <div className='flex items-center space-x-2'>
                <span className='font-small text-sm text-base-900 bg-gray-300 rounded w-20 h-4'></span>
                <span className='text-sm text-gray-500 bg-gray-300 rounded w-32 h-4'></span>
              </div>
              <div className='text-base-600 bg-gray-300 rounded-full w-4 h-4'></div>
            </div>
          ))}
        </div>
      ) : (
        audiences.map(
          ({
            id,
            name,
            docflows_templates: { name: templateName },
            created_date,
            data
          }) => (
            <div
              key={id}
              className='flex items-center justify-between text-xs text-base-600 group py-1 px-2 rounded-md bg-base-100 hover:bg-brand-100 transition-colors duration-200 cursor-pointer'
            >
              <div className='flex items-center space-x-2'>
                <div className='flex items-center space-x-1'>
                  <FileText className='w-4 h-4 text-base-600' />
                  <div className='flex items-center space-x-3'>
                    <span className='font-small text-sm text-base-900'>
                      {templateName}
                    </span>
                    <Play className='w-4 h-4 text-base-600' />
                  </div>
                </div>
                <span className='font-small text-sm text-base-900'>
                  {name ||
                    `${id} (${new Date(created_date).toLocaleDateString()})`}
                </span>
              </div>
              <button
                className='text-base-600 hover:text-danger-600 transition-colors duration-200'
                onClick={() => handleRemoveAudience(id, data[0].id)}
              >
                <X />
              </button>
            </div>
          )
        )
      )}
      {!loading && audiences.length === 0 && (
        <p className='text-sm text-base-600'>No +Ai DocFlows available.</p>
      )}
    </div>
  );
};

export default DocflowsTab;
