import React, { useState, useEffect, useContext } from 'react';
import { Search, ChevronRight, Plus } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Input, SoftButton, FilledButton } from 'core';
import CoreEntityType from '../../../../models/CoreEntityType';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { getIconComponent } from '../../../../utilities/Formatting';
import Guide from '../../../../models/Guide';
import CreateDocCategory from '../../../../components/popups/help/CreateDocCategory';
import { UserProfileContext } from '../../../../App';
import { PERMISSIONS } from '../../../../utilities/Permissions';

export default function Docs() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [docCategories, setDocCategories] = useState([]);
  const [showCreateCategory, setShowCreateCategory] = useState(false);
  const { userProfile } = useContext(UserProfileContext);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Fetch categories using CoreEntityType model
        const categories = await CoreEntityType.getByEntityId(
          EntityTypeEnum.GuideCategories
        );

        // Transform categories into required format with metadata
        const formattedCategories = categories.map(category => ({
          title: category.type,
          description: category.description,
          icon: getIconComponent(category.metadata?.icon || 'Book'),
          link: `/help/docs/${
            category.metadata?.slug || category.type.toLowerCase()
          }`,
          colour: category.metadata?.colour || 'gray',
          disabled: category.metadata?.disabled || false
        }));

        setDocCategories(formattedCategories);
      } catch (error) {
        console.error('Error fetching doc categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearchChange = async e => {
    setSearchQuery(e.target.value);

    if (!e.target.value.trim()) {
      setSearchResults([]);
      return;
    }

    try {
      const { data: guides } = await Guide.getAll({
        content: {
          operator: FilterOperatorEnum.TEXTSEARCH,
          value: e.target.value
        }
      });

      setSearchResults(guides);
    } catch (error) {
      console.error('Error searching guides:', error);
      setSearchResults([]);
    }
  };

  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12'>
      <div className='text-left mb-12'>
        <div className='flex justify-between items-center mb-4'>
          <h1 className='text-4xl font-bold text-gray-900'>Documentation</h1>
          <FilledButton
            colour='primary'
            onClick={() => setShowCreateCategory(true)}
          >
            Create Category
          </FilledButton>
        </div>
        <p className='text-xl text-gray-500 mb-8'>
          Comprehensive guides and documentation to help you understand and use
          our platform
        </p>

        <div className='relative'>
          <Input
            placeholder='Search documentation...'
            value={searchQuery}
            onChange={handleSearchChange}
            leftIcon={Search}
            size='lg'
          />
          {searchQuery && searchResults.length > 0 && (
            <div className='absolute z-10 mt-1 w-full max-w-2xl bg-white rounded-lg shadow-lg border border-gray-200'>
              {searchResults.map(result => (
                <Link
                  key={result.id}
                  to={`/help/docs/${
                    result.category?.metadata?.slug ||
                    result.category?.type.toLowerCase()
                  }/${result.slug}`}
                  className='block px-4 py-2 hover:bg-gray-50'
                >
                  <div className='flex items-center justify-between'>
                    <div>
                      <div className='font-medium text-left'>
                        {result.title}
                      </div>
                      <div className='text-sm text-gray-500 text-left'>
                        {result.category.type}
                      </div>
                    </div>
                    <div className='text-gray-400'>
                      <ChevronRight size={16} />
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3'>
        {docCategories.map(category => (
          <div
            key={category.title}
            className={`relative group bg-white p-6 rounded-lg shadow-sm border border-gray-200 ${
              category.disabled
                ? 'opacity-60 cursor-not-allowed'
                : 'hover:shadow-md transition-all duration-200'
            }`}
          >
            {category.disabled ? (
              <div>
                <div
                  className={`inline-flex p-3 rounded-lg text-${category.colour}-600 bg-${category.colour}-50 mb-4`}
                >
                  <category.icon size={24} />
                </div>
                <div className='flex items-center gap-2 mb-2'>
                  <h3 className='text-xl font-semibold text-gray-900'>
                    {category.title}
                  </h3>
                  <span className='px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-600'>
                    Coming soon
                  </span>
                </div>
                <p className='text-gray-500'>{category.description}</p>
              </div>
            ) : (
              <Link to={category.link}>
                <div
                  className={`inline-flex p-3 rounded-lg text-${category.colour}-600 bg-${category.colour}-50 mb-4 group-hover:scale-110 transition-transform duration-200`}
                >
                  <category.icon size={24} />
                </div>
                <h3 className='text-xl font-semibold text-gray-900 mb-2'>
                  {category.title}
                </h3>
                <p className='text-gray-500'>{category.description}</p>
                <div className='absolute bottom-6 right-6 opacity-0 transform translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-200'>
                  →
                </div>
              </Link>
            )}
          </div>
        ))}
      </div>

      {userProfile.hasPermission(
        EntityTypeEnum.GuideCategories,
        PERMISSIONS[EntityTypeEnum.GuideCategories].CREATE
      ) && (
        <CreateDocCategory
          isOpen={showCreateCategory}
          onClose={() => setShowCreateCategory(false)}
        />
      )}
    </div>
  );
}
