import React from 'react';
import { Input } from 'core';
import {
  determineLinkDisplay,
  determineLinkType
} from '../../../../utilities/Formatting';

const BasicDetails = ({ companyData, handleInputChange }) => {
  const handleLinkChange = (field, value) => {
    // Format links array for company model
    const existingLinks = Array.isArray(companyData.links)
      ? companyData.links
      : [];
    const linkIndex = existingLinks.findIndex(
      link => link.type === field.charAt(0).toUpperCase() + field.slice(1)
    );

    const fullUrl = value.startsWith('http') ? value : `https://${value}`;
    const newLink = {
      id: Date.now(),
      url: fullUrl,
      display: determineLinkDisplay(fullUrl),
      type: determineLinkType(fullUrl)
    };

    let updatedLinks;
    if (linkIndex >= 0) {
      updatedLinks = [
        ...existingLinks.slice(0, linkIndex),
        newLink,
        ...existingLinks.slice(linkIndex + 1)
      ];
    } else {
      updatedLinks = [...existingLinks, newLink];
    }

    handleInputChange('links', updatedLinks);
  };

  const getLinkByType = type => {
    if (!Array.isArray(companyData.links)) return '';
    const link = companyData.links.find(l => l.type === type);
    return link ? link.url : '';
  };

  return (
    <div className='space-y-4'>
      <Input
        label='Company Name'
        value={companyData.name}
        onChange={e => handleInputChange('name', e.target.value)}
        required
        placeholder='New Company Ltd'
      />
      <Input
        label='Website'
        value={getLinkByType('Website')}
        onChange={e => handleLinkChange('website', e.target.value)}
        placeholder='https://www.example.com'
      />
      <Input
        label='LinkedIn'
        value={getLinkByType('Linkedin')}
        onChange={e => handleLinkChange('linkedin', e.target.value)}
        placeholder='https://www.linkedin.com/company/example'
      />
      <Input
        label='X'
        value={getLinkByType('X')}
        onChange={e => handleLinkChange('x', e.target.value)}
        placeholder='https://x.com/example'
      />
      <Input
        label='Facebook'
        value={getLinkByType('Facebook')}
        onChange={e => handleLinkChange('facebook', e.target.value)}
        placeholder='https://facebook.com/example'
      />
    </div>
  );
};

export default BasicDetails;
