import React, { useState, useEffect } from 'react';
import { Popup, Input, DropdownInput, FilledButton } from 'core';
import AdditionalItem from '../../../models/AdditionalItem';
import CoreEntityTypes from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import Booking from '../../../models/Booking';
import Invoice from '../../../models/Invoice';

const AddAdditionalItem = ({ isOpen, onClose, bookingId, onItemAdded }) => {
  const [item, setItem] = useState({
    description: '',
    itemTypeId: '',
    vatRate: '',
    amount: ''
  });
  const [itemTypes, setItemTypes] = useState([]);
  const [booking, setBooking] = useState(null);
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [itemTypeOptions, fetchedBooking] = await Promise.all([
          CoreEntityTypes.getByEntityId(EntityTypeEnum.AdditionalItemType),
          Booking.getById(bookingId)
        ]);

        setItemTypes(
          itemTypeOptions.map(option => ({
            value: option.id,
            label: option.type
          }))
        );
        setBooking(fetchedBooking);

        if (fetchedBooking.invoiceId) {
          const fetchedInvoice = await Invoice.getById(
            fetchedBooking.invoiceId
          );
          setInvoice(fetchedInvoice);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [bookingId]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    let tempVal = value;
    // If the item type is a credit note, convert it to a negative number
    if (name === 'amount' && item.itemTypeId == 134 && tempVal > 0) {
      tempVal = 0 - value;
    }
    if (item.itemTypeId == 134 && item.amount > 0) {
      setItem(prev => ({ ...prev, amount: 0 - item.amount }));
    }
    setItem(prev => ({ ...prev, [name]: tempVal }));
  };

  const handleAddItem = async () => {
    try {
      const newItem = new AdditionalItem({
        booking: bookingId,
        description: item.description,
        itemType: { id: item.itemTypeId },
        vatRate: item.vatRate,
        amount: item.amount
      });

      // Set invoice_id if booking has an invoice and its status is not sent
      if (booking?.invoiceId && invoice && invoice.status !== 220) {
        newItem.invoiceId = booking.invoiceId;
      }

      await newItem.insert();
      onItemAdded(newItem);
      onClose();
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Add Additional Item'>
      <div className='space-y-4'>
        <DropdownInput
          label='Item Type'
          name='itemTypeId'
          value={item.itemTypeId}
          onChange={handleInputChange}
          options={itemTypes}
          required
        />
        <Input
          label='Description'
          name='description'
          value={item.description}
          onChange={handleInputChange}
        />

        {item.itemTypeId != 137 && (
          <>
            <Input
              label='VAT Rate'
              name='vatRate'
              type='number'
              value={item.vatRate}
              onChange={handleInputChange}
              required
            />
            <Input
              label='Amount'
              name='amount'
              type='number'
              value={item.amount}
              onChange={handleInputChange}
              required
              hint={
                item.itemTypeId === 134
                  ? 'Credit notes are automatically converted to a negative value.'
                  : ''
              }
            />
          </>
        )}
        <div className='flex justify-end space-x-2'>
          <FilledButton onClick={onClose} colour='base'>
            Cancel
          </FilledButton>
          <FilledButton onClick={handleAddItem} colour='primary'>
            Add Item
          </FilledButton>
        </div>
      </div>
    </Popup>
  );
};

export default AddAdditionalItem;
