import React, { useEffect, useState } from 'react';
import { FilledButton, PageHeading } from 'core';
import { supabase } from '../../../../utilities/supabase';
import * as SimpleIcons from '@icons-pack/react-simple-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotification } from 'core';

const Integrations = () => {
  const [integrations, setIntegrations] = useState({});
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  useEffect(() => {
    loadIntegrationStatus();
  }, [location]);

  const handleXeroPopupMessage = async event => {
    // Verify the origin of the message
    if (event.origin !== window.location.origin) return;

    const { type, code, error } = event.data;
    if (type !== 'xero-auth') return;

    if (code) {
      await handleXeroConnect(code);
    } else if (error) {
      console.error('Xero OAuth error:', error);
      addNotification({
        type: 'error',
        title: 'Xero Connection Failed',
        description: 'Failed to connect to Xero. Please try again.'
      });
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleXeroPopupMessage);
    return () => window.removeEventListener('message', handleXeroPopupMessage);
  }, []);

  const handleXeroConnect = async code => {
    try {
      const { error } = await supabase.functions.invoke('xero/connect', {
        body: {
          code: code,
          redirect_uri: `${window.location.origin}/connect-xero` // Updated to use origin
        }
      });

      if (error) throw error;

      // Reload integration status after connecting
      await loadIntegrationStatus();

      addNotification({
        type: 'success',
        title: 'Connected to Xero',
        description: 'Successfully connected to Xero.'
      });
    } catch (error) {
      console.error('Error handling Xero callback:', error);
      addNotification({
        type: 'error',
        title: 'Connection Failed',
        description: 'Failed to complete Xero connection. Please try again.'
      });
    }
  };

  const loadIntegrationStatus = async () => {
    try {
      const { data, error } = await supabase.from('integrations').select('*');

      if (error) throw error;

      if (data) {
        const integrationsMap = {};
        data.forEach(integration => {
          integrationsMap[integration.integration_name] = {
            isConnected: integration.status === 'connected',
            createdAt: integration.created_date,
            expiresAt: integration.expiry_date,
            accountId: integration.account_id,
            metadata: integration.metadata
          };
        });
        setIntegrations(integrationsMap);
      }
    } catch (error) {
      console.error('Error loading integration status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleConnect = async integrationType => {
    if (integrationType === 'xero') {
      const baseUrl = 'https://login.xero.com/identity/connect/authorize';
      const redirectUri = `${window.location.origin}/connect-xero`;

      const params = new URLSearchParams({
        response_type: 'code',
        client_id: integrations[integrationType]?.metadata?.xero_client_id,
        redirect_uri: redirectUri,
        scope:
          'offline_access openid profile email accounting.settings accounting.contacts accounting.transactions',
        state: 'xero-oauth'
      });

      // Open popup window
      const width = 600;
      const height = 700;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;

      const popup = window.open(
        `${baseUrl}?${params.toString()}`,
        'Connect to Xero',
        `width=${width},height=${height},left=${left},top=${top}`
      );

      // Check if popup was blocked
      if (!popup || popup.closed || typeof popup.closed === 'undefined') {
        addNotification({
          type: 'error',
          title: 'Popup Blocked',
          description: 'Please allow popups for this site to connect with Xero.'
        });
      }
    }
  };

  const handleDisconnect = async integrationType => {
    try {
      const { error } = await supabase.functions.invoke(
        `${integrationType}/disconnect`
      );
      if (error) throw error;

      const { error: updateError } = await supabase
        .from('integrations')
        .update({
          status: 'disconnected',
          updated_date: new Date().toISOString(),
          expiry_date: null,
          account_id: null
        })
        .eq('integration_name', integrationType);

      if (updateError) throw updateError;

      setIntegrations(prev => ({
        ...prev,
        [integrationType]: {
          isConnected: false,
          createdAt: null,
          expiresAt: null,
          accountId: null
        }
      }));
      navigate('/settings/integrations');
    } catch (error) {
      console.error(`Error disconnecting from ${integrationType}:`, error);
    }
  };

  const getIntegrationDetails = name => {
    switch (name) {
      case 'xero':
        return {
          title: 'Xero',
          description:
            'Connect your Xero account to sync contacts and invoices',
          icon: SimpleIcons.SiXero,
          color: '#13B5EA'
        };
      // Add cases for other integrations here
      default:
        return {
          title: name.charAt(0).toUpperCase() + name.slice(1),
          description: `Connect your ${
            name.charAt(0).toUpperCase() + name.slice(1)
          } account`,
          icon: null,
          color: '#6B7280'
        };
    }
  };

  return (
    <div className='container mx-auto flex flex-col h-full'>
      <PageHeading
        title='Integrations'
        pages={[
          { name: 'Settings', href: '/settings' },
          { name: 'Integrations', href: '/settings/integrations' }
        ]}
      />

      <div className='py-6'>
        <ul className='divide-y divide-gray-200 bg-white shadow rounded-lg'>
          {Object.entries(integrations).map(
            ([integrationType, integration]) => {
              const details = getIntegrationDetails(integrationType);
              const Icon = details.icon;

              return (
                <li
                  key={integrationType}
                  className='p-4 sm:p-6 flex flex-row items-start sm:items-center gap-4 sm:gap-6'
                >
                  <div className='flex-1 flex flex-col sm:flex-row sm:items-center gap-4'>
                    <div className='min-w-[200px] md:w-96 flex items-center gap-4'>
                      <div
                        className='p-3 rounded-lg flex-none'
                        style={{ backgroundColor: `${details.color}15` }}
                      >
                        {Icon ? (
                          <Icon size={24} style={{ color: details.color }} />
                        ) : (
                          <div
                            className='w-6 h-6 rounded-full'
                            style={{ backgroundColor: details.color }}
                          />
                        )}
                      </div>
                      <div>
                        <h3 className='text-sm font-medium text-gray-900'>
                          {details.title}
                        </h3>
                        <p className='text-xs text-gray-500'>
                          {details.description}
                        </p>
                      </div>
                    </div>

                    {integration.isConnected && (
                      <div className='space-y-1'>
                        <p className='text-sm'>
                          Connected since:{' '}
                          {new Date(integration.createdAt).toLocaleDateString()}
                        </p>
                        <p className='text-sm'>
                          Expires:{' '}
                          {integration.expiresAt
                            ? new Date(
                                integration.expiresAt
                              ).toLocaleDateString()
                            : 'N/A'}
                        </p>
                        {integration.accountId && (
                          <p className='text-sm'>
                            Account ID: {integration.accountId}
                          </p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className='flex-none'>
                    <FilledButton
                      colour={integration.isConnected ? 'danger' : 'primary'}
                      onClick={() =>
                        integration.isConnected
                          ? handleDisconnect(integrationType)
                          : handleConnect(integrationType)
                      }
                    >
                      {integration.isConnected ? 'Disconnect' : 'Connect'}
                    </FilledButton>
                  </div>
                </li>
              );
            }
          )}
        </ul>
      </div>
    </div>
  );
};

export default Integrations;
