import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const XeroCallback = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('code');
    const error = searchParams.get('error');

    // Send message to parent window
    window.opener.postMessage(
      {
        type: 'xero-auth',
        code,
        error
      },
      window.location.origin
    );

    // Close the popup
    window.close();
  }, []);

  return (
    <div className='flex items-center justify-center min-h-screen'>
      <p className='text-gray-500'>Completing Xero authentication...</p>
    </div>
  );
};

export default XeroCallback;
