import React, { useEffect, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

const tailwindcolours = [
  'zinc',
  'red',
  'orange',
  'amber',
  'yellow',
  'lime',
  'green',
  'emerald',
  'teal',
  'cyan',
  'sky',
  'blue',
  'indigo',
  'violet',
  'purple',
  'fuchsia',
  'pink',
  'rose'
];

const shades = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950];

const ColourPicker = ({ colour, onChange, showShades = true }) => {
  const [selectedColour, setSelectedColour] = useState(
    colour?.split('-')[0] || 'gray'
  );
  const [selectedShade, setSelectedShade] = useState(
    parseInt(colour?.split('-')[1]) || 500
  );

  useEffect(() => {
    if (colour) {
      setSelectedColour(colour?.split('-')[0]);
      setSelectedShade(colour?.split('-')[1]);
    }
  }, [colour]);

  const handleColourChange = newColour => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedColour(newColour);
    onChange(`${newColour}-${selectedShade}`);
  };

  const handleShadeChange = newShade => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedShade(newShade);
    onChange(`${selectedColour}-${newShade}`);
  };

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <MenuButton
        className={`w-8 h-8 rounded-full border-2 bg-${selectedColour}-${selectedShade} border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
      >
        <span className='sr-only'>Open colour picker</span>
      </MenuButton>
      <MenuItems className='absolute z-10 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
        <div className='py-1'>
          <div className='px-4 py-2'>
            <p className='text-sm font-medium text-gray-700 mb-1'>Colours:</p>
            <div className='flex flex-wrap gap-2 justify-center'>
              {tailwindcolours.map(colourOption => (
                <button
                  key={colourOption}
                  className={`w-6 h-6 rounded-full border-2 bg-${colourOption}-600 ${
                    selectedColour === colourOption
                      ? 'border-primary-800'
                      : 'border-transparent'
                  }`}
                  onClick={() => handleColourChange(colourOption)}
                  title={colourOption}
                />
              ))}
            </div>
            <p className='text-sm text-center pt-2'>{selectedColour}</p>
          </div>
          {showShades && (
            <div className='px-4 py-2 border-t border-gray-100'>
              <p className='text-sm font-medium text-gray-700 mb-1'>Shades:</p>
              <div className='flex flex-wrap gap-2 justify-center'>
                {shades.map(shade => (
                  <button
                    key={shade}
                    className={`w-6 h-6 rounded-full border-2 bg-${selectedColour}-${shade} ${
                      selectedShade === shade
                        ? 'border-gray-800'
                        : 'border-transparent'
                    }`}
                    onClick={() => handleShadeChange(shade)}
                    title={shade}
                  />
                ))}
              </div>
              <p className='text-sm text-center pt-2'>{selectedShade}</p>
            </div>
          )}
        </div>
      </MenuItems>
    </Menu>
  );
};

export { ColourPicker };
