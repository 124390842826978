import React, { useState, useEffect } from 'react';
import Automation from '../../../models/Automations';
import Create from './Create';
import EntityTable from '../../../components/tables/EntityTable';
import RunsPopup from '../../../components/popups/automations/Runs';
import { formatDate, formatProfile } from '../../../utilities/Formatting';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import { useNavigate } from 'react-router-dom';
import { bgColours, renderIcon } from './ColoursIcons';

const AutomationsPage = ({ create }) => {
  const navigate = useNavigate();

  const [automations, setAutomations] = useState([]);
  const [isCreating, setIsCreating] = useState(create ?? false);
  const [editAuto, setEditAuto] = useState(null);
  const [selectedAutomationId, setSelectedAutomationId] = useState(null);

  useEffect(() => {
    fetchEntityAutomations();
  }, []);

  const fetchEntityAutomations = async () => {
    try {
      const fetchedAutomations = await Automation.getAll();
      setAutomations(fetchedAutomations.data);
    } catch (error) {
      console.error('Error fetching entity automations:', error);
    }
  };

  const handleEditAutomation = automation => {
    setEditAuto(automation);
    setIsCreating(true);
  };

  const handleViewRuns = automation => {
    setSelectedAutomationId(automation);
  };

  if (isCreating)
    return (
      <Create
        entityId={null}
        entityType={null}
        edit={editAuto}
        setEditAuto={setEditAuto}
        setIsCreating={setIsCreating}
        automations={automations}
        setAutomations={setAutomations}
        isCreating={isCreating}
      />
    );

  return (
    <div className='p-4'>
      <EntityTable
        name='Automations'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          { field: 'name', label: 'Name', type: 'text' },
          { field: 'description', label: 'Description', type: 'text' },
          { field: 'entity.display_name', label: 'Entity', type: 'text' },
          { field: 'trigger.type', label: 'Action', type: 'text' },
          {
            field: 'user_profiles.first_name',
            label: 'Owner',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          { field: 'created_date_from', label: 'Created From', type: 'date' },
          { field: 'created_date_to', label: 'Created To', type: 'date' },
          { field: 'updated_date_from', label: 'Updated From', type: 'date' },
          { field: 'updated_date_to', label: 'Updated To', type: 'date' }
        ]}
        sortDefault={{ field: 'id', order: 'asc' }}
        columns={[
          {
            label: 'ID',
            field: 'id'
          },
          {
            label: 'Name',
            field: 'name',
            cell: (value, data) => (
              <div className='flex items-center'>
                <div
                  className={`${
                    bgColours[data.metadata.colour]
                  } w-9 h-9 flex items-center justify-center rounded mr-3`}
                >
                  {renderIcon(data.metadata.colour, data.metadata.icon)}
                </div>
                <span>{value}</span>
              </div>
            )
          },
          { label: 'Description', field: 'description' },
          {
            label: 'Entity',
            field: 'entity.display_name',
            foreignKey: { table: 'core_entities', column: 'id' }
          },
          {
            label: 'Action',
            field: 'trigger.type',
            foreignKey: { table: 'core_entity_types', column: 'id' }
          },
          {
            label: 'Owner',
            field: 'owner.firstName',
            foreignKey: { table: 'user_profiles', column: 'id' },
            cell: (value, data) => formatProfile(data.owner)
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        model={Automation}
        entityType={EntityTypeEnum.Automations}
        canCreate={true}
        onNewClick={() => {
          setIsCreating(true);
          navigate('/automations/create');
        }}
        onRowClick={data => handleEditAutomation(data)}
        menuItems={[
          {
            label: 'View Runs',
            onClick: data => {
              handleViewRuns(data);
              console.log(data);
            }
          }
        ]}
      />
      <RunsPopup
        isOpen={selectedAutomationId}
        automation={selectedAutomationId}
        onClose={() => setSelectedAutomationId(null)}
      />
    </div>
  );
};

export default AutomationsPage;
