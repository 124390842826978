import React, { useContext } from 'react';
import { Plus } from 'lucide-react';
import { SoftButton } from 'core';
import { UserProfileContext } from '../../../App';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { NavLink } from 'react-router-dom';

const PipelineSection = ({ title, pipelines, filter }) => (
  <div>
    <h3 className='text-sm font-semibold text-base-500 dark:text-base-400 mb-2'>
      {title}
    </h3>
    <div className='space-y-1'>
      {pipelines.filter(filter).map(pipeline => (
        <NavLink
          key={pipeline.id}
          to={`/pipelines/${pipeline.id}`}
          className={({ isActive }) => `
            px-2 py-1.5 rounded-md transition-colors duration-200 w-full flex items-center space-x-2
            ${
              isActive
                ? 'bg-primary-100 text-base-950'
                : 'hover:bg-base-100 dark:hover:bg-base-700 text-base-700 dark:text-base-300'
            }
          `}
        >
          <span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.75rem] font-medium'>
            {pipeline.title[0]}
          </span>
          <span className='hidden md:block text-sm truncate'>
            {pipeline.title}
          </span>
        </NavLink>
      ))}
    </div>
  </div>
);

const PipelineSidebar = ({
  pipelines,
  selectedPipeline,
  setSelectedPipeline,
  navigate,
  setShowBasicPopup
}) => {
  const { userProfile: currentUser } = useContext(UserProfileContext);

  return (
    <>
      <div className='hidden md:flex md:flex-col md:space-y-4 md:items-stretch'>
        {/* New Pipeline Button */}
        {currentUser.hasPermission(
          EntityTypeEnum.Pipelines,
          PERMISSIONS[EntityTypeEnum.Pipelines].CREATE
        ) && (
          <SoftButton
            onClick={() => setShowBasicPopup(true)}
            className='w-full mb-4'
          >
            <Plus className='mr-2' size={16} />
            New Pipeline
          </SoftButton>
        )}

        <PipelineSection
          title='Global Pipelines'
          pipelines={pipelines}
          filter={p => !p.private && !p.archived}
        />

        {pipelines.some(p => p.private && !p.archived) && (
          <PipelineSection
            title='My Pipelines'
            pipelines={pipelines}
            filter={p => p.private && !p.archived}
          />
        )}

        {pipelines.some(p => p.archived) && (
          <PipelineSection
            title='Archived'
            pipelines={pipelines}
            filter={p => p.archived}
          />
        )}
      </div>

      <div className='md:hidden'>
        <select
          className='w-full p-2 rounded-lg bg-base-100 dark:bg-base-700 text-base-700 dark:text-base-300 border border-base-300 dark:border-base-600'
          value={selectedPipeline ? selectedPipeline.id : ''}
          onChange={e => {
            const selected = pipelines.find(
              p => p.id === parseInt(e.target.value)
            );
            if (selected) {
              setSelectedPipeline(selected);
              navigate(`/pipelines/${selected.id}`);
            }
          }}
        >
          <option value=''>Select a pipeline</option>
          <optgroup label='Global Pipelines'>
            {pipelines
              .filter(p => !p.private && !p.archived)
              .map(pipeline => (
                <option key={pipeline.id} value={pipeline.id}>
                  {pipeline.title}
                </option>
              ))}
          </optgroup>
          <optgroup label='My Pipelines'>
            {pipelines
              .filter(p => p.private && !p.archived)
              .map(pipeline => (
                <option key={pipeline.id} value={pipeline.id}>
                  {pipeline.title}
                </option>
              ))}
          </optgroup>
          <optgroup label='Archived'>
            {pipelines
              .filter(p => p.archived)
              .map(pipeline => (
                <option key={pipeline.id} value={pipeline.id}>
                  {pipeline.title}
                </option>
              ))}
          </optgroup>
        </select>
      </div>
    </>
  );
};

export default PipelineSidebar;
