import { Node, mergeAttributes, generateJSON } from '@tiptap/core';
import {
  ReactNodeViewRenderer,
  NodeViewWrapper,
  NodeViewContent
} from '@tiptap/react';
import { FilledButton } from '../../components/inputs/buttons/Button';

// Extension imports
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Typography from '@tiptap/extension-typography';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import TextAlign from '@tiptap/extension-text-align';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { SmilieReplacer } from './SmilieReplacer';
import { Video } from './video';
import { BrainCircuit } from 'lucide-react';

const extensions = [
  StarterKit,
  Placeholder,
  SmilieReplacer,
  Typography,
  Video,
  Image,
  CodeBlockLowlight,
  Link,
  TextAlign,
  Table,
  TableRow,
  TableHeader,
  TableCell
];

const AiContentComponent = ({ node, editor }) => {
  if (!node || !editor) {
    return null;
  }

  const handleReplace = () => {
    const content = node.attrs.content;
    if (!content) return;

    editor.commands.saveAiContent(content, 'replace');
  };

  const handleInsert = () => {
    const content = node.attrs.content;
    if (!content) return;

    editor.commands.saveAiContent(content, 'insert');
  };

  const handleReject = () => {
    editor.commands.rejectAiContent();
  };

  const displayContent = node.attrs.content;

  return (
    <NodeViewWrapper className='ai-content-node border border-primary-300 rounded-lg p-4'>
      <div className='ai-content mb-3'>
        <div dangerouslySetInnerHTML={{ __html: displayContent }} />
      </div>
      <div className='flex flex-row justify-end items-center'>
        <div className='flex gap-2 justify-end'>
          {node.attrs.status === 'starting' ? (
            <div className='flex items-center gap-2'>
              <BrainCircuit size={18} className='animate-pulse' />
              <span className='text-sm text-primary-700'>Thinking...</span>
            </div>
          ) : node.attrs.status === 'running' ? (
            <div className='flex items-center gap-2'>
              <div className='animate-spin rounded-full h-5 w-5 border-2 border-primary-700 border-t-transparent' />
              <span className='text-sm text-primary-700'>Generating...</span>
            </div>
          ) : (
            <div className='flex flex-row space-x-2'>
              <FilledButton onClick={handleReject} colour='base' size='md'>
                Reject
              </FilledButton>
              <FilledButton onClick={handleReplace} colour='primary' size='md'>
                Replace
              </FilledButton>
              <FilledButton onClick={handleInsert} colour='primary' size='sm'>
                Insert
              </FilledButton>
            </div>
          )}
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export default Node.create({
  name: 'aiContentNode',
  group: 'block',
  inline: false,
  atom: true,

  addOptions() {
    return {
      content: '',
      originalContent: '',
      status: 'starting'
    };
  },

  addAttributes() {
    return {
      content: {
        default: ''
      },
      status: {
        default: this.options.status
      },
      originalContent: {
        default: ''
      },
      originalStartPos: {
        default: ''
      },
      originalEndPos: {
        default: ''
      }
    };
  },

  addCommands() {
    return {
      createAiNode:
        attrs =>
        ({ tr, state, dispatch }) => {
          if (dispatch) {
            console.log('Creating ai node');
            const type = state.schema.nodes[this.name];
            const { from, to } = state.selection;
            const node = type.create({
              ...attrs,
              originalStartPos: from,
              originalEndPos: to
            });
            // Find the end of the block containing the selection
            const { $to } = state.selection;
            const afterPos = $to.after(1);
            //tr = tr.setMeta('addToHistory', false);
            tr.insert(afterPos, node);
            dispatch(tr);
          }
          return true;
        },

      updateAiContent:
        attrs =>
        ({ tr, state, dispatch }) => {
          if (dispatch) {
            const positions = [];
            state.doc.descendants((node, pos) => {
              if (node.type.name === this.name) {
                positions.push(pos);
              }
            });

            if (positions.length > 0) {
              const pos = positions[0];
              const currentNode = state.doc.nodeAt(pos);
              const currentStatus = currentNode.attrs.status;
              const shouldSaveHistory = currentStatus === 'completed';
              tr = tr.setMeta('addToHistory', shouldSaveHistory);

              if (attrs.content) {
                tr.setNodeAttribute(pos, 'content', attrs.content);
              }
              if (attrs.status) {
                tr.setNodeAttribute(pos, 'status', attrs.status);
              }

              dispatch(tr);
            }
          }
          return true;
        },

      saveAiContent:
        (content, mode = 'replace') =>
        ({ tr, state, dispatch }) => {
          const positions = [];
          state.doc.descendants((node, pos) => {
            if (node.type.name === this.name) {
              positions.push({ pos, node });
            }
          });

          if (positions.length > 0) {
            const { pos, node } = positions[0];
            const { originalStartPos, originalEndPos } = node.attrs;

            if (mode === 'replace') {
              // Delete the AI node
              tr.delete(pos, pos + node.nodeSize);

              // Replace the original selected content with new content
              tr.replaceWith(
                originalStartPos,
                originalEndPos,
                state.schema.nodeFromJSON(generateJSON(content, extensions))
              );
            } else if (mode === 'insert') {
              // Insert new content after the block and delete AI node
              const endPos = pos + node.nodeSize;
              tr.insert(
                endPos,
                state.schema.nodeFromJSON(generateJSON(content, extensions))
              );
              tr.delete(pos, pos + node.nodeSize);
            }

            dispatch(tr);
          }
          return true;
        },

      rejectAiContent:
        () =>
        ({ tr, state, dispatch }) => {
          const positions = [];
          state.doc.descendants((node, pos) => {
            if (node.type.name === this.name) {
              positions.push({ pos, node });
            }
          });

          // Simply delete the AI nodes
          positions.reverse().forEach(({ pos, node }) => {
            //tr = tr.setMeta('addToHistory', false);
            tr.delete(pos, pos + node.nodeSize);
          });

          if (dispatch) {
            dispatch(tr);
          }
          return true;
        }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'ai-content-node'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'ai-content-node',
      mergeAttributes(HTMLAttributes, {
        'data-content': HTMLAttributes.content,
        'data-status': HTMLAttributes.status
      })
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(AiContentComponent);
  }
});
