import React, { useState, useEffect, useContext } from 'react';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import BookingSpace from '../../../models/BookingSpace';
import EntityTable from '../../../components/tables/EntityTable';
import { supabase } from '../../../utilities/supabase';
import { formatDate } from '../../../utilities/Formatting';
import { Link, useNavigate } from 'react-router-dom';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import CoreEntityType from '../../../models/CoreEntityType';
import { UserProfileContext } from '../../../App';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { Alert } from 'core';

const BookingSpaces = () => {
  document.title = 'Booking Spaces | CRM | BOHSA LTD';
  const navigate = useNavigate();
  const [statusOptions, setStatusOptions] = useState([]);
  const [levyStatusOptions, setLevyStatusOptions] = useState([]);

  const { userProfile: currentUser } = useContext(UserProfileContext);
  //Test user permissions for view access
  if (
    !currentUser.hasPermission(
      EntityTypeEnum.BookingSpace,
      PERMISSIONS[EntityTypeEnum.BookingSpace].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view booking spaces.'
        style='danger'
      />
    );
  }

  const userCanEdit = currentUser.hasPermission(
    EntityTypeEnum.BookingSpace,
    PERMISSIONS[EntityTypeEnum.BookingSpace].EDIT
  );

  const userCanDelete = currentUser.hasPermission(
    EntityTypeEnum.BookingSpace,
    PERMISSIONS[EntityTypeEnum.BookingSpace].DELETE
  );

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [statuses, levyStatuses] = await Promise.all([
          CoreEntityType.getByEntityId(EntityTypeEnum.BookingSpaceStatus),
          CoreEntityType.getByEntityId(EntityTypeEnum.BookingSpaceLevyType)
        ]);

        setStatusOptions(
          statuses.map(status => ({
            key: status.id,
            value: status.type
          }))
        );

        setLevyStatusOptions(
          levyStatuses.map(status => ({
            key: status.id,
            value: status.type
          }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/booking-spaces/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/booking-spaces/${data.id}`)
    }
    // {
    //   label: 'Delete',
    //   icon: <Trash size={14} className='text-danger-700' />,
    //   onClick: data => console.log('Delete clicked', data)
    // }
  ];

  const handleRowClick = data => {
    navigate(`/booking-spaces/${data.id}`);
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Booking Spaces'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          { field: 'booking_id', label: 'Booking ID', type: 'text' },
          {
            field: 'booking.company.name',
            label: 'Company Name',
            type: 'text',
            operator: FilterOperatorEnum.TEXTSEARCH
          },
          {
            field: 'delegate.full_name',
            label: 'Delegate Name',
            type: 'text',
            operator: FilterOperatorEnum.TEXTSEARCH
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions,
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'levy_status',
            label: 'Levy Status',
            type: 'select',
            options: levyStatusOptions,
            operator: FilterOperatorEnum.EQUALS
          },
          { field: 'updated_date', label: 'Updated Date', type: 'daterange' },
          {
            field: 'completion_date',
            label: 'Completion Date',
            type: 'daterange'
          },
          { field: 'expiry_date', label: 'Expiry Date', type: 'daterange' }
        ]}
        sort={[
          { value: 'updated_date', label: 'Date Updated' },
          { value: 'completion_date', label: 'Completion Date' },
          { value: 'expiry_date', label: 'Expiry Date' },
          { value: 'status', label: 'Status' },
          { value: 'levy_status', label: 'Levy Status' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/booking-spaces/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          {
            label: 'Booking ID',
            field: 'booking.id',
            cell: (value, row) => (
              <Link
                to={`/bookings/${row.booking.id}`}
                className='text-brand-700 hover:text-brand-800'
                onClick={e => e.stopPropagation()}
              >
                {row.booking.id}
              </Link>
            )
          },
          {
            label: 'Company Name',
            field: 'booking.company.name',
            cell: (value, row) => (
              <Link
                to={`/companies/${row.booking.company.id}`}
                className='text-brand-700 hover:text-brand-800'
                onClick={e => e.stopPropagation()}
              >
                {row.booking.company.name}
              </Link>
            )
          },
          {
            label: 'Delegate Name',
            field: 'delegate.fullName',
            cell: (value, row) => (
              <Link
                to={`/user-profiles/${row.delegate.displayId}`}
                className='text-brand-700 hover:text-brand-800'
                onClick={e => e.stopPropagation()}
              >
                {row.delegate.fullName}
              </Link>
            )
          },
          { label: 'Status', field: 'status.name' },
          { label: 'Levy Status', field: 'levyStatus.name' },
          {
            label: 'Completion Date',
            field: 'completionDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Expiry Date',
            field: 'expiryDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        model={BookingSpace}
        entityType={EntityTypeEnum.BookingSpace}
        menuItems={menuItems}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default BookingSpaces;
