import { Description, Field, Label, Switch } from '@headlessui/react';

const Toggle = ({ checked, onChange, disabled, label, description, id }) => {
  return (
    <Field className='flex items-center justify-between'>
      <span className='flex flex-grow flex-col'>
        {label && (
          <Label
            as='span'
            passive
            className='text-sm font-medium leading-6 text-primary-900'
          >
            {label}
          </Label>
        )}
        {description && (
          <Description as='span' className='text-sm text-primary-500'>
            {description}
          </Description>
        )}
      </span>
      <Switch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        id={id}
        className={`group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2 ${
          checked ? 'bg-brand-700' : 'bg-primary-200'
        } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        <span
          aria-hidden='true'
          className={`pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
            checked ? 'translate-x-5' : 'translate-x-0'
          }`}
        />
      </Switch>
    </Field>
  );
};

export { Toggle };
