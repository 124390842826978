import React, { useState, useEffect } from 'react';
import { supabase } from '../../../utilities/supabase';
import { LogoLoader, Popup } from 'core';
import StageOne from './StageOne';
import StageTwo from './StageTwo';

const SendEmail = ({ onClose, entity }) => {
  const [stage, setStage] = useState(1);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchTemplates();
    fetchCategories();
  }, []);

  const fetchTemplates = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase.functions.invoke(
        'integrations/add-ai/email-templates'
      );
      if (error) throw error;
      setTemplates(data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase.functions.invoke(
        'integrations/add-ai/email-categories'
      );
      if (error) throw error;
      setCategories(
        data.map(category => ({ value: category.id, label: category.name }))
      );
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleTemplateSelect = async template => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase.functions.invoke(
        `integrations/add-ai/email-template/${template.id}`
      );
      if (error) throw error;
      const selectedTemplate = data[0];
      setSelectedTemplate({ ...template, html: selectedTemplate.html });
      setStage(2);
    } catch (error) {
      console.error('Error fetching template html:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewEmail = () => {
    const defaultTemplate = templates.find(template => template.default);
    if (defaultTemplate) {
      handleTemplateSelect(defaultTemplate);
    }
  };

  const handleBack = () => {
    setStage(1);
    fetchTemplates();
  };

  const renderContent = () => (
    <>
      {isLoading ? (
        <div className='flex justify-center items-center h-96'>
          <LogoLoader
            logo={'/logo-star.png'}
            size='small'
            selfContained={false}
          />
        </div>
      ) : (
        <>
          {stage === 1 && (
            <StageOne
              templates={templates}
              categories={categories}
              onTemplateSelect={handleTemplateSelect}
              onNewEmail={handleNewEmail}
            />
          )}

          {stage === 2 && selectedTemplate && (
            <StageTwo
              selectedTemplate={selectedTemplate}
              entity={entity}
              onBack={handleBack}
              onClose={onClose}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <Popup
      isOpen={true}
      onClose={onClose}
      title={stage === 1 ? 'Select Email Template' : 'Compose Email'}
      size='lg'
    >
      {renderContent()}
    </Popup>
  );
};

export default SendEmail;
