import Company from './Company';

/**
 * Represents an Imported Company in the system.
 * @extends Company
 */
class ImportedCompany extends Company {
  /** @type {string} The name of the database table for Imported Companies */
  static table = 'imported_companies';

  /**
   * Creates an ImportedCompany instance from database format data
   * @param {Object} data - The database format imported company data
   * @returns {ImportedCompany} A new ImportedCompany instance
   */
  static fromDatabase(data = {}) {
    const importedCompany = new ImportedCompany();
    importedCompany.id = data.id;
    importedCompany.createdDate = data.created_date;
    importedCompany.updatedDate = data.updated_date;
    importedCompany.name = data.name;
    importedCompany.accountManager = data.user_profiles
      ? {
          id: data.user_profiles.id,
          firstName: data.user_profiles.first_name,
          lastName: data.user_profiles.last_name,
          fullName: data.user_profiles.full_name,
          profilePhoto: data.user_profiles.profile_photo
        }
      : null;
    importedCompany.onAccount = data.on_account || false;
    importedCompany.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    importedCompany.capsuleId = data.capsule_id;
    importedCompany.accountNumber = data.account_number;
    importedCompany.terms = {
      days: data.term_days || null,
      type: data.term_type
        ? { id: data.term_type.id, type: data.term_type.type }
        : null
    };
    importedCompany.creditContract = data.credit_contract || null;
    importedCompany.levy = data.levy;
    importedCompany.tradex = data.tradex || false;
    importedCompany.tradexOption = data.tradex_option;
    importedCompany.lastBooked = data.last_booked;
    importedCompany.links = data.links;
    importedCompany.accountComment = data.account_comment;
    importedCompany.readOnly = data.read_only;
    return importedCompany;
  }

  /**
   * Creates an instance of ImportedCompany.
   * @param {Object} data - The imported company data.
   */
  constructor(data = {}) {
    super(data);
    this.readOnly = data.readOnly ?? this.readOnly ?? null;
  }

  /**
   * Converts the ImportedCompany instance to a database-friendly format.
   * @returns {Object} The imported company data ready for database operations.
   */
  toDatabase() {
    const baseData = super.toDatabase();
    // Add any additional properties specific to imported companies here
    return {
      ...baseData,
      read_only: this.readOnly
    };
  }
}

export default ImportedCompany;
