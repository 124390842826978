import React, { useEffect, useState } from 'react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { formatDate } from '../../../../utilities/Formatting';
import Booking from '../../../../models/Booking';
import { SquareArrowOutUpRight, Pencil, Trash } from 'lucide-react';
import UserProfile from '../../../../models/UserProfile';
import CoreEntityType from '../../../../models/CoreEntityType';

const UserBookings = ({ userId }) => {
  const [uniqueUserId, setUniqueUserId] = useState(null);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [statusTypes, setStatusTypes] = useState([]);
  const [subStatusTypes, setSubStatusTypes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user ID
        if (userId) {
          const user = await UserProfile.getById(userId);
          setUniqueUserId(user.id);
        }

        // Fetch delivery types
        const { data: types } = await CoreEntityType.getAll({
          entity_id: {
            operator: FilterOperatorEnum.EQUALS,
            value: EntityTypeEnum.CourseDelivery
          }
        });
        setDeliveryTypes(
          types.map(type => ({
            value: type.type,
            key: type.id
          }))
        );

        // Fetch status types
        const { data: statusTypes } = await CoreEntityType.getAll({
          entity_id: {
            operator: FilterOperatorEnum.EQUALS,
            value: EntityTypeEnum.BookingStatus
          }
        });
        setStatusTypes(
          statusTypes.map(type => ({
            value: type.type,
            key: type.id
          }))
        );

        // Fetch sub status types
        const { data: subStatusTypes } = await CoreEntityType.getAll({
          entity_id: {
            operator: FilterOperatorEnum.EQUALS,
            value: EntityTypeEnum.BookingSubStatus
          }
        });
        setSubStatusTypes(
          subStatusTypes.map(type => ({
            value: type.type,
            key: type.id
          }))
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userId]);

  if (!uniqueUserId) {
    return null;
  }

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/bookings/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => (window.location.href = `/bookings/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  return (
    <EntityTable
      name='User Bookings'
      filter={[
        {
          field: 'id',
          label: 'Booking ID',
          type: 'number',
          operator: FilterOperatorEnum.EQUALS
        },
        {
          field: 'course.name',
          label: 'Course Name',
          type: 'text',
          operator: FilterOperatorEnum.TEXTSEARCH
        },
        {
          field: 'companies.name',
          label: 'Company Name',
          type: 'text',
          operator: FilterOperatorEnum.TEXTSEARCH
        },
        {
          field: 'delivery_type',
          label: 'Delivery Type',
          type: 'select',
          options: deliveryTypes
        },
        {
          field: 'status',
          label: 'Status',
          type: 'select',
          options: statusTypes
        },
        {
          field: 'sub_status',
          label: 'Sub Status',
          type: 'select',
          options: subStatusTypes
        },
        {
          field: 'booking_date',
          label: 'Booking Date',
          type: 'daterange'
        }
      ]}
      columns={[
        { label: 'ID', field: 'id' },
        { label: 'Course', field: 'course.name' },
        { label: 'Company', field: 'company.name' },
        { label: 'Delivery', field: 'deliveryType.name' },
        {
          label: 'Booking Date',
          field: 'bookingDate',
          cell: value => formatDate(value)
        },
        { label: 'Status', field: 'status.name' },
        { label: 'Sub Status', field: 'subStatus.name' }
      ]}
      menuItems={menuItems}
      model={Booking}
      entityType={EntityTypeEnum.Booking}
      defaultFilters={{
        contacts: {
          value: JSON.stringify([
            {
              user_id: uniqueUserId,
              contact_type: 'Booker'
            }
          ]),

          operator: 'contains'
        }
      }}
      canCreate={false}
    />
  );
};

export default UserBookings;
