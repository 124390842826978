import React, { useEffect, useState } from 'react';
import { Input, Toggle, DropdownInput, TextButton } from 'core';
import CoreEntityType from '../../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';
import { supabase } from '../../../../utilities/supabase';

const AccountDetails = ({ companyData, handleInputChange }) => {
  const [paymentTermOptions, setPaymentTermOptions] = useState([]);
  const [xeroContacts, setXeroContacts] = useState([]);
  const [showXeroResults, setShowXeroResults] = useState(false);

  useEffect(() => {
    const loadPaymentTerms = async () => {
      const terms = await CoreEntityType.getByEntityId(
        EntityTypeEnum.CompanyTerms
      );
      setPaymentTermOptions(
        terms.map(term => ({
          metadata: term.metadata,
          label: term.type,
          value: term.id,
          key: term.id
        }))
      );
    };
    loadPaymentTerms();
  }, []);

  const searchXeroContacts = async () => {
    try {
      const { data, error } = await supabase.functions.invoke(
        'xero/searchContacts',
        {
          body: { searchTerm: companyData.accountNumber, isCustomer: true }
        }
      );

      if (error) throw error;
      console.log('Xero API response:', data);
      setXeroContacts(data);
      setShowXeroResults(true);
    } catch (error) {
      console.error('Error fetching Xero contacts:', error);
    }
  };

  const selectXeroContact = contact => {
    // Set Xero ID
    handleInputChange('xeroAccountId', contact.ContactID);

    // Set account number if available
    if (contact.AccountNumber) {
      handleInputChange('accountNumber', contact.AccountNumber);
    }

    // Set payment terms if available
    if (contact.PaymentTerms?.Sales) {
      const { Day, Type } = contact.PaymentTerms.Sales;

      // Find matching payment term type in options
      const matchingTerm = paymentTermOptions.find(
        opt => opt.metadata?.xero_type === Type
      );

      if (matchingTerm) {
        handleInputChange('paymentTermType', {
          value: matchingTerm.value,
          label: matchingTerm.label
        });
      }

      handleInputChange('paymentTermDays', Day?.toString() || '');
    }

    // Close results
    setShowXeroResults(false);
  };

  return (
    <div className='space-y-4'>
      <Input
        label='Account Number'
        value={companyData.accountNumber}
        onChange={e => handleInputChange('accountNumber', e.target.value)}
        placeholder='NEWCO01'
      />

      <div className='space-y-2'>
        {(companyData.accountNumber || companyData.xeroAccountId) && (
          <div className='text-xs text-gray-500'>
            Xero Account ID: {companyData.xeroAccountId || 'Not linked'}
          </div>
        )}

        <div className='text-xs'>
          <TextButton size='sm' onClick={searchXeroContacts}>
            Link to Xero
          </TextButton>
        </div>

        {showXeroResults && xeroContacts.length > 0 && (
          <div className='mt-2 p-2 border border-base-200 rounded-lg max-h-40 overflow-y-auto'>
            {xeroContacts.map(contact => (
              <div
                key={contact.ContactID}
                className='p-2 hover:bg-base-100 cursor-pointer text-sm'
                onClick={() => selectXeroContact(contact)}
              >
                {contact.Name}
              </div>
            ))}
          </div>
        )}
      </div>

      <Input
        label='Levy'
        value={companyData.levy}
        onChange={e => handleInputChange('levy', e.target.value)}
        placeholder=''
      />
      <div className='flex space-x-4'>
        <DropdownInput
          label='Payment Terms'
          value={companyData.paymentTermType?.value || ''}
          onChange={e =>
            handleInputChange('paymentTermType', {
              value: e.target.value,
              label: paymentTermOptions.find(
                opt => opt.value === e.target.value
              )?.label
            })
          }
          options={paymentTermOptions}
          placeholder='Select payment terms'
        />
        <Input
          label='Days After'
          type='number'
          value={companyData.paymentTermDays}
          onChange={e => handleInputChange('paymentTermDays', e.target.value)}
          placeholder='0'
        />
      </div>

      <Toggle
        label='On Account'
        checked={companyData.onAccount}
        onChange={value => handleInputChange('onAccount', value)}
      />

      {companyData.onAccount && (
        <>
          {/* <FileUploader
            label='Credit Contract'
            onChange={file => handleInputChange('creditContract', file)}
            accept='.pdf,.doc,.docx'
          /> */}
        </>
      )}

      <Toggle
        label='Tradex Enabled'
        checked={companyData.tradexEnabled}
        onChange={value => handleInputChange('tradexEnabled', value)}
      />

      {companyData.tradexEnabled && (
        <div className='space-y-4 pl-4 border-l-2 border-base-200'>
          <Input
            label='Tradex Hours'
            type='number'
            value={companyData.tradexOption || 0}
            onChange={e => handleInputChange('tradexOption', e.target.value)}
            placeholder='0'
          />
        </div>
      )}
    </div>
  );
};

export default AccountDetails;
