import React, { useState, useMemo } from 'react';
import { Search } from 'lucide-react';
import { Badge, Input, MultiSelectBox } from 'core';

const StageOne = ({ templates, categories, onTemplateSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);

  const filteredTemplates = useMemo(() => {
    const t = templates.filter(template =>
      template.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    console.log(t);

    return t;
  }, [templates, searchQuery, selectedCategory]);
  const handleNewEmailClick = () => {
    window.open('https://tools.add.ai/docflows/create', '_blank');
  };

  return (
    <>
      <div className='mb-6 flex items-center space-x-4'>
        <div className='relative flex-grow'>
          <div className='absolute inset-y-0 left-0 pl-2 flex items-center'>
            <Search className='h-4 w-4 text-gray-400' />
          </div>
          <Input
            id='templateSearch'
            name='templateSearch'
            type='text'
            placeholder='Search templates...'
            className='bg-white text-gray-500 pl-8 pr-8 w-full'
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
        {filteredTemplates.map(template => (
          <div
            key={template.id}
            className='border border-neutral-200 p-6 rounded-lg shadow-sm cursor-pointer hover:shadow-md transition-shadow duration-200 ease-in-out bg-white'
            onClick={() => onTemplateSelect(template)}
          >
            <img
              src={template.preview}
              alt={`${template.name} preview`}
              className='w-full h-32 object-cover mb-4 rounded'
            />
            <h3 className='font-semibold text-lg mb-2 text-neutral-800'>
              {template.name}
            </h3>
            <p className='text-sm text-neutral-600 mb-4'>
              {template.description}
            </p>
          </div>
        ))}
        <div
          className={`border border-dashed border-neutral-300 p-6 rounded-lg cursor-pointer transition-colors duration-200 ease-in-out flex items-center justify-center hover:border-brand-500 hover:bg-brand-50`}
          onClick={handleNewEmailClick}
        >
          <div className='flex flex-col items-center justify-center'>
            <span className='text-lg font-semibold text-brand-600'>
              + New Template
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default StageOne;
