import React from 'react';
import {
  Book,
  Download,
  FileText,
  Headphones,
  LifeBuoy,
  MessageSquare,
  Video
} from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Help() {
  const helpCategories = [
    {
      title: 'Resources',
      description: 'Download plugins, assets, icons and other resources',
      icon: Download,
      link: '/help/resources',
      colour: 'primary'
    },
    {
      title: 'Documentation',
      description: 'Read our comprehensive guides and API documentation',
      icon: Book,
      link: '/help/docs',
      colour: 'info',
      disabled: false
    },
    {
      title: 'Video Tutorials',
      description: 'Watch step-by-step video guides and walkthroughs',
      icon: Video,
      link: '/help/tutorials',
      colour: 'success',
      disabled: true
    },
    {
      title: 'Support',
      description: 'Get help from our support team',
      icon: LifeBuoy,
      link: '/help/support',
      colour: 'warning',
      disabled: true
    }
  ];

  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12'>
      <div className='text-center mb-12'>
        <h1 className='text-4xl font-bold text-gray-900 mb-4'>
          How can we help you?
        </h1>
        <p className='text-xl text-gray-500'>
          Access downloadable resources, documentation & help guides or reach
          out to our support team.
        </p>
      </div>

      <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3'>
        {helpCategories.map(category => (
          <div
            key={category.title}
            className={`relative group bg-white p-6 rounded-lg shadow-sm border border-gray-200 ${
              category.disabled
                ? 'opacity-60 cursor-not-allowed'
                : 'hover:shadow-md transition-all duration-200'
            }`}
          >
            {category.disabled ? (
              <div>
                <div
                  className={`inline-flex p-3 rounded-lg text-${category.colour}-600 bg-${category.colour}-50 mb-4`}
                >
                  <category.icon size={24} />
                </div>
                <div className='flex items-center gap-2 mb-2'>
                  <h3 className='text-xl font-semibold text-gray-900'>
                    {category.title}
                  </h3>
                  <span className='px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-600'>
                    Coming soon
                  </span>
                </div>
                <p className='text-gray-500'>{category.description}</p>
              </div>
            ) : (
              <Link to={category.link}>
                <div
                  className={`inline-flex p-3 rounded-lg text-${category.colour}-600 bg-${category.colour}-50 mb-4 group-hover:scale-110 transition-transform duration-200`}
                >
                  <category.icon size={24} />
                </div>
                <h3 className='text-xl font-semibold text-gray-900 mb-2'>
                  {category.title}
                </h3>
                <p className='text-gray-500'>{category.description}</p>
                <div className='absolute bottom-6 right-6 opacity-0 transform translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-200'>
                  →
                </div>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
