import { Node, nodeInputRule } from '@tiptap/react';
import { Plugin, PluginKey } from 'prosemirror-state';

const VIDEO_INPUT_REGEX = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

export const Video = Node.create({
  name: 'video',

  group: 'block',

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: el => el.getAttribute('src'),
        renderHTML: attrs => ({ src: attrs.src })
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'video',
        getAttrs: el => ({ src: el.getAttribute('src') })
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'video',
      {
        controls: 'true',
        class:
          'w-auto max-h-[calc(100vh-52px)] justify-center rounded-lg hover:border',
        ...HTMLAttributes
      },
      ['source', HTMLAttributes]
    ];
  },

  addCommands() {
    return {
      setVideo:
        src =>
        ({ commands }) =>
          commands.insertContent(
            `<video controls="true" class="w-full h-full rounded-lg" src="${src}" />`
          ),

      toggleVideo:
        () =>
        ({ commands }) =>
          commands.toggleNode(this.name, 'paragraph')
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: VIDEO_INPUT_REGEX,
        type: this.type,
        getAttributes: match => {
          const [, , src] = match;

          return { src };
        }
      })
    ];
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('videoDropPlugin'),

        props: {
          handleDOMEvents: {
            drop(view, event) {
              const {
                state: { schema, tr },
                dispatch
              } = view;
              const hasFiles =
                event.dataTransfer &&
                event.dataTransfer.files &&
                event.dataTransfer.files.length;

              if (!hasFiles) return false;

              const videos = Array.from(event.dataTransfer.files).filter(file =>
                /video/i.test(file.type)
              );

              if (videos.length === 0) return false;

              event.preventDefault();

              const coordinates = view.posAtCoords({
                left: event.clientX,
                top: event.clientY
              });

              videos.forEach(video => {
                const reader = new FileReader();

                reader.onload = readerEvent => {
                  const node = schema.nodes.video.create({
                    src: readerEvent.target?.result
                  });

                  if (coordinates && typeof coordinates.pos === 'number') {
                    const transaction = tr.insert(coordinates?.pos, node);

                    dispatch(transaction);
                  }
                };

                reader.readAsDataURL(video);
              });

              return true;
            }
          }
        }
      })
    ];
  }
});
