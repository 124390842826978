import {
  Building,
  CheckCircle,
  Globe,
  Star,
  CreditCard,
  FileText,
  Contact,
  Mail,
  Phone,
  Hash,
  Check,
  Pencil,
  Clock,
  CheckCircle2,
  Plus,
  XCircle,
  Trash
} from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import Trainer from '../../../models/Trainer';
import DateCard from '../../../components/entity/DateCard';
import {
  Badge,
  TextButton,
  Input,
  Modal,
  FilledButton,
  SoftButton,
  DropdownInput,
  StackedList,
  Field,
  Popup,
  Alert
} from 'core';
import CoreEntityTypes from '../../../models/CoreEntityType';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { UserProfileContext } from '../../../App';
import News from './tabs/News';
import ComplianceTab from './tabs/Compliance';
import Locations from './tabs/Locations';
import Bookings from './tabs/Bookings';
import CourseOptions from './tabs/CourseOptions';
import AgreedRates from './tabs/AgreedRates';
import BookingSpaces from './tabs/BookingSpaces';
import CoursesTab from './tabs/Courses';
import { SiXero } from '@icons-pack/react-simple-icons';
import LinkToXero from '../../../components/popups/trainers/LinkToXero';

const TrainerDetails = () => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [showAddContactType, setShowAddContactType] = useState(false);
  const [newContactType, setNewContactType] = useState({
    type: '',
    email: '',
    phone: ''
  });
  const [contactSearchTerm, setContactSearchTerm] = useState('');
  const [termsPopupOpen, setTermsPopupOpen] = useState(false);
  const [termOptions, setTermOptions] = useState([]);
  const [terms, setTerms] = useState({ days: '', type: '' });
  const [linkToXeroOpen, setLinkToXeroOpen] = useState(false);
  const { id } = useParams();

  const { userProfile: currentUser } = useContext(UserProfileContext);
  //Test user permissions for view access
  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Trainer,
      PERMISSIONS[EntityTypeEnum.Trainer].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view trainers.'
        style='danger'
      />
    );
  }

  const userCanEdit = currentUser.hasPermission(
    EntityTypeEnum.Trainer,
    PERMISSIONS[EntityTypeEnum.Trainer].EDIT
  );

  const userCanDelete = currentUser.hasPermission(
    EntityTypeEnum.Trainer,
    PERMISSIONS[EntityTypeEnum.Trainer].DELETE
  );

  useEffect(() => {
    // Set the document title
    document.title = `Trainer #${id} | CRM | BOHSA LTD`;
    const fetchOptions = async () => {
      try {
        const options = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.TrainerStatus
        );
        const terms = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.CompanyTerms //EntityTypeEnum.TrainerTerms
        );
        const formattedOptions = options.map(option => ({
          label: option.type,
          value: option.id
        }));
        const formattedTerms = terms.map(option => ({
          label: option.type,
          value: option.id
        }));
        setStatusOptions(formattedOptions);
        setTermOptions(formattedTerms);
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchOptions();
  }, []);

  const handleAddContactType = async () => {
    try {
      const trainer = await Trainer.getById(id);
      trainer.contacts = [...trainer.contacts, newContactType];
      await trainer.update({ contacts: trainer.contacts });
      setShowAddContactType(false);
      setNewContactType({
        type: '',
        email: '',
        phone: ''
      });
    } catch (error) {
      console.error('Error updating contact type:', error);
    }
  };

  const handleDeleteContactType = async contactType => {
    try {
      const trainer = await Trainer.getById(id);
      trainer.contacts = trainer.contacts.filter(
        contact =>
          !(
            contact.type === contactType.type &&
            contact.email === contactType.email &&
            contact.phone === contactType.phone
          )
      );
      await trainer.update({ contacts: trainer.contacts });
    } catch (error) {
      console.error('Error deleting contact type:', error);
    }
  };

  const handleTermsEdit = async () => {
    try {
      const trainer = await Trainer.getById(id);
      trainer.terms = {
        days: terms.days,
        type: { id: terms.type }
      };
      await trainer.update();
      setTermsPopupOpen(false);
    } catch (error) {
      console.error('Error updating terms:', error);
    }
  };

  const infoItems = [
    {
      icon: Building,
      label: 'Name',
      field: 'name',
      dataType: 'text',
      isEditable: true
    },
    {
      icon: CheckCircle,
      label: 'Status',
      displayField: 'status.name',
      editField: 'status.id',
      dataType: 'select',
      isEditable: true,
      options: statusOptions
    },

    {
      icon: CheckCircle,
      label: 'Approved',
      field: 'approved',
      dataType: 'select',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ],
      isEditable: true
    },
    {
      icon: Hash,
      label: 'Capsule ID',
      field: 'capsuleId',
      dataType: 'text',
      isEditable: true
    },
    {
      icon: Hash,
      label: 'Account Number',
      field: 'accountNumber',
      dataType: 'text',
      isEditable: true
    },
    {
      icon: SiXero,
      label: 'Xero ID',
      field: 'xeroId',
      dataType: 'text',
      isEditable: false,
      extension: value =>
        !value && (
          <SoftButton
            colour='base'
            size='xs'
            onClick={() => setLinkToXeroOpen(true)}
          >
            Link to Xero
          </SoftButton>
        )
    },
    {
      icon: Star,
      label: 'Rating',
      field: 'rating',
      dataType: 'rating',
      isEditable: true
    }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data }) => (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
          <DateCard date={data.createdDate} label='Created' />
          <DateCard date={data.updatedDate} label='Updated' />
        </div>
      )
    },
    {
      title: 'Availability',
      content: ({ data }) => (
        <div className='grid grid-cols-2 gap-4 text-sm'>
          <div className='space-y-2'>
            <div className='flex flex-row items-center'>
              {data.options.nvq ? (
                <CheckCircle2 size={18} className='mr-2 text-success-500' />
              ) : (
                <XCircle size={18} className='mr-2 text-danger-500' />
              )}
              <span className='text-medium'>NVQ</span>
            </div>
            <div className='flex flex-row items-center'>
              {data.options.onsite ? (
                <CheckCircle2 size={18} className='mr-2 text-success-500' />
              ) : (
                <XCircle size={18} className='mr-2 text-danger-500' />
              )}
              <span className='text-medium'>Onsite</span>
            </div>
            <div className='flex flex-row items-center'>
              {data.options.remote ? (
                <CheckCircle2 size={18} className='mr-2 text-success-500' />
              ) : (
                <XCircle size={18} className='mr-2 text-danger-500' />
              )}
              <span className='text-medium'>Remote</span>
            </div>
          </div>
          <div className='space-y-2'>
            <div className='flex flex-row items-center'>
              {data.options.classroom ? (
                <CheckCircle2 size={18} className='mr-2 text-success-500' />
              ) : (
                <XCircle size={18} className='mr-2 text-danger-500' />
              )}
              <span className='text-medium'>Classroom</span>
            </div>
            <div className='flex flex-row items-center'>
              {data.options.elearning ? (
                <CheckCircle2 size={18} className='mr-2 text-success-500' />
              ) : (
                <XCircle size={18} className='mr-2 text-danger-500' />
              )}
              <span className='text-medium'>E-learning</span>
            </div>
            <div className='flex flex-row items-center'>
              <Badge colour='base' size='xs'>
                {data.options.venue_count}
              </Badge>
              <span className='text-medium ml-2'>
                Venue{data.options.venue_count > 1 ? 's' : ''}
              </span>
            </div>
          </div>
        </div>
      )
    },
    {
      title: 'Contact Information',
      content: ({ data }) => (
        <div>
          <div className='flex gap-x-1 mb-2'>
            <Input
              size='sm'
              placeholder='Search contacts...'
              value={contactSearchTerm}
              onChange={e => {
                setContactSearchTerm(e.target.value);
              }}
            />
            <SoftButton size='sm' onClick={() => setShowAddContactType(true)}>
              <Plus size={18} />
            </SoftButton>
          </div>
          <StackedList
            style='none'
            items={data.contacts
              .filter(contact => {
                const searchTerm = new RegExp(contactSearchTerm, 'i');
                return (
                  searchTerm.test(contact.type) ||
                  searchTerm.test(contact.email) ||
                  searchTerm.test(contact.phone)
                );
              })
              .map(contact => ({
                title: contact.type,
                description: (
                  <div className='flex gap-x-4'>
                    <div className='flex items-center gap-x-1 min-w-32'>
                      <Phone size={16} />
                      <span>{contact.phone ?? 'n/a'}</span>
                    </div>
                    <div className='flex items-center gap-x-1'>
                      <Mail size={16} />
                      <span>{contact.email ?? 'n/a'}</span>
                    </div>
                  </div>
                ),
                action: (
                  <TextButton
                    size='sm'
                    colour='danger'
                    onClick={() => handleDeleteContactType(contact)}
                  >
                    <Trash size={16} />
                  </TextButton>
                )
              }))}
          />
          <Modal
            isOpen={showAddContactType}
            onClose={() => setShowAddContactType(false)}
            title='Add Contact Type'
            actions={[
              {
                label: 'Add',
                onClick: handleAddContactType,
                style: 'primary'
              },
              {
                label: 'Cancel',
                onClick: () => setShowAddContactType(false),
                style: 'base'
              }
            ]}
          >
            <div className='space-y-4'>
              <DropdownInput
                label='Type'
                value={newContactType.type}
                onChange={e =>
                  setNewContactType({ ...newContactType, type: e.target.value })
                }
                options={[
                  { label: 'General', value: 'General' },
                  { label: 'Accounts', value: 'Accounts' },
                  { label: 'Support', value: 'Support' },
                  { label: 'Bookings', value: 'Bookings' },
                  { label: 'Other', value: 'Other' }
                ]}
              />
              <Input
                label='Email'
                value={newContactType.email}
                onChange={e =>
                  setNewContactType({
                    ...newContactType,
                    email: e.target.value
                  })
                }
                placeholder='Enter email...'
              />
              <Input
                label='Phone'
                value={newContactType.phone}
                onChange={e =>
                  setNewContactType({
                    ...newContactType,
                    phone: e.target.value
                  })
                }
                placeholder='Enter phone number...'
              />
            </div>
          </Modal>
        </div>
      )
    },
    {
      title: 'Financial Details',
      content: ({ data, onUpdate }) => (
        <div>
          <Field
            Icon={Clock}
            label='Terms'
            value={
              data.terms != null
                ? `${data.terms.days} ${data.terms.type.type}`
                : 'N/A'
            }
            editField='terms'
            isEditable={false}
            onUpdate={onUpdate}
            extension={() => (
              <TextButton size='sm' onClick={() => setTermsPopupOpen(true)}>
                <Pencil size={16} />
              </TextButton>
            )}
          />
          <Field
            Icon={CreditCard}
            label='Credit Limit'
            value={data.creditLimit}
            editField='creditLimit'
            isEditable={true}
            onUpdate={onUpdate}
            dataType='number'
          />
          <Field
            Icon={CheckCircle}
            label='Terms Agreed'
            value={data.termsAgreed ? 'Yes' : 'No'}
            editField='termsAgreed'
            isEditable={true}
            onUpdate={onUpdate}
            dataType='boolean'
          />
        </div>
      )
    }
  ];

  const additionalTabs = [
    // TBD: Do we plan to extend the news feature to include trainers or not
    // {
    //   label: 'News',
    //   path: 'news',
    //   content: <News trainerId={id} />
    // },
    {
      label: 'Bookings',
      path: 'bookings',
      content: <Bookings trainerId={id} />
    },
    {
      label: 'Spaces',
      path: 'spaces',
      content: <BookingSpaces trainerId={id} />
    },
    {
      label: 'Compliance',
      path: 'compliance',
      content: <ComplianceTab trainerId={id} />
    },
    {
      label: 'Agreed Rates',
      path: 'rates',
      content: <AgreedRates trainerId={id} />
    },
    {
      label: 'Locations',
      path: 'locations',
      content: <Locations trainerId={id} />
    },
    {
      label: 'Courses',
      path: 'courses',
      content: <CoursesTab trainerId={id} />
    },
    {
      label: 'Options',
      path: 'options',
      content: <CourseOptions trainerId={id} />
    }
  ];

  return (
    <>
      <Entity
        currentUser={currentUser}
        entityType={EntityTypeEnum.Trainer}
        model={Trainer}
        infoItems={infoItems}
        accordionItems={accordionItems}
        additionalTabs={additionalTabs}
      />
      <Popup
        isOpen={termsPopupOpen}
        onClose={() => setTermsPopupOpen(false)}
        title='Edit Terms'
        size='sm'
      >
        <div className='space-y-2'>
          <Input
            type='number'
            placeholder='Days'
            value={terms.days}
            onChange={e => setTerms({ ...terms, days: e.target.value })}
          />
          <DropdownInput
            options={termOptions}
            value={terms.type}
            onChange={e => setTerms({ ...terms, type: e.target.value })}
            placeholder='Select Type'
            label='Type'
          />
          <div className='flex justify-end'>
            <FilledButton size='md' colour='primary' onClick={handleTermsEdit}>
              Save
            </FilledButton>
          </div>
        </div>
      </Popup>
      <LinkToXero
        isOpen={linkToXeroOpen}
        onClose={() => setLinkToXeroOpen(false)}
        trainerId={id}
      />
    </>
  );
};

export default TrainerDetails;
