import React, { useState, useEffect } from 'react';
import { Popup } from 'core';
import { Link } from 'react-router-dom';
import CoreEntity from '../../../models/CoreEntity';

const FileConnections = ({ document, onClose }) => {
  const [entityTypes, setEntityTypes] = useState({});

  useEffect(() => {
    const loadEntityTypes = async () => {
      const types = {};
      if (document.connections) {
        for (const [entityType] of Object.entries(document.connections)) {
          const entity = await CoreEntity.getById(entityType);
          if (entity) {
            types[entityType] = entity;
          }
        }
      }
      setEntityTypes(types);
    };

    loadEntityTypes();
  }, [document]);

  return (
    <Popup
      isOpen={true}
      title='Document Connections'
      onClose={onClose}
      size='sm'
    >
      {document.connections &&
      Object.entries(document.connections).length > 0 ? (
        Object.entries(document.connections).map(([entityType, entityIds]) => (
          <div
            key={entityType}
            className='p-4 mb-4 bg-white rounded-lg shadow-sm border border-neutral-200'
          >
            <h3 className='text-lg font-semibold text-neutral-900 mb-3'>
              {entityTypes[entityType]?.displayName || entityType}
            </h3>
            <ul className='space-y-2.5'>
              {entityIds.map(id => (
                <li key={id} className='flex items-center'>
                  <Link
                    to={`/${
                      entityTypes[entityType]?.tableName.replaceAll('_', '-') ||
                      entityType
                    }/${id}`}
                    className='text-sm text-primary-600 hover:text-primary-800 hover:underline transition-colors duration-200 ease-in-out'
                  >
                    #{id}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))
      ) : (
        <div className='text-center py-4 text-base-500'>
          No connections found for this document
        </div>
      )}
    </Popup>
  );
};

export default FileConnections;
